import { useContext } from 'react';
import FilterButton from './Fields/FilterButton';
import Context from '../../../contexts/FilterContext';
import Icon from '../../../assets/Icons/search.png';

const FSearch = () => {
    const { SearchHandler } = useContext(Context);

    return (
        <FilterButton Icon={Icon} EventHandler={SearchHandler} Title="Lancer la recherche" Color="#009EE2" />
    );
}

export default FSearch;
