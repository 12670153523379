const TrimestreToggler = () => {
  document.onclick = (e) => {
    if (e.target.classList.contains("trimestres")) {
      if (e.target.classList.contains("active") === false) {
        const trimestres = document.querySelectorAll(".production-td");

        for (let i = 0; i < e.target.parentElement.children.length; i++) {
          if (e.target.parentElement.children[i].classList.contains("active")) {
            e.target.parentElement.children[i].classList.remove("active");
          }
        }

        let dataset;
        if (e.target.classList.contains('trimestres-one')) {
          dataset = 'data-t1';
        } else if (e.target.classList.contains('trimestres-two')) {
          dataset = 'data-t2';
        } else if (e.target.classList.contains('trimestres-three')) {
          dataset = 'data-t3';
        } else if (e.target.classList.contains('trimestres-four')) {
          dataset = 'data-t4';
        }

        trimestres.forEach(trimestre => {
          trimestre.textContent = trimestre.getAttribute(dataset) || '-';
        });

        e.target.classList.add("active");
      }
    }
  };
};

export { TrimestreToggler };
