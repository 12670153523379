const Regions = [
  { value: "Casablanca-Settat", label: "Casablanca-Settat" },
  { value: "Rabat-Salé-Kénitra", label: "Rabat-Salé-Kénitra" },
  { value: "Marrakech-Safi", label: "Marrakech-Safi" },
  { value: "Souss-Massa", label: "Souss-Massa" },
  { value: "Béni Mellal-Khénifra", label: "Béni Mellal-Khénifra" },
  { value: "L'oriental", label: "L'oriental" },
  { value: "Tanger-Tetouan-Al Hoceima", label: "Tanger-Tetouan-Al Hoceima" },
  { value: "Drâa-Tafilalet", label: "Drâa-Tafilalet" },
  { value: "Fès-Meknès", label: "Fès-Meknès" },
  { value: "Guelmim-Oued Noun", label: "Guelmim-Oued Noun" },
  { value: "Laâyoune-Sakia El Hamra", label: "Laâyoune-Sakia El Hamra" },
  { value: "Dakhla-Oued Ed-Dahab", label: "Dakhla-Oued Ed-Dahab" },
];

export { Regions };
