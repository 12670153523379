import { Tooltip } from 'antd'

const FilterButton = ({ EventHandler, Icon, Title, Color }) => {
    return (
        <section className="filter-section">
            <Tooltip trigger={['hover']} title={Title} placement="topLeft">
                <span className="filter-icon search" onClick={EventHandler} style={{ backgroundColor: Color }}>
                    <img src={Icon} alt='address' />
                </span>
            </Tooltip>
        </section>
    );
}

export default FilterButton;
