import { useEffect, useRef, useContext } from "react";
import Leaflet from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import types from "../../Utilities/Types";
import Popup from '../../HTMLComponents/Popup';
import { LatLng } from '../../Utilities/States';
import RayonContext from '../../contexts/RayonContext';
import { getRegion } from "../../Utilities/GetRegion";
import { geojsonFeature } from '../../Utilities/RegionGeo';
import GetBranchesMarkup from '../../Utilities/GetBranchesMarkup';

const MapTwo = ({ markers }) => {
    const mapContainer = useRef();
    const { type, Rayon, RayonHandler } = useContext(RayonContext);

    useEffect(() => {
        const zoom = Rayon.active ? Rayon.zoomLevel : 6.48;
        const latLng = Rayon.active ? [Rayon.coordination.lat, Rayon.coordination.lng] : LatLng;
        let map = Leaflet.map(mapContainer.current, {
            maxZoom: 20,
            scrollWheelZoom: true,
            zoomControl: false,
            doubleClickZoom: false
        }).setView(latLng, zoom);

        Leaflet.control.zoom({
            position: 'topright',
        }).addTo(map);

        const markerGroup = (cluster) => {
            return Leaflet.divIcon({
                html: `<span>${cluster.getChildCount()}</span>`,
                className: 'marker-cluster',
                iconSize: Leaflet.point(38, 38),
            });
        };

        Leaflet.tileLayer('https://2.base.maps.cit.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png?app_id=XRBnYEjl04KYt531CLBD&app_code=SziRf158ottC-SlbLhg-Mg', {
            attribution: '&copy; <a href="https://www.acaps.ma">ACAPS</a>',
            maxZoom: 20,
        }).addTo(map);

        const markerClusterGroup = Leaflet.markerClusterGroup({
            iconCreateFunction: (cluster) => markerGroup(cluster)
        });

        const markerIcon = Leaflet.divIcon({
            html: '<svg height="38" viewBox="0 0 48 48" width="38"><path d="M24 4c-7.73 0-14 6.27-14 14 0 10.5 14 26 14 26s14-15.5 14-26c0-7.73-6.27-14-14-14zm0 19c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"/></svg>',
            className: 'marker-icon',
            popupAnchor: [0, -34],
        });

        const markerMarkupClickHandler = async (e, marker, type) => {
            e.target.getElement().classList.add('selected-marker');

            if (type === types.Intern || type === types.Company) {
                const markerPopup = document.getElementById(marker.code);
                markerPopup.innerHTML = await GetBranchesMarkup(marker);
            }
        }

        markers.forEach((marker) => {
            const markerMarkup = Leaflet.marker([marker.latitude, marker.longitude], { icon: markerIcon });
            markerMarkup.bindPopup(Popup(marker, type));
            markerMarkup.on('click', async (e) => await markerMarkupClickHandler(e, marker, type));

            markerClusterGroup.addLayer(markerMarkup);
        });

        Leaflet.geoJSON(geojsonFeature, {
            onEachFeature: (feature, layer) => {
                layer.on('click', async () => {
                    const markup = await getRegion(feature.region);
                    layer.bindPopup(markup).openPopup();
                });
            }
        }).addTo(map);

        if (Rayon.active) {
            Leaflet.circle(Rayon.coordination, {
                color: 'red',
                fillColor: 'red',
                fillOpacity: 0.2,
                radius: Rayon.rayon * 1000
            }).addTo(map);
        }

        map.on('dblclick', (e) => {
            RayonHandler({ ...Rayon, coordination: e.latlng, active: true });
        });

        map.addLayer(markerClusterGroup);

        return () => {
            map.remove();
        };
    }, [markers, Rayon]);

    return (
        <div ref={mapContainer} className="map-container"></div>
    );
};

export default MapTwo;