import GetCookie from "./Utilities/GetCookie";

// const urlParams = new URLSearchParams(window.location.search);
// const token = urlParams.get(process.env.REACT_APP_TOKEN);

const token = GetCookie(process.env.REACT_APP_TOKEN);

const headers = {
  "Content-Type": "application/json; charset=utf-8",
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,HEAD,PUT,PATCH,POST,DELETE,OPTION",
};

if (token) {
  headers.Authorization = token;
} else {
  delete headers.Authorization;
}

export default headers;
