import { useContext, useEffect, useState } from 'react';
import axios from 'axios';

import Context from '../../../contexts/FilterContext';
import FilterSelect from './Fields/FilterSelect';
import Icon from '../../../assets/Icons/department.png';
import exceptionHandler from "../../../Utilities/ExceptionHandler";

const FEar = () => {
    const { Filter, FilterHandler } = useContext(Context);
    const [ears, setEars] = useState([]);

    useEffect(() => {
        axios
            .get("/intermediates/ears")
            .then((response) => response.data)
            .then((response) => {
                const options = response.map(x => {
                    return {
                        label: x,
                        value: x
                    };
                });

                setEars(options);
            }).catch((exception) => exceptionHandler(exception));
    }, []);

    const Props = {
        Icon: Icon,
        Title: 'EAR',
        placeholder: "Recherche par EAR...",
        FieldOptions: ears,
        FieldToggler: Filter.earToggler,
        ToggleFilter: () => {
            FilterHandler({
                ...Filter,
                ear: '',
                earToggler: !Filter.earToggler
            });
        },
        FilterHandler: (value) => {
            FilterHandler({
                ...Filter,
                ear: value
            });
        }
    }

    return (
        <FilterSelect {...Props} />
    )
}

export default FEar