import { useContext } from 'react';

import Context from '../../../contexts/FilterContext';
import RayonContext from "../../../contexts/RayonContext";
import FilterInput from './Fields/FilterInput';
import Icon from '../../../assets/Icons/rayon.png';

const FRayon = () => {
    const { Filter, FilterHandler } = useContext(Context);
    const { Rayon, RayonHandler } = useContext(RayonContext);

    const Props = {
        Rayon,
        RayonHandler,
        Icon: Icon,
        Title: 'trouver un intermédiaire dans un périmètre de .. (en Km)',
        placeholder: "trouver un intermédiaire dans un périmètre de .. (en Km)",
        Filter: Filter,
        FieldToggler: Filter.rayonToggler,
        ToggleFilter: () => {
            FilterHandler({
                ...Filter,
                rayon: 1,
                rayonToggler: !Filter.rayonToggler
            });
        },
        FilterHandler: (e) => {
            FilterHandler({
                ...Filter,
                rayon: Number(e.target.value)
            });
        }
    }

    return (
        <FilterInput {...Props} />
    )
}

export default FRayon;