import { useContext } from 'react';
import FilterButton from './Fields/FilterButton';
import Context from '../../../contexts/FilterContext';
import Icon from '../../../assets/Icons/reset.png';

const FReset = () => {
    const { ResetHandler } = useContext(Context);

    return (
        <FilterButton Icon={Icon} EventHandler={ResetHandler} Title="Rafraîchir" Color="#B4A78E" />
    );
};

export default FReset;
