import axios from "axios";
import regionPopupMarkup from "../HTMLComponents/RegionPopup";

const getRegion = async (region) => {
  let popupMarkup;

  await axios
    .get("/regions", {
      params: { region },
    })
    .then((response) => response.data)
    .then((response) => {
      popupMarkup = regionPopupMarkup(response, true);
    })
    .catch((exception) => {
      popupMarkup = regionPopupMarkup(region, false);
    });

  return popupMarkup;
};

export { getRegion };
