import { useContext } from 'react';

import { Regions } from '../Regions';
import Context from '../../../contexts/FilterContext';
import FilterSelect from './Fields/FilterSelect';
import Icon from '../../../assets/Icons/map.png';

const FRegion = () => {
    const { Filter, FilterHandler } = useContext(Context);
    const Props = {
        Icon: Icon,
        Title: 'Région',
        placeholder: "Recherche par région...",
        FieldOptions: Regions,
        FieldToggler: Filter.regionToggler,
        ToggleFilter: () => {
            FilterHandler({
                ...Filter,
                region: '',
                regionToggler: !Filter.regionToggler
            });
        },
        FilterHandler: (value) => {
            FilterHandler({
                ...Filter,
                region: value
            });
        }
    }

    return (
        <FilterSelect {...Props} />
    );
}

export default FRegion;