import { useContext, useState, useEffect } from 'react';
import axios from 'axios';

import Context from '../../../contexts/FilterContext';
import FilterSelect from './Fields/FilterSelect';
import Icon from '../../../assets/Icons/address.png';
import exceptionHandler from "../../../Utilities/ExceptionHandler";

const FAddress = () => {
    const { Filter, FilterHandler } = useContext(Context);
    const [addresses, setAddresses] = useState([]);

    useEffect(() => {
        axios
            .get("/intermediates/addresses")
            .then((response) => response.data)
            .then((response) => {
                const options = response.map(x => {
                    return {
                        label: x.replace(/"/g, '').replace(/\\n/g, '').replace(/\\r/g, '').replace(/\\/g, ''),
                        value: x
                    }
                });

                setAddresses(options);
            }).catch((exception) => exceptionHandler(exception));
    }, []);

    const Props = {
        Icon: Icon,
        Title: 'Adresse',
        placeholder: "Recherche par adresse...",
        FieldOptions: addresses,
        FieldToggler: Filter.addressToggler,
        ToggleFilter: () => {
            FilterHandler({
                ...Filter,
                address: '',
                addressToggler: !Filter.addressToggler
            });
        },
        FilterHandler: (value) => {
            FilterHandler({
                ...Filter,
                address: value
            });
        }
    };

    return (
        <FilterSelect {...Props} />
    );
};

export default FAddress;