const geojsonFeature = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      region: "Tanger-Tetouan-Al Hoceima",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-6.245, 35],
              [-6.17, 35.195],
              [-6.15, 35.200000000000003],
              [-6.06, 35.439999999999998],
              [-6.045, 35.465000000000003],
              [-6.03, 35.469999999999999],
              [-6.02, 35.494999999999997],
              [-5.975000000000001, 35.660000000000004],
              [-5.93, 35.789999999999999],
              [-5.91, 35.800000000000004],
              [-5.88, 35.795000000000002],
              [-5.845, 35.800000000000004],
              [-5.82, 35.789999999999999],
              [-5.81, 35.795000000000002],
              [-5.8, 35.785000000000004],
              [-5.805, 35.780000000000001],
              [-5.78, 35.774999999999999],
              [-5.765, 35.789999999999999],
              [-5.755, 35.789999999999999],
              [-5.75, 35.82],
              [-5.7, 35.829999999999998],
              [-5.69, 35.840000000000003],
              [-5.675, 35.829999999999998],
              [-5.67, 35.835000000000001],
              [-5.595, 35.829999999999998],
              [-5.575, 35.844999999999999],
              [-5.57, 35.840000000000003],
              [-5.565, 35.850000000000001],
              [-5.55, 35.844999999999999],
              [-5.535, 35.869999999999997],
              [-5.53, 35.865000000000002],
              [-5.485, 35.899999999999999],
              [-5.485, 35.910000000000004],
              [-5.47, 35.905000000000001],
              [-5.465, 35.914999999999999],
              [-5.45, 35.914999999999999],
              [-5.45, 35.905000000000001],
              [-5.44, 35.910000000000004],
              [-5.43, 35.905000000000001],
              [-5.41, 35.910000000000004],
              [-5.405, 35.920000000000002],
              [-5.395, 35.914999999999999],
              [-5.4, 35.910000000000004],
              [-5.38, 35.914999999999999],
              [-5.375, 35.884999999999998],
              [-5.345, 35.869999999999997],
              [-5.355, 35.835000000000001],
              [-5.34, 35.730000000000004],
              [-5.325, 35.685000000000002],
              [-5.315, 35.68],
              [-5.29, 35.689999999999998],
              [-5.275, 35.685000000000002],
              [-5.285, 35.664999999999999],
              [-5.265, 35.594999999999999],
              [-5.245, 35.555],
              [-5.225000000000001, 35.550000000000004],
              [-5.22, 35.539999999999999],
              [-5.175, 35.535000000000004],
              [-5.145, 35.494999999999997],
              [-5.13, 35.494999999999997],
              [-5.100000000000001, 35.469999999999999],
              [-5.06, 35.405000000000001],
              [-5.03, 35.399999999999999],
              [-5.015, 35.405000000000001],
              [-4.925, 35.314999999999998],
              [-4.865, 35.300000000000004],
              [-4.845, 35.274999999999999],
              [-4.815, 35.255000000000003],
              [-4.8, 35.255000000000003],
              [-4.78, 35.234999999999999],
              [-4.685, 35.204999999999998],
              [-4.65, 35.215000000000003],
              [-4.635, 35.195],
              [-4.62, 35.189999999999998],
              [-4.485, 35.175000000000004],
              [-4.455, 35.160000000000004],
              [-4.43, 35.155000000000001],
              [-4.4, 35.160000000000004],
              [-4.355, 35.145000000000003],
              [-4.335, 35.160000000000004],
              [-4.335, 35.155000000000001],
              [-4.32, 35.160000000000004],
              [-4.31, 35.170000000000002],
              [-4.3, 35.164999999999999],
              [-4.29, 35.170000000000002],
              [-4.29, 35.18],
              [-4.27, 35.18],
              [-4.255, 35.189999999999998],
              [-4.215, 35.185000000000002],
              [-4.19, 35.200000000000003],
              [-4.185, 35.195],
              [-4.135, 35.204999999999998],
              [-4.13, 35.200000000000003],
              [-4.065, 35.230000000000004],
              [-4.025, 35.240000000000002],
              [-4.01, 35.230000000000004],
              [-3.98, 35.234999999999999],
              [-3.94, 35.259999999999998],
              [-3.92, 35.259999999999998],
              [-3.915, 35.25],
              [-3.92, 35.25],
              [-3.925, 35.25],
              [-3.925, 35.244999999999997],
              [-3.925, 35.234999999999999],
              [-3.91, 35.215000000000003],
              [-3.85, 35.195],
              [-3.825, 35.200000000000003],
              [-3.805, 35.175000000000004],
              [-3.805, 35.164999999999999],
              [-3.815, 35.160000000000004],
              [-3.805, 35.149999999999999],
              [-3.815, 35.115000000000002],
              [-3.815, 35.009999999999998],
              [-3.805, 34.984999999999999],
              [-3.825, 34.935000000000002],
              [-3.82, 34.93],
              [-3.83, 34.920000000000002],
              [-3.82, 34.895000000000003],
              [-3.83, 34.869999999999997],
              [-3.9, 34.850000000000001],
              [-3.925, 34.850000000000001],
              [-3.93, 34.840000000000003],
              [-3.95, 34.835000000000001],
              [-4.035, 34.825000000000003],
              [-4.1, 34.765000000000001],
              [-4.145, 34.744999999999997],
              [-4.155, 34.759999999999998],
              [-4.17, 34.75],
              [-4.21, 34.765000000000001],
              [-4.25, 34.805],
              [-4.305, 34.759999999999998],
              [-4.295, 34.740000000000002],
              [-4.325, 34.715000000000003],
              [-4.345, 34.710000000000001],
              [-4.355, 34.715000000000003],
              [-4.38, 34.704999999999998],
              [-4.415, 34.710000000000001],
              [-4.435, 34.670000000000002],
              [-4.495, 34.689999999999998],
              [-4.505, 34.700000000000003],
              [-4.52, 34.695],
              [-4.54, 34.700000000000003],
              [-4.575, 34.685000000000002],
              [-4.59, 34.715000000000003],
              [-4.63, 34.725000000000001],
              [-4.73, 34.719999999999999],
              [-4.745, 34.734999999999999],
              [-4.76, 34.734999999999999],
              [-4.78, 34.75],
              [-4.79, 34.844999999999999],
              [-4.805, 34.835000000000001],
              [-4.865, 34.825000000000003],
              [-4.885, 34.835000000000001],
              [-4.91, 34.825000000000003],
              [-4.915, 34.814999999999998],
              [-4.95, 34.810000000000002],
              [-4.98, 34.789999999999999],
              [-4.985, 34.795000000000002],
              [-5.015, 34.789999999999999],
              [-5.02, 34.795000000000002],
              [-5.015, 34.800000000000004],
              [-5.025, 34.800000000000004],
              [-5.085, 34.774999999999999],
              [-5.09, 34.780000000000001],
              [-5.125, 34.765000000000001],
              [-5.145, 34.765000000000001],
              [-5.18, 34.740000000000002],
              [-5.185, 34.719999999999999],
              [-5.18, 34.695],
              [-5.19, 34.670000000000002],
              [-5.18, 34.649999999999999],
              [-5.18, 34.605000000000004],
              [-5.195, 34.594999999999999],
              [-5.18, 34.590000000000003],
              [-5.2, 34.560000000000002],
              [-5.26, 34.555],
              [-5.265, 34.535000000000004],
              [-5.255, 34.535000000000004],
              [-5.28, 34.515000000000001],
              [-5.315, 34.515000000000001],
              [-5.365, 34.535000000000004],
              [-5.39, 34.535000000000004],
              [-5.385, 34.524999999999999],
              [-5.395, 34.520000000000003],
              [-5.41, 34.535000000000004],
              [-5.43, 34.535000000000004],
              [-5.435, 34.560000000000002],
              [-5.43, 34.575000000000003],
              [-5.415, 34.585000000000001],
              [-5.42, 34.609999999999999],
              [-5.445, 34.625],
              [-5.455, 34.619999999999997],
              [-5.46, 34.630000000000003],
              [-5.49, 34.630000000000003],
              [-5.5, 34.645000000000003],
              [-5.53, 34.640000000000001],
              [-5.54, 34.670000000000002],
              [-5.55, 34.670000000000002],
              [-5.55, 34.695],
              [-5.575, 34.695],
              [-5.59, 34.719999999999999],
              [-5.605, 34.715000000000003],
              [-5.605, 34.725000000000001],
              [-5.61, 34.719999999999999],
              [-5.635, 34.725000000000001],
              [-5.63, 34.740000000000002],
              [-5.665, 34.734999999999999],
              [-5.68, 34.715000000000003],
              [-5.715, 34.704999999999998],
              [-5.71, 34.719999999999999],
              [-5.73, 34.730000000000004],
              [-5.745, 34.725000000000001],
              [-5.755, 34.734999999999999],
              [-5.775, 34.734999999999999],
              [-5.79, 34.744999999999997],
              [-5.795, 34.75],
              [-5.78, 34.780000000000001],
              [-5.79, 34.795000000000002],
              [-5.79, 34.850000000000001],
              [-5.775, 34.875],
              [-5.79, 34.890000000000001],
              [-5.79, 34.910000000000004],
              [-5.78, 34.93],
              [-5.835, 34.945],
              [-5.86, 34.935000000000002],
              [-5.92, 34.939999999999998],
              [-5.96, 34.969999999999999],
              [-6.005, 34.990000000000002],
              [-6.025, 34.975000000000001],
              [-6.055, 34.975000000000001],
              [-6.065, 34.990000000000002],
              [-6.06, 35],
              [-6.09, 35.015000000000001],
              [-6.11, 35.015000000000001],
              [-6.140000000000001, 34.994999999999997],
              [-6.165, 34.990000000000002],
              [-6.245, 35],
            ],
            [
              [-6.15, 35.200000000000003],
              [-6.145, 35.200000000000003],
              [-6.145, 35.204999999999998],
              [-6.15, 35.200000000000003],
            ],
          ],
          [
            [
              [-3.925, 35.244999999999997],
              [-3.92, 35.25],
              [-3.92, 35.244999999999997],
              [-3.925, 35.244999999999997],
            ],
          ],
          [
            [
              [-5.8, 35.785000000000004],
              [-5.8, 35.789999999999999],
              [-5.795, 35.789999999999999],
              [-5.8, 35.780000000000001],
              [-5.8, 35.785000000000004],
            ],
          ],
          [
            [
              [-5.505, 35.890000000000001],
              [-5.505, 35.895000000000003],
              [-5.5, 35.897500000000001],
              [-5.5, 35.895000000000003],
              [-5.505, 35.890000000000001],
            ],
          ],
          [
            [
              [-5.52, 35.875],
              [-5.525, 35.880000000000003],
              [-5.52, 35.880000000000003],
              [-5.52, 35.875],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      region: "Casablanca-Settat",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-9.0593229, 32.721006199999998],
              [-9.0575753, 32.721218200000003],
              [-9.0487378, 32.721921799999997],
              [-8.980193399999999, 32.674135100000001],
              [-9.0037, 32.655869899999999],
              [-9.0107363, 32.633576499999997],
              [-9.014972, 32.623662699999997],
              [-9.0174611, 32.616278000000001],
              [-9.0184053, 32.608618100000001],
              [-9.006880000000001, 32.609899900000002],
              [-8.9969432, 32.586056999999997],
              [-8.9946559, 32.580567899999998],
              [-8.971481600000001, 32.568441999999997],
              [-8.95032, 32.544319899999998],
              [-8.931265700000001, 32.529745900000002],
              [-8.932492999999999, 32.522668400000001],
              [-8.912975100000001, 32.513122199999998],
              [-8.9028729, 32.510480399999999],
              [-8.887942600000001, 32.502876499999999],
              [-8.878870300000001, 32.501280399999999],
              [-8.8701113, 32.501211499999997],
              [-8.8521217, 32.494243500000003],
              [-8.840455199999999, 32.494968999999998],
              [-8.8360837, 32.500129299999998],
              [-8.8258282, 32.499437200000003],
              [-8.8152028, 32.505284799999998],
              [-8.8030168, 32.5119908],
              [-8.7911722, 32.509356199999999],
              [-8.7792589, 32.513825699999998],
              [-8.765174099999999, 32.5075973],
              [-8.7541963, 32.508538299999998],
              [-8.7493935, 32.5135407],
              [-8.745763500000001, 32.517321299999999],
              [-8.7465438, 32.510998600000001],
              [-8.7472054, 32.505635699999999],
              [-8.743676799999999, 32.504776300000003],
              [-8.740501, 32.507523300000003],
              [-8.7352398, 32.507141500000003],
              [-8.7333438, 32.5073802],
              [-8.7252995, 32.513906800000001],
              [-8.7196149, 32.518518700000001],
              [-8.714348299999999, 32.522791099999999],
              [-8.6891055, 32.525331299999998],
              [-8.6630945, 32.513208900000002],
              [-8.6507349, 32.4996188],
              [-8.639572599999999, 32.491601299999999],
              [-8.6298241, 32.479375500000003],
              [-8.62247, 32.448859900000002],
              [-8.6138244, 32.426899300000002],
              [-8.61103, 32.419799900000001],
              [-8.5985, 32.390259899999997],
              [-8.576700000000001, 32.350989900000002],
              [-8.5482107, 32.332114500000003],
              [-8.557059799999999, 32.297300200000002],
              [-8.5558614, 32.297244399999997],
              [-8.5426231, 32.296629099999997],
              [-8.5330187, 32.3164844],
              [-8.517067000000001, 32.316694599999998],
              [-8.5067459, 32.3311536],
              [-8.480636199999999, 32.328644199999999],
              [-8.474157399999999, 32.337180799999999],
              [-8.4705554, 32.341926399999998],
              [-8.438695600000001, 32.347703199999998],
              [-8.4357945, 32.357861300000003],
              [-8.422816900000001, 32.360717999999999],
              [-8.403410600000001, 32.361243600000002],
              [-8.378322300000001, 32.3664019],
              [-8.366421900000001, 32.362903799999998],
              [-8.3632762, 32.371258900000001],
              [-8.35195, 32.386299899999997],
              [-8.33329, 32.402219899999999],
              [-8.31517, 32.418599899999997],
              [-8.2927, 32.429879900000003],
              [-8.2880346, 32.431272300000003],
              [-8.26586, 32.437889900000002],
              [-8.243107200000001, 32.446934900000002],
              [-8.22639, 32.453579900000001],
              [-8.194741199999999, 32.466295199999998],
              [-8.171798600000001, 32.476867300000002],
              [-8.1687786, 32.478684000000001],
              [-8.1516971, 32.488958599999997],
              [-8.136654099999999, 32.4904358],
              [-8.117760000000001, 32.502089900000001],
              [-8.09132, 32.528009900000001],
              [-8.0799877, 32.543582299999997],
              [-8.075700400000001, 32.551403499999999],
              [-8.0692889, 32.558211100000001],
              [-8.0646369, 32.562443000000002],
              [-8.05541, 32.572669900000001],
              [-8.055008000000001, 32.5789787],
              [-8.0548585, 32.5813244],
              [-8.054180000000001, 32.591969900000002],
              [-8.059519999999999, 32.6113499],
              [-8.06978, 32.633539900000002],
              [-8.072232700000001, 32.646366299999997],
              [-8.074009999999999, 32.655659900000003],
              [-8.07441, 32.676819899999998],
              [-8.06664, 32.690989899999998],
              [-8.0594, 32.706539900000003],
              [-8.05376, 32.727619900000001],
              [-8.049239999999999, 32.746429900000003],
              [-8.04744, 32.768009900000003],
              [-8.0425652, 32.7785382],
              [-8.036849999999999, 32.7908799],
              [-8.0181, 32.802149900000003],
              [-7.9960669, 32.814245100000001],
              [-7.9957738, 32.814011200000003],
              [-7.9954396, 32.813996000000003],
              [-7.9948603, 32.813770599999998],
              [-7.9941629, 32.813364800000002],
              [-7.993369, 32.813022199999999],
              [-7.9926609, 32.812733600000001],
              [-7.9921781, 32.812580400000002],
              [-7.9917704, 32.812255700000001],
              [-7.9912017, 32.8117327],
              [-7.9905167, 32.810929299999998],
              [-7.9900661, 32.810180899999999],
              [-7.9898193, 32.8094775],
              [-7.9897748, 32.8090817],
              [-7.9897426, 32.808630800000003],
              [-7.9897212, 32.807783200000003],
              [-7.9898714, 32.807043700000001],
              [-7.9899036, 32.806475499999998],
              [-7.9899357, 32.805934600000001],
              [-7.9899787, 32.805140999999999],
              [-7.9900645, 32.804798300000002],
              [-7.990322, 32.8044917],
              [-7.9905151, 32.8040679],
              [-7.9908262, 32.803535799999999],
              [-7.9911803, 32.803039800000001],
              [-7.9914592, 32.802733199999999],
              [-7.9917382, 32.802435600000003],
              [-7.9918777, 32.802201099999998],
              [-7.9921244, 32.8018134],
              [-7.9923175, 32.801335399999999],
              [-7.9926931, 32.801019799999999],
              [-7.9927789, 32.800568900000002],
              [-7.9927682, 32.800181100000003],
              [-7.9927789, 32.799766200000001],
              [-7.9926931, 32.799288300000001],
              [-7.9926394, 32.798711099999998],
              [-7.9925321, 32.7982151],
              [-7.9922854, 32.797755100000003],
              [-7.9920295, 32.797402499999997],
              [-7.9916845, 32.796835199999997],
              [-7.9913198, 32.796393299999998],
              [-7.9910086, 32.796077699999998],
              [-7.9905687, 32.795825100000002],
              [-7.9899572, 32.795509500000001],
              [-7.9892829, 32.795048600000001],
              [-7.9887448, 32.794959300000002],
              [-7.9880383, 32.794922399999997],
              [-7.9872857, 32.794968400000002],
              [-7.9866651, 32.795129799999998],
              [-7.9857531, 32.795643900000002],
              [-7.9848074, 32.796312100000002],
              [-7.9840456, 32.796772099999998],
              [-7.9834464, 32.797077799999997],
              [-7.9827903, 32.797223000000002],
              [-7.9823397, 32.797277100000002],
              [-7.9816853, 32.797168800000001],
              [-7.9810753, 32.796987600000001],
              [-7.9802798, 32.796636800000002],
              [-7.979797, 32.796321200000001],
              [-7.9790567, 32.795969399999997],
              [-7.9785095, 32.795563600000001],
              [-7.9782966, 32.795192900000004],
              [-7.977964, 32.794669800000001],
              [-7.9778674, 32.794453400000002],
              [-7.977898, 32.7940665],
              [-7.9777692, 32.793741799999999],
              [-7.9777478, 32.793182600000002],
              [-7.9778122, 32.792677500000003],
              [-7.9779211, 32.792207699999999],
              [-7.9780375, 32.791910799999997],
              [-7.9783808, 32.79054],
              [-7.9785632, 32.789899599999998],
              [-7.9786275, 32.789412599999999],
              [-7.9785417, 32.788709099999998],
              [-7.9784559, 32.788267099999999],
              [-7.9783395, 32.7879054],
              [-7.9780911, 32.787301999999997],
              [-7.9777371, 32.786544399999997],
              [-7.9775885, 32.786444299999999],
              [-7.9772328, 32.785822799999998],
              [-7.9766427, 32.785326699999999],
              [-7.9762457, 32.784956899999997],
              [-7.975248, 32.784469799999997],
              [-7.9741858, 32.784154100000002],
              [-7.973533, 32.784162299999998],
              [-7.9728554, 32.784199200000003],
              [-7.9720508, 32.784307499999997],
              [-7.9711281, 32.784370600000003],
              [-7.9701319, 32.784306600000001],
              [-7.9690912, 32.7841983],
              [-7.9679416, 32.783946700000001],
              [-7.9673837, 32.7838384],
              [-7.9661837, 32.783666199999999],
              [-7.9650341, 32.783369399999998],
              [-7.9641238, 32.783097900000001],
              [-7.9633728, 32.782710000000002],
              [-7.9627489, 32.782440299999998],
              [-7.9622339, 32.782007399999998],
              [-7.9618155, 32.781655600000001],
              [-7.9615365, 32.781024100000003],
              [-7.9613005, 32.780645300000003],
              [-7.9613541, 32.780167300000002],
              [-7.9614078, 32.779571900000001],
              [-7.9614829, 32.779129900000001],
              [-7.9617618, 32.777821899999999],
              [-7.9620408, 32.777082299999996],
              [-7.9622232, 32.776360599999997],
              [-7.9623304, 32.775900499999999],
              [-7.9626523, 32.773879899999997],
              [-7.9627167, 32.772923599999999],
              [-7.9627703, 32.772247100000001],
              [-7.9628562, 32.771056199999997],
              [-7.9628454, 32.769585800000002],
              [-7.9627811, 32.768836999999998],
              [-7.9629205, 32.768214499999999],
              [-7.962611, 32.767690399999999],
              [-7.9625879, 32.767158999999999],
              [-7.9626738, 32.766608599999998],
              [-7.9627274, 32.765634300000002],
              [-7.9626952, 32.764885499999998],
              [-7.962427, 32.764271999999998],
              [-7.9619227, 32.7636495],
              [-7.9616438, 32.763090200000001],
              [-7.961279, 32.762332299999997],
              [-7.9609035, 32.761827099999998],
              [-7.9605082, 32.761293899999998],
              [-7.9602169, 32.760545899999997],
              [-7.9598752, 32.760211200000001],
              [-7.9594567, 32.759787099999997],
              [-7.9590903, 32.759354999999999],
              [-7.9587685, 32.759075299999999],
              [-7.9585002, 32.7586783],
              [-7.9579745, 32.757703800000002],
              [-7.9578029, 32.757198600000002],
              [-7.9577063, 32.756576000000003],
              [-7.9575776, 32.756160999999999],
              [-7.957245, 32.755294800000001],
              [-7.9565261, 32.754482699999997],
              [-7.9558948, 32.753823099999998],
              [-7.9550456, 32.753102200000001],
              [-7.9545306, 32.752623999999997],
              [-7.9532002, 32.751469],
              [-7.9521488, 32.750692999999998],
              [-7.9511403, 32.7500614],
              [-7.9505931, 32.749411700000003],
              [-7.9495326, 32.7484453],
              [-7.9487708, 32.747660199999999],
              [-7.9480933, 32.7471107],
              [-7.9473423, 32.746758800000002],
              [-7.94672, 32.746451999999998],
              [-7.9457973, 32.745558600000003],
              [-7.9450785, 32.744945000000001],
              [-7.9447153, 32.744483899999999],
              [-7.9446171, 32.744141900000002],
              [-7.9446493, 32.7431853],
              [-7.9446708, 32.742030200000002],
              [-7.9446171, 32.7412451],
              [-7.9445635, 32.740595399999997],
              [-7.9443704, 32.740080900000002],
              [-7.9439734, 32.739656799999999],
              [-7.9433726, 32.739214599999997],
              [-7.9424408, 32.738934],
              [-7.9418491, 32.739016100000001],
              [-7.9413572, 32.739015199999997],
              [-7.9398337, 32.738609099999998],
              [-7.9382029, 32.738148799999998],
              [-7.9369889, 32.737725599999997],
              [-7.9357245, 32.737038699999999],
              [-7.9345015, 32.736425099999998],
              [-7.9334704, 32.7355236],
              [-7.9328911, 32.734846699999999],
              [-7.9322591, 32.734051600000001],
              [-7.9320108, 32.7338418],
              [-7.931786, 32.733583199999998],
              [-7.9313354, 32.732888199999998],
              [-7.9311101, 32.732419],
              [-7.931035, 32.7319496],
              [-7.9309599, 32.7314984],
              [-7.9310039, 32.731037200000003],
              [-7.9312066, 32.730550700000002],
              [-7.9313783, 32.729783599999998],
              [-7.9313032, 32.729187899999999],
              [-7.9310039, 32.728293499999999],
              [-7.9309706, 32.727924299999998],
              [-7.9312399, 32.727409000000002],
              [-7.9317216, 32.727103],
              [-7.9316465, 32.726742000000002],
              [-7.9314427, 32.726426099999998],
              [-7.9308955, 32.726092100000002],
              [-7.9300372, 32.725694900000001],
              [-7.9292754, 32.725496399999997],
              [-7.9284922, 32.7254693],
              [-7.9273442, 32.725478299999999],
              [-7.9266694, 32.725531599999997],
              [-7.9261759, 32.725639899999997],
              [-7.9256394, 32.725838500000002],
              [-7.9250279, 32.726172400000003],
              [-7.9239443, 32.726714000000001],
              [-7.9229465, 32.727074999999999],
              [-7.9224789, 32.727263000000001],
              [-7.9208695, 32.726366200000001],
              [-7.9200961, 32.725838699999997],
              [-7.9197408, 32.725223300000003],
              [-7.919908, 32.724080299999997],
              [-7.9203779, 32.723303799999996],
              [-7.9203573, 32.7224279],
              [-7.9205217, 32.721649999999997],
              [-7.9209601, 32.720658899999997],
              [-7.9209054, 32.718809200000003],
              [-7.9212957, 32.717109299999997],
              [-7.9213162, 32.716452400000001],
              [-7.9213299, 32.715587999999997],
              [-7.9215355, 32.714262599999998],
              [-7.9216313, 32.713219600000002],
              [-7.9215766, 32.712533800000003],
              [-7.9213915, 32.7118538],
              [-7.920871, 32.7108165],
              [-7.9197889, 32.709761899999997],
              [-7.9191657, 32.709087599999997],
              [-7.9170356, 32.708396],
              [-7.9162479, 32.708107900000002],
              [-7.9153232, 32.707790899999999],
              [-7.9146931, 32.707468200000001],
              [-7.9138781, 32.7068054],
              [-7.9133987, 32.705641200000002],
              [-7.9132138, 32.704990000000002],
              [-7.9128713, 32.704067899999998],
              [-7.9123644, 32.702851799999998],
              [-7.9120014, 32.702125600000002],
              [-7.9113987, 32.701410899999999],
              [-7.9105357, 32.700465700000002],
              [-7.9103166, 32.700189100000003],
              [-7.9100015, 32.699555099999998],
              [-7.9099399, 32.698799999999999],
              [-7.9098714, 32.698085300000002],
              [-7.910029, 32.697209299999997],
              [-7.9102276, 32.696119899999999],
              [-7.910618, 32.694465700000002],
              [-7.9105632, 32.693722100000002],
              [-7.9100563, 32.692799899999997],
              [-7.9097755, 32.692056299999997],
              [-7.9093645, 32.691623999999997],
              [-7.9088373, 32.691370399999997],
              [-7.9080839, 32.691168599999997],
              [-7.9073578, 32.6910995],
              [-7.9062826, 32.691059099999997],
              [-7.9056523, 32.691018800000002],
              [-7.9041592, 32.6910764],
              [-7.9033921, 32.691105200000003],
              [-7.902269, 32.691214700000003],
              [-7.9011525, 32.691410699999999],
              [-7.9005429, 32.691485700000001],
              [-7.899947, 32.691474100000001],
              [-7.8987622, 32.691370399999997],
              [-7.8983855, 32.691312699999997],
              [-7.8972417, 32.691260900000003],
              [-7.8965911, 32.691266599999999],
              [-7.8954129, 32.691410699999999],
              [-7.8947965, 32.691641300000001],
              [-7.8943513, 32.691693200000003],
              [-7.893913, 32.691670100000003],
              [-7.8933308, 32.691370399999997],
              [-7.8928446, 32.690857399999999],
              [-7.8926529, 32.690540300000002],
              [-7.8916665, 32.6892146],
              [-7.8908104, 32.688073199999998],
              [-7.8900022, 32.6868512],
              [-7.8894337, 32.686095999999999],
              [-7.8885159, 32.685433099999997],
              [-7.8872146, 32.684891200000003],
              [-7.8851393, 32.684435800000003],
              [-7.8845367, 32.684360900000001],
              [-7.8840298, 32.684360900000001],
              [-7.8837353, 32.684384000000001],
              [-7.8834065, 32.684435800000003],
              [-7.8829545, 32.684349400000002],
              [-7.8827628, 32.684268699999997],
              [-7.8821669, 32.683957399999997],
              [-7.8816874, 32.6832195],
              [-7.8808518, 32.682078099999998],
              [-7.880208, 32.680660000000003],
              [-7.8792971, 32.678336700000003],
              [-7.8784684, 32.676872400000001],
              [-7.8775916, 32.675217799999999],
              [-7.8762013, 32.673061599999997],
              [-7.8755643, 32.671954599999999],
              [-7.8749273, 32.671003300000002],
              [-7.874626, 32.6706401],
              [-7.8740917, 32.670380700000003],
              [-7.8737013, 32.670225000000002],
              [-7.8541972, 32.664349799999997],
              [-7.8452736, 32.660775899999997],
              [-7.8395916, 32.655081699999997],
              [-7.8335062, 32.648306699999999],
              [-7.82579, 32.643348899999999],
              [-7.8203054, 32.6380403],
              [-7.8138338, 32.628968999999998],
              [-7.8051134, 32.623713799999997],
              [-7.7974616, 32.618761800000001],
              [-7.7926465, 32.615504899999998],
              [-7.788518, 32.613263799999999],
              [-7.784587, 32.6104007],
              [-7.7804757, 32.607819599999999],
              [-7.7763816, 32.605303399999997],
              [-7.7733603, 32.603915200000003],
              [-7.7678071, 32.601717100000002],
              [-7.764185, 32.600965100000003],
              [-7.7602454, 32.599280299999997],
              [-7.7552157, 32.597595499999997],
              [-7.7454739, 32.598007699999997],
              [-7.7411481, 32.598419800000002],
              [-7.7395902, 32.600683099999998],
              [-7.738545, 32.600693100000001],
              [-7.7377285, 32.5999701],
              [-7.7382113, 32.598477299999999],
              [-7.7392751, 32.597128300000001],
              [-7.7390852, 32.595687499999997],
              [-7.7381491, 32.594927800000001],
              [-7.7352695, 32.594932300000004],
              [-7.7331843, 32.596675900000001],
              [-7.731266, 32.5968296],
              [-7.7288247, 32.596557900000001],
              [-7.7257551, 32.595592099999998],
              [-7.7221063, 32.594392200000001],
              [-7.7201021, 32.594128300000001],
              [-7.7182734, 32.594318100000002],
              [-7.7166775, 32.593130799999997],
              [-7.7165364, 32.592209199999999],
              [-7.7170417, 32.590767],
              [-7.7190593, 32.589796100000001],
              [-7.722536, 32.589533099999997],
              [-7.7260899, 32.589468500000002],
              [-7.73031, 32.588513800000001],
              [-7.7309388, 32.5876375],
              [-7.7307709, 32.5849513],
              [-7.7294791, 32.5836264],
              [-7.7252681, 32.584097399999997],
              [-7.7215838, 32.583112999999997],
              [-7.7176109, 32.581488899999997],
              [-7.7172504, 32.580402200000002],
              [-7.7182669, 32.578240100000002],
              [-7.7219469, 32.575778800000002],
              [-7.7221513, 32.573832199999998],
              [-7.7163052, 32.571120700000002],
              [-7.7134642, 32.569200299999999],
              [-7.712762, 32.567725099999997],
              [-7.7132028, 32.566107500000001],
              [-7.7154952, 32.5641739],
              [-7.7165509, 32.562331100000002],
              [-7.7196579, 32.560312799999998],
              [-7.7207963, 32.558567600000003],
              [-7.7204401, 32.555938900000001],
              [-7.7185936, 32.555015599999997],
              [-7.7165219, 32.555358300000002],
              [-7.7119332, 32.555889100000002],
              [-7.7097842, 32.554759599999997],
              [-7.7066825, 32.5521642],
              [-7.7043608, 32.552260099999998],
              [-7.700846, 32.550806799999997],
              [-7.6990661, 32.548624599999997],
              [-7.6982185, 32.541974500000002],
              [-7.6972367, 32.537999300000003],
              [-7.6974905, 32.531931399999998],
              [-7.6970748, 32.530017899999997],
              [-7.6951683, 32.528874999999999],
              [-7.6927956, 32.529542599999999],
              [-7.6919529, 32.533246300000002],
              [-7.6905318, 32.5347559],
              [-7.6862929, 32.535354699999999],
              [-7.6828497, 32.533777100000002],
              [-7.680855, 32.5288465],
              [-7.6815651, 32.5253497],
              [-7.6850692, 32.522568999999997],
              [-7.6848139, 32.519755099999998],
              [-7.6830522, 32.519086999999999],
              [-7.6805841, 32.520825299999998],
              [-7.6774078, 32.522204899999998],
              [-7.6757223, 32.521680199999999],
              [-7.6738292, 32.518036299999999],
              [-7.671942, 32.515662800000001],
              [-7.6668291, 32.514442699999996],
              [-7.6656785, 32.511887899999998],
              [-7.6676767, 32.508558299999997],
              [-7.6684004, 32.505394299999999],
              [-7.6697946, 32.503945700000003],
              [-7.6749047, 32.504299500000002],
              [-7.6758907, 32.502414700000003],
              [-7.6727182, 32.497585000000001],
              [-7.6675029, 32.499459000000002],
              [-7.6654446, 32.499608299999998],
              [-7.6624313, 32.4981206],
              [-7.6624808, 32.493730100000001],
              [-7.6572912, 32.494208399999998],
              [-7.6536155, 32.4964516],
              [-7.6505642, 32.495592000000002],
              [-7.6468502, 32.484691599999998],
              [-7.641357, 32.477947200000003],
              [-7.6369539, 32.475286199999999],
              [-7.6264997, 32.468425199999999],
              [-7.620144, 32.465467099999998],
              [-7.6127996, 32.462019900000001],
              [-7.606474, 32.459999500000002],
              [-7.5953346, 32.4562606],
              [-7.5912647, 32.456407200000001],
              [-7.5872773, 32.456423600000001],
              [-7.5834348, 32.455775799999998],
              [-7.578795, 32.456106699999999],
              [-7.5739971, 32.456139299999997],
              [-7.5691463, 32.455255700000002],
              [-7.5633777, 32.455741000000003],
              [-7.557376, 32.454451800000001],
              [-7.5518142, 32.453041300000002],
              [-7.5457402, 32.445680699999997],
              [-7.5362645, 32.4468903],
              [-7.5206558, 32.447339100000001],
              [-7.5019876, 32.4543429],
              [-7.5057422, 32.463114900000001],
              [-7.5017755, 32.468340599999998],
              [-7.4889499, 32.467284399999997],
              [-7.4844671, 32.460951799999997],
              [-7.4814244, 32.457801400000001],
              [-7.4805618, 32.453517499999997],
              [-7.48345, 32.445586499999997],
              [-7.481536, 32.442236399999999],
              [-7.4751502, 32.441026800000003],
              [-7.4693738, 32.440867400000002],
              [-7.4667517, 32.442602200000003],
              [-7.4657389, 32.445818299999999],
              [-7.465962, 32.451953099999997],
              [-7.4668504, 32.455541799999999],
              [-7.4662839, 32.457841299999998],
              [-7.4630266, 32.458648799999999],
              [-7.458572, 32.457482800000001],
              [-7.453332, 32.458409799999998],
              [-7.4491993, 32.457381400000003],
              [-7.445839, 32.456983100000002],
              [-7.4418951, 32.456610099999999],
              [-7.4384876, 32.452717200000002],
              [-7.4275286, 32.446420400000001],
              [-7.414315, 32.446460199999997],
              [-7.4071079, 32.452843999999999],
              [-7.3898002, 32.461570999999999],
              [-7.3827277, 32.463884800000002],
              [-7.3772603, 32.4644786],
              [-7.3724323, 32.462067099999999],
              [-7.3664318, 32.454144900000003],
              [-7.3572231, 32.444503599999997],
              [-7.3512879, 32.437520800000001],
              [-7.342516, 32.425672599999999],
              [-7.337555, 32.417949200000002],
              [-7.3371259, 32.414482200000002],
              [-7.3381515, 32.4111744],
              [-7.3386408, 32.408656399999998],
              [-7.3376451, 32.405794100000001],
              [-7.3355831, 32.404632900000003],
              [-7.3278604, 32.406763300000001],
              [-7.3240624, 32.407386500000001],
              [-7.3225067, 32.408623800000001],
              [-7.3222235, 32.410967900000003],
              [-7.3210584, 32.411669000000003],
              [-7.3187924, 32.411525900000001],
              [-7.316932, 32.410681699999998],
              [-7.3154236, 32.409319400000001],
              [-7.3142241, 32.407721600000002],
              [-7.3142005, 32.401246800000003],
              [-7.3111192, 32.399984099999998],
              [-7.3092234, 32.399053700000003],
              [-7.3091107, 32.397600699999998],
              [-7.3078766, 32.397315900000002],
              [-7.3074324, 32.397199899999997],
              [-7.3056557, 32.396921800000001],
              [-7.3043093, 32.397033200000003],
              [-7.3028416, 32.397297799999997],
              [-7.3013878, 32.398228099999997],
              [-7.2996304, 32.399078699999997],
              [-7.2988333, 32.399705599999997],
              [-7.2989438, 32.4004865],
              [-7.2987249, 32.4014503],
              [-7.2979203, 32.402510999999997],
              [-7.2969632, 32.403160499999998],
              [-7.2954226, 32.403714899999997],
              [-7.2947424, 32.403590800000003],
              [-7.2931974, 32.4023027],
              [-7.2916943, 32.4016333],
              [-7.2909283, 32.401659500000001],
              [-7.290056, 32.402767400000002],
              [-7.2885475, 32.403209400000001],
              [-7.2865101, 32.403175900000001],
              [-7.2847517, 32.403047299999997],
              [-7.2828849, 32.402422299999998],
              [-7.2807659, 32.401328900000003],
              [-7.2785075, 32.400165800000003],
              [-7.2770087, 32.399068800000002],
              [-7.275792, 32.396689000000002],
              [-7.2738523, 32.393882499999997],
              [-7.2720337, 32.3925299],
              [-7.2702227, 32.392177500000003],
              [-7.2678044, 32.392374099999998],
              [-7.2653368, 32.392359599999999],
              [-7.2629936, 32.391889399999997],
              [-7.2605421, 32.390863000000003],
              [-7.2590787, 32.389420700000002],
              [-7.2586474, 32.388193100000002],
              [-7.2590143, 32.386786999999998],
              [-7.2599402, 32.384388800000004],
              [-7.261483, 32.381677000000003],
              [-7.2611364, 32.3787685],
              [-7.2601204, 32.376604800000003],
              [-7.2586828, 32.375200300000003],
              [-7.2567752, 32.373626399999999],
              [-7.2512895, 32.373623700000003],
              [-7.24529, 32.373589299999999],
              [-7.2430498, 32.373819400000002],
              [-7.2412881, 32.373781399999999],
              [-7.2402474, 32.373781399999999],
              [-7.2391337, 32.373324699999998],
              [-7.2383205, 32.3722572],
              [-7.2375523, 32.371149899999999],
              [-7.2367841, 32.370151300000003],
              [-7.2357906, 32.3690821],
              [-7.2355653, 32.368587300000002],
              [-7.2363971, 32.367827200000001],
              [-7.2351911, 32.366225399999998],
              [-7.2326999, 32.365424300000001],
              [-7.2306679, 32.365703400000001],
              [-7.2283161, 32.366787199999997],
              [-7.2252798, 32.367950800000003],
              [-7.2213595, 32.367379900000003],
              [-7.2191065, 32.364965699999999],
              [-7.2169414, 32.361953399999997],
              [-7.2129739, 32.359655199999999],
              [-7.2094977, 32.3561804],
              [-7.2085236, 32.3539745],
              [-7.2056976, 32.349593200000001],
              [-7.2036848, 32.3487212],
              [-7.1993697, 32.348159299999999],
              [-7.196651, 32.346603899999998],
              [-7.1960245, 32.341741900000002],
              [-7.1909841, 32.334364899999997],
              [-7.1901601, 32.330274500000002],
              [-7.1886816, 32.329538399999997],
              [-7.1842549, 32.332954299999997],
              [-7.1821468, 32.334598],
              [-7.1808582, 32.335835199999998],
              [-7.1773005, 32.3358189],
              [-7.1718245, 32.335077400000003],
              [-7.1672004, 32.334132799999999],
              [-7.1646083, 32.332952499999998],
              [-7.1639431, 32.330898300000001],
              [-7.1635569, 32.3283725],
              [-7.1628101, 32.327442400000002],
              [-7.1622136, 32.327012699999997],
              [-7.1598425, 32.327157700000001],
              [-7.1578255, 32.3272865],
              [-7.1540361, 32.327696199999998],
              [-7.1519483, 32.330461300000003],
              [-7.1499162, 32.331367899999996],
              [-7.148, 32.331364200000003],
              [-7.1463349, 32.332524599999999],
              [-7.1444896, 32.335546999999998],
              [-7.143348, 32.335153499999997],
              [-7.1412859, 32.333389400000002],
              [-7.1406315, 32.330956299999997],
              [-7.1404663, 32.328336299999997],
              [-7.1418288, 32.325663599999999],
              [-7.141419, 32.324013600000001],
              [-7.1399427, 32.323034399999997],
              [-7.1367498, 32.320929200000002],
              [-7.1330398, 32.319266399999997],
              [-7.1305884, 32.324784600000001],
              [-7.1146668, 32.343147199999997],
              [-7.1055259, 32.352979699999999],
              [-7.0406752, 32.373646700000002],
              [-7.0348988, 32.3847588],
              [-7.0344439, 32.3980727],
              [-7.0265733, 32.414123500000002],
              [-7.0139047, 32.439081799999997],
              [-7.0055447, 32.457167699999999],
              [-6.9999915, 32.471513700000003],
              [-6.9964124, 32.485632899999999],
              [-6.9922067, 32.499460300000003],
              [-6.9960605, 32.510404899999997],
              [-6.9946872, 32.520226399999999],
              [-7.0030312, 32.535103700000001],
              [-7.0089665, 32.548883799999999],
              [-7.0151248, 32.556614000000003],
              [-6.99629, 32.5604899],
              [-6.9946879, 32.569243899999996],
              [-6.99327, 32.576989900000001],
              [-6.9905, 32.609129899999999],
              [-6.9864937, 32.635688199999997],
              [-6.9929675, 32.643394700000002],
              [-7.0027994, 32.650170000000003],
              [-7.0130819, 32.655821199999998],
              [-7.0179486, 32.662241600000002],
              [-7.0220342, 32.668379700000003],
              [-7.0261798, 32.672870199999998],
              [-7.0300121, 32.676710100000001],
              [-7.0369215, 32.6809978],
              [-7.0429726, 32.684342600000001],
              [-7.0465345, 32.690208300000002],
              [-7.0502253, 32.696729099999999],
              [-7.0559243, 32.698706299999998],
              [-7.0545085, 32.704338499999999],
              [-7.0519335, 32.709693700000003],
              [-7.0500453, 32.7170165],
              [-7.04614, 32.723049699999997],
              [-7.0457537, 32.733580799999999],
              [-7.042578, 32.740093000000002],
              [-7.0337374, 32.753469699999997],
              [-7.0224077, 32.770423999999998],
              [-7.0344066, 32.770129699999998],
              [-7.0289241, 32.788590900000003],
              [-7.0197832, 32.801911599999997],
              [-7.0128373, 32.810851700000001],
              [-7.0092664, 32.819350200000002],
              [-7.0072801, 32.824551599999999],
              [-7.0065998, 32.826718999999997],
              [-7.0036526, 32.835048700000002],
              [-7.0013848, 32.842703399999998],
              [-6.9998747, 32.847800100000001],
              [-7.0031084, 32.900601399999999],
              [-7.0060546, 32.922324500000002],
              [-7.0039002, 32.9312434],
              [-6.9987225, 32.938366100000003],
              [-7.0082626, 32.947431199999997],
              [-7.0036053, 32.954512299999998],
              [-7.0025463, 32.956122499999999],
              [-6.9812949, 32.949779399999997],
              [-6.9688065, 32.952617099999998],
              [-6.9626696, 32.959314999999997],
              [-6.9601376, 32.965506400000002],
              [-6.9552623, 32.972176099999999],
              [-6.9506854, 32.980467300000001],
              [-6.9428641, 32.991750699999997],
              [-6.9444219, 33.008907899999997],
              [-6.922548, 33.048709100000004],
              [-6.932191, 33.056967899999997],
              [-6.9031009, 33.076730400000002],
              [-6.9137911, 33.101643899999999],
              [-6.8951551, 33.105752899999999],
              [-6.8925437, 33.141487599999998],
              [-6.8836795, 33.159231800000001],
              [-6.8786886, 33.172234600000003],
              [-6.8739014, 33.185993199999999],
              [-6.8691292, 33.201672600000002],
              [-6.8647523, 33.206644900000001],
              [-6.85889, 33.211140299999997],
              [-6.8546586, 33.2148386],
              [-6.8502727, 33.219146899999998],
              [-6.8412862, 33.224345300000003],
              [-6.832952, 33.2268799],
              [-6.829227, 33.228545599999997],
              [-6.8221459, 33.2290554],
              [-6.8050742, 33.235681800000002],
              [-6.8011003, 33.239824200000001],
              [-6.7987056, 33.2494862],
              [-6.7986026, 33.260403199999999],
              [-6.7974266, 33.275588499999998],
              [-6.8011431, 33.302859400000003],
              [-6.8014951, 33.322663300000002],
              [-6.8058982, 33.341358499999998],
              [-6.8089538, 33.368243300000003],
              [-6.8080182, 33.3833883],
              [-6.8090224, 33.397076200000001],
              [-6.8071856, 33.407888700000001],
              [-6.8050313, 33.415060500000003],
              [-6.7964932, 33.421197800000002],
              [-6.7963323, 33.427234900000002],
              [-6.8038124, 33.435803900000003],
              [-6.809797, 33.443270300000002],
              [-6.8245663, 33.452818499999999],
              [-6.8282313, 33.462919200000002],
              [-6.827579, 33.478663400000002],
              [-6.8240942, 33.485440599999997],
              [-6.8094215, 33.4940876],
              [-6.7983064, 33.498747199999997],
              [-6.7891547, 33.496544399999998],
              [-6.7820737, 33.502653199999997],
              [-6.7724284, 33.513322000000002],
              [-6.7569361, 33.542720500000001],
              [-6.7615237, 33.548450500000001],
              [-6.767519, 33.549756000000002],
              [-6.7692785, 33.553632899999997],
              [-6.7716947, 33.558267800000003],
              [-6.7735486, 33.562276699999998],
              [-6.7837152, 33.562591400000002],
              [-6.7963753, 33.561865400000002],
              [-6.8031559, 33.567973199999997],
              [-6.8102541, 33.570941099999999],
              [-6.8164597, 33.571813599999999],
              [-6.8269267, 33.568566799999999],
              [-6.8534182, 33.572081699999998],
              [-6.8623362, 33.568405900000002],
              [-6.8733482, 33.568938699999997],
              [-6.8803992, 33.572131800000001],
              [-6.8929776, 33.574280700000003],
              [-6.9079465, 33.571917200000001],
              [-6.917036, 33.583151299999997],
              [-6.9257779, 33.584109400000003],
              [-6.9368887, 33.5799764],
              [-6.9494243, 33.564994499999997],
              [-6.9503105, 33.557740299999999],
              [-6.9459481, 33.551183000000002],
              [-6.9446607, 33.548035599999999],
              [-6.9462764, 33.5456875],
              [-6.9486797, 33.543469899999998],
              [-6.9507546, 33.542169700000002],
              [-6.9538875, 33.543421600000002],
              [-6.9584794, 33.5443158],
              [-6.9598527, 33.548643599999998],
              [-6.9639726, 33.5540442],
              [-6.96629, 33.557262899999998],
              [-6.9698949, 33.5591583],
              [-6.9735427, 33.5616974],
              [-6.9742722, 33.562484099999999],
              [-6.9756885, 33.5664534],
              [-6.9806237, 33.568634699999997],
              [-6.9803662, 33.570529899999997],
              [-6.9791217, 33.570637099999999],
              [-6.9734569, 33.571638299999996],
              [-6.9720407, 33.572818300000002],
              [-6.9724269, 33.5739983],
              [-6.9741435, 33.574820600000002],
              [-6.9774051, 33.573712200000003],
              [-6.9778771, 33.577752500000003],
              [-6.9813962, 33.578074299999997],
              [-6.9847865, 33.581220600000002],
              [-6.9843574, 33.582042999999999],
              [-6.9812675, 33.582078699999997],
              [-6.9809241, 33.5827223],
              [-6.9825549, 33.583759100000002],
              [-6.9861598, 33.584009299999998],
              [-6.9876189, 33.584617100000003],
              [-6.9871469, 33.5871554],
              [-6.9873185, 33.590909099999998],
              [-6.9879622, 33.591874300000001],
              [-6.9904942, 33.590265700000003],
              [-6.9922109, 33.591016400000001],
              [-6.9919963, 33.593411500000002],
              [-6.9904942, 33.595306100000002],
              [-6.9922109, 33.596986200000003],
              [-6.9921679, 33.600918200000002],
              [-6.9910092, 33.601525899999999],
              [-6.9883914, 33.602955700000003],
              [-6.9889064, 33.604242399999997],
              [-6.9910092, 33.606065299999997],
              [-6.9919963, 33.609782500000001],
              [-6.9934125, 33.6102828],
              [-6.9959874, 33.608531499999998],
              [-6.9980044, 33.608603000000002],
              [-7.0003648, 33.6130706],
              [-7.0039523, 33.615041400000003],
              [-7.0042956, 33.6241901],
              [-7.0022271, 33.629471600000002],
              [-6.9977743, 33.630887700000002],
              [-7.0034373, 33.641341199999999],
              [-7.005317, 33.651623100000002],
              [-7.0133955, 33.6549215],
              [-7.015061, 33.6712925],
              [-7.0151102, 33.671776000000001],
              [-7.0188782, 33.678626000000001],
              [-7.0291865, 33.6826328],
              [-7.0259163, 33.6887677],
              [-7.0281565, 33.692345600000003],
              [-7.0214531, 33.703049800000002],
              [-7.0242959, 33.709047200000001],
              [-7.0272982, 33.723619100000001],
              [-7.0303795, 33.7340339],
              [-7.0372546, 33.743605199999998],
              [-7.038138, 33.752254700000002],
              [-7.0391343, 33.762009800000001],
              [-7.0434258, 33.769858599999999],
              [-7.0465173, 33.769739399999999],
              [-7.0506442, 33.769580300000001],
              [-7.0521805, 33.775280899999998],
              [-7.0624888, 33.783135600000001],
              [-7.0602572, 33.7976873],
              [-7.0636004, 33.799114199999998],
              [-7.0662568, 33.800247900000002],
              [-7.0755651, 33.795462200000003],
              [-7.0820582, 33.792123699999998],
              [-7.089431, 33.792401900000002],
              [-7.099396, 33.795262200000003],
              [-7.1097306, 33.813966700000002],
              [-7.11081, 33.815919999999998],
              [-7.1260185, 33.8317561],
              [-7.1263618, 33.830858999999997],
              [-7.126376, 33.830243899999999],
              [-7.1269626, 33.829440699999999],
              [-7.1276251, 33.828688399999997],
              [-7.1285488, 33.828249900000003],
              [-7.1291412, 33.8281615],
              [-7.1298997, 33.827976800000002],
              [-7.131525, 33.8278441],
              [-7.1326884, 33.8279152],
              [-7.1335692, 33.828156900000003],
              [-7.1340286, 33.8285026],
              [-7.1343934, 33.829147399999997],
              [-7.1347184, 33.828967400000003],
              [-7.1346839, 33.828680900000002],
              [-7.1347465, 33.8284819],
              [-7.1347691, 33.8283196],
              [-7.1346035, 33.827996200000001],
              [-7.1347358, 33.827564600000002],
              [-7.135117, 33.827113500000003],
              [-7.1355, 33.826839700000001],
              [-7.137114, 33.825574799999998],
              [-7.1386138, 33.824617799999999],
              [-7.1396777, 33.8239868],
              [-7.1409064, 33.823599199999997],
              [-7.1417733, 33.8234949],
              [-7.1430451, 33.823566],
              [-7.1436819, 33.8236147],
              [-7.1444635, 33.823773000000003],
              [-7.1451047, 33.8240081],
              [-7.1452445, 33.824480800000003],
              [-7.1452261, 33.824909099999999],
              [-7.1451634, 33.825204900000003],
              [-7.1449736, 33.8255464],
              [-7.143915, 33.826412699999999],
              [-7.1439142, 33.826587400000001],
              [-7.144018, 33.826651499999997],
              [-7.1442368, 33.826593199999998],
              [-7.1445857, 33.826272799999998],
              [-7.1449091, 33.825913499999999],
              [-7.1451715, 33.825599500000003],
              [-7.1454004, 33.825176800000001],
              [-7.1454632, 33.824810200000002],
              [-7.1452439, 33.824067499999998],
              [-7.1449481, 33.8227495],
              [-7.1450055, 33.822683699999999],
              [-7.1449697, 33.822457800000002],
              [-7.1449996, 33.822229900000004],
              [-7.1449172, 33.822112199999999],
              [-7.1448231, 33.8220788],
              [-7.1439797, 33.822091],
              [-7.1439763, 33.821911399999998],
              [-7.1440627, 33.821910899999999],
              [-7.1440666, 33.821475300000003],
              [-7.1435689, 33.821473599999997],
              [-7.1435952, 33.821899899999998],
              [-7.1439083, 33.821906400000003],
              [-7.1439166, 33.8220873],
              [-7.1438734, 33.822088299999997],
              [-7.1438543, 33.822055900000002],
              [-7.1431188, 33.822047599999998],
              [-7.1428939, 33.821771400000003],
              [-7.1426258, 33.821769799999998],
              [-7.1425494, 33.8216167],
              [-7.1429122, 33.821301599999998],
              [-7.142904, 33.821003599999997],
              [-7.1430841, 33.820956899999999],
              [-7.1433814, 33.8203344],
              [-7.143368, 33.820291599999997],
              [-7.143724, 33.820087600000001],
              [-7.1442878, 33.8200474],
              [-7.1443124, 33.820177999999999],
              [-7.1442068, 33.820201699999998],
              [-7.1437943, 33.8203982],
              [-7.1436949, 33.8203684],
              [-7.1436288, 33.820427799999997],
              [-7.1436597, 33.820503899999999],
              [-7.1437511, 33.820515499999999],
              [-7.1437809, 33.820593799999997],
              [-7.1437478, 33.820625100000001],
              [-7.1438398, 33.820892399999998],
              [-7.1438953, 33.820882099999999],
              [-7.1439571, 33.821012199999998],
              [-7.1441284, 33.8209649],
              [-7.1441371, 33.8209126],
              [-7.1441914, 33.8208938],
              [-7.1442418, 33.820951600000001],
              [-7.144342, 33.821001000000003],
              [-7.1444433, 33.821001899999999],
              [-7.1445481, 33.820919099999998],
              [-7.1445549, 33.820816899999997],
              [-7.1447524, 33.820770400000001],
              [-7.1449536, 33.820912200000002],
              [-7.1454405, 33.820657300000001],
              [-7.1453519, 33.820504100000001],
              [-7.1452599, 33.820286199999998],
              [-7.1449431, 33.820014299999997],
              [-7.144408, 33.820156900000001],
              [-7.144388, 33.820016500000001],
              [-7.1447889, 33.819843900000002],
              [-7.1448186, 33.819662899999997],
              [-7.1449133, 33.819546600000002],
              [-7.1450786, 33.8194947],
              [-7.1452581, 33.819507999999999],
              [-7.1454691, 33.819583799999997],
              [-7.1455993, 33.819557500000002],
              [-7.145658, 33.819587599999998],
              [-7.1456029, 33.819700500000003],
              [-7.1456947, 33.819724100000002],
              [-7.1460028, 33.819692099999997],
              [-7.1461784, 33.819555800000003],
              [-7.1460213, 33.819472400000002],
              [-7.1458823, 33.8194856],
              [-7.1456968, 33.819535899999998],
              [-7.1456629, 33.819496000000001],
              [-7.1460641, 33.819366899999999],
              [-7.1463043, 33.819534300000001],
              [-7.1462689, 33.819632599999998],
              [-7.1461339, 33.819705599999999],
              [-7.1460142, 33.819750300000003],
              [-7.1454531, 33.8198072],
              [-7.1453434, 33.819772700000001],
              [-7.1452187, 33.819641400000002],
              [-7.1449862, 33.819803999999998],
              [-7.1451358, 33.819960799999997],
              [-7.1450405, 33.8200067],
              [-7.1452379, 33.820130300000002],
              [-7.145416, 33.820431399999997],
              [-7.1455858, 33.820524800000001],
              [-7.1459753, 33.820315800000003],
              [-7.1460981, 33.8201204],
              [-7.1465715, 33.819985899999999],
              [-7.1467213, 33.819927100000001],
              [-7.1466821, 33.819715600000002],
              [-7.1463464, 33.819651299999997],
              [-7.1463848, 33.819530399999998],
              [-7.1466231, 33.819499200000003],
              [-7.1469859, 33.819849099999999],
              [-7.1464635, 33.820185799999997],
              [-7.1463987, 33.820157399999999],
              [-7.1462685, 33.8201921],
              [-7.1459912, 33.820595599999997],
              [-7.1458466, 33.820566300000003],
              [-7.1455691, 33.8208342],
              [-7.1451261, 33.8211443],
              [-7.1447972, 33.821447300000003],
              [-7.144804, 33.821566900000001],
              [-7.1452079, 33.821750799999997],
              [-7.1450875, 33.822433500000002],
              [-7.1451624, 33.822476299999998],
              [-7.145241, 33.822035200000002],
              [-7.1454404, 33.821620500000002],
              [-7.1457965, 33.821203599999997],
              [-7.146098, 33.8215687],
              [-7.1458015, 33.8219572],
              [-7.1456874, 33.822473600000002],
              [-7.1458243, 33.823160600000001],
              [-7.146499, 33.824136000000003],
              [-7.1466454, 33.824773800000003],
              [-7.1465322, 33.8255087],
              [-7.1461642, 33.826139099999999],
              [-7.1449371, 33.8273376],
              [-7.1452332, 33.827640500000001],
              [-7.1472702, 33.828249100000001],
              [-7.1480331, 33.827493799999999],
              [-7.1489373, 33.827004299999999],
              [-7.1491014, 33.827054199999999],
              [-7.1495604, 33.826849899999999],
              [-7.1501382, 33.826745000000003],
              [-7.1502567, 33.8271996],
              [-7.1510616, 33.827451199999999],
              [-7.1515704, 33.826856399999997],
              [-7.151613, 33.826186499999999],
              [-7.1510295, 33.825841599999997],
              [-7.1503626, 33.826104600000001],
              [-7.149699, 33.825765500000003],
              [-7.1497579, 33.825279799999997],
              [-7.1495831, 33.824873199999999],
              [-7.1494556, 33.824603799999998],
              [-7.149041, 33.824545700000002],
              [-7.1483036, 33.824428500000003],
              [-7.1481753, 33.8242057],
              [-7.1480239, 33.823944300000001],
              [-7.1480321, 33.823528799999998],
              [-7.1482353, 33.822916599999999],
              [-7.1488453, 33.822040600000001],
              [-7.1496951, 33.821187799999997],
              [-7.1505605, 33.820419600000001],
              [-7.1517539, 33.819989100000001],
              [-7.1519913, 33.819887600000001],
              [-7.1531102, 33.819409],
              [-7.154343, 33.819003600000002],
              [-7.1555635, 33.819212],
              [-7.1567468, 33.8196023],
              [-7.1577954, 33.819893700000002],
              [-7.1581571, 33.8198778],
              [-7.1580992, 33.819487100000003],
              [-7.1582682, 33.818995399999999],
              [-7.1587585, 33.818468799999998],
              [-7.1595618, 33.817987299999999],
              [-7.1618376, 33.817073600000001],
              [-7.163903, 33.816617100000002],
              [-7.1650552, 33.816440200000002],
              [-7.1654626, 33.816552399999999],
              [-7.1660712, 33.81653],
              [-7.1675528, 33.8167951],
              [-7.1683076, 33.817681499999999],
              [-7.1692585, 33.818239599999998],
              [-7.1695034, 33.818433300000002],
              [-7.1695851, 33.8187043],
              [-7.1697461, 33.8189013],
              [-7.1689366, 33.819941200000002],
              [-7.1689197, 33.820586599999999],
              [-7.1691885, 33.8209217],
              [-7.1698539, 33.820512999999998],
              [-7.170533, 33.819681199999998],
              [-7.1705908, 33.8194011],
              [-7.1706862, 33.8192977],
              [-7.1710501, 33.819212100000001],
              [-7.1709808, 33.819012299999997],
              [-7.1714436, 33.818519299999998],
              [-7.1712468, 33.817765399999999],
              [-7.1719572, 33.818192000000003],
              [-7.1720694, 33.818081399999997],
              [-7.1717518, 33.817779899999998],
              [-7.1718894, 33.817687999999997],
              [-7.17186, 33.817196699999997],
              [-7.1723205, 33.816721700000002],
              [-7.1723592, 33.816718700000003],
              [-7.1729553, 33.8166723],
              [-7.1731743, 33.816937799999998],
              [-7.1734652, 33.817151000000003],
              [-7.1735051, 33.816805100000003],
              [-7.173448, 33.8165871],
              [-7.173699, 33.816535000000002],
              [-7.1739613, 33.816771899999999],
              [-7.174121, 33.816762400000002],
              [-7.1739271, 33.816378700000001],
              [-7.1741237, 33.816138899999999],
              [-7.1743687, 33.816201399999997],
              [-7.1746742, 33.816388099999998],
              [-7.1748738, 33.816501899999999],
              [-7.1751524, 33.8161536],
              [-7.1754441, 33.815729500000003],
              [-7.1751532, 33.815502100000003],
              [-7.1753968, 33.8150361],
              [-7.1757863, 33.815118300000002],
              [-7.1761341, 33.815123],
              [-7.1767815, 33.814939199999998],
              [-7.1773546, 33.814260699999998],
              [-7.1774915, 33.813156599999999],
              [-7.1778619, 33.812212199999998],
              [-7.1778445, 33.812012699999997],
              [-7.1781416, 33.8116688],
              [-7.1788071, 33.8112882],
              [-7.1800316, 33.810502999999997],
              [-7.180966, 33.809978700000002],
              [-7.181763, 33.809683300000003],
              [-7.1825272, 33.809621700000001],
              [-7.1832394, 33.809733999999999],
              [-7.1834683, 33.810139900000003],
              [-7.1832914, 33.8106595],
              [-7.1836393, 33.8106358],
              [-7.1841004, 33.810081199999999],
              [-7.1845675, 33.809495499999997],
              [-7.1846753, 33.8092866],
              [-7.1863475, 33.808556799999998],
              [-7.1868779, 33.807699499999998],
              [-7.1874413, 33.807242299999999],
              [-7.1884497, 33.806828500000002],
              [-7.1901599, 33.804609599999999],
              [-7.1932637, 33.804788000000002],
              [-7.195903, 33.805419100000002],
              [-7.196668, 33.805248800000001],
              [-7.1989887, 33.804389399999998],
              [-7.2001163, 33.803713700000003],
              [-7.201055, 33.803899100000002],
              [-7.2022577, 33.803787700000001],
              [-7.2035774, 33.802446400000001],
              [-7.2047189, 33.802387500000002],
              [-7.2051229, 33.802127599999999],
              [-7.2057494, 33.801906600000002],
              [-7.205749, 33.8026561],
              [-7.206045, 33.803005800000001],
              [-7.206667, 33.802926399999997],
              [-7.2073335, 33.803489399999997],
              [-7.2082702, 33.803268799999998],
              [-7.2082755, 33.802247100000002],
              [-7.2086553, 33.8017915],
              [-7.2091344, 33.802705199999998],
              [-7.2109095, 33.803201000000001],
              [-7.2127119, 33.802572099999999],
              [-7.2133079, 33.802415199999999],
              [-7.2138062, 33.8023782],
              [-7.2145632, 33.8023661],
              [-7.2158721, 33.802222999999998],
              [-7.2170303, 33.801971600000002],
              [-7.2178569, 33.801776400000001],
              [-7.2181466, 33.801037299999997],
              [-7.2209146, 33.801915899999997],
              [-7.2233576, 33.802664300000004],
              [-7.2242701, 33.802473599999999],
              [-7.2265258, 33.803275900000003],
              [-7.2271851, 33.803868299999998],
              [-7.2280386, 33.804122900000003],
              [-7.2287499, 33.8036472],
              [-7.2293368, 33.803304500000003],
              [-7.229524, 33.802880999999999],
              [-7.2300132, 33.802233700000002],
              [-7.2305003, 33.801306099999998],
              [-7.2307406, 33.800489900000002],
              [-7.2304574, 33.799642499999997],
              [-7.2303426, 33.799034399999996],
              [-7.2301495, 33.798762500000002],
              [-7.2294414, 33.798409900000003],
              [-7.2286335, 33.797977899999999],
              [-7.2293277, 33.7967783],
              [-7.230937, 33.796835799999997],
              [-7.2320083, 33.796177399999998],
              [-7.2329036, 33.795800700000001],
              [-7.2339083, 33.796570600000003],
              [-7.2343412, 33.796870599999998],
              [-7.2353122, 33.796340999999998],
              [-7.2362392, 33.7957459],
              [-7.2365358, 33.795082499999999],
              [-7.237517, 33.795395499999998],
              [-7.2379139, 33.795850199999997],
              [-7.238864, 33.796261199999996],
              [-7.2403279, 33.7943353],
              [-7.2411026, 33.792401400000003],
              [-7.2415569, 33.791991699999997],
              [-7.241713, 33.793340700000002],
              [-7.2425824, 33.792906299999999],
              [-7.2430128, 33.7918825],
              [-7.2432676, 33.790778199999998],
              [-7.2437826, 33.791064900000002],
              [-7.2445846, 33.790834400000001],
              [-7.2460067, 33.7895945],
              [-7.2463763, 33.789003800000003],
              [-7.2468414, 33.788310500000001],
              [-7.2473886, 33.787971300000002],
              [-7.247726, 33.787351100000002],
              [-7.2481788, 33.786918200000002],
              [-7.2500252, 33.786709500000001],
              [-7.2510412, 33.787682799999999],
              [-7.2519853, 33.787674799999998],
              [-7.2521463, 33.786479900000003],
              [-7.2538414, 33.7864705],
              [-7.2546568, 33.784914100000002],
              [-7.255719, 33.784110200000001],
              [-7.2563804, 33.783344200000002],
              [-7.2570435, 33.783612599999998],
              [-7.2580793, 33.783158299999997],
              [-7.2592858, 33.782863999999996],
              [-7.2607401, 33.782407900000003],
              [-7.262204, 33.781756899999998],
              [-7.2630731, 33.781536199999998],
              [-7.2642827, 33.781170600000003],
              [-7.2653191, 33.780698399999999],
              [-7.2665063, 33.7799342],
              [-7.2675314, 33.778689300000003],
              [-7.270593, 33.777625100000002],
              [-7.2709051, 33.777632500000003],
              [-7.2713155, 33.777454599999999],
              [-7.2718922, 33.777285599999999],
              [-7.2728422, 33.7763007],
              [-7.2728637, 33.775498900000002],
              [-7.2735047, 33.7748591],
              [-7.2739414, 33.774463099999998],
              [-7.2749665, 33.773908800000001],
              [-7.2760179, 33.773658699999999],
              [-7.2777674, 33.772842300000001],
              [-7.2777827, 33.772071599999997],
              [-7.2787005, 33.772341400000002],
              [-7.2785678, 33.771311599999997],
              [-7.2790443, 33.770547100000002],
              [-7.2799672, 33.770559900000002],
              [-7.2799774, 33.770046200000003],
              [-7.2805901, 33.770182699999999],
              [-7.2818336, 33.769558000000004],
              [-7.2821721, 33.768020700000001],
              [-7.2837155, 33.767785199999999],
              [-7.2842604, 33.766627700000001],
              [-7.2849767, 33.766260699999997],
              [-7.2861572, 33.7649714],
              [-7.2874735, 33.764496200000004],
              [-7.2892954, 33.761890700000002],
              [-7.2912416, 33.760693600000003],
              [-7.2921321, 33.7604975],
              [-7.291867, 33.760188200000002],
              [-7.2928256, 33.7584023],
              [-7.2939378, 33.757895599999998],
              [-7.2971524, 33.757434099999998],
              [-7.2971422, 33.757947999999999],
              [-7.2983752, 33.757836099999999],
              [-7.2996234, 33.756954200000003],
              [-7.2988753, 33.755915799999997],
              [-7.2984641, 33.753340100000003],
              [-7.2978489, 33.753331600000003],
              [-7.2978642, 33.752560899999999],
              [-7.30093, 33.753117099999997],
              [-7.3009402, 33.752603200000003],
              [-7.3021755, 33.752363199999998],
              [-7.3024476, 33.754166099999999],
              [-7.304626, 33.752910900000003],
              [-7.3055792, 33.751381899999998],
              [-7.3068096, 33.751398899999998],
              [-7.3065223, 33.750366800000002],
              [-7.3086854, 33.749882599999999],
              [-7.3090182, 33.748602099999999],
              [-7.3117965, 33.748125999999999],
              [-7.3124368, 33.746849900000001],
              [-7.3139847, 33.746357000000003],
              [-7.3140048, 33.7453292],
              [-7.3149275, 33.7453419],
              [-7.3148145, 33.743284199999998],
              [-7.315603, 33.742266999999998],
              [-7.316218, 33.742275300000003],
              [-7.3173133, 33.741437599999998],
              [-7.3187135, 33.740510299999997],
              [-7.3196651, 33.740119399999998],
              [-7.3208962, 33.739867400000001],
              [-7.3214916, 33.740034000000001],
              [-7.3214716, 33.741061799999997],
              [-7.3227118, 33.7405647],
              [-7.3232002, 33.739680900000003],
              [-7.323967, 33.739296699999997],
              [-7.3239571, 33.738678499999999],
              [-7.3246071, 33.738020400000003],
              [-7.3250016, 33.737728400000002],
              [-7.3264648, 33.737402500000002],
              [-7.3270749, 33.737667899999998],
              [-7.3308027, 33.7357911],
              [-7.331709, 33.734346799999997],
              [-7.332224, 33.733418899999997],
              [-7.3322068, 33.732762200000003],
              [-7.3324986, 33.732212599999997],
              [-7.3332282, 33.731348799999999],
              [-7.3348675, 33.7306065],
              [-7.3360348, 33.730256699999998],
              [-7.3366443, 33.730399499999997],
              [-7.3367816, 33.730149599999997],
              [-7.3370391, 33.729978299999999],
              [-7.3381464, 33.730406600000002],
              [-7.3384811, 33.730763500000002],
              [-7.3387986, 33.731027599999997],
              [-7.3395024, 33.731099],
              [-7.3406763, 33.730526099999999],
              [-7.3418056, 33.730130500000001],
              [-7.3428571, 33.729265900000001],
              [-7.3435405, 33.7285574],
              [-7.3444611, 33.727395600000001],
              [-7.3451692, 33.726467599999999],
              [-7.345298, 33.725967900000001],
              [-7.3449761, 33.725075599999997],
              [-7.3444611, 33.724772199999997],
              [-7.3440609, 33.724839099999997],
              [-7.3437605, 33.724749899999999],
              [-7.3435599, 33.724325999999998],
              [-7.3430235, 33.724254600000002],
              [-7.3431333, 33.723770899999998],
              [-7.3437652, 33.723640899999999],
              [-7.3445963, 33.723768300000003],
              [-7.3447036, 33.723447100000001],
              [-7.3442541, 33.723447100000001],
              [-7.3438035, 33.723447100000001],
              [-7.3433958, 33.723464900000003],
              [-7.3429061, 33.723441899999997],
              [-7.342661, 33.723070700000001],
              [-7.3424704, 33.7221136],
              [-7.3427736, 33.721230200000001],
              [-7.3428594, 33.7210544],
              [-7.3432287, 33.720240199999999],
              [-7.3436788, 33.719753699999998],
              [-7.3455595, 33.718919100000001],
              [-7.3473674, 33.718389299999998],
              [-7.3483597, 33.718550700000002],
              [-7.3493472, 33.719613000000003],
              [-7.3507997, 33.719611399999998],
              [-7.3517075, 33.718663499999998],
              [-7.3516217, 33.718049499999999],
              [-7.3519172, 33.716398699999999],
              [-7.352389, 33.7153718],
              [-7.3535099, 33.714694000000001],
              [-7.3559008, 33.713346600000001],
              [-7.3575663, 33.712581299999997],
              [-7.3619272, 33.711122799999998],
              [-7.3633966, 33.710905699999998],
              [-7.364874, 33.711295499999999],
              [-7.3662129, 33.711938099999998],
              [-7.3667107, 33.7125664],
              [-7.3670025, 33.712502200000003],
              [-7.3672601, 33.7115954],
              [-7.3681881, 33.711026599999997],
              [-7.3697577, 33.710702900000001],
              [-7.3707018, 33.709503400000003],
              [-7.3720752, 33.709053599999997],
              [-7.3728299, 33.709366299999999],
              [-7.3726759, 33.710131799999999],
              [-7.3730364, 33.710260300000002],
              [-7.3739462, 33.709896100000002],
              [-7.3743668, 33.709596300000001],
              [-7.3756065, 33.708888700000003],
              [-7.3765328, 33.708686999999998],
              [-7.3778391, 33.708338500000004],
              [-7.3818721, 33.708165399999999],
              [-7.3849695, 33.7083175],
              [-7.3884783, 33.708818200000003],
              [-7.3909132, 33.709685700000001],
              [-7.3923439, 33.710418900000001],
              [-7.3934447, 33.711164099999998],
              [-7.3943309, 33.7122034],
              [-7.3931313, 33.713332700000002],
              [-7.3931238, 33.713469500000002],
              [-7.3933726, 33.7134176],
              [-7.3953352, 33.711477100000003],
              [-7.3958312, 33.711732300000001],
              [-7.3960838, 33.711871100000003],
              [-7.3962468, 33.712005699999999],
              [-7.3959189, 33.712523599999997],
              [-7.3963489, 33.712502399999998],
              [-7.3966607, 33.712004100000001],
              [-7.3966978, 33.7117997],
              [-7.3969058, 33.711481399999997],
              [-7.3972817, 33.711429699999997],
              [-7.398278, 33.711686],
              [-7.3986534, 33.711988599999998],
              [-7.3984958, 33.712375999999999],
              [-7.39857, 33.7123992],
              [-7.3986835, 33.712139000000001],
              [-7.39876, 33.7121602],
              [-7.3989055, 33.711830300000003],
              [-7.3990087, 33.711867300000002],
              [-7.399112, 33.711666899999997],
              [-7.3993119, 33.7117419],
              [-7.3992119, 33.711964100000003],
              [-7.3992968, 33.711996399999997],
              [-7.3997247, 33.712259799999998],
              [-7.4001212, 33.712549799999998],
              [-7.4001474, 33.7126114],
              [-7.4001196, 33.712894900000002],
              [-7.4000912, 33.712939499999997],
              [-7.399515, 33.713313300000003],
              [-7.3987794, 33.713688099999999],
              [-7.398721, 33.713634900000002],
              [-7.3973019, 33.714584799999997],
              [-7.3972197, 33.714565299999997],
              [-7.3972187, 33.714649999999999],
              [-7.3964835, 33.714646700000003],
              [-7.3964759, 33.714477799999997],
              [-7.3957044, 33.714544099999998],
              [-7.3956425, 33.714606500000002],
              [-7.3956867, 33.7147066],
              [-7.3949499, 33.714742399999999],
              [-7.3949151, 33.714373199999997],
              [-7.3949793, 33.7142822],
              [-7.3949071, 33.714210899999998],
              [-7.3930013, 33.714358300000001],
              [-7.393006, 33.714399899999997],
              [-7.3939281, 33.714329999999997],
              [-7.3939324, 33.714574399999997],
              [-7.3939913, 33.714638200000003],
              [-7.3946248, 33.714784000000002],
              [-7.3946807, 33.714842699999998],
              [-7.3946672, 33.714923800000001],
              [-7.3942425, 33.715291800000003],
              [-7.3941337, 33.715308],
              [-7.393257, 33.715040700000003],
              [-7.392995, 33.7152514],
              [-7.3930445, 33.715298500000003],
              [-7.3929242, 33.715469300000002],
              [-7.3927421, 33.7155962],
              [-7.3918848, 33.714694000000001],
              [-7.3917822, 33.7147699],
              [-7.3917303, 33.7148082],
              [-7.3933268, 33.716478899999998],
              [-7.393241, 33.718056699999998],
              [-7.3938813, 33.718380199999999],
              [-7.3940135, 33.719220300000003],
              [-7.3945971, 33.719655799999998],
              [-7.3950945, 33.719295000000002],
              [-7.3963137, 33.719634399999997],
              [-7.395919, 33.7203412],
              [-7.3969146, 33.721033800000001],
              [-7.3964167, 33.721626200000003],
              [-7.3937216, 33.7230968],
              [-7.3935157, 33.7235108],
              [-7.3928806, 33.7239249],
              [-7.3919707, 33.724124799999998],
              [-7.3908463, 33.724181899999998],
              [-7.3907776, 33.724460299999997],
              [-7.3909972, 33.724510500000001],
              [-7.3914643, 33.724617299999998],
              [-7.3931895, 33.724331800000002],
              [-7.3953181, 33.723139600000003],
              [-7.3959532, 33.723853499999997],
              [-7.3874903, 33.732954900000003],
              [-7.3865462, 33.732619399999997],
              [-7.3863402, 33.732754999999997],
              [-7.3863659, 33.732997699999999],
              [-7.3864603, 33.733368900000002],
              [-7.3868723, 33.733725800000002],
              [-7.3871985, 33.733818599999999],
              [-7.3875246, 33.733789999999999],
              [-7.3952895, 33.725337699999997],
              [-7.3968558, 33.723816100000001],
              [-7.3979359, 33.722554199999998],
              [-7.3992148, 33.7211979],
              [-7.4006649, 33.717440199999999],
              [-7.4013084, 33.715906400000001],
              [-7.4015923, 33.715015299999997],
              [-7.4019567, 33.7141156],
              [-7.4021506, 33.713431999999997],
              [-7.4026549, 33.713101899999998],
              [-7.4027943, 33.712851999999998],
              [-7.4029982, 33.712566299999999],
              [-7.4031055, 33.712030900000002],
              [-7.4032918, 33.7119097],
              [-7.403717, 33.7117632],
              [-7.4039101, 33.711789899999999],
              [-7.4042213, 33.711870300000001],
              [-7.4045277, 33.711731100000002],
              [-7.4050884, 33.711072000000001],
              [-7.4054118, 33.709789000000001],
              [-7.4060393, 33.709542200000001],
              [-7.4073, 33.708389599999997],
              [-7.4082294, 33.707514400000001],
              [-7.4091711, 33.706868800000002],
              [-7.4100979, 33.706253400000001],
              [-7.4103472, 33.7059991],
              [-7.4103689, 33.7058459],
              [-7.4103903, 33.7056763],
              [-7.4109993, 33.705333600000003],
              [-7.4113513, 33.704984500000002],
              [-7.411995, 33.704534000000002],
              [-7.4117203, 33.703962699999998],
              [-7.4102698, 33.704441099999997],
              [-7.409669, 33.705076599999998],
              [-7.4092398, 33.7055121],
              [-7.407941, 33.706482700000002],
              [-7.4066391, 33.707268599999999],
              [-7.4059096, 33.707768399999999],
              [-7.4051028, 33.707882699999999],
              [-7.4042015, 33.707554199999997],
              [-7.4034119, 33.707047299999999],
              [-7.4034953, 33.7068765],
              [-7.4038879, 33.707036100000003],
              [-7.4043508, 33.707246699999999],
              [-7.405141, 33.707323899999999],
              [-7.4062029, 33.706929299999999],
              [-7.4070682, 33.706191799999999],
              [-7.4091407, 33.705324900000001],
              [-7.4100806, 33.704304700000002],
              [-7.4110251, 33.703869900000001],
              [-7.4120722, 33.702805900000001],
              [-7.4131108, 33.702834500000002],
              [-7.4133168, 33.703327199999997],
              [-7.4141751, 33.703591500000002],
              [-7.4150688, 33.703490100000003],
              [-7.4150877, 33.702462300000001],
              [-7.4144836, 33.702013299999997],
              [-7.4146767, 33.701406300000002],
              [-7.4158998, 33.699978199999997],
              [-7.4169512, 33.699014200000001],
              [-7.4199553, 33.697300400000003],
              [-7.4227877, 33.695479400000004],
              [-7.4239894, 33.694729600000002],
              [-7.4248047, 33.6943725],
              [-7.4260065, 33.693658499999998],
              [-7.4266502, 33.693497800000003],
              [-7.4269468, 33.692880199999998],
              [-7.4273798, 33.692712299999997],
              [-7.4277231, 33.692837300000001],
              [-7.4293753, 33.691676800000003],
              [-7.4294612, 33.691337599999997],
              [-7.4299118, 33.691355299999998],
              [-7.4305555, 33.690462699999998],
              [-7.4311349, 33.689998500000002],
              [-7.4315211, 33.689748600000001],
              [-7.4327442, 33.6886595],
              [-7.4335167, 33.688248700000003],
              [-7.4341175, 33.6878381],
              [-7.4347379, 33.687163099999999],
              [-7.4360191, 33.688395200000002],
              [-7.4361989, 33.688230900000001],
              [-7.4349805, 33.686973999999999],
              [-7.4362766, 33.685774199999997],
              [-7.4375485, 33.684757500000003],
              [-7.4386455, 33.684117200000003],
              [-7.439212, 33.685131400000003],
              [-7.4393064, 33.686067000000001],
              [-7.4391519, 33.686909800000002],
              [-7.4386455, 33.687623899999998],
              [-7.4373838, 33.688895100000003],
              [-7.4369713, 33.689391499999999],
              [-7.4365169, 33.689616399999998],
              [-7.4368211, 33.689855600000001],
              [-7.4371859, 33.689516400000002],
              [-7.437894, 33.6887665],
              [-7.4386021, 33.688141600000002],
              [-7.4393463, 33.6873504],
              [-7.4396536, 33.686517000000002],
              [-7.4397179, 33.685856200000003],
              [-7.4395677, 33.6850886],
              [-7.4392459, 33.6846064],
              [-7.4390097, 33.6839279],
              [-7.439954, 33.683535200000001],
              [-7.4412842, 33.682392399999998],
              [-7.4422285, 33.6821603],
              [-7.4431081, 33.681356700000002],
              [-7.4439879, 33.680713900000001],
              [-7.44425, 33.679933900000002],
              [-7.4442861, 33.679299200000003],
              [-7.4447715, 33.6788259],
              [-7.4453145, 33.678717800000001],
              [-7.4459406, 33.678285500000001],
              [-7.4468056, 33.677676400000003],
              [-7.4476833, 33.6766595],
              [-7.4488152, 33.676957700000003],
              [-7.4501782, 33.676160000000003],
              [-7.4506617, 33.674603500000003],
              [-7.4522021, 33.673376099999999],
              [-7.4525671, 33.673053500000002],
              [-7.4531356, 33.672744899999998],
              [-7.4540606, 33.672532099999998],
              [-7.455597, 33.671560700000001],
              [-7.4558327, 33.671323299999997],
              [-7.4565805, 33.670734000000003],
              [-7.4573047, 33.669937599999997],
              [-7.4587166, 33.6691456],
              [-7.4602487, 33.668274099999998],
              [-7.4639913, 33.666581800000003],
              [-7.4643159, 33.666330899999998],
              [-7.4642659, 33.6658604],
              [-7.4647208, 33.665674600000003],
              [-7.4650469, 33.665753199999997],
              [-7.4658799, 33.664808299999997],
              [-7.4661541, 33.6637244],
              [-7.4668665, 33.662838600000001],
              [-7.4682227, 33.661838400000001],
              [-7.4706861, 33.660009600000002],
              [-7.4734326, 33.658080599999998],
              [-7.4750119, 33.6577877],
              [-7.476549, 33.656556299999998],
              [-7.4781925, 33.655141899999997],
              [-7.479937, 33.654609700000002],
              [-7.4817583, 33.6546442],
              [-7.4826638, 33.654220600000002],
              [-7.4826166, 33.653715499999997],
              [-7.4822818, 33.653365399999998],
              [-7.4823504, 33.652615099999998],
              [-7.4830971, 33.6520364],
              [-7.4841958, 33.651857700000001],
              [-7.4851641, 33.6516965],
              [-7.4856297, 33.650360399999997],
              [-7.4868723, 33.649166999999998],
              [-7.4882947, 33.6496852],
              [-7.489153, 33.649495899999998],
              [-7.4899599, 33.648797399999999],
              [-7.4909984, 33.6484241],
              [-7.4915027, 33.6476203],
              [-7.4923653, 33.647420199999999],
              [-7.4930262, 33.646394899999997],
              [-7.4933525, 33.645690899999998],
              [-7.4946698, 33.645342800000002],
              [-7.4962169, 33.644403199999999],
              [-7.4991588, 33.6423421],
              [-7.5011473, 33.6412829],
              [-7.5017517, 33.640874699999998],
              [-7.5027868, 33.640175399999997],
              [-7.5037737, 33.640146799999997],
              [-7.5045376, 33.6388034],
              [-7.5053358, 33.638210200000003],
              [-7.5095587, 33.636052200000002],
              [-7.5101166, 33.635809199999997],
              [-7.5104514, 33.635444800000002],
              [-7.5157992, 33.632555400000001],
              [-7.5171789, 33.631812199999999],
              [-7.5184213, 33.631145799999999],
              [-7.5194878, 33.630206000000001],
              [-7.5232471, 33.628283600000003],
              [-7.5265967, 33.626566599999997],
              [-7.5282997, 33.625059],
              [-7.5320721, 33.623302700000004],
              [-7.5332223, 33.6223022],
              [-7.5360289, 33.6208727],
              [-7.5370417, 33.6204939],
              [-7.5387401, 33.620327199999998],
              [-7.5407416, 33.620271799999998],
              [-7.5418569, 33.620251000000003],
              [-7.5437138, 33.620239900000001],
              [-7.545333, 33.620015799999997],
              [-7.5462571, 33.619898200000002],
              [-7.5482883, 33.619856400000003],
              [-7.5488776, 33.619800699999999],
              [-7.5498555, 33.619883700000003],
              [-7.550923, 33.619809099999998],
              [-7.5524645, 33.619355400000003],
              [-7.5538228, 33.618592],
              [-7.5552489, 33.617523200000001],
              [-7.5564532, 33.616595799999999],
              [-7.5569285, 33.616309000000001],
              [-7.5582031, 33.615960299999998],
              [-7.5592921, 33.6157428],
              [-7.5596445, 33.615811399999998],
              [-7.5603858, 33.616190199999998],
              [-7.5609734, 33.6166707],
              [-7.5609306, 33.6171595],
              [-7.561495, 33.6176064],
              [-7.5617267, 33.617349099999998],
              [-7.5622759, 33.617098800000001],
              [-7.5625077, 33.616512700000001],
              [-7.5629883, 33.616026699999999],
              [-7.5628896, 33.615216099999998],
              [-7.5633381, 33.6149597],
              [-7.5639706, 33.614632200000003],
              [-7.5649743, 33.614321699999998],
              [-7.5664484, 33.613513099999999],
              [-7.5669977, 33.6131423],
              [-7.5680078, 33.6125531],
              [-7.5687234, 33.6121233],
              [-7.5696333, 33.611667199999999],
              [-7.5708515, 33.611132400000002],
              [-7.5715022, 33.610845099999999],
              [-7.5720167, 33.610638299999998],
              [-7.572434, 33.610487300000003],
              [-7.5727795, 33.610353199999999],
              [-7.5734559, 33.610183499999998],
              [-7.5737558, 33.610030700000003],
              [-7.5740261, 33.610101100000001],
              [-7.5744124, 33.609922300000001],
              [-7.5744773, 33.609450299999999],
              [-7.5746876, 33.609264000000003],
              [-7.5750036, 33.608710899999998],
              [-7.5754215, 33.607999700000001],
              [-7.5756993, 33.607660600000003],
              [-7.5761065, 33.607529700000001],
              [-7.5768683, 33.607511299999999],
              [-7.5808991, 33.606568199999998],
              [-7.5817821, 33.607748600000001],
              [-7.5838409, 33.607038699999997],
              [-7.5857684, 33.606369399999998],
              [-7.5906601, 33.613889499999999],
              [-7.5909342, 33.613729800000002],
              [-7.585579, 33.605268700000003],
              [-7.5856391, 33.6050544],
              [-7.5880262, 33.603972300000002],
              [-7.588544, 33.604667200000002],
              [-7.5937947, 33.6130207],
              [-7.5944851, 33.613179100000004],
              [-7.5976375, 33.612770699999999],
              [-7.5979434, 33.612666900000001],
              [-7.5949175, 33.607790700000002],
              [-7.5950543, 33.607762200000003],
              [-7.5949433, 33.607591399999997],
              [-7.59494, 33.607476599999998],
              [-7.595293, 33.607303000000002],
              [-7.5954577, 33.607325400000001],
              [-7.5956695, 33.607244199999997],
              [-7.5960698, 33.607054699999999],
              [-7.5961167, 33.606947400000003],
              [-7.5959452, 33.606591799999997],
              [-7.5956964, 33.606434999999998],
              [-7.5954308, 33.606073700000003],
              [-7.5953869, 33.605818300000003],
              [-7.5955149, 33.605794799999998],
              [-7.5956264, 33.6056077],
              [-7.5960995, 33.605513500000001],
              [-7.5961518, 33.605612499999999],
              [-7.5965369, 33.605537200000001],
              [-7.5966463, 33.605715400000001],
              [-7.5968524, 33.605625199999999],
              [-7.5996135, 33.609799299999999],
              [-7.6026728, 33.608481099999999],
              [-7.6042963, 33.611051600000003],
              [-7.605473, 33.610505099999997],
              [-7.6001284, 33.602167299999998],
              [-7.6023508, 33.602210900000003],
              [-7.6059282, 33.607765700000002],
              [-7.6079873, 33.606860699999999],
              [-7.6051201, 33.602271100000003],
              [-7.6073424, 33.602307500000002],
              [-7.6114355, 33.608946400000001],
              [-7.611768, 33.608789700000003],
              [-7.6132109, 33.607448499999997],
              [-7.6103335, 33.602879700000003],
              [-7.61048, 33.6027384],
              [-7.6118756, 33.602793800000001],
              [-7.6141673, 33.6056454],
              [-7.6155678, 33.6043995],
              [-7.6145468, 33.603067000000003],
              [-7.6145554, 33.602559499999998],
              [-7.616212, 33.602530899999998],
              [-7.616315, 33.601301300000003],
              [-7.6169244, 33.6013156],
              [-7.6165628, 33.602540599999998],
              [-7.6165554, 33.602745300000002],
              [-7.6167013, 33.602788199999999],
              [-7.6167613, 33.603059899999998],
              [-7.6164956, 33.603265899999997],
              [-7.6175595, 33.604253700000001],
              [-7.6175066, 33.604435799999997],
              [-7.6185666, 33.605247400000003],
              [-7.6178672, 33.605905399999997],
              [-7.6164026, 33.604815100000003],
              [-7.6163151, 33.604816700000001],
              [-7.616228, 33.604874600000002],
              [-7.6162277, 33.6050574],
              [-7.6157746, 33.605846999999997],
              [-7.6158211, 33.605936999999997],
              [-7.6161175, 33.606133499999999],
              [-7.6162525, 33.606173099999999],
              [-7.6167503, 33.605750100000002],
              [-7.617363, 33.606237700000001],
              [-7.616497, 33.6069563],
              [-7.6128637, 33.610424799999997],
              [-7.6113295, 33.611068199999998],
              [-7.6114154, 33.611202200000001],
              [-7.6113831, 33.611416599999998],
              [-7.6111042, 33.611568499999997],
              [-7.61101, 33.611693899999999],
              [-7.6109643, 33.611754599999998],
              [-7.6107325, 33.611883300000002],
              [-7.6093232, 33.612470999999999],
              [-7.6090013, 33.612113600000001],
              [-7.6061046, 33.613409099999998],
              [-7.605847, 33.613275100000003],
              [-7.6054822, 33.6127033],
              [-7.6053428, 33.612748000000003],
              [-7.6058364, 33.6135789],
              [-7.6059436, 33.613909499999998],
              [-7.6023923, 33.615508800000001],
              [-7.6023173, 33.615330100000001],
              [-7.6024783, 33.6152497],
              [-7.602371, 33.615160400000001],
              [-7.6016092, 33.615526699999997],
              [-7.6017808, 33.615812599999998],
              [-7.5942813, 33.619073700000001],
              [-7.593541, 33.617983700000003],
              [-7.593423, 33.6180284],
              [-7.5941526, 33.619234499999997],
              [-7.5915669, 33.620351300000003],
              [-7.5914597, 33.620503100000001],
              [-7.59161, 33.6206818],
              [-7.5918244, 33.620699700000003],
              [-7.6012443, 33.616509499999999],
              [-7.605536, 33.614651100000003],
              [-7.6117471, 33.611849100000001],
              [-7.6126535, 33.611450099999999],
              [-7.6132093, 33.610997099999999],
              [-7.6146092, 33.609762600000003],
              [-7.6149703, 33.609426800000001],
              [-7.6158309, 33.608652300000003],
              [-7.6166968, 33.607847100000001],
              [-7.6169017, 33.607661800000002],
              [-7.617085, 33.6075971],
              [-7.6172697, 33.607564600000003],
              [-7.6182482, 33.607675200000003],
              [-7.6189072, 33.607739700000003],
              [-7.6193764, 33.607789500000003],
              [-7.6201363, 33.607875499999999],
              [-7.620389, 33.608104400000002],
              [-7.6205366, 33.608364600000002],
              [-7.6209999, 33.6092777],
              [-7.6217109, 33.609620800000002],
              [-7.622238, 33.609848700000001],
              [-7.6232073, 33.610260799999999],
              [-7.6240435, 33.610656900000002],
              [-7.6249818, 33.610939299999998],
              [-7.6255606, 33.610982700000001],
              [-7.6261888, 33.610897100000003],
              [-7.6267644, 33.610714700000003],
              [-7.6269212, 33.610686200000004],
              [-7.6270593, 33.6108318],
              [-7.627086, 33.6108172],
              [-7.6269732, 33.610698499999998],
              [-7.6270722, 33.610693499999996],
              [-7.6272051, 33.610617400000002],
              [-7.6291424, 33.609174500000002],
              [-7.6294131, 33.608966799999997],
              [-7.6295217, 33.608900499999997],
              [-7.6296599, 33.608796900000002],
              [-7.6297759, 33.608628899999999],
              [-7.6299613, 33.608264900000002],
              [-7.6300508, 33.608263600000001],
              [-7.6302114, 33.608304199999999],
              [-7.6304105, 33.608328899999997],
              [-7.6305604, 33.608393900000003],
              [-7.6306905, 33.6085116],
              [-7.630777, 33.6087159],
              [-7.6309161, 33.608891200000002],
              [-7.6311306, 33.609018200000001],
              [-7.6313081, 33.6090613],
              [-7.631643, 33.609083599999998],
              [-7.6340226, 33.609195300000003],
              [-7.6340977, 33.609168500000003],
              [-7.6341619, 33.609105999999997],
              [-7.6342546, 33.608282099999997],
              [-7.634302, 33.607446600000003],
              [-7.6340809, 33.607268500000004],
              [-7.6341749, 33.607059700000001],
              [-7.6341886, 33.606961499999997],
              [-7.6342431, 33.606886000000003],
              [-7.6346553, 33.6067763],
              [-7.6347848, 33.606711199999999],
              [-7.6350344, 33.606641199999999],
              [-7.6351438, 33.606648300000003],
              [-7.6352684, 33.606614700000002],
              [-7.6365493, 33.605844400000002],
              [-7.6365594, 33.605857200000003],
              [-7.6365214, 33.605880999999997],
              [-7.6365398, 33.605904700000004],
              [-7.6366705, 33.605822699999997],
              [-7.6366502, 33.605802699999998],
              [-7.636607, 33.605828700000004],
              [-7.6365976, 33.605815300000003],
              [-7.6372897, 33.605398999999998],
              [-7.6389002, 33.605015799999997],
              [-7.6399104, 33.604796899999997],
              [-7.6413473, 33.604718499999997],
              [-7.6413481, 33.604734200000003],
              [-7.6412939, 33.604736500000001],
              [-7.6412942, 33.604763300000002],
              [-7.6414583, 33.6047589],
              [-7.6414553, 33.604728999999999],
              [-7.6414011, 33.604730500000002],
              [-7.6413996, 33.604714600000001],
              [-7.6428466, 33.604645699999999],
              [-7.6441797, 33.6048841],
              [-7.6442942, 33.605146499999996],
              [-7.6446869, 33.605291899999997],
              [-7.6450685, 33.605107799999999],
              [-7.6460051, 33.605510299999999],
              [-7.6479149, 33.606672000000003],
              [-7.6487516, 33.607172300000002],
              [-7.6495564, 33.607851500000002],
              [-7.6496959, 33.608244599999999],
              [-7.6498782, 33.608762800000001],
              [-7.6501787, 33.6092096],
              [-7.6503074, 33.609665300000003],
              [-7.6499319, 33.610612500000002],
              [-7.6499212, 33.611139600000001],
              [-7.650125, 33.612131400000003],
              [-7.6504566, 33.612843499999997],
              [-7.6506128, 33.613391800000002],
              [-7.6504988, 33.613652999999999],
              [-7.6507481, 33.614019999999996],
              [-7.6510478, 33.614204100000002],
              [-7.6514113, 33.614165399999997],
              [-7.6517128, 33.614077199999997],
              [-7.6523144, 33.613903399999998],
              [-7.6526515, 33.613451099999999],
              [-7.653063, 33.613111600000003],
              [-7.6536119, 33.613016000000002],
              [-7.6537621, 33.612596099999998],
              [-7.6536012, 33.612256500000001],
              [-7.6533865, 33.611961700000002],
              [-7.6530969, 33.611666800000002],
              [-7.6532149, 33.611505999999999],
              [-7.6537191, 33.611783000000003],
              [-7.6539444, 33.611827699999999],
              [-7.654277, 33.6118813],
              [-7.6545667, 33.611407700000001],
              [-7.6544701, 33.611014599999997],
              [-7.6546633, 33.611068199999998],
              [-7.6547706, 33.611541699999997],
              [-7.6551247, 33.611470199999999],
              [-7.65535, 33.610934100000001],
              [-7.6552963, 33.610683999999999],
              [-7.6555752, 33.610719699999997],
              [-7.6558756, 33.610094199999999],
              [-7.6562834, 33.60971],
              [-7.65594, 33.608977299999999],
              [-7.6560043, 33.608646700000001],
              [-7.6566266, 33.608468000000002],
              [-7.657045, 33.607771],
              [-7.6576725, 33.606616099999997],
              [-7.6588367, 33.605939300000003],
              [-7.6599471, 33.604901300000002],
              [-7.6607624, 33.603987199999999],
              [-7.6608705, 33.603493299999997],
              [-7.6639913, 33.602277000000001],
              [-7.6655904, 33.601184600000003],
              [-7.6670822, 33.6006328],
              [-7.6678879, 33.600115700000003],
              [-7.6687147, 33.599763000000003],
              [-7.6700788, 33.598746499999997],
              [-7.6716752, 33.597891500000003],
              [-7.6725068, 33.597457300000002],
              [-7.6734868, 33.5973252],
              [-7.6747099, 33.597177799999997],
              [-7.6759115, 33.596931099999999],
              [-7.6764212, 33.597193900000001],
              [-7.677183, 33.5972832],
              [-7.6779127, 33.597557199999997],
              [-7.6782819, 33.5978049],
              [-7.6789534, 33.598334700000002],
              [-7.6789212, 33.598844],
              [-7.6791036, 33.598924500000003],
              [-7.6793149, 33.5968479],
              [-7.6794969, 33.594263499999997],
              [-7.6800507, 33.594135700000002],
              [-7.6802793, 33.592750700000003],
              [-7.6798809, 33.592449100000003],
              [-7.6800156, 33.592288199999999],
              [-7.6809244, 33.591961499999996],
              [-7.6833768, 33.590499600000001],
              [-7.685295, 33.589370500000001],
              [-7.6890254, 33.587380799999998],
              [-7.6925157, 33.585808],
              [-7.6944164, 33.584969200000003],
              [-7.695965, 33.584541999999999],
              [-7.6979924, 33.583811400000002],
              [-7.6987362, 33.584218200000002],
              [-7.6993535, 33.584379400000003],
              [-7.7011726, 33.583475399999998],
              [-7.7013967, 33.582589400000003],
              [-7.7018765, 33.5820589],
              [-7.7028603, 33.581674],
              [-7.7037056, 33.582370400000002],
              [-7.7043111, 33.583103199999996],
              [-7.7052742, 33.581718500000001],
              [-7.7053209, 33.580535599999997],
              [-7.7059405, 33.579664600000001],
              [-7.7068304, 33.578616099999998],
              [-7.7078588, 33.578360000000004],
              [-7.7095772, 33.575458699999999],
              [-7.7139846, 33.572771600000003],
              [-7.7158857, 33.571638100000001],
              [-7.7176239, 33.570595699999998],
              [-7.7195035, 33.569212],
              [-7.7216364, 33.568057000000003],
              [-7.7242671, 33.5664783],
              [-7.726533, 33.565503800000002],
              [-7.7305413, 33.563910700000001],
              [-7.7347615, 33.5626587],
              [-7.7363387, 33.5622325],
              [-7.7364389, 33.562176899999997],
              [-7.7378076, 33.561351500000001],
              [-7.7388376, 33.561208499999999],
              [-7.7402688, 33.560577299999999],
              [-7.7415413, 33.559956900000003],
              [-7.7420563, 33.559314899999997],
              [-7.7427, 33.558134799999998],
              [-7.7446033, 33.5570369],
              [-7.7457191, 33.5562501],
              [-7.746445, 33.555743499999998],
              [-7.7517451, 33.553365700000001],
              [-7.7529988, 33.552536400000001],
              [-7.75351, 33.551821500000003],
              [-7.7540625, 33.551105300000003],
              [-7.7551944, 33.550330099999996],
              [-7.7574335, 33.549332200000002],
              [-7.7595235, 33.548463099999999],
              [-7.7618409, 33.5475779],
              [-7.7639588, 33.546719000000003],
              [-7.7656271, 33.5459143],
              [-7.7712254, 33.543528100000003],
              [-7.7767669, 33.540877199999997],
              [-7.7861653, 33.536876700000001],
              [-7.7922679, 33.536336900000002],
              [-7.7939116, 33.539597899999997],
              [-7.7947114, 33.539866699999997],
              [-7.7949544, 33.538739],
              [-7.7950638, 33.536818099999998],
              [-7.7950891, 33.533206800000002],
              [-7.7984742, 33.531233399999998],
              [-7.7993704, 33.530394200000003],
              [-7.8014502, 33.5297932],
              [-7.8060158, 33.528836699999999],
              [-7.8096915, 33.527624299999999],
              [-7.8169425, 33.527298600000002],
              [-7.8171266, 33.528066899999999],
              [-7.8171696, 33.529176],
              [-7.8178133, 33.530732100000002],
              [-7.8166546, 33.531072000000002],
              [-7.8168906, 33.531250800000002],
              [-7.8179206, 33.530911000000003],
              [-7.8184999, 33.531322400000001],
              [-7.818728, 33.533270899999998],
              [-7.819015, 33.533522400000003],
              [-7.8192295, 33.534327300000001],
              [-7.8199161, 33.535024900000003],
              [-7.8204955, 33.535382499999997],
              [-7.8210749, 33.535990599999998],
              [-7.8219118, 33.53642],
              [-7.8229203, 33.536884999999998],
              [-7.8233359, 33.536988000000001],
              [-7.8238001, 33.5370639],
              [-7.8252807, 33.535704500000001],
              [-7.8263546, 33.534842300000001],
              [-7.82689, 33.534309399999998],
              [-7.8283491, 33.533898000000001],
              [-7.8288115, 33.533892399999999],
              [-7.8293801, 33.533752900000003],
              [-7.8298629, 33.533466300000001],
              [-7.8306676, 33.533234200000003],
              [-7.831322, 33.532793300000002],
              [-7.8307212, 33.532277299999997],
              [-7.8309492, 33.531643199999998],
              [-7.8313113, 33.530969800000001],
              [-7.8318478, 33.529756999999996],
              [-7.8331996, 33.528952099999998],
              [-7.8413525, 33.524704],
              [-7.8417387, 33.524167400000003],
              [-7.8435411, 33.523559200000001],
              [-7.8447857, 33.522772099999997],
              [-7.8452792, 33.522950999999999],
              [-7.8467732, 33.522696400000001],
              [-7.8474035, 33.522253300000003],
              [-7.8483141, 33.5221965],
              [-7.8493133, 33.520321299999999],
              [-7.8526607, 33.518425100000002],
              [-7.8534556, 33.5179641],
              [-7.8543966, 33.517606700000002],
              [-7.8556727, 33.517165300000002],
              [-7.8570723, 33.516899600000002],
              [-7.8597883, 33.5160956],
              [-7.8619695, 33.515300400000001],
              [-7.8634968, 33.514936599999999],
              [-7.8648851, 33.5149045],
              [-7.8657407, 33.51493],
              [-7.8662224, 33.514956300000001],
              [-7.8667599, 33.515180000000001],
              [-7.86735, 33.515468400000003],
              [-7.8674788, 33.515884499999999],
              [-7.8668592, 33.516603199999999],
              [-7.8677669, 33.516546599999998],
              [-7.8678956, 33.5168508],
              [-7.8683248, 33.516976],
              [-7.8689256, 33.516868700000003],
              [-7.869226, 33.516582399999997],
              [-7.8695908, 33.516725600000001],
              [-7.8696766, 33.516564500000001],
              [-7.8697839, 33.516349900000002],
              [-7.8709222, 33.516335599999998],
              [-7.8712333, 33.515748500000001],
              [-7.8711153, 33.515326299999998],
              [-7.8698493, 33.515434999999997],
              [-7.8694523, 33.515251599999999],
              [-7.8693477, 33.514704500000001],
              [-7.8692941, 33.513862000000003],
              [-7.8695918, 33.5131625],
              [-7.8702355, 33.512521900000003],
              [-7.8717268, 33.511607300000001],
              [-7.8748194, 33.512462499999998],
              [-7.8749562, 33.511261099999999],
              [-7.8751118, 33.510381799999998],
              [-7.8755248, 33.510835299999997],
              [-7.8761176, 33.510033700000001],
              [-7.8763853, 33.509536400000002],
              [-7.8767367, 33.509000999999998],
              [-7.8775306, 33.508539800000001],
              [-7.8785713, 33.507823199999997],
              [-7.8799875, 33.507417099999998],
              [-7.8811891, 33.506742199999998],
              [-7.881683, 33.506357600000001],
              [-7.883391, 33.506382600000002],
              [-7.8838502, 33.507184199999998],
              [-7.8854681, 33.507058899999997],
              [-7.8874636, 33.506432699999998],
              [-7.8895536, 33.505602500000002],
              [-7.8895536, 33.504307099999998],
              [-7.8898025, 33.503294400000001],
              [-7.8915406, 33.502950900000002],
              [-7.8922659, 33.501898799999999],
              [-7.8940726, 33.500692800000003],
              [-7.8959695, 33.4994868],
              [-7.8985658, 33.498169799999999],
              [-7.9012022, 33.497360999999998],
              [-7.9015755, 33.4972809],
              [-7.9024961, 33.497288500000003],
              [-7.903526, 33.497986400000002],
              [-7.9038156, 33.497136400000002],
              [-7.9036441, 33.496161200000003],
              [-7.9041054, 33.4954812],
              [-7.9050603, 33.495016],
              [-7.9060043, 33.495078599999999],
              [-7.90697, 33.496044900000001],
              [-7.9079914, 33.494650499999999],
              [-7.9089248, 33.495232100000003],
              [-7.9096093, 33.495105500000001],
              [-7.910408, 33.494745299999998],
              [-7.911328, 33.4938596],
              [-7.9127528, 33.494328799999998],
              [-7.9142747, 33.493360799999998],
              [-7.9148686, 33.491617300000001],
              [-7.9197706, 33.489161699999997],
              [-7.9228937, 33.489452499999999],
              [-7.9246345, 33.487867799999997],
              [-7.9266251, 33.487024499999997],
              [-7.9303909, 33.484773599999997],
              [-7.9416262, 33.481408100000003],
              [-7.9501556, 33.4808077],
              [-7.9534601, 33.480619699999998],
              [-7.9549922, 33.481602700000003],
              [-7.9571615, 33.482511000000002],
              [-7.9587688, 33.482755300000001],
              [-7.9590948, 33.482084800000003],
              [-7.9593113, 33.481639700000002],
              [-7.962553, 33.481150399999997],
              [-7.9647898, 33.480268500000001],
              [-7.9688718, 33.478604199999999],
              [-7.9714044, 33.4773572],
              [-7.9735236, 33.476313699999999],
              [-7.975662, 33.4752607],
              [-7.9774129, 33.474398600000001],
              [-7.9806343, 33.472812300000001],
              [-7.9811434, 33.4725617],
              [-7.9820199, 33.472326000000002],
              [-7.9831177, 33.4720309],
              [-7.9837999, 33.471847500000003],
              [-7.9854138, 33.470824100000002],
              [-7.9867245, 33.469993199999998],
              [-7.9880365, 33.469515899999998],
              [-7.9892543, 33.469073000000002],
              [-7.9906478, 33.468566199999998],
              [-7.9915718, 33.4682301],
              [-7.9933497, 33.467143399999998],
              [-7.9940202, 33.466733599999998],
              [-7.9967667, 33.4658297],
              [-7.9982636, 33.464775199999998],
              [-7.9993309, 33.464023500000003],
              [-8.0004855, 33.463169800000003],
              [-8.0013694, 33.462516299999997],
              [-8.002973799999999, 33.461531600000001],
              [-8.0050664, 33.460037200000002],
              [-8.006217400000001, 33.459401800000002],
              [-8.007083400000001, 33.458923800000001],
              [-8.009859199999999, 33.457532800000003],
              [-8.011123899999999, 33.457016299999999],
              [-8.014804399999999, 33.4556659],
              [-8.021390200000001, 33.454224099999998],
              [-8.0264191, 33.453123099999999],
              [-8.0293154, 33.452211599999998],
              [-8.0322245, 33.4512961],
              [-8.0382637, 33.449395600000003],
              [-8.0411433, 33.448385500000001],
              [-8.0432667, 33.447640700000001],
              [-8.0509322, 33.444951799999998],
              [-8.0526596, 33.4439742],
              [-8.054183099999999, 33.443156000000002],
              [-8.0556529, 33.442344900000002],
              [-8.0581637, 33.441841199999999],
              [-8.059731299999999, 33.442287399999998],
              [-8.060703699999999, 33.442406400000003],
              [-8.063022500000001, 33.442033700000003],
              [-8.0628303, 33.443133699999997],
              [-8.064446200000001, 33.442592699999999],
              [-8.0641275, 33.441504899999998],
              [-8.0653861, 33.441353499999998],
              [-8.0658347, 33.440377099999999],
              [-8.0669612, 33.439567699999998],
              [-8.068044799999999, 33.438516700000001],
              [-8.072325599999999, 33.436899699999998],
              [-8.0794052, 33.434805300000001],
              [-8.0858364, 33.433120899999999],
              [-8.0868688, 33.432929299999998],
              [-8.0886195, 33.431882799999997],
              [-8.092354200000001, 33.431026299999999],
              [-8.0932941, 33.431285299999999],
              [-8.094169000000001, 33.430458600000001],
              [-8.096277799999999, 33.428774300000001],
              [-8.0996551, 33.427917800000003],
              [-8.1002951, 33.426829300000001],
              [-8.103636099999999, 33.425286999999997],
              [-8.1121646, 33.4219668],
              [-8.1146604, 33.421208100000001],
              [-8.1168961, 33.419987900000002],
              [-8.118771199999999, 33.418677899999999],
              [-8.1188751, 33.418498],
              [-8.1229982, 33.416350600000001],
              [-8.128898, 33.414061599999997],
              [-8.1383265, 33.410752500000001],
              [-8.141166500000001, 33.411142699999999],
              [-8.1450798, 33.410664099999998],
              [-8.148613299999999, 33.409571200000002],
              [-8.150483400000001, 33.4081732],
              [-8.151979799999999, 33.4074843],
              [-8.152863200000001, 33.407228500000002],
              [-8.1536007, 33.407291000000001],
              [-8.1549184, 33.407281099999999],
              [-8.155706199999999, 33.407440899999997],
              [-8.156103399999999, 33.407871900000004],
              [-8.1569366, 33.407717099999999],
              [-8.1566361, 33.4071353],
              [-8.157661900000001, 33.406901300000001],
              [-8.158181000000001, 33.407314100000001],
              [-8.159599800000001, 33.407125100000002],
              [-8.16072, 33.406668500000002],
              [-8.1618409, 33.407227200000001],
              [-8.162512299999999, 33.4073882],
              [-8.163208300000001, 33.4072715],
              [-8.164749, 33.407163400000002],
              [-8.1658461, 33.406509999999997],
              [-8.167916699999999, 33.405211299999998],
              [-8.1684854, 33.405059100000003],
              [-8.1690679, 33.404797600000002],
              [-8.170036700000001, 33.404616599999997],
              [-8.170871399999999, 33.404485000000001],
              [-8.171706199999999, 33.404496600000002],
              [-8.1728991, 33.404337200000001],
              [-8.1739634, 33.4039717],
              [-8.1751887, 33.403816800000001],
              [-8.176081399999999, 33.404020099999997],
              [-8.177208200000001, 33.404643299999996],
              [-8.1784845, 33.405094900000002],
              [-8.1807026, 33.404622699999997],
              [-8.181956899999999, 33.404355700000004],
              [-8.184449799999999, 33.4027125],
              [-8.187475299999999, 33.399487999999998],
              [-8.191296899999999, 33.396524999999997],
              [-8.1956528, 33.394357300000003],
              [-8.1966781, 33.393711099999997],
              [-8.200757599999999, 33.391139699999997],
              [-8.2050921, 33.389563099999997],
              [-8.2111883, 33.386940099999997],
              [-8.2177618, 33.384800800000001],
              [-8.226986500000001, 33.382181299999999],
              [-8.2277562, 33.382142299999998],
              [-8.2397195, 33.3815363],
              [-8.2443179, 33.381319499999996],
              [-8.247999999999999, 33.378954299999997],
              [-8.2509976, 33.377106900000001],
              [-8.253845200000001, 33.376261100000001],
              [-8.2549872, 33.376176600000001],
              [-8.255895199999999, 33.376221200000003],
              [-8.256584699999999, 33.376255100000002],
              [-8.2579057, 33.376550799999997],
              [-8.259226699999999, 33.376547799999997],
              [-8.260427, 33.376097600000001],
              [-8.2619062, 33.3758087],
              [-8.262299000000001, 33.375761199999999],
              [-8.264907600000001, 33.375445800000001],
              [-8.2657396, 33.375070299999997],
              [-8.266833399999999, 33.374576699999999],
              [-8.269240999999999, 33.373553600000001],
              [-8.270403099999999, 33.372932599999999],
              [-8.2734767, 33.371290299999998],
              [-8.2751074, 33.370727199999997],
              [-8.2770049, 33.370071899999999],
              [-8.277338, 33.369734399999999],
              [-8.279390899999999, 33.369538400000003],
              [-8.2814113, 33.369043699999999],
              [-8.284225599999999, 33.368626900000002],
              [-8.2881442, 33.368789399999997],
              [-8.2916381, 33.368171599999997],
              [-8.293285900000001, 33.3680637],
              [-8.2936975, 33.368125900000003],
              [-8.2950754, 33.368334099999998],
              [-8.294551800000001, 33.3687915],
              [-8.2955322, 33.368962099999997],
              [-8.296152599999999, 33.368110000000001],
              [-8.296643899999999, 33.367962800000001],
              [-8.298436499999999, 33.367425500000003],
              [-8.298023300000001, 33.3685847],
              [-8.297955699999999, 33.369743999999997],
              [-8.298360199999999, 33.369863600000002],
              [-8.298539, 33.369251900000002],
              [-8.2988391, 33.368663599999998],
              [-8.2994301, 33.367783299999999],
              [-8.300051699999999, 33.367060299999999],
              [-8.2996648, 33.3667357],
              [-8.301380999999999, 33.3659277],
              [-8.301632, 33.366231200000001],
              [-8.3021935, 33.366053999999998],
              [-8.302502799999999, 33.365744200000002],
              [-8.302920200000001, 33.3652303],
              [-8.3044481, 33.364487400000002],
              [-8.307055200000001, 33.362610699999998],
              [-8.3071559, 33.362538200000003],
              [-8.3081976, 33.361914599999999],
              [-8.308971700000001, 33.361384800000003],
              [-8.309782800000001, 33.360834599999997],
              [-8.310608999999999, 33.360264700000002],
              [-8.311559600000001, 33.359589],
              [-8.3120446, 33.358753800000002],
              [-8.3130487, 33.357778799999998],
              [-8.3139199, 33.356226700000001],
              [-8.3149423, 33.354440599999997],
              [-8.3163147, 33.352043399999999],
              [-8.3163614, 33.351975600000003],
              [-8.3175785, 33.350213400000001],
              [-8.3191492, 33.347842],
              [-8.3205978, 33.3461918],
              [-8.321305499999999, 33.344688699999999],
              [-8.3224324, 33.342295499999999],
              [-8.322464399999999, 33.342227800000003],
              [-8.324664, 33.3374503],
              [-8.325760300000001, 33.335835199999998],
              [-8.326715200000001, 33.334695099999998],
              [-8.3273954, 33.333280600000002],
              [-8.3283074, 33.332240800000001],
              [-8.3288929, 33.331547200000003],
              [-8.331015300000001, 33.3290334],
              [-8.3319274, 33.328004100000001],
              [-8.3325128, 33.327300200000003],
              [-8.3326999, 33.327075399999998],
              [-8.334444400000001, 33.325325599999999],
              [-8.3353392, 33.3242525],
              [-8.3362512, 33.323207199999999],
              [-8.336978200000001, 33.322164399999998],
              [-8.337812899999999, 33.321366099999999],
              [-8.338106700000001, 33.320468699999999],
              [-8.3380282, 33.319409],
              [-8.3383859, 33.319110100000003],
              [-8.3387414, 33.318769799999998],
              [-8.340710700000001, 33.3187414],
              [-8.345263299999999, 33.316062799999997],
              [-8.346599700000001, 33.315221999999999],
              [-8.347974600000001, 33.313898600000002],
              [-8.3500739, 33.312847900000001],
              [-8.3524308, 33.311169399999997],
              [-8.3561151, 33.308446799999999],
              [-8.3592479, 33.306405599999998],
              [-8.360849099999999, 33.305362199999998],
              [-8.362161, 33.304507399999999],
              [-8.363833700000001, 33.303204800000003],
              [-8.3655755, 33.301848499999998],
              [-8.366999699999999, 33.300739399999998],
              [-8.368592100000001, 33.299696099999998],
              [-8.370534899999999, 33.298423200000002],
              [-8.3724609, 33.297161299999999],
              [-8.3739043, 33.296215599999996],
              [-8.374724499999999, 33.295678199999998],
              [-8.375922600000001, 33.2945359],
              [-8.3771097, 33.293404000000002],
              [-8.3784302, 33.292144899999997],
              [-8.380001200000001, 33.290788599999999],
              [-8.3814739, 33.289517099999998],
              [-8.3831338, 33.288084099999999],
              [-8.3875967, 33.284807299999997],
              [-8.3894366, 33.283456399999999],
              [-8.3913776, 33.282031199999999],
              [-8.392767600000001, 33.281010700000003],
              [-8.393765399999999, 33.280278000000003],
              [-8.3938197, 33.280238099999998],
              [-8.395180999999999, 33.279429499999999],
              [-8.3973678, 33.278130699999998],
              [-8.399271600000001, 33.2769999],
              [-8.4011972, 33.275856099999999],
              [-8.4033152, 33.274597999999997],
              [-8.4055125, 33.273292900000001],
              [-8.409280499999999, 33.271054700000001],
              [-8.411612699999999, 33.269669299999997],
              [-8.4127776, 33.268977399999997],
              [-8.414984199999999, 33.2677324],
              [-8.418728700000001, 33.265619899999997],
              [-8.4187815, 33.265590099999997],
              [-8.422525200000001, 33.263621100000002],
              [-8.4265179, 33.261521199999997],
              [-8.429475, 33.259965999999999],
              [-8.432482200000001, 33.258384300000003],
              [-8.435246299999999, 33.256930500000003],
              [-8.4369619, 33.256028100000002],
              [-8.438512100000001, 33.255212700000001],
              [-8.442091400000001, 33.253886799999997],
              [-8.4468443, 33.252126099999998],
              [-8.4493983, 33.251185900000003],
              [-8.4526299, 33.249996199999998],
              [-8.4560855, 33.248724099999997],
              [-8.459416900000001, 33.247497699999997],
              [-8.4627532, 33.246476299999998],
              [-8.464919399999999, 33.246126699999998],
              [-8.466199400000001, 33.245961299999998],
              [-8.4698791, 33.245487799999999],
              [-8.473279700000001, 33.245340200000001],
              [-8.475811999999999, 33.245323999999997],
              [-8.477855099999999, 33.245286100000001],
              [-8.479058800000001, 33.245427900000003],
              [-8.4803946, 33.2455243],
              [-8.4845177, 33.2457517],
              [-8.4859092, 33.2458338],
              [-8.486110699999999, 33.245845699999997],
              [-8.486174500000001, 33.245849499999998],
              [-8.487464599999999, 33.2459256],
              [-8.4895209, 33.246425000000002],
              [-8.4905952, 33.247031200000002],
              [-8.492419999999999, 33.248060899999999],
              [-8.4924766, 33.248089800000002],
              [-8.494702, 33.249222699999997],
              [-8.4948876, 33.249317099999999],
              [-8.4949163, 33.249334599999997],
              [-8.495551600000001, 33.249721100000002],
              [-8.4962239, 33.250129999999999],
              [-8.496590599999999, 33.250419100000002],
              [-8.4973823, 33.251043000000003],
              [-8.4976792, 33.251276900000001],
              [-8.4977313, 33.251317899999997],
              [-8.4986803, 33.251951099999999],
              [-8.499148999999999, 33.2522637],
              [-8.5001902, 33.253411399999997],
              [-8.4991191, 33.254577400000002],
              [-8.499007499999999, 33.254542399999998],
              [-8.4979467, 33.255655400000002],
              [-8.4975457, 33.2564913],
              [-8.497760299999999, 33.256547400000002],
              [-8.4981589, 33.255722499999997],
              [-8.4984872, 33.255803399999998],
              [-8.498981300000001, 33.255317400000003],
              [-8.499235799999999, 33.255453899999999],
              [-8.500401699999999, 33.253873400000003],
              [-8.500913499999999, 33.254149699999999],
              [-8.499792100000001, 33.255635599999998],
              [-8.5000187, 33.255779099999998],
              [-8.501995000000001, 33.255645299999998],
              [-8.502525500000001, 33.255506599999997],
              [-8.5026435, 33.255721000000001],
              [-8.501997899999999, 33.255927800000002],
              [-8.500734100000001, 33.255994000000001],
              [-8.5007456, 33.255875799999998],
              [-8.5002455, 33.255893299999997],
              [-8.5002745, 33.256220300000003],
              [-8.5003154, 33.256315000000001],
              [-8.500432500000001, 33.256413999999999],
              [-8.500411400000001, 33.256480500000002],
              [-8.500409899999999, 33.256608100000001],
              [-8.5003656, 33.256946800000001],
              [-8.500223099999999, 33.257476199999999],
              [-8.500197200000001, 33.257954900000001],
              [-8.500095099999999, 33.258113600000001],
              [-8.498234500000001, 33.257098599999999],
              [-8.4979864, 33.257143399999997],
              [-8.495618, 33.256789400000002],
              [-8.495518799999999, 33.256634900000002],
              [-8.495225599999999, 33.256764799999999],
              [-8.495309000000001, 33.256941599999998],
              [-8.4981074, 33.257385200000002],
              [-8.498687800000001, 33.257591499999997],
              [-8.500173699999999, 33.2585251],
              [-8.5003195, 33.2592389],
              [-8.5009058, 33.260652200000003],
              [-8.500743, 33.262286400000001],
              [-8.502057000000001, 33.2636556],
              [-8.5038567, 33.264220199999997],
              [-8.5049153, 33.265517099999997],
              [-8.5063282, 33.265485699999999],
              [-8.5073431, 33.265101700000002],
              [-8.508094399999999, 33.264712799999998],
              [-8.509712199999999, 33.266047399999998],
              [-8.5104902, 33.2653441],
              [-8.5109063, 33.265305900000001],
              [-8.5119229, 33.2647981],
              [-8.5126954, 33.264315600000003],
              [-8.5136655, 33.263756700000002],
              [-8.5142933, 33.263212699999997],
              [-8.515080899999999, 33.262090899999997],
              [-8.5161348, 33.260984700000002],
              [-8.516697000000001, 33.260151299999997],
              [-8.5174772, 33.2589854],
              [-8.5178338, 33.258386700000003],
              [-8.518318600000001, 33.258079500000001],
              [-8.5186724, 33.2584035],
              [-8.5189539, 33.260630800000001],
              [-8.519542599999999, 33.261338100000003],
              [-8.5204416, 33.260746500000003],
              [-8.522449099999999, 33.260385100000001],
              [-8.524119499999999, 33.259228700000001],
              [-8.5253253, 33.258308900000003],
              [-8.5260208, 33.259169700000001],
              [-8.526085999999999, 33.259250399999999],
              [-8.5265919, 33.2589629],
              [-8.525542, 33.257695200000001],
              [-8.526267900000001, 33.257063700000003],
              [-8.5272211, 33.256801199999998],
              [-8.529310600000001, 33.255459999999999],
              [-8.5300841, 33.254679000000003],
              [-8.530659200000001, 33.2540397],
              [-8.5310486, 33.254869599999999],
              [-8.531678400000001, 33.254080000000002],
              [-8.532216500000001, 33.252822600000002],
              [-8.53299, 33.252100599999999],
              [-8.5333965, 33.252841600000004],
              [-8.534243699999999, 33.252614999999999],
              [-8.5349384, 33.251729300000001],
              [-8.535411, 33.251012500000002],
              [-8.536387299999999, 33.250447700000002],
              [-8.537716100000001, 33.249783700000002],
              [-8.5390207, 33.248632499999999],
              [-8.540202499999999, 33.247545500000001],
              [-8.5418558, 33.246826400000003],
              [-8.543674299999999, 33.246327000000001],
              [-8.5446528, 33.246066300000003],
              [-8.5463308, 33.245508700000002],
              [-8.546912900000001, 33.244301399999998],
              [-8.5467476, 33.242917300000002],
              [-8.547949300000001, 33.241480299999999],
              [-8.547953, 33.239984800000002],
              [-8.5492209, 33.239183300000001],
              [-8.5486971, 33.238731700000002],
              [-8.5488859, 33.238217599999999],
              [-8.5499791, 33.237772399999997],
              [-8.5501068, 33.237219500000002],
              [-8.5499759, 33.236113699999997],
              [-8.5503695, 33.235855800000003],
              [-8.551151600000001, 33.2357917],
              [-8.5517729, 33.236409700000003],
              [-8.553187100000001, 33.235545700000003],
              [-8.554642100000001, 33.235468900000001],
              [-8.5559429, 33.234435900000001],
              [-8.557943699999999, 33.234182500000003],
              [-8.5584048, 33.233370800000003],
              [-8.558759800000001, 33.2322931],
              [-8.557559100000001, 33.231403899999997],
              [-8.555741299999999, 33.231335999999999],
              [-8.5548821, 33.229818000000002],
              [-8.555142200000001, 33.227672599999998],
              [-8.555482700000001, 33.2260177],
              [-8.556760000000001, 33.223827300000004],
              [-8.5577352, 33.222985000000001],
              [-8.5594593, 33.222104999999999],
              [-8.560033300000001, 33.222076700000002],
              [-8.5603797, 33.221926199999999],
              [-8.5602207, 33.221195399999999],
              [-8.560444, 33.2207686],
              [-8.560784, 33.220510099999998],
              [-8.561313999999999, 33.220095499999999],
              [-8.561833200000001, 33.219653000000001],
              [-8.5620397, 33.219446900000001],
              [-8.5623041, 33.219209999999997],
              [-8.5625321, 33.218867099999997],
              [-8.5629741, 33.218374400000002],
              [-8.5635578, 33.217859199999999],
              [-8.5638614, 33.217685500000002],
              [-8.5642032, 33.2173838],
              [-8.5660718, 33.2179079],
              [-8.5678152, 33.217903700000001],
              [-8.5698814, 33.218181000000001],
              [-8.572662299999999, 33.2191142],
              [-8.575629899999999, 33.218656600000003],
              [-8.5766154, 33.217689700000001],
              [-8.5765236, 33.217048699999999],
              [-8.576523, 33.216381200000001],
              [-8.5769708, 33.215967599999999],
              [-8.5776982, 33.216179799999999],
              [-8.578200300000001, 33.215924299999998],
              [-8.5789711, 33.215858799999999],
              [-8.5790369, 33.215321400000001],
              [-8.579159300000001, 33.215074899999998],
              [-8.5796654, 33.214824100000001],
              [-8.579651200000001, 33.214575099999998],
              [-8.5797843, 33.213612900000001],
              [-8.5801251, 33.213582299999999],
              [-8.580518100000001, 33.212921000000001],
              [-8.580359100000001, 33.212632800000002],
              [-8.581055900000001, 33.212380199999998],
              [-8.5813962, 33.212048000000003],
              [-8.581736599999999, 33.211765100000001],
              [-8.5820133, 33.211687499999996],
              [-8.582482300000001, 33.211761500000001],
              [-8.5832251, 33.211424299999997],
              [-8.583023799999999, 33.210796799999997],
              [-8.5834396, 33.210488400000003],
              [-8.584931299999999, 33.209895799999998],
              [-8.584812100000001, 33.209393400000003],
              [-8.5854351, 33.208814099999998],
              [-8.5864271, 33.208781799999997],
              [-8.5865068, 33.208234099999999],
              [-8.587272799999999, 33.207693599999999],
              [-8.587920499999999, 33.206844500000003],
              [-8.588446299999999, 33.206199499999997],
              [-8.5887756, 33.205422599999999],
              [-8.589657799999999, 33.2050403],
              [-8.5899748, 33.204554700000003],
              [-8.5896489, 33.203444400000002],
              [-8.5900628, 33.203000799999998],
              [-8.590579699999999, 33.203060100000002],
              [-8.590738699999999, 33.202813300000003],
              [-8.5904486, 33.202348299999997],
              [-8.5905325, 33.201692700000002],
              [-8.5907357, 33.201289699999997],
              [-8.5913077, 33.200698099999997],
              [-8.591705899999999, 33.200315600000003],
              [-8.5923643, 33.200109900000001],
              [-8.593311399999999, 33.199798800000003],
              [-8.5940146, 33.1998143],
              [-8.594259299999999, 33.1995608],
              [-8.593772299999999, 33.199259499999997],
              [-8.593924299999999, 33.198926399999998],
              [-8.5939639, 33.198568399999999],
              [-8.594410099999999, 33.198482499999997],
              [-8.5943421, 33.197605600000003],
              [-8.594615599999999, 33.196769500000002],
              [-8.5954911, 33.196711899999997],
              [-8.595520199999999, 33.196159000000002],
              [-8.595955699999999, 33.196177599999999],
              [-8.596750999999999, 33.196053999999997],
              [-8.5974974, 33.196393],
              [-8.5979992, 33.1961893],
              [-8.598425900000001, 33.1956408],
              [-8.5987896, 33.194865],
              [-8.5980097, 33.193950999999998],
              [-8.5978803, 33.193173000000002],
              [-8.5993046, 33.193319899999999],
              [-8.5998264, 33.192983699999999],
              [-8.6007041, 33.192440499999996],
              [-8.599906799999999, 33.191945099999998],
              [-8.5994831, 33.191239899999999],
              [-8.5998593, 33.190502199999997],
              [-8.6001139, 33.190387800000003],
              [-8.6003797, 33.190302500000001],
              [-8.6007306, 33.190470900000001],
              [-8.601117800000001, 33.190815899999997],
              [-8.6017698, 33.190730299999998],
              [-8.6025539, 33.191040399999999],
              [-8.6032925, 33.190820000000002],
              [-8.6041211, 33.190436099999999],
              [-8.6047919, 33.190317399999998],
              [-8.6054355, 33.1901522],
              [-8.606192800000001, 33.190633400000003],
              [-8.6071285, 33.190940599999998],
              [-8.6073431, 33.190416900000002],
              [-8.607493, 33.190172799999999],
              [-8.608535, 33.189171600000002],
              [-8.608735100000001, 33.189118700000002],
              [-8.608980000000001, 33.189355800000001],
              [-8.610398699999999, 33.187805300000001],
              [-8.611193800000001, 33.185910499999999],
              [-8.6111109, 33.184083700000002],
              [-8.6121257, 33.182805500000001],
              [-8.6128342, 33.1824315],
              [-8.613675499999999, 33.181871200000003],
              [-8.614459399999999, 33.181012299999999],
              [-8.6155826, 33.1801429],
              [-8.6164884, 33.179186700000002],
              [-8.617350999999999, 33.178008800000001],
              [-8.618201300000001, 33.177901499999997],
              [-8.618792600000001, 33.178264599999999],
              [-8.6191865, 33.177804600000002],
              [-8.6190376, 33.177534399999999],
              [-8.620033899999999, 33.176828999999998],
              [-8.6212131, 33.177532900000003],
              [-8.6217597, 33.1773004],
              [-8.620942899999999, 33.176134099999999],
              [-8.6204676, 33.176390599999998],
              [-8.619541699999999, 33.175954699999998],
              [-8.619757399999999, 33.1753663],
              [-8.6194378, 33.174202999999999],
              [-8.6201109, 33.173683799999999],
              [-8.6199767, 33.173063399999997],
              [-8.6198481, 33.172539399999998],
              [-8.620785700000001, 33.173129500000002],
              [-8.6209898, 33.173007499999997],
              [-8.6209474, 33.172461900000002],
              [-8.6212819, 33.172312499999997],
              [-8.621536799999999, 33.172632900000004],
              [-8.622759200000001, 33.172793400000003],
              [-8.6234445, 33.172216200000001],
              [-8.6231993, 33.171422300000003],
              [-8.623818099999999, 33.171113800000001],
              [-8.626256700000001, 33.172226700000003],
              [-8.626785099999999, 33.171699500000003],
              [-8.6251844, 33.170446699999999],
              [-8.6250412, 33.170043],
              [-8.625378700000001, 33.169866300000002],
              [-8.625023799999999, 33.169211799999999],
              [-8.6256603, 33.1679928],
              [-8.6265327, 33.168021099999997],
              [-8.62701, 33.167832900000001],
              [-8.627129999999999, 33.1674796],
              [-8.6265728, 33.166230200000001],
              [-8.6277024, 33.165174299999997],
              [-8.627515799999999, 33.164526500000001],
              [-8.629674, 33.163056500000003],
              [-8.6285969, 33.161962699999997],
              [-8.629704, 33.1616882],
              [-8.6291102, 33.1607542],
              [-8.628547299999999, 33.160662500000001],
              [-8.628005699999999, 33.160677499999998],
              [-8.6278305, 33.160705399999998],
              [-8.6271226, 33.160265799999998],
              [-8.6258158, 33.1597869],
              [-8.6250368, 33.159313900000001],
              [-8.6244438, 33.158674300000001],
              [-8.623940899999999, 33.158042600000002],
              [-8.6242912, 33.156958799999998],
              [-8.6234448, 33.155955800000001],
              [-8.6231758, 33.155480599999997],
              [-8.623181000000001, 33.154907000000001],
              [-8.6226273, 33.153972400000001],
              [-8.6210463, 33.152140899999999],
              [-8.620601300000001, 33.1516588],
              [-8.6200717, 33.150930500000001],
              [-8.618694899999999, 33.149443900000001],
              [-8.6170606, 33.1481548],
              [-8.61655, 33.147373399999999],
              [-8.615036399999999, 33.145153899999997],
              [-8.613640800000001, 33.143437499999997],
              [-8.6128211, 33.140853800000002],
              [-8.6132911, 33.139589999999998],
              [-8.6138385, 33.139366199999998],
              [-8.613964299999999, 33.139563799999998],
              [-8.6144832, 33.139322100000001],
              [-8.6145578, 33.139031000000003],
              [-8.6148478, 33.138880299999997],
              [-8.6155843, 33.138846200000003],
              [-8.616041900000001, 33.1389298],
              [-8.6161545, 33.1384148],
              [-8.6158667, 33.138190100000003],
              [-8.6159733, 33.1374803],
              [-8.616478600000001, 33.136490299999998],
              [-8.6171934, 33.1360727],
              [-8.6181257, 33.136277100000001],
              [-8.619122900000001, 33.136206600000001],
              [-8.619745200000001, 33.135610900000003],
              [-8.619443199999999, 33.135357599999999],
              [-8.618783799999999, 33.135957400000002],
              [-8.618156600000001, 33.1360052],
              [-8.6167017, 33.135228699999999],
              [-8.6165726, 33.134774800000002],
              [-8.6162317, 33.134645300000003],
              [-8.6161201, 33.134710099999999],
              [-8.6146545, 33.133938499999999],
              [-8.6140361, 33.133731400000002],
              [-8.613189, 33.133726199999998],
              [-8.6127994, 33.133596699999998],
              [-8.612706599999999, 33.133280900000003],
              [-8.6134301, 33.132985699999999],
              [-8.6133621, 33.132856199999999],
              [-8.6135476, 33.132825199999999],
              [-8.6148299, 33.133617399999999],
              [-8.616313999999999, 33.134363100000002],
              [-8.6163305, 33.134481999999998],
              [-8.6166354, 33.134642700000001],
              [-8.617166599999999, 33.134553799999999],
              [-8.6180518, 33.135025499999998],
              [-8.6188713, 33.134614800000001],
              [-8.619559199999999, 33.134884900000003],
              [-8.6214073, 33.135901400000002],
              [-8.6228639, 33.136681000000003],
              [-8.6234261, 33.136925300000001],
              [-8.6240869, 33.137136400000003],
              [-8.625043700000001, 33.137125099999999],
              [-8.6256591, 33.136927999999997],
              [-8.628422199999999, 33.136176599999999],
              [-8.631894000000001, 33.135131800000003],
              [-8.6344107, 33.133890100000002],
              [-8.635595199999999, 33.133061699999999],
              [-8.6366791, 33.132085500000002],
              [-8.6386982, 33.130100800000001],
              [-8.645006499999999, 33.123356299999998],
              [-8.644540900000001, 33.123176600000001],
              [-8.6397172, 33.128301700000002],
              [-8.6363634, 33.131676300000002],
              [-8.6346103, 33.1332594],
              [-8.632956999999999, 33.134151500000002],
              [-8.632708299999999, 33.133626300000003],
              [-8.632138299999999, 33.133873000000001],
              [-8.6323229, 33.134462399999997],
              [-8.6292115, 33.135415700000003],
              [-8.6256141, 33.136408299999999],
              [-8.625379199999999, 33.136056199999999],
              [-8.625048700000001, 33.136111],
              [-8.6251917, 33.136510199999996],
              [-8.6240711, 33.136589299999997],
              [-8.623518000000001, 33.136434299999998],
              [-8.6221125, 33.135731300000003],
              [-8.624834999999999, 33.133718000000002],
              [-8.6219374, 33.1321309],
              [-8.6212293, 33.132415799999997],
              [-8.620082399999999, 33.134373400000001],
              [-8.6192517, 33.1339039],
              [-8.619306399999999, 33.133310999999999],
              [-8.618944300000001, 33.133184300000003],
              [-8.6191026, 33.132063000000002],
              [-8.6199373, 33.132420199999999],
              [-8.6205322, 33.131843799999999],
              [-8.619809099999999, 33.131470100000001],
              [-8.6196723, 33.131042800000003],
              [-8.6191637, 33.130898600000002],
              [-8.61918, 33.130485499999999],
              [-8.619235099999999, 33.130068899999998],
              [-8.6200384, 33.130130999999999],
              [-8.620505400000001, 33.1295249],
              [-8.6226699, 33.130493000000001],
              [-8.623381800000001, 33.129648899999999],
              [-8.626519699999999, 33.131139099999999],
              [-8.627323199999999, 33.130437399999998],
              [-8.627056100000001, 33.129845299999999],
              [-8.6251981, 33.128822300000003],
              [-8.6246156, 33.1288658],
              [-8.6228759, 33.127935000000001],
              [-8.6238195, 33.126731499999998],
              [-8.625284799999999, 33.124937699999997],
              [-8.6270658, 33.125987199999997],
              [-8.626593700000001, 33.127975499999998],
              [-8.628214, 33.128821799999997],
              [-8.628623899999999, 33.129018100000003],
              [-8.6287784, 33.128753500000002],
              [-8.62828, 33.128611999999997],
              [-8.626883599999999, 33.127958399999997],
              [-8.627374700000001, 33.126067999999997],
              [-8.630354499999999, 33.127591199999998],
              [-8.6304035, 33.127616099999997],
              [-8.6306063, 33.1273646],
              [-8.624941400000001, 33.124506400000001],
              [-8.6262396, 33.1229151],
              [-8.6301533, 33.124888599999998],
              [-8.629981600000001, 33.125119099999999],
              [-8.631780300000001, 33.125973100000003],
              [-8.632141600000001, 33.125457900000001],
              [-8.630445099999999, 33.124525599999998],
              [-8.6303287, 33.124668900000003],
              [-8.626802100000001, 33.122855299999998],
              [-8.6271229, 33.122072600000003],
              [-8.627450100000001, 33.121564100000001],
              [-8.628099199999999, 33.120853699999998],
              [-8.628369299999999, 33.120753200000003],
              [-8.633288500000001, 33.123395799999997],
              [-8.6330642, 33.123769600000003],
              [-8.6360075, 33.125309600000001],
              [-8.636314199999999, 33.124882200000002],
              [-8.6372611, 33.125335200000002],
              [-8.6375189, 33.125094900000001],
              [-8.628932600000001, 33.120538400000001],
              [-8.6287004, 33.1170337],
              [-8.6293393, 33.1158395],
              [-8.6299004, 33.1158754],
              [-8.6302105, 33.115782000000003],
              [-8.630361799999999, 33.115137199999999],
              [-8.629928400000001, 33.114884699999998],
              [-8.6299332, 33.114606700000003],
              [-8.6302532, 33.114015000000002],
              [-8.631282499999999, 33.113075000000002],
              [-8.6318444, 33.111907199999997],
              [-8.6322966, 33.111321500000003],
              [-8.6328344, 33.111001399999999],
              [-8.6333135, 33.110280899999999],
              [-8.6339819, 33.109896300000003],
              [-8.634046400000001, 33.109853999999999],
              [-8.635693099999999, 33.108769600000002],
              [-8.636168400000001, 33.108162700000001],
              [-8.637173499999999, 33.107495900000004],
              [-8.6380564, 33.108187000000001],
              [-8.6388911, 33.108796300000002],
              [-8.6392141, 33.108539299999997],
              [-8.6387345, 33.108334399999997],
              [-8.637784999999999, 33.107420400000002],
              [-8.6382423, 33.106679800000002],
              [-8.639099, 33.105782499999997],
              [-8.6400121, 33.106545500000003],
              [-8.6402658, 33.107066699999997],
              [-8.640063100000001, 33.108192799999998],
              [-8.639739499999999, 33.109082100000002],
              [-8.638986900000001, 33.109734899999999],
              [-8.638497600000001, 33.110110599999999],
              [-8.6389531, 33.110429199999999],
              [-8.640234599999999, 33.109072599999998],
              [-8.6406686, 33.107686800000003],
              [-8.6406268, 33.106424199999999],
              [-8.639436999999999, 33.105418],
              [-8.6403739, 33.104638700000002],
              [-8.6412757, 33.1039891],
              [-8.641519799999999, 33.103130800000002],
              [-8.6424463, 33.102639600000003],
              [-8.643113100000001, 33.102346900000001],
              [-8.6434497, 33.101364500000003],
              [-8.6439726, 33.100824299999999],
              [-8.6451028, 33.1002814],
              [-8.645553899999999, 33.099433400000002],
              [-8.646086199999999, 33.099275900000002],
              [-8.6460282, 33.098695800000002],
              [-8.647128800000001, 33.0986604],
              [-8.6474969, 33.098879699999998],
              [-8.6486506, 33.098584600000002],
              [-8.648747500000001, 33.098320999999999],
              [-8.648022900000001, 33.098390600000002],
              [-8.647961499999999, 33.098219399999998],
              [-8.647781699999999, 33.0977186],
              [-8.6480707, 33.097565000000003],
              [-8.648349400000001, 33.097383800000003],
              [-8.6483284, 33.097016000000004],
              [-8.6473604, 33.097200299999997],
              [-8.6466995, 33.096666200000001],
              [-8.6462836, 33.095946499999997],
              [-8.646380000000001, 33.094873800000002],
              [-8.6475387, 33.094778099999999],
              [-8.648, 33.094152000000001],
              [-8.648654499999999, 33.093862600000001],
              [-8.6497145, 33.093796500000003],
              [-8.6498454, 33.092491799999998],
              [-8.650575, 33.091706700000003],
              [-8.6506715, 33.090881000000003],
              [-8.651346800000001, 33.089790800000003],
              [-8.6537829, 33.086981600000001],
              [-8.6556701, 33.086663000000001],
              [-8.656325600000001, 33.087691800000002],
              [-8.657114699999999, 33.087884099999997],
              [-8.659801699999999, 33.086493099999998],
              [-8.660145099999999, 33.0858171],
              [-8.6602199, 33.085445100000001],
              [-8.6599997, 33.083732900000001],
              [-8.6593801, 33.083570700000003],
              [-8.6590604, 33.082863199999998],
              [-8.659242799999999, 33.0819683],
              [-8.6599117, 33.080812700000003],
              [-8.660988400000001, 33.081022099999998],
              [-8.662376699999999, 33.080195500000002],
              [-8.6621287, 33.0791495],
              [-8.662562700000001, 33.078497800000001],
              [-8.664263800000001, 33.078806999999998],
              [-8.6651183, 33.078213699999999],
              [-8.6650749, 33.0772154],
              [-8.6658849, 33.076884999999997],
              [-8.6655952, 33.075883900000001],
              [-8.666060699999999, 33.0751779],
              [-8.666669799999999, 33.0740208],
              [-8.667596100000001, 33.073249799999999],
              [-8.6685886, 33.073295700000003],
              [-8.6697354, 33.0723597],
              [-8.6710981, 33.0713686],
              [-8.6709616, 33.071077799999998],
              [-8.6716239, 33.070159500000003],
              [-8.673014, 33.069713100000001],
              [-8.673050699999999, 33.0691469],
              [-8.6738047, 33.068734499999998],
              [-8.673946300000001, 33.068154300000003],
              [-8.674907899999999, 33.067883299999998],
              [-8.674788899999999, 33.067507200000001],
              [-8.675251299999999, 33.066975800000002],
              [-8.676087000000001, 33.066216799999999],
              [-8.678289100000001, 33.0653322],
              [-8.6788454, 33.0661646],
              [-8.679156600000001, 33.065722899999997],
              [-8.678610900000001, 33.065067399999997],
              [-8.67862, 33.064156699999998],
              [-8.679535, 33.063255400000003],
              [-8.6807695, 33.063744100000001],
              [-8.6811916, 33.062225300000001],
              [-8.681272099999999, 33.061936099999997],
              [-8.6829114, 33.061150300000001],
              [-8.6850384, 33.057298600000003],
              [-8.6872965, 33.055714399999999],
              [-8.6876698, 33.054738100000002],
              [-8.6881541, 33.054235300000002],
              [-8.68825, 33.052570500000002],
              [-8.6890315, 33.0517921],
              [-8.6901458, 33.0514291],
              [-8.6910072, 33.048930800000001],
              [-8.691192300000001, 33.048506699999997],
              [-8.6933492, 33.046327599999998],
              [-8.695074, 33.0452248],
              [-8.697033899999999, 33.044741100000003],
              [-8.698269, 33.044890600000002],
              [-8.698982600000001, 33.045366799999996],
              [-8.6989661, 33.046391],
              [-8.699897500000001, 33.045557100000003],
              [-8.700172, 33.0448156],
              [-8.7001101, 33.043954399999997],
              [-8.6995728, 33.043901099999999],
              [-8.699256999999999, 33.044568499999997],
              [-8.6985846, 33.044465000000002],
              [-8.6979232, 33.044024],
              [-8.6977426, 33.043311600000003],
              [-8.6980538, 33.041811799999998],
              [-8.699360199999999, 33.040375599999997],
              [-8.7009682, 33.0392151],
              [-8.701738199999999, 33.039152199999997],
              [-8.7020369, 33.039476000000001],
              [-8.703089500000001, 33.0399861],
              [-8.7037402, 33.038768300000001],
              [-8.7040498, 33.037483700000003],
              [-8.703439299999999, 33.037482099999998],
              [-8.7034444, 33.0359397],
              [-8.704054899999999, 33.035941000000001],
              [-8.704056599999999, 33.035426999999999],
              [-8.7046671, 33.035428500000002],
              [-8.7058939, 33.033631800000002],
              [-8.7042167, 33.033370499999997],
              [-8.704222700000001, 33.031571100000001],
              [-8.7052955, 33.030031100000002],
              [-8.7059058, 33.030032599999998],
              [-8.7065213, 33.028491500000001],
              [-8.7071316, 33.028492999999997],
              [-8.7077463, 33.0272091],
              [-8.708356500000001, 33.027210500000002],
              [-8.7114227, 33.022847200000001],
              [-8.7153972, 33.0205427],
              [-8.7153989, 33.020028600000003],
              [-8.718150100000001, 33.018492299999998],
              [-8.7187646, 33.017208400000001],
              [-8.7202894, 33.017468800000003],
              [-8.721209699999999, 33.015928500000001],
              [-8.721819999999999, 33.015929800000002],
              [-8.7213677, 33.014386299999998],
              [-8.722133899999999, 33.013102699999997],
              [-8.722744199999999, 33.013103899999997],
              [-8.7233594, 33.011562900000001],
              [-8.7245814, 33.011051399999999],
              [-8.7255015, 33.009511000000003],
              [-8.7261118, 33.009512299999997],
              [-8.7264193, 33.008741899999997],
              [-8.727335399999999, 33.008486599999998],
              [-8.7285599, 33.007204000000002],
              [-8.7287868, 33.006979100000002],
              [-8.7301509, 33.005972399999997],
              [-8.7311718, 33.004774500000003],
              [-8.7318643, 33.004433800000001],
              [-8.7337591, 33.003101899999997],
              [-8.735183599999999, 33.002196400000003],
              [-8.7364505, 33.001391499999997],
              [-8.737568, 33.0014647],
              [-8.739120700000001, 33.000434800000001],
              [-8.7395663, 32.999515100000004],
              [-8.741092500000001, 32.999261199999999],
              [-8.741094, 32.998747000000002],
              [-8.739672300000001, 32.999169799999997],
              [-8.7404899, 32.996689000000003],
              [-8.7417094, 32.996948699999997],
              [-8.742010799999999, 32.998234799999999],
              [-8.7435378, 32.997723700000002],
              [-8.7433894, 32.996437999999998],
              [-8.744460999999999, 32.994897799999997],
              [-8.7438509, 32.994896500000003],
              [-8.744464000000001, 32.993869400000001],
              [-8.7450741, 32.993870800000003],
              [-8.747223399999999, 32.988990600000001],
              [-8.7478335, 32.9889917],
              [-8.7481416, 32.987964099999999],
              [-8.7487517, 32.987965299999999],
              [-8.7493661, 32.986424],
              [-8.7502818, 32.986168800000002],
              [-8.7505899, 32.985140999999999],
              [-8.751200000000001, 32.985142400000001],
              [-8.752424299999999, 32.9836022],
              [-8.753645300000001, 32.983347600000002],
              [-8.7539532, 32.982319799999999],
              [-8.7551747, 32.981808200000003],
              [-8.7558287, 32.9813616],
              [-8.7563975, 32.980782099999999],
              [-8.756398300000001, 32.980525100000001],
              [-8.7533467, 32.9810333],
              [-8.7530397, 32.981675000000003],
              [-8.749991700000001, 32.980897599999999],
              [-8.749688600000001, 32.980254799999997],
              [-8.748164299999999, 32.979994599999998],
              [-8.7483644, 32.980339700000002],
              [-8.748464, 32.981794800000003],
              [-8.7490741, 32.981795900000002],
              [-8.7493769, 32.982567799999998],
              [-8.7481562, 32.982822599999999],
              [-8.748002700000001, 32.983336299999998],
              [-8.747238899999999, 32.983591799999999],
              [-8.7472382, 32.983848999999999],
              [-8.7481533, 32.983850599999997],
              [-8.748604800000001, 32.986165300000003],
              [-8.7463161, 32.986288700000003],
              [-8.7449326, 32.990271300000003],
              [-8.744472200000001, 32.991041600000003],
              [-8.742644200000001, 32.990266499999997],
              [-8.7420303, 32.991550799999999],
              [-8.7405034, 32.992061700000001],
              [-8.7409587, 32.993091],
              [-8.7404983, 32.993861299999999],
              [-8.737902699999999, 32.994884200000001],
              [-8.7369813, 32.996938800000002],
              [-8.736382000000001, 32.996372600000001],
              [-8.7339004, 32.997096399999997],
              [-8.7316425, 32.996798599999998],
              [-8.728769, 32.9963792],
              [-8.7289222, 32.9955505],
              [-8.7298186, 32.994609799999999],
              [-8.7301632, 32.994383399999997],
              [-8.730736200000001, 32.993840499999997],
              [-8.7311639, 32.993552800000003],
              [-8.7313486, 32.993070699999997],
              [-8.7331965, 32.990686699999998],
              [-8.7337755, 32.989185200000001],
              [-8.734885200000001, 32.988266099999997],
              [-8.7365519, 32.987296999999998],
              [-8.738989800000001, 32.988073399999998],
              [-8.741433600000001, 32.986922],
              [-8.7414486, 32.981780399999998],
              [-8.7432809, 32.981013099999998],
              [-8.7432839, 32.979984799999997],
              [-8.743893999999999, 32.979985900000003],
              [-8.744817100000001, 32.977159999999998],
              [-8.7463444, 32.976391900000003],
              [-8.7469573, 32.975364599999999],
              [-8.747566900000001, 32.975493999999998],
              [-8.7484851, 32.974339399999998],
              [-8.7521466, 32.9738325],
              [-8.752454500000001, 32.9728049],
              [-8.7548943, 32.972809599999998],
              [-8.755814900000001, 32.970754800000002],
              [-8.7567293, 32.971013800000001],
              [-8.756117100000001, 32.971783799999997],
              [-8.756727, 32.971784900000003],
              [-8.75703, 32.9725568],
              [-8.758554800000001, 32.972559799999999],
              [-8.7582456, 32.974101599999997],
              [-8.7564157, 32.974098099999999],
              [-8.755649500000001, 32.975639000000001],
              [-8.756100099999999, 32.9779537],
              [-8.754470400000001, 32.978295000000003],
              [-8.7530451, 32.9797473],
              [-8.753655, 32.979748399999998],
              [-8.753653699999999, 32.980262500000002],
              [-8.757010899999999, 32.979498],
              [-8.7577479, 32.978861600000002],
              [-8.757930200000001, 32.977957199999999],
              [-8.7581579, 32.977099000000003],
              [-8.7588495, 32.976416499999999],
              [-8.7592593, 32.975815900000001],
              [-8.760684299999999, 32.9746205],
              [-8.760992, 32.973592699999998],
              [-8.762824200000001, 32.972825],
              [-8.7628255, 32.972310899999997],
              [-8.764046799999999, 32.971798900000003],
              [-8.7643539, 32.9710283],
              [-8.765269399999999, 32.970773000000001],
              [-8.7652708, 32.970258700000002],
              [-8.7661865, 32.970003400000003],
              [-8.766187800000001, 32.969489299999999],
              [-8.767103499999999, 32.969234],
              [-8.7674112, 32.968206199999997],
              [-8.768326699999999, 32.967950799999997],
              [-8.7692449, 32.966667000000001],
              [-8.769855, 32.9666681],
              [-8.7704681, 32.965383799999998],
              [-8.7716894, 32.964872],
              [-8.7719969, 32.963844199999997],
              [-8.7726068, 32.963845399999997],
              [-8.7732201, 32.962561100000002],
              [-8.774746800000001, 32.961792500000001],
              [-8.775666899999999, 32.959737500000003],
              [-8.778719600000001, 32.958457600000003],
              [-8.7790283, 32.956915700000003],
              [-8.780555, 32.956147000000001],
              [-8.7802475, 32.957174799999997],
              [-8.781162800000001, 32.956919499999998],
              [-8.7819307, 32.954864100000002],
              [-8.7813222, 32.954348899999999],
              [-8.781478699999999, 32.952549599999998],
              [-8.782088399999999, 32.952550500000001],
              [-8.7830063, 32.9512669],
              [-8.7836724, 32.951127800000002],
              [-8.7842267, 32.951011899999997],
              [-8.785472800000001, 32.948579600000002],
              [-8.787431700000001, 32.947245600000002],
              [-8.788890800000001, 32.945516900000001],
              [-8.790244, 32.944161299999998],
              [-8.791723299999999, 32.9429959],
              [-8.7930946, 32.941990699999998],
              [-8.7939326, 32.941182300000001],
              [-8.794862200000001, 32.940399499999998],
              [-8.7949848, 32.940258700000001],
              [-8.798074700000001, 32.937737599999998],
              [-8.8000381, 32.936206800000001],
              [-8.801601399999999, 32.934831099999997],
              [-8.8029671, 32.933775599999997],
              [-8.804340399999999, 32.932118699999997],
              [-8.806228600000001, 32.930029500000003],
              [-8.806721400000001, 32.929367800000001],
              [-8.807516100000001, 32.928300499999999],
              [-8.808575899999999, 32.927452000000002],
              [-8.809380900000001, 32.926966999999998],
              [-8.8098147, 32.926410500000003],
              [-8.8099703, 32.926271399999997],
              [-8.811724099999999, 32.925090900000001],
              [-8.812389100000001, 32.924641999999999],
              [-8.813126199999999, 32.924041500000001],
              [-8.813983800000001, 32.923476600000001],
              [-8.814536800000001, 32.9230795],
              [-8.8146767, 32.922918000000003],
              [-8.815208800000001, 32.922303499999998],
              [-8.8164187, 32.9211934],
              [-8.8179158, 32.919927000000001],
              [-8.8187119, 32.919451600000002],
              [-8.8194993, 32.918561400000002],
              [-8.820354399999999, 32.917501399999999],
              [-8.8214962, 32.916364399999999],
              [-8.822393099999999, 32.915234099999999],
              [-8.8227186, 32.914823699999999],
              [-8.824852699999999, 32.913087599999997],
              [-8.8256123, 32.9125479],
              [-8.826381599999999, 32.912000999999997],
              [-8.827908600000001, 32.910460399999998],
              [-8.8300448, 32.908920799999997],
              [-8.831571200000001, 32.9076375],
              [-8.8327692, 32.9067139],
              [-8.8337383, 32.906244999999998],
              [-8.834342700000001, 32.9053708],
              [-8.8352342, 32.904557400000002],
              [-8.8361497, 32.903787399999999],
              [-8.8375772, 32.9026061],
              [-8.838689, 32.901746199999998],
              [-8.839972599999999, 32.900478499999998],
              [-8.8407901, 32.899730900000002],
              [-8.8415955, 32.898780299999999],
              [-8.841687500000001, 32.898709599999997],
              [-8.841999599999999, 32.898467799999999],
              [-8.842959499999999, 32.897723599999999],
              [-8.844086600000001, 32.896342099999998],
              [-8.8450419, 32.895742200000001],
              [-8.846322900000001, 32.895016499999997],
              [-8.8470981, 32.894095499999999],
              [-8.847549900000001, 32.8937004],
              [-8.8486636, 32.892748500000003],
              [-8.8494303, 32.891990300000003],
              [-8.8498392, 32.891585800000001],
              [-8.850105599999999, 32.891464999999997],
              [-8.850513100000001, 32.891280000000002],
              [-8.8509277, 32.891024799999997],
              [-8.851494799999999, 32.8906755],
              [-8.8523692, 32.890074599999998],
              [-8.852853100000001, 32.889652900000002],
              [-8.8530429, 32.889541899999998],
              [-8.853622400000001, 32.889202500000003],
              [-8.854664100000001, 32.888652],
              [-8.8550016, 32.888693400000001],
              [-8.8555224, 32.888938600000003],
              [-8.855483, 32.888307699999999],
              [-8.8550545, 32.887888199999999],
              [-8.8551137, 32.887569599999999],
              [-8.855806299999999, 32.886930700000001],
              [-8.8562911, 32.886844500000002],
              [-8.856917899999999, 32.886091700000001],
              [-8.857512, 32.885560599999998],
              [-8.858075100000001, 32.885309900000003],
              [-8.858378699999999, 32.885340999999997],
              [-8.859083999999999, 32.885254400000001],
              [-8.8593925, 32.884578300000001],
              [-8.8593286, 32.8844049],
              [-8.8594586, 32.883823999999997],
              [-8.8600159, 32.883345499999997],
              [-8.8607338, 32.882644999999997],
              [-8.8608426, 32.882511299999997],
              [-8.8608685, 32.882479400000001],
              [-8.861333, 32.882149099999999],
              [-8.8613622, 32.881825800000001],
              [-8.8621392, 32.881374200000003],
              [-8.8620813, 32.881302099999999],
              [-8.8627407, 32.880678899999999],
              [-8.8630827, 32.880431899999998],
              [-8.863904700000001, 32.879644200000001],
              [-8.864550299999999, 32.879106700000001],
              [-8.8651403, 32.878370699999998],
              [-8.865616299999999, 32.877991100000003],
              [-8.8659578, 32.877552199999997],
              [-8.8663604, 32.877343699999997],
              [-8.8668742, 32.876738899999999],
              [-8.8676127, 32.875741400000003],
              [-8.8679697, 32.875448900000002],
              [-8.868212, 32.8751636],
              [-8.868769500000001, 32.874862499999999],
              [-8.8693881, 32.875291799999999],
              [-8.8702782, 32.874327299999997],
              [-8.8706342, 32.8735742],
              [-8.8707116, 32.873087300000002],
              [-8.870285300000001, 32.872767000000003],
              [-8.8709045, 32.8728889],
              [-8.871233699999999, 32.8726135],
              [-8.8712433, 32.871692799999998],
              [-8.872555, 32.870628699999997],
              [-8.872700200000001, 32.870069200000003],
              [-8.8732682, 32.869670300000003],
              [-8.8767905, 32.866267899999997],
              [-8.8766, 32.8661867],
              [-8.876500200000001, 32.866033600000002],
              [-8.8767133, 32.865653299999998],
              [-8.8771144, 32.865328300000002],
              [-8.8772611, 32.865183999999999],
              [-8.877735100000001, 32.8649658],
              [-8.878004199999999, 32.864623700000003],
              [-8.878061900000001, 32.864355600000003],
              [-8.878409400000001, 32.864091999999999],
              [-8.878893, 32.863693099999999],
              [-8.879073200000001, 32.8635679],
              [-8.879344, 32.863303199999997],
              [-8.8796339, 32.8631289],
              [-8.879795400000001, 32.862886899999999],
              [-8.879969300000001, 32.8628505],
              [-8.880072699999999, 32.862721100000002],
              [-8.8803698, 32.862280699999999],
              [-8.881403300000001, 32.8613347],
              [-8.8819806, 32.860667499999998],
              [-8.882301099999999, 32.860084800000003],
              [-8.8826147, 32.859846500000003],
              [-8.883380499999999, 32.859524499999999],
              [-8.8837995, 32.859017100000003],
              [-8.8837201, 32.858837100000002],
              [-8.883771599999999, 32.858657000000001],
              [-8.883934999999999, 32.858305399999999],
              [-8.884073300000001, 32.857998199999997],
              [-8.8843373, 32.8577248],
              [-8.8844092, 32.857513099999998],
              [-8.8847478, 32.857237900000001],
              [-8.8850084, 32.856810500000002],
              [-8.8853144, 32.856425399999999],
              [-8.885468100000001, 32.856357299999999],
              [-8.8855866, 32.856200299999998],
              [-8.8859209, 32.856065700000002],
              [-8.885968200000001, 32.855914200000001],
              [-8.886179800000001, 32.855785400000002],
              [-8.8863108, 32.8556059],
              [-8.886665900000001, 32.855504500000002],
              [-8.8869001, 32.855331300000003],
              [-8.88735, 32.854973200000003],
              [-8.8879082, 32.854585499999999],
              [-8.888964400000001, 32.853640800000001],
              [-8.8892823, 32.853332600000002],
              [-8.8896456, 32.852977699999997],
              [-8.890137299999999, 32.852779300000002],
              [-8.890355899999999, 32.852496100000003],
              [-8.890784999999999, 32.852390700000001],
              [-8.89063, 32.8521839],
              [-8.8907341, 32.851857199999998],
              [-8.891089900000001, 32.851665799999999],
              [-8.891337500000001, 32.8518063],
              [-8.891434, 32.851735499999997],
              [-8.891351999999999, 32.8514816],
              [-8.89148, 32.851197999999997],
              [-8.891770599999999, 32.8509308],
              [-8.8925576, 32.850433299999999],
              [-8.8926903, 32.8501896],
              [-8.8933477, 32.849589700000003],
              [-8.8939235, 32.8489954],
              [-8.8942199, 32.848716199999998],
              [-8.894328, 32.848603199999999],
              [-8.894768300000001, 32.848134899999998],
              [-8.8947097, 32.848014499999998],
              [-8.894978399999999, 32.847724900000003],
              [-8.895241199999999, 32.847604599999997],
              [-8.8951428, 32.847478299999999],
              [-8.8959188, 32.846896000000001],
              [-8.896035299999999, 32.846880499999997],
              [-8.896380199999999, 32.846579499999997],
              [-8.8962304, 32.8464417],
              [-8.8966092, 32.846033200000001],
              [-8.8968828, 32.845821800000003],
              [-8.8971564, 32.845589199999999],
              [-8.8975425, 32.845073399999997],
              [-8.8977927, 32.844788100000002],
              [-8.8980856, 32.844509100000003],
              [-8.898631200000001, 32.844264099999997],
              [-8.898999399999999, 32.8438728],
              [-8.8993676, 32.8435153],
              [-8.899522599999999, 32.843236699999999],
              [-8.8996412, 32.843115599999997],
              [-8.899758200000001, 32.842996100000001],
              [-8.900077100000001, 32.842818200000004],
              [-8.900347699999999, 32.842567699999996],
              [-8.9007182, 32.842369900000001],
              [-8.901088700000001, 32.842108699999997],
              [-8.901475899999999, 32.841735800000002],
              [-8.9018354, 32.841485599999999],
              [-8.902118700000001, 32.841239899999998],
              [-8.9023919, 32.840920099999998],
              [-8.9026979, 32.840648899999998],
              [-8.903024, 32.840466499999998],
              [-8.9033575, 32.840161899999998],
              [-8.9038244, 32.839859300000001],
              [-8.9043358, 32.839522299999999],
              [-8.905093900000001, 32.8390731],
              [-8.9058317, 32.838375300000003],
              [-8.9062862, 32.837996699999998],
              [-8.9065846, 32.8378169],
              [-8.906617799999999, 32.837788799999998],
              [-8.907684, 32.836888299999998],
              [-8.908176299999999, 32.8365334],
              [-8.9091, 32.835973799999998],
              [-8.909907199999999, 32.835263500000003],
              [-8.910654600000001, 32.834562599999998],
              [-8.9113732, 32.833853599999998],
              [-8.912103699999999, 32.833422300000002],
              [-8.9121161, 32.833316799999999],
              [-8.91234, 32.833231099999999],
              [-8.9126344, 32.832992699999998],
              [-8.913123499999999, 32.832563700000001],
              [-8.9133636, 32.832470000000001],
              [-8.913548199999999, 32.832223900000002],
              [-8.913769500000001, 32.832012800000001],
              [-8.9139027, 32.8318127],
              [-8.9140298, 32.831720799999999],
              [-8.914156999999999, 32.831667799999998],
              [-8.9143188, 32.831464599999997],
              [-8.9147984, 32.831145599999999],
              [-8.915171600000001, 32.831007499999998],
              [-8.915232899999999, 32.830879400000001],
              [-8.9154356, 32.830846399999999],
              [-8.916065400000001, 32.830339899999998],
              [-8.9165303, 32.8298676],
              [-8.9171245, 32.829371500000001],
              [-8.917764399999999, 32.8289385],
              [-8.9182997, 32.8283883],
              [-8.9190711, 32.827937200000001],
              [-8.919781499999999, 32.827356299999998],
              [-8.9204969, 32.826865099999999],
              [-8.9209295, 32.826541599999999],
              [-8.9213857, 32.826181099999999],
              [-8.921720499999999, 32.8257428],
              [-8.9222166, 32.825454200000003],
              [-8.923042300000001, 32.824682500000002],
              [-8.924129799999999, 32.8238281],
              [-8.9245731, 32.8238536],
              [-8.9250077, 32.8236676],
              [-8.9250414, 32.823642200000002],
              [-8.9261763, 32.822767900000002],
              [-8.926354, 32.8225476],
              [-8.9266735, 32.822307799999997],
              [-8.926895999999999, 32.822216900000001],
              [-8.927626200000001, 32.821870400000002],
              [-8.928544199999999, 32.821570700000002],
              [-8.9288639, 32.821166900000001],
              [-8.928891500000001, 32.820923499999999],
              [-8.929259, 32.820597100000001],
              [-8.9297454, 32.820347499999997],
              [-8.9300315, 32.8200334],
              [-8.930794499999999, 32.819518899999998],
              [-8.9311083, 32.819278799999999],
              [-8.9316285, 32.818971300000001],
              [-8.931905799999999, 32.818668600000002],
              [-8.9324691, 32.818205499999998],
              [-8.932709900000001, 32.818145899999998],
              [-8.9328848, 32.818035199999997],
              [-8.9331665, 32.817856900000002],
              [-8.933733399999999, 32.8176226],
              [-8.9339528, 32.817485300000001],
              [-8.9342136, 32.8173113],
              [-8.9344047, 32.8170243],
              [-8.934828599999999, 32.816683900000001],
              [-8.935079, 32.816542699999999],
              [-8.9352593, 32.816468700000001],
              [-8.935554, 32.816413300000001],
              [-8.935680899999999, 32.816401599999999],
              [-8.93601, 32.816183799999997],
              [-8.936006600000001, 32.815793399999997],
              [-8.936228699999999, 32.815602300000002],
              [-8.936776, 32.815387100000002],
              [-8.937648100000001, 32.814771800000003],
              [-8.9387518, 32.814211399999998],
              [-8.938759900000001, 32.814104200000003],
              [-8.9389147, 32.813770499999997],
              [-8.9397821, 32.813695299999999],
              [-8.940163699999999, 32.813279799999997],
              [-8.9407418, 32.813129199999999],
              [-8.941349199999999, 32.812670300000001],
              [-8.9415633, 32.812153000000002],
              [-8.942585899999999, 32.811993000000001],
              [-8.9436017, 32.8114372],
              [-8.944266000000001, 32.810835599999997],
              [-8.9461645, 32.809648600000003],
              [-8.946358699999999, 32.809411599999997],
              [-8.946896499999999, 32.809127500000002],
              [-8.946888599999999, 32.8089108],
              [-8.947457500000001, 32.8084889],
              [-8.947629600000001, 32.808538300000002],
              [-8.948517900000001, 32.808045700000001],
              [-8.9482585, 32.8077653],
              [-8.948357700000001, 32.807638900000001],
              [-8.9485867, 32.807681000000002],
              [-8.949442400000001, 32.807494300000002],
              [-8.9499701, 32.807053699999997],
              [-8.949848100000001, 32.8065316],
              [-8.950340300000001, 32.806387999999998],
              [-8.950680200000001, 32.806151300000003],
              [-8.9508575, 32.805912399999997],
              [-8.9516834, 32.805852999999999],
              [-8.952739899999999, 32.805016100000003],
              [-8.953446, 32.804933400000003],
              [-8.954071600000001, 32.8044954],
              [-8.9539355, 32.804031899999998],
              [-8.9542626, 32.803492300000002],
              [-8.954952199999999, 32.803722800000003],
              [-8.955279300000001, 32.803123999999997],
              [-8.955712999999999, 32.8031297],
              [-8.956081899999999, 32.8028288],
              [-8.956262000000001, 32.802216199999997],
              [-8.9568449, 32.802092799999997],
              [-8.957259000000001, 32.801616600000003],
              [-8.9595366, 32.799554100000002],
              [-8.9606824, 32.798997],
              [-8.9608914, 32.798334400000002],
              [-8.9615469, 32.7981245],
              [-8.9627912, 32.796836599999999],
              [-8.963837699999999, 32.796173899999999],
              [-8.965063000000001, 32.795070799999998],
              [-8.9656465, 32.794802099999998],
              [-8.966874499999999, 32.7941827],
              [-8.9674788, 32.793376600000002],
              [-8.967843200000001, 32.793181099999998],
              [-8.968354700000001, 32.7928414],
              [-8.9697928, 32.792130999999998],
              [-8.97044, 32.791667799999999],
              [-8.9706604, 32.791335799999999],
              [-8.9712227, 32.791136299999998],
              [-8.9719423, 32.790746300000002],
              [-8.9721247, 32.7903801],
              [-8.972692800000001, 32.790095100000002],
              [-8.9737562, 32.789298799999997],
              [-8.9741841, 32.788870899999999],
              [-8.974717500000001, 32.788568099999999],
              [-8.9760229, 32.787757399999997],
              [-8.976684300000001, 32.787311500000001],
              [-8.9771179, 32.786873],
              [-8.9778453, 32.786544499999998],
              [-8.978362300000001, 32.786186499999999],
              [-8.978599600000001, 32.785862100000003],
              [-8.978933400000001, 32.785574599999997],
              [-8.979674599999999, 32.7851024],
              [-8.9800737, 32.784739799999997],
              [-8.9804727, 32.784590899999998],
              [-8.981304400000001, 32.784262699999999],
              [-8.982635, 32.783296399999998],
              [-8.9832728, 32.782688999999998],
              [-8.984942500000001, 32.781801799999997],
              [-8.9857289, 32.781154100000002],
              [-8.986696200000001, 32.780658799999998],
              [-8.986941, 32.780344800000002],
              [-8.987260300000001, 32.780174500000001],
              [-8.9873846, 32.7798698],
              [-8.987515500000001, 32.779672599999998],
              [-8.9875282, 32.779512699999998],
              [-8.987527099999999, 32.779307500000002],
              [-8.987358800000001, 32.779251700000003],
              [-8.9874411, 32.779173100000001],
              [-8.9875433, 32.779248000000003],
              [-8.9877644, 32.779296000000002],
              [-8.9878743, 32.7792551],
              [-8.9879116, 32.779130100000003],
              [-8.9877953, 32.779011300000001],
              [-8.987790199999999, 32.778881300000002],
              [-8.9879061, 32.778811099999999],
              [-8.9882241, 32.778665199999999],
              [-8.988458400000001, 32.778764099999997],
              [-8.988721699999999, 32.778792600000003],
              [-8.9889227, 32.778627700000001],
              [-8.9888555, 32.7785285],
              [-8.9895628, 32.778070399999997],
              [-8.989663, 32.7781132],
              [-8.990286299999999, 32.7777131],
              [-8.9903371, 32.777531699999997],
              [-8.990993899999999, 32.777195800000001],
              [-8.991740699999999, 32.7769519],
              [-8.992511500000001, 32.776478599999997],
              [-8.992316499999999, 32.776311900000003],
              [-8.992416499999999, 32.776082000000002],
              [-8.992564399999999, 32.776259000000003],
              [-8.9931377, 32.776054100000003],
              [-8.9928439, 32.775836400000003],
              [-8.9935996, 32.775312499999998],
              [-8.9939284, 32.775277799999998],
              [-8.9942282, 32.775199399999998],
              [-8.9942438, 32.775120399999999],
              [-8.994491200000001, 32.775109],
              [-8.9945109, 32.774981799999999],
              [-8.9946924, 32.774869199999998],
              [-8.994742, 32.774763800000002],
              [-8.9948991, 32.774732899999997],
              [-8.9950455, 32.774807299999999],
              [-8.9952921, 32.774678999999999],
              [-8.9952793, 32.7745435],
              [-8.9955268, 32.774510200000002],
              [-8.995536100000001, 32.774419100000003],
              [-8.995728, 32.774329299999998],
              [-8.9958575, 32.774397700000002],
              [-8.996075899999999, 32.774286600000003],
              [-8.996041, 32.774205199999997],
              [-8.9961968, 32.774123799999998],
              [-8.9962195, 32.7739853],
              [-8.9963573, 32.773985500000002],
              [-8.9965115, 32.7738643],
              [-8.9966407, 32.773865000000001],
              [-8.996899000000001, 32.773720599999997],
              [-8.9971727, 32.773694300000002],
              [-8.997257599999999, 32.7734618],
              [-8.9970166, 32.7734928],
              [-8.996962699999999, 32.773365200000001],
              [-8.997352299999999, 32.773022500000003],
              [-8.9974779, 32.773212100000002],
              [-8.9977176, 32.773187900000003],
              [-8.9979251, 32.772993700000001],
              [-8.997906499999999, 32.772755799999999],
              [-8.998106399999999, 32.772250999999997],
              [-8.998448399999999, 32.772408300000002],
              [-8.9986175, 32.772639499999997],
              [-8.998863099999999, 32.772523499999998],
              [-8.998686599999999, 32.772300600000001],
              [-8.9988309, 32.772190100000003],
              [-8.998986500000001, 32.772397099999999],
              [-8.999089400000001, 32.7723297],
              [-8.999271, 32.772177999999997],
              [-8.9994642, 32.772094500000001],
              [-8.9994631, 32.771990600000002],
              [-8.9996495, 32.771874099999998],
              [-8.9997414, 32.771902799999999],
              [-8.9999894, 32.771715100000002],
              [-9.0000865, 32.771548899999999],
              [-9.0003615, 32.7712845],
              [-9.0007375, 32.771061600000003],
              [-9.000847500000001, 32.7711009],
              [-9.001021100000001, 32.771076899999997],
              [-9.001050299999999, 32.770918000000002],
              [-9.001293799999999, 32.770770200000001],
              [-9.0013413, 32.770615800000002],
              [-9.001438800000001, 32.770677999999997],
              [-9.001894800000001, 32.770331900000002],
              [-9.0020782, 32.770050400000002],
              [-9.0023631, 32.769693799999999],
              [-9.0025786, 32.769755400000001],
              [-9.002784, 32.7693729],
              [-9.003039899999999, 32.769095],
              [-9.0031078, 32.768858299999998],
              [-9.0035154, 32.768633000000001],
              [-9.0038333, 32.768552],
              [-9.0039718, 32.768458099999997],
              [-9.0041683, 32.768151500000002],
              [-9.0045325, 32.767952000000001],
              [-9.004590200000001, 32.767795999999997],
              [-9.0047864, 32.767712600000003],
              [-9.0051732, 32.7674971],
              [-9.005423800000001, 32.767379800000001],
              [-9.005471999999999, 32.7671919],
              [-9.005661999999999, 32.7670429],
              [-9.005817199999999, 32.766879299999999],
              [-9.0060307, 32.766869999999997],
              [-9.0062269, 32.766763400000002],
              [-9.006399999999999, 32.766570600000001],
              [-9.0066252, 32.766411900000001],
              [-9.006573100000001, 32.766247999999997],
              [-9.006786999999999, 32.766159500000001],
              [-9.0070721, 32.766101599999999],
              [-9.007351399999999, 32.765929399999997],
              [-9.007505800000001, 32.765776600000002],
              [-9.0075447, 32.765623900000001],
              [-9.007859399999999, 32.765401099999998],
              [-9.0080285, 32.765470999999998],
              [-9.008215099999999, 32.765472799999998],
              [-9.008512700000001, 32.7652991],
              [-9.0087004, 32.765008600000002],
              [-9.0085035, 32.764893200000003],
              [-9.009012200000001, 32.764845800000003],
              [-9.009228200000001, 32.764614000000002],
              [-9.009438400000001, 32.764489099999999],
              [-9.0096796, 32.764142100000001],
              [-9.009932600000001, 32.764178700000002],
              [-9.0103495, 32.763863499999999],
              [-9.010650699999999, 32.763485099999997],
              [-9.011207000000001, 32.763535500000003],
              [-9.0114219, 32.763269600000001],
              [-9.011469200000001, 32.763032899999999],
              [-9.0118297, 32.762909499999999],
              [-9.012114, 32.762800499999997],
              [-9.0122827, 32.762584400000001],
              [-9.012510199999999, 32.762565600000002],
              [-9.012446499999999, 32.762338300000003],
              [-9.0125964, 32.762194399999998],
              [-9.0128836, 32.762159500000003],
              [-9.013147999999999, 32.761938600000001],
              [-9.013824100000001, 32.761670500000001],
              [-9.0140134, 32.761282999999999],
              [-9.0148961, 32.761184200000002],
              [-9.0156809, 32.760287300000002],
              [-9.0161427, 32.7602932],
              [-9.016496, 32.759977900000003],
              [-9.0171501, 32.759750199999999],
              [-9.017615599999999, 32.759214999999998],
              [-9.0182547, 32.758991000000002],
              [-9.0188247, 32.758456299999999],
              [-9.0192841, 32.757999900000002],
              [-9.0198593, 32.757621399999998],
              [-9.019750200000001, 32.757133500000002],
              [-9.020069899999999, 32.756999200000003],
              [-9.020094800000001, 32.756724400000003],
              [-9.0203162, 32.756736500000002],
              [-9.020588699999999, 32.7566858],
              [-9.0206295, 32.756449799999999],
              [-9.0208119, 32.756330699999999],
              [-9.020994200000001, 32.756323100000003],
              [-9.0211434, 32.756058199999998],
              [-9.021233499999999, 32.755758200000002],
              [-9.0211123, 32.755582699999998],
              [-9.0213038, 32.755428500000001],
              [-9.021558799999999, 32.755554400000001],
              [-9.021843199999999, 32.755392200000003],
              [-9.0219244, 32.755110600000002],
              [-9.0221187, 32.754972100000003],
              [-9.022277900000001, 32.7547821],
              [-9.0225343, 32.754631099999997],
              [-9.0229208, 32.754421000000001],
              [-9.022896899999999, 32.7541923],
              [-9.0230657, 32.754011499999997],
              [-9.023682600000001, 32.7539813],
              [-9.0240636, 32.753737399999999],
              [-9.023997, 32.753379299999999],
              [-9.0242022, 32.753190799999999],
              [-9.024524599999999, 32.753214900000003],
              [-9.0251594, 32.752854900000003],
              [-9.025257099999999, 32.752643300000003],
              [-9.025594, 32.752398200000002],
              [-9.025653200000001, 32.752118299999999],
              [-9.0263767, 32.751905399999998],
              [-9.026486999999999, 32.751647499999997],
              [-9.026411299999999, 32.751378500000001],
              [-9.026624200000001, 32.7511777],
              [-9.0268034, 32.751215000000002],
              [-9.0270131, 32.751035100000003],
              [-9.0271352, 32.751114299999998],
              [-9.027275400000001, 32.751026600000003],
              [-9.0272588, 32.750724400000003],
              [-9.0273457, 32.750485599999998],
              [-9.027626700000001, 32.750413899999998],
              [-9.0279256, 32.750711600000002],
              [-9.028025899999999, 32.750590699999997],
              [-9.0279884, 32.750385899999998],
              [-9.0282529, 32.750325500000002],
              [-9.0283446, 32.7500602],
              [-9.0284589, 32.749791700000003],
              [-9.0286691, 32.749678500000002],
              [-9.028822, 32.749756499999997],
              [-9.028951299999999, 32.749668100000001],
              [-9.028928199999999, 32.749482700000002],
              [-9.0290768, 32.749361800000003],
              [-9.0294308, 32.749291800000002],
              [-9.029886899999999, 32.749045500000001],
              [-9.0302828, 32.748929099999998],
              [-9.030316600000001, 32.748719700000002],
              [-9.0303396, 32.748539700000002],
              [-9.030491899999999, 32.74832],
              [-9.0309297, 32.747965399999998],
              [-9.031344900000001, 32.747600800000001],
              [-9.0315791, 32.747455000000002],
              [-9.0316881, 32.747387199999999],
              [-9.0318223, 32.747303700000003],
              [-9.0319038, 32.747253000000001],
              [-9.0321102, 32.747117899999999],
              [-9.032387200000001, 32.746936699999999],
              [-9.032685499999999, 32.746750800000001],
              [-9.032943, 32.746550499999998],
              [-9.033026700000001, 32.746331599999998],
              [-9.0333018, 32.746165099999999],
              [-9.0339504, 32.7460041],
              [-9.0347095, 32.745496000000003],
              [-9.035267299999999, 32.745267300000002],
              [-9.0358371, 32.744844499999999],
              [-9.0357652, 32.744557999999998],
              [-9.036004999999999, 32.744309399999999],
              [-9.0362399, 32.744249400000001],
              [-9.036593399999999, 32.744054900000002],
              [-9.0368306, 32.7441149],
              [-9.037164799999999, 32.743941700000001],
              [-9.0373523, 32.744008600000001],
              [-9.037758500000001, 32.7438225],
              [-9.0378992, 32.743617700000001],
              [-9.038244499999999, 32.743492699999997],
              [-9.038705999999999, 32.743327600000001],
              [-9.0387898, 32.743095099999998],
              [-9.0384463, 32.742908900000003],
              [-9.0383063, 32.742496000000003],
              [-9.0380708, 32.7425578],
              [-9.037811700000001, 32.7426697],
              [-9.0374459, 32.742879600000002],
              [-9.037070399999999, 32.742703599999999],
              [-9.0364535, 32.742586299999999],
              [-9.036078, 32.742198199999997],
              [-9.035884899999999, 32.741765100000002],
              [-9.035884899999999, 32.7412688],
              [-9.036258699999999, 32.740737600000003],
              [-9.035945999999999, 32.7405689],
              [-9.035690499999999, 32.740276899999998],
              [-9.035285500000001, 32.739967399999998],
              [-9.0350109, 32.739891200000002],
              [-9.034849899999999, 32.739891200000002],
              [-9.034534000000001, 32.739893899999998],
              [-9.034351600000001, 32.739794099999997],
              [-9.034072099999999, 32.739686800000001],
              [-9.033726100000001, 32.739657899999997],
              [-9.033393500000001, 32.739757099999999],
              [-9.0332513, 32.739864099999998],
              [-9.032983099999999, 32.740089699999999],
              [-9.032803400000001, 32.7403257],
              [-9.0326398, 32.740676299999997],
              [-9.0323742, 32.741255199999998],
              [-9.0319746, 32.7419121],
              [-9.031676900000001, 32.742500499999998],
              [-9.031462299999999, 32.743051000000001],
              [-9.031298700000001, 32.743473299999998],
              [-9.0310975, 32.743935299999997],
              [-9.030601300000001, 32.744628400000003],
              [-9.0303009, 32.744980300000002],
              [-9.0300329, 32.745105500000001],
              [-9.029279000000001, 32.745775799999997],
              [-9.028774800000001, 32.746095199999999],
              [-9.0283026, 32.746389399999998],
              [-9.027963700000001, 32.746592900000003],
              [-9.027385900000001, 32.746890700000002],
              [-9.026695, 32.7471131],
              [-9.0259918, 32.7473186],
              [-9.0256265, 32.747442599999999],
              [-9.0250778, 32.747628800000001],
              [-9.0246858, 32.747803900000001],
              [-9.0244106, 32.747926800000002],
              [-9.024044399999999, 32.748162700000002],
              [-9.0238216, 32.748306200000002],
              [-9.023734599999999, 32.7484593],
              [-9.023629700000001, 32.748643700000002],
              [-9.023433000000001, 32.749095599999997],
              [-9.023350600000001, 32.749284799999998],
              [-9.0233069, 32.749673399999999],
              [-9.023276299999999, 32.749945099999998],
              [-9.0231768, 32.750574],
              [-9.0231394, 32.750809599999997],
              [-9.022986700000001, 32.751175000000003],
              [-9.022905, 32.751370299999998],
              [-9.0226267, 32.7517651],
              [-9.0224669, 32.752027400000003],
              [-9.022300899999999, 32.752299700000002],
              [-9.022123799999999, 32.752535700000003],
              [-9.021949599999999, 32.752767599999999],
              [-9.021438399999999, 32.753566399999997],
              [-9.020920200000001, 32.754094000000002],
              [-9.020403999999999, 32.754327400000001],
              [-9.0202239, 32.754408900000001],
              [-9.0189971, 32.7547359],
              [-9.017818, 32.754898400000002],
              [-9.0172896, 32.754925499999999],
              [-9.0167386, 32.754953899999997],
              [-9.016377, 32.754922299999997],
              [-9.0156749, 32.754860899999997],
              [-9.015219399999999, 32.754805699999999],
              [-9.0148665, 32.754762900000003],
              [-9.014662599999999, 32.754738199999998],
              [-9.0142335, 32.754832],
              [-9.0136906, 32.754950700000002],
              [-9.0134715, 32.755045899999999],
              [-9.0130646, 32.755222699999997],
              [-9.0129172, 32.755363699999997],
              [-9.012669799999999, 32.755600299999998],
              [-9.012527199999999, 32.755824500000003],
              [-9.0123012, 32.756180000000001],
              [-9.012252800000001, 32.756379500000001],
              [-9.0121526, 32.7567922],
              [-9.0122964, 32.757541099999997],
              [-9.012381100000001, 32.757745300000003],
              [-9.012789400000001, 32.758728900000001],
              [-9.0128699, 32.759384900000001],
              [-9.0128881, 32.759532800000002],
              [-9.012358600000001, 32.7603656],
              [-9.011971300000001, 32.760689499999998],
              [-9.0110148, 32.761015200000003],
              [-9.0107202, 32.761065100000003],
              [-9.0101227, 32.761166299999999],
              [-9.009775400000001, 32.761224900000002],
              [-9.009469899999999, 32.7612764],
              [-9.0093259, 32.761392499999999],
              [-9.0091035, 32.7615719],
              [-9.009032899999999, 32.761730300000004],
              [-9.0089656, 32.761881299999999],
              [-9.0090152, 32.7620681],
              [-9.0090509, 32.762202500000001],
              [-9.0091109, 32.7624292],
              [-9.009191, 32.762732],
              [-9.009203299999999, 32.762778599999997],
              [-9.0088229, 32.763201299999999],
              [-9.008350800000001, 32.763495900000002],
              [-9.007283899999999, 32.763757499999997],
              [-9.0067272, 32.763946400000002],
              [-9.0063827, 32.764063299999997],
              [-9.006086099999999, 32.764119100000002],
              [-9.004931600000001, 32.764336200000002],
              [-9.0046608, 32.764405699999998],
              [-9.0043364, 32.764488900000003],
              [-9.0035299, 32.764695799999998],
              [-9.002224699999999, 32.765226200000001],
              [-9.0013632, 32.765481600000001],
              [-9.0009839, 32.765887499999998],
              [-9.0006846, 32.766454600000003],
              [-9.000140099999999, 32.766913799999998],
              [-8.9997946, 32.767509599999997],
              [-8.9994256, 32.768096],
              [-8.999393899999999, 32.768211399999998],
              [-8.999198099999999, 32.768924200000001],
              [-8.9992094, 32.769498400000003],
              [-8.9989948, 32.769955799999998],
              [-8.9986204, 32.770192600000001],
              [-8.9978538, 32.770606700000002],
              [-8.9973093, 32.770868800000002],
              [-8.9960234, 32.7711051],
              [-8.995278900000001, 32.7711611],
              [-8.996581900000001, 32.770822799999998],
              [-8.9972192, 32.770670799999998],
              [-8.998198199999999, 32.770144899999998],
              [-8.998336, 32.770070799999999],
              [-8.998833899999999, 32.769634699999997],
              [-8.9988489, 32.769161500000003],
              [-8.9988285, 32.769070399999997],
              [-8.998752100000001, 32.768729499999999],
              [-8.9986874, 32.768440699999999],
              [-8.9976381, 32.7684298],
              [-8.996746, 32.768449699999998],
              [-8.9955026, 32.768412699999999],
              [-8.994861999999999, 32.768467299999998],
              [-8.994108300000001, 32.7687636],
              [-8.993881, 32.769061999999998],
              [-8.993701700000001, 32.769297299999998],
              [-8.993259200000001, 32.770028400000001],
              [-8.9928627, 32.770637800000003],
              [-8.992381, 32.7711015],
              [-8.992069300000001, 32.771579600000003],
              [-8.9919197, 32.771975699999999],
              [-8.991597799999999, 32.7723631],
              [-8.991159, 32.772705500000001],
              [-8.990807800000001, 32.7727413],
              [-8.9902669, 32.7727966],
              [-8.9897638, 32.7727298],
              [-8.989512100000001, 32.772696500000002],
              [-8.988809399999999, 32.772723499999998],
              [-8.9887359, 32.772736899999998],
              [-8.9881882, 32.772836699999999],
              [-8.9877038, 32.773352299999999],
              [-8.987189300000001, 32.773947700000001],
              [-8.98664, 32.774182699999997],
              [-8.9860188, 32.774144300000003],
              [-8.985417399999999, 32.773896700000002],
              [-8.984952399999999, 32.7739738],
              [-8.9845951, 32.7746748],
              [-8.9842105, 32.775235799999997],
              [-8.983583400000001, 32.775457799999998],
              [-8.983095199999999, 32.775501300000002],
              [-8.9827583, 32.775531299999997],
              [-8.9824403, 32.775691299999998],
              [-8.982157000000001, 32.775833900000002],
              [-8.9818786, 32.776068100000003],
              [-8.981384500000001, 32.776483900000002],
              [-8.9816216, 32.776020199999998],
              [-8.9822126, 32.775572699999998],
              [-8.982505099999999, 32.775351299999997],
              [-8.9828388, 32.775312200000002],
              [-8.9832009, 32.775269700000003],
              [-8.983328200000001, 32.775238700000003],
              [-8.9838001, 32.775123999999998],
              [-8.9841874, 32.7748141],
              [-8.984453500000001, 32.774221500000003],
              [-8.9846659, 32.773861500000002],
              [-8.985030099999999, 32.773624699999999],
              [-8.9855483, 32.7735007],
              [-8.9861422, 32.773623800000003],
              [-8.986652899999999, 32.773676999999999],
              [-8.9868729, 32.773573599999999],
              [-8.987065400000001, 32.7734831],
              [-8.987124100000001, 32.773395700000002],
              [-8.9873104, 32.773117900000003],
              [-8.9873932, 32.772994599999997],
              [-8.9875162, 32.772907400000001],
              [-8.9877147, 32.772766799999999],
              [-8.987853400000001, 32.772668500000002],
              [-8.988205000000001, 32.772484400000003],
              [-8.9884237, 32.772369900000001],
              [-8.9889703, 32.772108699999997],
              [-8.989557700000001, 32.772083500000001],
              [-8.9900968, 32.772180900000002],
              [-8.9906107, 32.772238600000001],
              [-8.9911048, 32.772110499999997],
              [-8.991574699999999, 32.771756500000002],
              [-8.991730799999999, 32.771190799999999],
              [-8.991906200000001, 32.770710399999999],
              [-8.9920323, 32.770314900000002],
              [-8.9922629, 32.7701396],
              [-8.992708800000001, 32.769800600000003],
              [-8.992843300000001, 32.769588900000002],
              [-8.993262400000001, 32.768929200000002],
              [-8.993816499999999, 32.768113200000002],
              [-8.994158799999999, 32.767781599999999],
              [-8.994505200000001, 32.7675372],
              [-8.9947236, 32.767453500000002],
              [-8.994881899999999, 32.767392899999997],
              [-8.9950493, 32.767328800000001],
              [-8.995593100000001, 32.767282700000003],
              [-8.9962304, 32.767252999999997],
              [-8.997016800000001, 32.7672016],
              [-8.9973779, 32.7671682],
              [-8.997728800000001, 32.767135699999997],
              [-8.9983044, 32.766963799999999],
              [-8.9985275, 32.766829299999998],
              [-8.998673999999999, 32.766741000000003],
              [-8.9990136, 32.766363400000003],
              [-8.9992137, 32.766049500000001],
              [-8.9995371, 32.765585799999997],
              [-9.0000044, 32.765264100000003],
              [-9.0006673, 32.764884799999997],
              [-9.0013459, 32.764593400000003],
              [-9.0018861, 32.764439500000002],
              [-9.002262200000001, 32.7642393],
              [-9.002366, 32.764119800000003],
              [-9.0025368, 32.763922999999998],
              [-9.002655300000001, 32.763727199999998],
              [-9.0028013, 32.763485500000002],
              [-9.0028522, 32.763044299999997],
              [-9.0029992, 32.762456499999999],
              [-9.0033066, 32.761831299999997],
              [-9.0039113, 32.760931300000003],
              [-9.004572599999999, 32.7600543],
              [-9.005376200000001, 32.759386200000002],
              [-9.0057271, 32.759030299999999],
              [-9.006054199999999, 32.7585598],
              [-9.0063215, 32.758217799999997],
              [-9.006729200000001, 32.757837500000001],
              [-9.007055299999999, 32.757753100000002],
              [-9.0071507, 32.757728299999997],
              [-9.0076497, 32.757735599999997],
              [-9.0079645, 32.757622300000001],
              [-9.0084237, 32.757508600000001],
              [-9.008957000000001, 32.757446399999999],
              [-9.0094361, 32.757365200000002],
              [-9.0097729, 32.757173899999998],
              [-9.0098995, 32.756833700000001],
              [-9.009892499999999, 32.756384400000002],
              [-9.0100128, 32.755817800000003],
              [-9.010340899999999, 32.755140300000001],
              [-9.0106541, 32.754591599999998],
              [-9.0110619, 32.754269200000003],
              [-9.0116376, 32.753828499999997],
              [-9.0132745, 32.753407600000003],
              [-9.013855700000001, 32.753341900000002],
              [-9.014504799999999, 32.753347300000001],
              [-9.0151409, 32.753543100000002],
              [-9.0153739, 32.753614800000001],
              [-9.0157776, 32.753628399999997],
              [-9.0162601, 32.753644600000001],
              [-9.016807200000001, 32.753625900000003],
              [-9.017329699999999, 32.753608100000001],
              [-9.0177944, 32.753624500000001],
              [-9.018352699999999, 32.753644199999997],
              [-9.0187382, 32.7536299],
              [-9.019131700000001, 32.7536153],
              [-9.0194033, 32.753573099999997],
              [-9.0200023, 32.753480000000003],
              [-9.0202414, 32.753303099999997],
              [-9.020462, 32.7531398],
              [-9.020706300000001, 32.752890100000002],
              [-9.0208779, 32.7527148],
              [-9.021187299999999, 32.752357000000003],
              [-9.0214111, 32.7521226],
              [-9.021745299999999, 32.751772299999999],
              [-9.021891800000001, 32.751537300000003],
              [-9.0220982, 32.751206099999997],
              [-9.0222119, 32.750579000000002],
              [-9.022242200000001, 32.750106899999999],
              [-9.022273, 32.749624799999999],
              [-9.0222912, 32.749404499999997],
              [-9.022322900000001, 32.749017500000001],
              [-9.0223662, 32.748781299999997],
              [-9.022440400000001, 32.748375500000002],
              [-9.0225005, 32.747696900000001],
              [-9.022620099999999, 32.747305300000001],
              [-9.0227982, 32.747151700000003],
              [-9.0231437, 32.746853700000003],
              [-9.0232768, 32.746750499999997],
              [-9.023877499999999, 32.746284299999999],
              [-9.0242305, 32.745899399999999],
              [-9.024521500000001, 32.745685100000003],
              [-9.025162099999999, 32.745481599999998],
              [-9.0261867, 32.745182700000001],
              [-9.027211299999999, 32.745003099999998],
              [-9.027451599999999, 32.744909],
              [-9.0277051, 32.744898900000003],
              [-9.0278665, 32.744958699999998],
              [-9.028385, 32.744787000000002],
              [-9.028650799999999, 32.7445837],
              [-9.0288992, 32.744457199999999],
              [-9.029259700000001, 32.744183999999997],
              [-9.0296167, 32.743774700000003],
              [-9.029789299999999, 32.743290299999998],
              [-9.029813499999999, 32.743222400000001],
              [-9.029887799999999, 32.742688399999999],
              [-9.0300394, 32.742336299999998],
              [-9.0301861, 32.742019800000001],
              [-9.030336, 32.741758500000003],
              [-9.030536100000001, 32.741409699999998],
              [-9.0306134, 32.741279499999997],
              [-9.0310893, 32.740477900000002],
              [-9.0312126, 32.740270199999998],
              [-9.0313751, 32.739745200000002],
              [-9.031738000000001, 32.738906],
              [-9.0320752, 32.738598400000001],
              [-9.032655099999999, 32.738182799999997],
              [-9.032823499999999, 32.738132700000001],
              [-9.0330692, 32.737921800000002],
              [-9.0332612, 32.737899200000001],
              [-9.0334485, 32.7377945],
              [-9.0336499, 32.737751199999998],
              [-9.034132700000001, 32.737359099999999],
              [-9.0345511, 32.736972799999997],
              [-9.036266400000001, 32.735642599999998],
              [-9.0379535, 32.7345167],
              [-9.0383894, 32.734228199999997],
              [-9.038816499999999, 32.734209300000003],
              [-9.038990399999999, 32.734179400000002],
              [-9.040001500000001, 32.734005400000001],
              [-9.040722000000001, 32.733881500000003],
              [-9.040867799999999, 32.733856400000001],
              [-9.041435099999999, 32.733840299999997],
              [-9.0417582, 32.733831100000003],
              [-9.042180800000001, 32.7338551],
              [-9.042377399999999, 32.733866300000003],
              [-9.0427575, 32.734011899999999],
              [-9.0428756, 32.734057100000001],
              [-9.043161, 32.734327899999997],
              [-9.043316300000001, 32.734573599999997],
              [-9.0433115, 32.734895999999999],
              [-9.043296399999999, 32.735162000000003],
              [-9.0432884, 32.735301900000003],
              [-9.043287599999999, 32.735518499999998],
              [-9.0432872, 32.735640600000004],
              [-9.0430796, 32.735884200000001],
              [-9.042879900000001, 32.7360483],
              [-9.0428686, 32.736091700000003],
              [-9.0428468, 32.736175699999997],
              [-9.0429002, 32.736269499999999],
              [-9.0429239, 32.736311100000002],
              [-9.0431484, 32.7365864],
              [-9.043201, 32.736902000000001],
              [-9.043041799999999, 32.737526699999997],
              [-9.042877900000001, 32.7383886],
              [-9.0427254, 32.738656200000001],
              [-9.0425007, 32.738801700000003],
              [-9.0422145, 32.739152099999998],
              [-9.041968499999999, 32.739601],
              [-9.0416483, 32.739853699999998],
              [-9.041466700000001, 32.740104799999997],
              [-9.041475200000001, 32.740258400000002],
              [-9.0414092, 32.7404741],
              [-9.0416002, 32.740540699999997],
              [-9.0417813, 32.740435300000001],
              [-9.0419717, 32.740422000000002],
              [-9.042164400000001, 32.740549899999998],
              [-9.0422005, 32.740666599999997],
              [-9.0423977, 32.7405531],
              [-9.0425159, 32.7403507],
              [-9.0425009, 32.740234999999998],
              [-9.0426018, 32.740129400000001],
              [-9.0426179, 32.7399755],
              [-9.0429183, 32.739905100000001],
              [-9.043049999999999, 32.739840200000003],
              [-9.043006, 32.739599200000001],
              [-9.043108399999999, 32.739562900000003],
              [-9.0431784, 32.739485700000003],
              [-9.0433193, 32.739456199999999],
              [-9.0434681, 32.739310699999997],
              [-9.0436073, 32.739241800000002],
              [-9.0435923, 32.739172600000003],
              [-9.0434974, 32.739021899999997],
              [-9.043620499999999, 32.738987600000002],
              [-9.043772000000001, 32.739036800000001],
              [-9.0438715, 32.739034099999998],
              [-9.0439712, 32.738944500000002],
              [-9.043941500000001, 32.738817900000001],
              [-9.0437919, 32.7386214],
              [-9.0440434, 32.738688099999997],
              [-9.0441997, 32.7386573],
              [-9.044287300000001, 32.738503600000001],
              [-9.044225900000001, 32.738345500000001],
              [-9.0442503, 32.738238299999999],
              [-9.044397500000001, 32.738276499999998],
              [-9.044489199999999, 32.738233999999999],
              [-9.044642700000001, 32.738255000000002],
              [-9.044819199999999, 32.738149700000001],
              [-9.0450056, 32.738045200000002],
              [-9.0449485, 32.737970199999999],
              [-9.045040500000001, 32.737842100000002],
              [-9.045043700000001, 32.737719900000002],
              [-9.045163799999999, 32.737703400000001],
              [-9.045233, 32.737636600000002],
              [-9.045412499999999, 32.737510299999997],
              [-9.045470099999999, 32.737382599999997],
              [-9.045332800000001, 32.737305399999997],
              [-9.0452478, 32.737207300000001],
              [-9.044865100000001, 32.737107100000003],
              [-9.044916499999999, 32.736889400000003],
              [-9.0451649, 32.736822799999999],
              [-9.045259, 32.736714300000003],
              [-9.045479200000001, 32.7367141],
              [-9.045567500000001, 32.736863200000002],
              [-9.045677700000001, 32.736860700000001],
              [-9.045837199999999, 32.736893299999998],
              [-9.0460216, 32.7369311],
              [-9.046161100000001, 32.736846700000001],
              [-9.046048900000001, 32.736780699999997],
              [-9.0459122, 32.7368077],
              [-9.0458572, 32.7367591],
              [-9.046050299999999, 32.736694],
              [-9.045998000000001, 32.736579599999999],
              [-9.045798400000001, 32.7365566],
              [-9.045658400000001, 32.736612800000003],
              [-9.045503500000001, 32.736469],
              [-9.0453701, 32.736342],
              [-9.0453913, 32.736121799999999],
              [-9.045505500000001, 32.735942399999999],
              [-9.0457164, 32.735806199999999],
              [-9.0459025, 32.735734399999998],
              [-9.0461045, 32.735737399999998],
              [-9.046257600000001, 32.735841399999998],
              [-9.046236800000001, 32.736165100000001],
              [-9.0463457, 32.736225400000002],
              [-9.0463877, 32.736148200000002],
              [-9.046462, 32.735960499999997],
              [-9.046596900000001, 32.736049199999997],
              [-9.046663199999999, 32.736161500000001],
              [-9.0467061, 32.736325299999997],
              [-9.046861699999999, 32.7363316],
              [-9.046957000000001, 32.736217699999997],
              [-9.047115700000001, 32.736187000000001],
              [-9.047056700000001, 32.736007600000001],
              [-9.046844800000001, 32.735908600000002],
              [-9.046779900000001, 32.735664900000003],
              [-9.0472179, 32.735604500000001],
              [-9.047451199999999, 32.735626400000001],
              [-9.0476119, 32.735523499999999],
              [-9.047772399999999, 32.735271699999998],
              [-9.047666899999999, 32.735055799999998],
              [-9.047285199999999, 32.735104700000001],
              [-9.0468359, 32.735240300000001],
              [-9.0465473, 32.735587500000001],
              [-9.046427700000001, 32.7354707],
              [-9.046397600000001, 32.735295100000002],
              [-9.046524, 32.735031599999999],
              [-9.0465696, 32.734638400000001],
              [-9.046681400000001, 32.734237899999997],
              [-9.0468405, 32.733802900000001],
              [-9.0470864, 32.733503900000002],
              [-9.0474009, 32.733259799999999],
              [-9.0477197, 32.732949300000001],
              [-9.0481578, 32.732510699999999],
              [-9.048754199999999, 32.732255700000003],
              [-9.049156, 32.732168000000001],
              [-9.0493717, 32.732191],
              [-9.0495546, 32.732348899999998],
              [-9.0496455, 32.7325175],
              [-9.0497721, 32.732657799999998],
              [-9.0497236, 32.732860199999998],
              [-9.049696300000001, 32.733031199999999],
              [-9.049797099999999, 32.733192099999997],
              [-9.049844, 32.733404200000003],
              [-9.0500168, 32.733325200000003],
              [-9.0499993, 32.733179900000003],
              [-9.050098, 32.733079099999998],
              [-9.0500594, 32.7329723],
              [-9.050331099999999, 32.732935599999998],
              [-9.0504341, 32.732879599999997],
              [-9.050364099999999, 32.732809000000003],
              [-9.050392199999999, 32.732737499999999],
              [-9.050265400000001, 32.732621899999998],
              [-9.050207, 32.732579700000002],
              [-9.050001699999999, 32.732638899999998],
              [-9.049907599999999, 32.732543399999997],
              [-9.0498011, 32.732357899999997],
              [-9.0495543, 32.731766999999998],
              [-9.049621399999999, 32.731370800000001],
              [-9.049923700000001, 32.731036000000003],
              [-9.050396299999999, 32.730837600000001],
              [-9.050852600000001, 32.730763199999998],
              [-9.0512485, 32.730982900000001],
              [-9.051639, 32.730893600000002],
              [-9.0518225, 32.730771300000001],
              [-9.0515714, 32.730560599999997],
              [-9.051416400000001, 32.7304216],
              [-9.0515291, 32.730204499999999],
              [-9.0518348, 32.730320900000002],
              [-9.0519614, 32.730126900000002],
              [-9.051739299999999, 32.729903499999999],
              [-9.0518144, 32.729742899999998],
              [-9.0527879, 32.729820199999999],
              [-9.0535301, 32.728892999999999],
              [-9.053944899999999, 32.728034899999997],
              [-9.0545384, 32.727618300000003],
              [-9.0551467, 32.727397400000001],
              [-9.0550356, 32.726492100000002],
              [-9.0555485, 32.726239999999997],
              [-9.0555968, 32.726127200000001],
              [-9.0560823, 32.726209300000001],
              [-9.0558763, 32.725917799999998],
              [-9.0568227, 32.725554099999997],
              [-9.0566724, 32.725303099999998],
              [-9.056644500000001, 32.724951099999998],
              [-9.0564827, 32.724877800000002],
              [-9.056191699999999, 32.725009800000002],
              [-9.055987999999999, 32.724823399999998],
              [-9.0559444, 32.724481300000001],
              [-9.0561477, 32.724083700000001],
              [-9.056435499999999, 32.723868299999999],
              [-9.0570463, 32.723609400000001],
              [-9.057238399999999, 32.723347199999999],
              [-9.057586499999999, 32.722890499999998],
              [-9.058175500000001, 32.722295199999998],
              [-9.0585559, 32.721966199999997],
              [-9.059040100000001, 32.721499799999997],
              [-9.0593229, 32.721006199999998],
            ],
          ],
          [
            [
              [-9.041135199999999, 32.734846300000001],
              [-9.040400999999999, 32.734776400000001],
              [-9.040003799999999, 32.734681399999999],
              [-9.039538800000001, 32.734747599999999],
              [-9.038903599999999, 32.734988100000002],
              [-9.0383911, 32.735194100000001],
              [-9.037285799999999, 32.735991599999998],
              [-9.036391800000001, 32.736601700000001],
              [-9.0356395, 32.737282700000002],
              [-9.0355776, 32.737636700000003],
              [-9.0357909, 32.738424199999997],
              [-9.036183100000001, 32.739053200000001],
              [-9.0364974, 32.739586099999997],
              [-9.0368928, 32.740101000000003],
              [-9.0372377, 32.740032399999997],
              [-9.037372400000001, 32.739646100000002],
              [-9.0378551, 32.738490400000003],
              [-9.038783499999999, 32.738073999999997],
              [-9.0398727, 32.737470100000003],
              [-9.040169799999999, 32.737574199999997],
              [-9.0402317, 32.7382718],
              [-9.040335900000001, 32.738471799999999],
              [-9.0405535, 32.7383655],
              [-9.0407376, 32.737591299999998],
              [-9.0410132, 32.737113899999997],
              [-9.041368800000001, 32.736742700000001],
              [-9.0417418, 32.736272800000002],
              [-9.0419246, 32.736111899999997],
              [-9.042018000000001, 32.736015500000001],
              [-9.0420526, 32.735809600000003],
              [-9.0419611, 32.735588],
              [-9.0417095, 32.735299900000001],
              [-9.041451199999999, 32.735055299999999],
              [-9.041135199999999, 32.734846300000001],
            ],
          ],
          [
            [
              [-9.039532100000001, 32.742680100000001],
              [-9.0399551, 32.742478900000002],
              [-9.0401039, 32.742471500000001],
              [-9.040278900000001, 32.742375799999998],
              [-9.040115200000001, 32.742153100000003],
              [-9.040203699999999, 32.741991599999999],
              [-9.040462700000001, 32.742074000000002],
              [-9.040659099999999, 32.7420331],
              [-9.0406844, 32.741867499999998],
              [-9.0406055, 32.741642800000001],
              [-9.040744999999999, 32.741499699999999],
              [-9.0406849, 32.741295800000003],
              [-9.0405111, 32.741213700000003],
              [-9.0402836, 32.741140600000001],
              [-9.040037399999999, 32.741248400000003],
              [-9.0396324, 32.7415363],
              [-9.0393878, 32.741520000000001],
              [-9.0392268, 32.741627000000001],
              [-9.039193600000001, 32.741838399999999],
              [-9.039014999999999, 32.741976200000003],
              [-9.0389914, 32.742130099999997],
              [-9.039160900000001, 32.742264499999997],
              [-9.0392773, 32.742424700000001],
              [-9.0392724, 32.742659799999998],
              [-9.039532100000001, 32.742680100000001],
            ],
          ],
          [
            [
              [-8.755187599999999, 32.977051600000003],
              [-8.7542723, 32.977178799999997],
              [-8.754273599999999, 32.976664700000001],
              [-8.748481099999999, 32.975752900000003],
              [-8.746495100000001, 32.977163300000001],
              [-8.746945500000001, 32.979477899999999],
              [-8.749996700000001, 32.979098100000002],
              [-8.7512139, 32.980128700000002],
              [-8.752738600000001, 32.980260800000003],
              [-8.7525894, 32.979232099999997],
              [-8.7536609, 32.977691700000001],
              [-8.7539657, 32.977692500000003],
              [-8.753964399999999, 32.978206499999999],
              [-8.754880200000001, 32.977951300000001],
              [-8.755187599999999, 32.977051600000003],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      region: "Draa-Tafilalet",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-7.735, 30.859999999999999],
              [-7.715, 31.039999999999999],
              [-7.73, 31.055],
              [-7.725000000000001, 31.065000000000001],
              [-7.75, 31.100000000000001],
              [-7.75, 31.120000000000001],
              [-7.735, 31.135000000000002],
              [-7.71, 31.129999999999999],
              [-7.695, 31.164999999999999],
              [-7.68, 31.175000000000001],
              [-7.605, 31.205000000000002],
              [-7.585, 31.23],
              [-7.585, 31.245000000000001],
              [-7.5, 31.25],
              [-7.48, 31.27],
              [-7.445, 31.275000000000002],
              [-7.435, 31.289999999999999],
              [-7.385, 31.285],
              [-7.350000000000001, 31.295000000000002],
              [-7.255, 31.350000000000001],
              [-7.235, 31.375],
              [-7.24, 31.395],
              [-7.22, 31.414999999999999],
              [-7.185, 31.43],
              [-7.175, 31.414999999999999],
              [-7.155, 31.43],
              [-7.140000000000001, 31.379999999999999],
              [-7.125, 31.365000000000002],
              [-7.06, 31.370000000000001],
              [-7.04, 31.390000000000001],
              [-7.01, 31.34],
              [-6.995, 31.350000000000001],
              [-6.735, 31.350000000000001],
              [-6.72, 31.359999999999999],
              [-6.715, 31.375],
              [-6.7, 31.470000000000002],
              [-6.635, 31.460000000000001],
              [-6.565, 31.510000000000002],
              [-6.495, 31.535],
              [-6.48, 31.555],
              [-6.455, 31.545000000000002],
              [-6.445, 31.550000000000001],
              [-6.425, 31.580000000000002],
              [-6.26, 31.635000000000002],
              [-6.140000000000001, 31.685000000000002],
              [-6.03, 31.715],
              [-5.965, 31.75],
              [-5.92, 31.82],
              [-5.815, 31.859999999999999],
              [-5.825, 31.870000000000001],
              [-5.825, 31.895],
              [-5.815, 31.900000000000002],
              [-5.8, 31.93],
              [-5.805, 31.940000000000001],
              [-5.825, 31.965],
              [-5.89, 31.984999999999999],
              [-5.865, 32.085000000000001],
              [-5.87, 32.100000000000001],
              [-5.86, 32.119999999999997],
              [-5.845, 32.130000000000003],
              [-5.795, 32.130000000000003],
              [-5.745, 32.145000000000003],
              [-5.68, 32.215000000000003],
              [-5.585, 32.219999999999999],
              [-5.565, 32.240000000000002],
              [-5.535, 32.240000000000002],
              [-5.51, 32.225000000000001],
              [-5.445, 32.215000000000003],
              [-5.45, 32.240000000000002],
              [-5.485, 32.259999999999998],
              [-5.51, 32.295000000000002],
              [-5.475000000000001, 32.344999999999999],
              [-5.535, 32.359999999999999],
              [-5.525, 32.369999999999997],
              [-5.535, 32.454999999999998],
              [-5.445, 32.454999999999998],
              [-5.41, 32.465000000000003],
              [-5.395, 32.484999999999999],
              [-5.405, 32.515000000000001],
              [-5.4, 32.524999999999999],
              [-5.355, 32.515000000000001],
              [-5.34, 32.575000000000003],
              [-5.345, 32.600000000000001],
              [-5.33, 32.615000000000002],
              [-5.32, 32.615000000000002],
              [-5.32, 32.765000000000001],
              [-5.3, 32.795000000000002],
              [-5.31, 32.799999999999997],
              [-5.31, 32.810000000000002],
              [-5.295, 32.82],
              [-5.29, 32.840000000000003],
              [-5.25, 32.855000000000004],
              [-5.255, 32.869999999999997],
              [-5.19, 32.914999999999999],
              [-5.17, 32.905000000000001],
              [-5.14, 32.910000000000004],
              [-5.125, 32.945],
              [-5.055, 32.980000000000004],
              [-5.07, 33.024999999999999],
              [-5.035, 33.060000000000002],
              [-4.995, 33.085000000000001],
              [-4.845, 33.075000000000003],
              [-4.83, 33.100000000000001],
              [-4.81, 33.049999999999997],
              [-4.775, 33.020000000000003],
              [-4.765, 33],
              [-4.755, 32.960000000000001],
              [-4.775, 32.924999999999997],
              [-4.77, 32.910000000000004],
              [-4.715, 32.890000000000001],
              [-4.655, 32.880000000000003],
              [-4.56, 32.895000000000003],
              [-4.48, 32.884999999999998],
              [-4.485, 32.869999999999997],
              [-4.465, 32.840000000000003],
              [-4.405, 32.810000000000002],
              [-4.285, 32.715000000000003],
              [-4.215, 32.615000000000002],
              [-4.175, 32.600000000000001],
              [-4.105, 32.625],
              [-4.06, 32.600000000000001],
              [-4.02, 32.609999999999999],
              [-4.005, 32.579999999999998],
              [-3.92, 32.535000000000004],
              [-3.94, 32.505000000000003],
              [-3.935, 32.480000000000004],
              [-3.915, 32.450000000000003],
              [-3.855, 32.405000000000001],
              [-3.82, 32.344999999999999],
              [-3.81, 32.314999999999998],
              [-3.81, 32.280000000000001],
              [-3.82, 32.265000000000001],
              [-3.815, 32.255000000000003],
              [-3.775, 32.265000000000001],
              [-3.755, 32.259999999999998],
              [-3.745, 32.244999999999997],
              [-3.755, 32.200000000000003],
              [-3.675, 32.195],
              [-3.6, 32.204999999999998],
              [-3.585, 32.200000000000003],
              [-3.635, 32.155000000000001],
              [-3.645, 32.134999999999998],
              [-3.635, 32.119999999999997],
              [-3.615, 32.109999999999999],
              [-3.565, 32.125],
              [-3.54, 32.145000000000003],
              [-3.485, 32.145000000000003],
              [-3.375, 32.109999999999999],
              [-3.335, 32.105000000000004],
              [-3.295, 32.134999999999998],
              [-3.195, 32.174999999999997],
              [-3.26, 32.090000000000003],
              [-3.27, 31.970000000000002],
              [-3.245, 31.905000000000001],
              [-3.195, 31.815000000000001],
              [-3.185, 31.815000000000001],
              [-3.18, 31.830000000000002],
              [-3.115, 31.84],
              [-3.155, 31.795000000000002],
              [-3.255, 31.73],
              [-3.25, 31.715],
              [-3.665, 31.635000000000002],
              [-3.665, 31.400000000000002],
              [-3.645, 31.395],
              [-3.66, 31.379999999999999],
              [-3.675, 31.385000000000002],
              [-3.68, 31.370000000000001],
              [-3.705, 31.365000000000002],
              [-3.725, 31.34],
              [-3.74, 31.345000000000002],
              [-3.74, 31.359999999999999],
              [-3.75, 31.359999999999999],
              [-3.76, 31.345000000000002],
              [-3.755, 31.335000000000001],
              [-3.765, 31.335000000000001],
              [-3.76, 31.315000000000001],
              [-3.785, 31.295000000000002],
              [-3.78, 31.285],
              [-3.775, 31.289999999999999],
              [-3.775, 31.280000000000001],
              [-3.755, 31.280000000000001],
              [-3.76, 31.260000000000002],
              [-3.785, 31.25],
              [-3.78, 31.240000000000002],
              [-3.79, 31.234999999999999],
              [-3.785, 31.220000000000002],
              [-3.77, 31.225000000000001],
              [-3.775, 31.210000000000001],
              [-3.76, 31.18],
              [-3.765, 31.16],
              [-3.775, 31.155000000000001],
              [-3.78, 31.120000000000001],
              [-3.775, 31.115000000000002],
              [-3.765, 31.125],
              [-3.74, 31.125],
              [-3.73, 31.115000000000002],
              [-3.725, 31.140000000000001],
              [-3.735, 31.145],
              [-3.72, 31.145],
              [-3.71, 31.155000000000001],
              [-3.705, 31.145],
              [-3.705, 31.155000000000001],
              [-3.715, 31.164999999999999],
              [-3.7, 31.175000000000001],
              [-3.7, 31.16],
              [-3.68, 31.164999999999999],
              [-3.67, 31.150000000000002],
              [-3.675, 31.140000000000001],
              [-3.67, 31.115000000000002],
              [-3.68, 31.085000000000001],
              [-3.645, 31.080000000000002],
              [-3.65, 31.09],
              [-3.665, 31.09],
              [-3.665, 31.105],
              [-3.655, 31.095000000000002],
              [-3.635, 31.100000000000001],
              [-3.645, 31.109999999999999],
              [-3.65, 31.135000000000002],
              [-3.64, 31.140000000000001],
              [-3.64, 31.155000000000001],
              [-3.635, 31.150000000000002],
              [-3.64, 31.115000000000002],
              [-3.63, 31.105],
              [-3.615, 31.120000000000001],
              [-3.62, 31.100000000000001],
              [-3.605, 31.095000000000002],
              [-3.605, 31.074999999999999],
              [-3.59, 31.074999999999999],
              [-3.595, 31.065000000000001],
              [-3.585, 31.050000000000001],
              [-3.57, 31.045000000000002],
              [-3.565, 31.055],
              [-3.565, 31.045000000000002],
              [-3.56, 31.060000000000002],
              [-3.55, 31.065000000000001],
              [-3.55, 31.030000000000001],
              [-3.54, 31.039999999999999],
              [-3.54, 30.955000000000002],
              [-3.555, 30.949999999999999],
              [-3.555, 30.93],
              [-3.575, 30.935000000000002],
              [-3.575, 30.920000000000002],
              [-3.585, 30.920000000000002],
              [-3.585, 30.905000000000001],
              [-3.595, 30.895],
              [-3.605, 30.895],
              [-3.615, 30.865000000000002],
              [-3.635, 30.875],
              [-3.64, 30.859999999999999],
              [-3.66, 30.850000000000001],
              [-3.65, 30.845000000000002],
              [-3.645, 30.77],
              [-3.635, 30.765000000000001],
              [-3.63, 30.75],
              [-3.63, 30.73],
              [-3.655, 30.670000000000002],
              [-3.67, 30.664999999999999],
              [-3.68, 30.670000000000002],
              [-3.72, 30.645],
              [-3.745, 30.640000000000001],
              [-3.77, 30.625],
              [-3.775, 30.609999999999999],
              [-3.79, 30.609999999999999],
              [-3.815, 30.59],
              [-3.895, 30.609999999999999],
              [-3.905, 30.600000000000001],
              [-3.925, 30.605],
              [-4.02, 30.59],
              [-4.145, 30.585000000000001],
              [-4.32, 30.530000000000001],
              [-4.605, 30.285],
              [-4.935, 30.140000000000001],
              [-5.115, 30.010000000000002],
              [-5.175, 29.98],
              [-5.24, 29.935000000000002],
              [-5.26, 29.91],
              [-5.285, 29.900000000000002],
              [-5.33, 29.835000000000001],
              [-5.32, 29.800000000000001],
              [-5.325, 29.785],
              [-5.515, 29.550000000000001],
              [-5.535, 29.515000000000001],
              [-5.555, 29.510000000000002],
              [-5.545, 29.5],
              [-5.565, 29.475000000000001],
              [-5.58, 29.490000000000002],
              [-5.615, 29.490000000000002],
              [-5.635, 29.504999999999999],
              [-5.66, 29.510000000000002],
              [-5.69, 29.539999999999999],
              [-5.715, 29.52],
              [-5.745, 29.515000000000001],
              [-5.745, 29.550000000000001],
              [-5.72, 29.580000000000002],
              [-5.75, 29.605],
              [-5.795, 29.615000000000002],
              [-5.865, 29.609999999999999],
              [-5.88, 29.594999999999999],
              [-5.965, 29.59],
              [-6.01, 29.574999999999999],
              [-6.04, 29.574999999999999],
              [-6.07, 29.560000000000002],
              [-6.15, 29.580000000000002],
              [-6.185, 29.574999999999999],
              [-6.22, 29.580000000000002],
              [-6.24, 29.565000000000001],
              [-6.375, 29.57],
              [-6.390000000000001, 29.550000000000001],
              [-6.41, 29.565000000000001],
              [-6.45, 29.565000000000001],
              [-6.42, 29.629999999999999],
              [-6.405, 29.645],
              [-6.405, 29.664999999999999],
              [-6.42, 29.68],
              [-6.425, 29.699999999999999],
              [-6.415, 29.725000000000001],
              [-6.390000000000001, 29.745000000000001],
              [-6.405, 29.789999999999999],
              [-6.405, 29.850000000000001],
              [-6.43, 29.975000000000001],
              [-6.4, 30],
              [-6.355, 30.010000000000002],
              [-6.355, 30.030000000000001],
              [-6.365, 30.039999999999999],
              [-6.4, 30.045000000000002],
              [-6.48, 30.025000000000002],
              [-6.51, 30.074999999999999],
              [-6.49, 30.129999999999999],
              [-6.59, 30.23],
              [-6.51, 30.289999999999999],
              [-6.51, 30.315000000000001],
              [-6.55, 30.34],
              [-6.615, 30.414999999999999],
              [-6.640000000000001, 30.414999999999999],
              [-6.640000000000001, 30.440000000000001],
              [-6.655, 30.460000000000001],
              [-6.67, 30.460000000000001],
              [-6.69, 30.445],
              [-6.81, 30.425000000000001],
              [-6.835, 30.460000000000001],
              [-6.86, 30.455000000000002],
              [-6.855, 30.359999999999999],
              [-6.885, 30.330000000000002],
              [-6.9, 30.350000000000001],
              [-6.925, 30.355],
              [-7.02, 30.335000000000001],
              [-7.16, 30.375],
              [-7.17, 30.370000000000001],
              [-7.26, 30.25],
              [-7.295, 30.215],
              [-7.3, 30.164999999999999],
              [-7.29, 30.150000000000002],
              [-7.29, 30.120000000000001],
              [-7.3, 30.105],
              [-7.33, 30.125],
              [-7.37, 30.190000000000001],
              [-7.42, 30.185000000000002],
              [-7.47, 30.215],
              [-7.49, 30.199999999999999],
              [-7.54, 30.225000000000001],
              [-7.59, 30.205000000000002],
              [-7.605, 30.215],
              [-7.625, 30.210000000000001],
              [-7.625, 30.23],
              [-7.605, 30.23],
              [-7.58, 30.25],
              [-7.58, 30.280000000000001],
              [-7.61, 30.315000000000001],
              [-7.625, 30.400000000000002],
              [-7.63, 30.405000000000001],
              [-7.635, 30.395],
              [-7.68, 30.379999999999999],
              [-7.695, 30.425000000000001],
              [-7.68, 30.455000000000002],
              [-7.61, 30.48],
              [-7.57, 30.525000000000002],
              [-7.555, 30.525000000000002],
              [-7.515000000000001, 30.545000000000002],
              [-7.525, 30.545000000000002],
              [-7.57, 30.645],
              [-7.555, 30.650000000000002],
              [-7.57, 30.73],
              [-7.71, 30.740000000000002],
              [-7.695, 30.754999999999999],
              [-7.685, 30.754999999999999],
              [-7.68, 30.760000000000002],
              [-7.685, 30.765000000000001],
              [-7.675, 30.765000000000001],
              [-7.67, 30.775000000000002],
              [-7.68, 30.82],
              [-7.67, 30.84],
              [-7.63, 30.865000000000002],
              [-7.71, 30.850000000000001],
              [-7.735, 30.859999999999999],
            ],
            [
              [-3.705, 31.145],
              [-3.71, 31.140000000000001],
              [-3.71, 31.135000000000002],
              [-3.705, 31.135000000000002],
              [-3.705, 31.145],
            ],
            [
              [-3.775, 31.109999999999999],
              [-3.775, 31.105],
              [-3.775, 31.100000000000001],
              [-3.77, 31.105],
              [-3.775, 31.109999999999999],
            ],
          ],
          [
            [
              [-3.64, 31.155000000000001],
              [-3.645, 31.155000000000001],
              [-3.64, 31.170000000000002],
              [-3.64, 31.164999999999999],
              [-3.64, 31.155000000000001],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      region: "Fes-Meknes",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-5.885, 34.105000000000004],
              [-5.855, 34.145000000000003],
              [-5.815, 34.160000000000004],
              [-5.83, 34.189999999999998],
              [-5.795, 34.189999999999998],
              [-5.795, 34.210000000000001],
              [-5.77, 34.164999999999999],
              [-5.76, 34.170000000000002],
              [-5.745, 34.160000000000004],
              [-5.7, 34.175000000000004],
              [-5.705, 34.119999999999997],
              [-5.61, 34.125],
              [-5.59, 34.105000000000004],
              [-5.54, 34.130000000000003],
              [-5.53, 34.160000000000004],
              [-5.505, 34.149999999999999],
              [-5.485, 34.155000000000001],
              [-5.47, 34.145000000000003],
              [-5.465, 34.164999999999999],
              [-5.455, 34.164999999999999],
              [-5.445, 34.185000000000002],
              [-5.465, 34.189999999999998],
              [-5.475000000000001, 34.210000000000001],
              [-5.45, 34.234999999999999],
              [-5.435, 34.234999999999999],
              [-5.42, 34.255000000000003],
              [-5.43, 34.259999999999998],
              [-5.44, 34.295000000000002],
              [-5.465, 34.32],
              [-5.47, 34.314999999999998],
              [-5.48, 34.325000000000003],
              [-5.49, 34.32],
              [-5.485, 34.314999999999998],
              [-5.505, 34.32],
              [-5.505, 34.335000000000001],
              [-5.49, 34.340000000000003],
              [-5.52, 34.365000000000002],
              [-5.535, 34.359999999999999],
              [-5.53, 34.375],
              [-5.515, 34.380000000000003],
              [-5.495, 34.369999999999997],
              [-5.455, 34.395000000000003],
              [-5.415, 34.399999999999999],
              [-5.395, 34.414999999999999],
              [-5.38, 34.439999999999998],
              [-5.36, 34.439999999999998],
              [-5.34, 34.460000000000001],
              [-5.345, 34.469999999999999],
              [-5.335, 34.480000000000004],
              [-5.345, 34.480000000000004],
              [-5.345, 34.490000000000002],
              [-5.32, 34.515000000000001],
              [-5.28, 34.515000000000001],
              [-5.255, 34.535000000000004],
              [-5.265, 34.535000000000004],
              [-5.26, 34.555],
              [-5.2, 34.560000000000002],
              [-5.18, 34.590000000000003],
              [-5.195, 34.594999999999999],
              [-5.18, 34.605000000000004],
              [-5.18, 34.649999999999999],
              [-5.19, 34.670000000000002],
              [-5.18, 34.695],
              [-5.185, 34.700000000000003],
              [-5.18, 34.740000000000002],
              [-5.145, 34.765000000000001],
              [-5.125, 34.765000000000001],
              [-5.09, 34.780000000000001],
              [-5.085, 34.774999999999999],
              [-5.025, 34.800000000000004],
              [-5.015, 34.800000000000004],
              [-5.02, 34.795000000000002],
              [-5.015, 34.789999999999999],
              [-5.01, 34.795000000000002],
              [-4.98, 34.789999999999999],
              [-4.95, 34.810000000000002],
              [-4.915, 34.814999999999998],
              [-4.91, 34.825000000000003],
              [-4.885, 34.835000000000001],
              [-4.865, 34.825000000000003],
              [-4.805, 34.835000000000001],
              [-4.79, 34.844999999999999],
              [-4.78, 34.75],
              [-4.76, 34.734999999999999],
              [-4.745, 34.734999999999999],
              [-4.73, 34.719999999999999],
              [-4.63, 34.725000000000001],
              [-4.59, 34.715000000000003],
              [-4.575, 34.685000000000002],
              [-4.54, 34.700000000000003],
              [-4.52, 34.695],
              [-4.505, 34.700000000000003],
              [-4.495, 34.689999999999998],
              [-4.435, 34.670000000000002],
              [-4.415, 34.710000000000001],
              [-4.38, 34.704999999999998],
              [-4.355, 34.715000000000003],
              [-4.345, 34.710000000000001],
              [-4.325, 34.715000000000003],
              [-4.295, 34.740000000000002],
              [-4.305, 34.759999999999998],
              [-4.25, 34.805],
              [-4.21, 34.765000000000001],
              [-4.17, 34.75],
              [-4.155, 34.759999999999998],
              [-4.145, 34.744999999999997],
              [-4.1, 34.765000000000001],
              [-4.035, 34.825000000000003],
              [-3.95, 34.835000000000001],
              [-3.93, 34.840000000000003],
              [-3.925, 34.850000000000001],
              [-3.9, 34.850000000000001],
              [-3.83, 34.869999999999997],
              [-3.82, 34.890000000000001],
              [-3.76, 34.880000000000003],
              [-3.765, 34.859999999999999],
              [-3.75, 34.844999999999999],
              [-3.695, 34.850000000000001],
              [-3.605, 34.844999999999999],
              [-3.59, 34.810000000000002],
              [-3.62, 34.740000000000002],
              [-3.615, 34.719999999999999],
              [-3.59, 34.700000000000003],
              [-3.635, 34.675000000000004],
              [-3.645, 34.630000000000003],
              [-3.705, 34.625],
              [-3.72, 34.640000000000001],
              [-3.73, 34.640000000000001],
              [-3.735, 34.625],
              [-3.75, 34.615000000000002],
              [-3.775, 34.619999999999997],
              [-3.76, 34.594999999999999],
              [-3.77, 34.57],
              [-3.76, 34.57],
              [-3.755, 34.560000000000002],
              [-3.76, 34.560000000000002],
              [-3.745, 34.545000000000002],
              [-3.755, 34.539999999999999],
              [-3.78, 34.490000000000002],
              [-3.8, 34.469999999999999],
              [-3.845, 34.454999999999998],
              [-3.85, 34.445],
              [-3.84, 34.439999999999998],
              [-3.855, 34.410000000000004],
              [-3.835, 34.414999999999999],
              [-3.835, 34.410000000000004],
              [-3.845, 34.405000000000001],
              [-3.84, 34.395000000000003],
              [-3.86, 34.369999999999997],
              [-3.84, 34.369999999999997],
              [-3.845, 34.344999999999999],
              [-3.835, 34.340000000000003],
              [-3.86, 34.305],
              [-3.915, 34.305],
              [-3.91, 34.259999999999998],
              [-3.885, 34.259999999999998],
              [-3.89, 34.240000000000002],
              [-3.88, 34.230000000000004],
              [-3.895, 34.225000000000001],
              [-3.815, 34.210000000000001],
              [-3.83, 34.164999999999999],
              [-3.83, 34.134999999999998],
              [-3.84, 34.125],
              [-3.83, 34.119999999999997],
              [-3.84, 34.094999999999999],
              [-3.84, 34.055],
              [-3.855, 34.030000000000001],
              [-3.825, 34.009999999999998],
              [-3.895, 33.969999999999999],
              [-3.91, 33.914999999999999],
              [-3.9, 33.890000000000001],
              [-3.905, 33.875],
              [-3.925, 33.835000000000001],
              [-3.965, 33.799999999999997],
              [-3.975, 33.780000000000001],
              [-3.965, 33.780000000000001],
              [-3.97, 33.770000000000003],
              [-3.96, 33.734999999999999],
              [-3.99, 33.719999999999999],
              [-4.03, 33.715000000000003],
              [-4.105, 33.68],
              [-4.08, 33.674999999999997],
              [-4.09, 33.664999999999999],
              [-4.125, 33.664999999999999],
              [-4.11, 33.640000000000001],
              [-4.085, 33.640000000000001],
              [-4.11, 33.560000000000002],
              [-4.075, 33.545000000000002],
              [-4.06, 33.524999999999999],
              [-4.03, 33.515000000000001],
              [-4.01, 33.524999999999999],
              [-3.985, 33.515000000000001],
              [-3.945, 33.515000000000001],
              [-3.93, 33.545000000000002],
              [-3.9, 33.549999999999997],
              [-3.89, 33.57],
              [-3.875, 33.57],
              [-3.845, 33.535000000000004],
              [-3.84, 33.509999999999998],
              [-3.795, 33.555],
              [-3.855, 33.594999999999999],
              [-3.805, 33.655000000000001],
              [-3.735, 33.68],
              [-3.71, 33.700000000000003],
              [-3.695, 33.730000000000004],
              [-3.65, 33.740000000000002],
              [-3.54, 33.869999999999997],
              [-3.515, 33.910000000000004],
              [-3.43, 33.865000000000002],
              [-3.42, 33.850000000000001],
              [-3.38, 33.829999999999998],
              [-3.33, 33.785000000000004],
              [-3.215, 33.759999999999998],
              [-3.045, 33.765000000000001],
              [-3.03, 33.730000000000004],
              [-3.125, 33.660000000000004],
              [-3.12, 33.634999999999998],
              [-3.19, 33.57],
              [-3.215, 33.564999999999998],
              [-3.23, 33.535000000000004],
              [-3.245, 33.530000000000001],
              [-3.255, 33.515000000000001],
              [-3.12, 33.405000000000001],
              [-3, 33.32],
              [-2.905, 33.215000000000003],
              [-2.9, 33.200000000000003],
              [-2.905, 33.174999999999997],
              [-2.94, 33.149999999999999],
              [-3.03, 33.115000000000002],
              [-3.34, 33.060000000000002],
              [-3.435, 33.055],
              [-3.575, 33.109999999999999],
              [-3.605, 33.094999999999999],
              [-3.685, 33.024999999999999],
              [-3.715, 32.980000000000004],
              [-3.725, 32.950000000000003],
              [-3.75, 32.924999999999997],
              [-3.8, 32.895000000000003],
              [-3.87, 32.825000000000003],
              [-3.945, 32.719999999999999],
              [-3.98, 32.689999999999998],
              [-4.015, 32.640000000000001],
              [-4.02, 32.609999999999999],
              [-4.06, 32.600000000000001],
              [-4.105, 32.625],
              [-4.175, 32.600000000000001],
              [-4.215, 32.615000000000002],
              [-4.285, 32.715000000000003],
              [-4.405, 32.810000000000002],
              [-4.465, 32.840000000000003],
              [-4.485, 32.869999999999997],
              [-4.48, 32.884999999999998],
              [-4.56, 32.895000000000003],
              [-4.655, 32.880000000000003],
              [-4.715, 32.890000000000001],
              [-4.77, 32.910000000000004],
              [-4.775, 32.924999999999997],
              [-4.755, 32.960000000000001],
              [-4.765, 33],
              [-4.775, 33.020000000000003],
              [-4.81, 33.049999999999997],
              [-4.83, 33.100000000000001],
              [-4.845, 33.075000000000003],
              [-4.995, 33.085000000000001],
              [-5.015, 33.075000000000003],
              [-5.07, 33.024999999999999],
              [-5.055, 32.980000000000004],
              [-5.125, 32.945],
              [-5.14, 32.910000000000004],
              [-5.17, 32.905000000000001],
              [-5.19, 32.914999999999999],
              [-5.25, 32.869999999999997],
              [-5.275, 32.859999999999999],
              [-5.24, 32.924999999999997],
              [-5.28, 32.969999999999999],
              [-5.305, 32.984999999999999],
              [-5.29, 33.005000000000003],
              [-5.29, 33.030000000000001],
              [-5.32, 33.035000000000004],
              [-5.33, 33.085000000000001],
              [-5.3, 33.100000000000001],
              [-5.285, 33.130000000000003],
              [-5.28, 33.170000000000002],
              [-5.295, 33.174999999999997],
              [-5.365, 33.155000000000001],
              [-5.42, 33.155000000000001],
              [-5.46, 33.170000000000002],
              [-5.485, 33.195],
              [-5.49, 33.234999999999999],
              [-5.51, 33.255000000000003],
              [-5.5, 33.305],
              [-5.51, 33.314999999999998],
              [-5.505, 33.325000000000003],
              [-5.515, 33.32],
              [-5.525, 33.340000000000003],
              [-5.545, 33.344999999999999],
              [-5.54, 33.350000000000001],
              [-5.55, 33.365000000000002],
              [-5.575, 33.369999999999997],
              [-5.57, 33.375],
              [-5.58, 33.384999999999998],
              [-5.595, 33.390000000000001],
              [-5.615, 33.380000000000003],
              [-5.625, 33.390000000000001],
              [-5.635, 33.384999999999998],
              [-5.655, 33.395000000000003],
              [-5.66, 33.390000000000001],
              [-5.68, 33.395000000000003],
              [-5.735, 33.420000000000002],
              [-5.75, 33.414999999999999],
              [-5.755, 33.43],
              [-5.775, 33.439999999999998],
              [-5.77, 33.445],
              [-5.785, 33.454999999999998],
              [-5.785, 33.480000000000004],
              [-5.8, 33.475000000000001],
              [-5.82, 33.490000000000002],
              [-5.815, 33.5],
              [-5.78, 33.515000000000001],
              [-5.78, 33.555],
              [-5.805, 33.605000000000004],
              [-5.79, 33.609999999999999],
              [-5.78, 33.649999999999999],
              [-5.785, 33.664999999999999],
              [-5.765, 33.689999999999998],
              [-5.74, 33.704999999999998],
              [-5.73, 33.755000000000003],
              [-5.715, 33.785000000000004],
              [-5.735, 33.805],
              [-5.765, 33.814999999999998],
              [-5.755, 33.829999999999998],
              [-5.765, 33.850000000000001],
              [-5.785, 33.859999999999999],
              [-5.79, 33.855000000000004],
              [-5.805, 33.869999999999997],
              [-5.8, 33.895000000000003],
              [-5.78, 33.895000000000003],
              [-5.775, 33.93],
              [-5.76, 33.93],
              [-5.755, 33.945],
              [-5.775, 33.980000000000004],
              [-5.81, 34.005000000000003],
              [-5.815, 34.015000000000001],
              [-5.8, 34.024999999999999],
              [-5.81, 34.045000000000002],
              [-5.855, 34.064999999999998],
              [-5.885, 34.105000000000004],
            ],
          ],
          [
            [
              [-5.82, 33.490000000000002],
              [-5.825, 33.490000000000002],
              [-5.82, 33.494999999999997],
              [-5.82, 33.490000000000002],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      region: "Marrakech-Safi",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-9.84, 31.120000000000001],
              [-9.84, 31.145],
              [-9.815, 31.185000000000002],
              [-9.825000000000001, 31.210000000000001],
              [-9.815, 31.225000000000001],
              [-9.82, 31.245000000000001],
              [-9.795, 31.289999999999999],
              [-9.800000000000001, 31.365000000000002],
              [-9.815, 31.385000000000002],
              [-9.845000000000001, 31.390000000000001],
              [-9.835000000000001, 31.400000000000002],
              [-9.835000000000001, 31.414999999999999],
              [-9.805, 31.435000000000002],
              [-9.785, 31.460000000000001],
              [-9.775, 31.490000000000002],
              [-9.765000000000001, 31.495000000000001],
              [-9.765000000000001, 31.510000000000002],
              [-9.775, 31.515000000000001],
              [-9.765000000000001, 31.515000000000001],
              [-9.69, 31.595000000000002],
              [-9.675000000000001, 31.635000000000002],
              [-9.675000000000001, 31.699999999999999],
              [-9.640000000000001, 31.725000000000001],
              [-9.640000000000001, 31.734999999999999],
              [-9.585000000000001, 31.789999999999999],
              [-9.585000000000001, 31.800000000000001],
              [-9.550000000000001, 31.824999999999999],
              [-9.515000000000001, 31.870000000000001],
              [-9.5, 31.875],
              [-9.495000000000001, 31.890000000000001],
              [-9.470000000000001, 31.905000000000001],
              [-9.470000000000001, 31.914999999999999],
              [-9.450000000000001, 31.935000000000002],
              [-9.43, 31.945],
              [-9.425000000000001, 31.960000000000001],
              [-9.345000000000001, 32.030000000000001],
              [-9.32, 32.100000000000001],
              [-9.325000000000001, 32.109999999999999],
              [-9.32, 32.119999999999997],
              [-9.295, 32.134999999999998],
              [-9.290000000000001, 32.149999999999999],
              [-9.279999999999999, 32.149999999999999],
              [-9.275, 32.164999999999999],
              [-9.26, 32.174999999999997],
              [-9.25, 32.204999999999998],
              [-9.255000000000001, 32.204999999999998],
              [-9.25, 32.225000000000001],
              [-9.265000000000001, 32.255000000000003],
              [-9.25, 32.274999999999999],
              [-9.245000000000001, 32.310000000000002],
              [-9.25, 32.32],
              [-9.279999999999999, 32.329999999999998],
              [-9.290000000000001, 32.369999999999997],
              [-9.255000000000001, 32.405000000000001],
              [-9.23, 32.480000000000004],
              [-9.265000000000001, 32.505000000000003],
              [-9.27, 32.530000000000001],
              [-9.285, 32.545000000000002],
              [-9.185, 32.609999999999999],
              [-9.154999999999999, 32.640000000000001],
              [-9.145, 32.640000000000001],
              [-9.065, 32.719999999999999],
              [-9.050000000000001, 32.719999999999999],
              [-8.98, 32.674999999999997],
              [-9.005000000000001, 32.655000000000001],
              [-9.02, 32.609999999999999],
              [-9.005000000000001, 32.609999999999999],
              [-8.995000000000001, 32.579999999999998],
              [-8.970000000000001, 32.57],
              [-8.915000000000001, 32.515000000000001],
              [-8.85, 32.494999999999997],
              [-8.779999999999999, 32.515000000000001],
              [-8.765000000000001, 32.509999999999998],
              [-8.745000000000001, 32.515000000000001],
              [-8.745000000000001, 32.505000000000003],
              [-8.734999999999999, 32.505000000000003],
              [-8.715, 32.524999999999999],
              [-8.665000000000001, 32.515000000000001],
              [-8.630000000000001, 32.480000000000004],
              [-8.6, 32.390000000000001],
              [-8.575000000000001, 32.350000000000001],
              [-8.550000000000001, 32.329999999999998],
              [-8.555, 32.295000000000002],
              [-8.545, 32.295000000000002],
              [-8.535, 32.314999999999998],
              [-8.515000000000001, 32.314999999999998],
              [-8.505000000000001, 32.329999999999998],
              [-8.48, 32.329999999999998],
              [-8.44, 32.350000000000001],
              [-8.435, 32.359999999999999],
              [-8.365, 32.365000000000002],
              [-8.315, 32.420000000000002],
              [-8.120000000000001, 32.5],
              [-8.055, 32.575000000000003],
              [-8.074999999999999, 32.674999999999997],
              [-8.06, 32.704999999999998],
              [-8.045, 32.780000000000001],
              [-7.99, 32.814999999999998],
              [-7.99, 32.795000000000002],
              [-7.96, 32.780000000000001],
              [-7.96, 32.755000000000003],
              [-7.945, 32.740000000000002],
              [-7.935, 32.740000000000002],
              [-7.930000000000001, 32.725000000000001],
              [-7.92, 32.725000000000001],
              [-7.91, 32.689999999999998],
              [-7.88, 32.685000000000002],
              [-7.875, 32.670000000000002],
              [-7.845, 32.660000000000004],
              [-7.77, 32.600000000000001],
              [-7.715, 32.594999999999999],
              [-7.73, 32.590000000000003],
              [-7.715, 32.57],
              [-7.72, 32.555],
              [-7.7, 32.549999999999997],
              [-7.695, 32.530000000000001],
              [-7.685, 32.535000000000004],
              [-7.68, 32.530000000000001],
              [-7.685, 32.520000000000003],
              [-7.665, 32.515000000000001],
              [-7.675, 32.5],
              [-7.65, 32.494999999999997],
              [-7.615, 32.460000000000001],
              [-7.55, 32.454999999999998],
              [-7.545, 32.445],
              [-7.52, 32.445],
              [-7.5, 32.454999999999998],
              [-7.5, 32.469999999999999],
              [-7.48, 32.460000000000001],
              [-7.485, 32.445],
              [-7.47, 32.439999999999998],
              [-7.465, 32.460000000000001],
              [-7.415, 32.445],
              [-7.375, 32.465000000000003],
              [-7.335, 32.414999999999999],
              [-7.34, 32.405000000000001],
              [-7.315, 32.410000000000004],
              [-7.31, 32.395000000000003],
              [-7.3, 32.405000000000001],
              [-7.295, 32.399999999999999],
              [-7.285, 32.405000000000001],
              [-7.27, 32.390000000000001],
              [-7.26, 32.390000000000001],
              [-7.26, 32.375],
              [-7.24, 32.375],
              [-7.235, 32.365000000000002],
              [-7.225000000000001, 32.369999999999997],
              [-7.195, 32.344999999999999],
              [-7.19, 32.329999999999998],
              [-7.165, 32.335000000000001],
              [-7.16, 32.325000000000003],
              [-7.140000000000001, 32.335000000000001],
              [-7.13, 32.314999999999998],
              [-7.115, 32.325000000000003],
              [-7.100000000000001, 32.305],
              [-7.115, 32.240000000000002],
              [-7.105, 32.215000000000003],
              [-7.125, 32.160000000000004],
              [-7.13, 32.100000000000001],
              [-7.12, 32.085000000000001],
              [-7.11, 32.020000000000003],
              [-7.055, 31.975000000000001],
              [-7.07, 31.965],
              [-7.07, 31.91],
              [-7.05, 31.905000000000001],
              [-7.055, 31.895],
              [-7.03, 31.859999999999999],
              [-7.02, 31.859999999999999],
              [-7.025, 31.845000000000002],
              [-7.015000000000001, 31.845000000000002],
              [-7, 31.82],
              [-7.02, 31.82],
              [-7.035, 31.830000000000002],
              [-7.045, 31.789999999999999],
              [-7.025, 31.775000000000002],
              [-7.05, 31.760000000000002],
              [-7.105, 31.760000000000002],
              [-7.175, 31.745000000000001],
              [-7.205, 31.765000000000001],
              [-7.215, 31.760000000000002],
              [-7.23, 31.775000000000002],
              [-7.23, 31.760000000000002],
              [-7.26, 31.765000000000001],
              [-7.265000000000001, 31.740000000000002],
              [-7.275, 31.75],
              [-7.285, 31.734999999999999],
              [-7.32, 31.715],
              [-7.305, 31.705000000000002],
              [-7.305, 31.695],
              [-7.32, 31.695],
              [-7.315, 31.670000000000002],
              [-7.27, 31.675000000000001],
              [-7.265000000000001, 31.620000000000001],
              [-7.22, 31.609999999999999],
              [-7.21, 31.574999999999999],
              [-7.195, 31.560000000000002],
              [-7.135, 31.560000000000002],
              [-7.13, 31.535],
              [-7.140000000000001, 31.530000000000001],
              [-7.155, 31.470000000000002],
              [-7.17, 31.465],
              [-7.185, 31.43],
              [-7.22, 31.414999999999999],
              [-7.24, 31.395],
              [-7.235, 31.375],
              [-7.255, 31.350000000000001],
              [-7.32, 31.310000000000002],
              [-7.385, 31.285],
              [-7.435, 31.289999999999999],
              [-7.445, 31.275000000000002],
              [-7.48, 31.27],
              [-7.5, 31.25],
              [-7.585, 31.245000000000001],
              [-7.585, 31.23],
              [-7.605, 31.205000000000002],
              [-7.695, 31.164999999999999],
              [-7.71, 31.129999999999999],
              [-7.76, 31.140000000000001],
              [-7.785, 31.164999999999999],
              [-7.795, 31.155000000000001],
              [-7.825, 31.164999999999999],
              [-7.81, 31.145],
              [-7.885, 31.105],
              [-7.905, 31.080000000000002],
              [-7.945, 30.960000000000001],
              [-7.98, 30.920000000000002],
              [-8.015000000000001, 30.91],
              [-8.135, 30.940000000000001],
              [-8.220000000000001, 30.914999999999999],
              [-8.25, 30.890000000000001],
              [-8.279999999999999, 30.879999999999999],
              [-8.315, 30.879999999999999],
              [-8.35, 30.865000000000002],
              [-8.449999999999999, 30.865000000000002],
              [-8.605, 30.879999999999999],
              [-8.67, 30.900000000000002],
              [-8.705, 30.865000000000002],
              [-8.73, 30.855],
              [-8.725, 30.82],
              [-8.745000000000001, 30.810000000000002],
              [-8.765000000000001, 30.855],
              [-8.76, 30.890000000000001],
              [-8.77, 30.900000000000002],
              [-8.77, 30.914999999999999],
              [-8.790000000000001, 30.925000000000001],
              [-8.779999999999999, 30.935000000000002],
              [-8.800000000000001, 30.960000000000001],
              [-8.825000000000001, 30.975000000000001],
              [-8.915000000000001, 30.914999999999999],
              [-8.984999999999999, 30.890000000000001],
              [-8.995000000000001, 30.824999999999999],
              [-9.040000000000001, 30.82],
              [-9.135, 30.830000000000002],
              [-9.15, 30.82],
              [-9.17, 30.830000000000002],
              [-9.245000000000001, 30.800000000000001],
              [-9.335000000000001, 30.890000000000001],
              [-9.345000000000001, 30.875],
              [-9.345000000000001, 30.855],
              [-9.385, 30.865000000000002],
              [-9.445, 30.84],
              [-9.495000000000001, 30.855],
              [-9.51, 30.870000000000001],
              [-9.525, 30.859999999999999],
              [-9.540000000000001, 30.870000000000001],
              [-9.535, 30.879999999999999],
              [-9.545, 30.885000000000002],
              [-9.57, 30.84],
              [-9.595000000000001, 30.859999999999999],
              [-9.609999999999999, 30.850000000000001],
              [-9.640000000000001, 30.800000000000001],
              [-9.66, 30.805],
              [-9.69, 30.785],
              [-9.715, 30.789999999999999],
              [-9.74, 30.845000000000002],
              [-9.734999999999999, 30.875],
              [-9.77, 30.890000000000001],
              [-9.77, 30.93],
              [-9.82, 30.940000000000001],
              [-9.825000000000001, 30.98],
              [-9.815, 30.990000000000002],
              [-9.81, 31.015000000000001],
              [-9.82, 31.050000000000001],
              [-9.815, 31.050000000000001],
              [-9.825000000000001, 31.065000000000001],
              [-9.815, 31.080000000000002],
              [-9.82, 31.095000000000002],
              [-9.84, 31.100000000000001],
              [-9.84, 31.120000000000001],
            ],
          ],
          [
            [
              [-9.25, 32.310000000000002],
              [-9.25, 32.305],
              [-9.255000000000001, 32.310000000000002],
              [-9.25, 32.310000000000002],
            ],
          ],
          [
            [
              [-9.295, 32.140000000000001],
              [-9.300000000000001, 32.145000000000003],
              [-9.31, 32.149999999999999],
              [-9.300000000000001, 32.149999999999999],
              [-9.295, 32.140000000000001],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      region: "Rabat-Sale-Kenitra",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-7.1260185, 33.8317561],
              [-7.11081, 33.815919999999998],
              [-7.1097306, 33.813966700000002],
              [-7.099396, 33.795262200000003],
              [-7.089431, 33.792401900000002],
              [-7.0820582, 33.792123699999998],
              [-7.0755651, 33.795462200000003],
              [-7.0662568, 33.800247900000002],
              [-7.0636004, 33.799114199999998],
              [-7.0602572, 33.7976873],
              [-7.0624888, 33.783135600000001],
              [-7.0521805, 33.775280899999998],
              [-7.0506442, 33.769580300000001],
              [-7.0465173, 33.769739399999999],
              [-7.0434258, 33.769858599999999],
              [-7.0391343, 33.762009800000001],
              [-7.038138, 33.752254700000002],
              [-7.0372546, 33.743605199999998],
              [-7.0303795, 33.7340339],
              [-7.0272982, 33.723619100000001],
              [-7.0242959, 33.709047200000001],
              [-7.0214531, 33.703049800000002],
              [-7.0281565, 33.692345600000003],
              [-7.0259163, 33.6887677],
              [-7.0291865, 33.6826328],
              [-7.0188782, 33.678626000000001],
              [-7.0151102, 33.671776000000001],
              [-7.015061, 33.6712925],
              [-7.0133955, 33.6549215],
              [-7.005317, 33.651623100000002],
              [-7.0034373, 33.641341199999999],
              [-6.9977743, 33.630887700000002],
              [-7.0022271, 33.629471600000002],
              [-7.0042956, 33.6241901],
              [-7.0039523, 33.615041400000003],
              [-7.0003648, 33.6130706],
              [-6.9980044, 33.608603000000002],
              [-6.9959874, 33.608531499999998],
              [-6.9934125, 33.6102828],
              [-6.9919963, 33.609782500000001],
              [-6.9910092, 33.606065299999997],
              [-6.9889064, 33.604242399999997],
              [-6.9883914, 33.602955700000003],
              [-6.9910092, 33.601525899999999],
              [-6.9921679, 33.600918200000002],
              [-6.9922109, 33.596986200000003],
              [-6.9904942, 33.595306100000002],
              [-6.9919963, 33.593411500000002],
              [-6.9922109, 33.591016400000001],
              [-6.9904942, 33.590265700000003],
              [-6.9879622, 33.591874300000001],
              [-6.9873185, 33.590909099999998],
              [-6.9871469, 33.5871554],
              [-6.9876189, 33.584617100000003],
              [-6.9861598, 33.584009299999998],
              [-6.9825549, 33.583759100000002],
              [-6.9809241, 33.5827223],
              [-6.9812675, 33.582078699999997],
              [-6.9843574, 33.582042999999999],
              [-6.9847865, 33.581220600000002],
              [-6.9813962, 33.578074299999997],
              [-6.9778771, 33.577752500000003],
              [-6.9774051, 33.573712200000003],
              [-6.9741435, 33.574820600000002],
              [-6.9724269, 33.5739983],
              [-6.9720407, 33.572818300000002],
              [-6.9734569, 33.571638299999996],
              [-6.9791217, 33.570637099999999],
              [-6.9803662, 33.570529899999997],
              [-6.9806237, 33.568634699999997],
              [-6.9756885, 33.5664534],
              [-6.9742722, 33.562484099999999],
              [-6.9735427, 33.5616974],
              [-6.9698949, 33.5591583],
              [-6.96629, 33.557262899999998],
              [-6.9639726, 33.5540442],
              [-6.9598527, 33.548643599999998],
              [-6.9584794, 33.5443158],
              [-6.9538875, 33.543421600000002],
              [-6.9507546, 33.542169700000002],
              [-6.9486797, 33.543469899999998],
              [-6.9462764, 33.5456875],
              [-6.9446607, 33.548035599999999],
              [-6.9459481, 33.551183000000002],
              [-6.9503105, 33.557740299999999],
              [-6.9494243, 33.564994499999997],
              [-6.9368887, 33.5799764],
              [-6.9257779, 33.584109400000003],
              [-6.917036, 33.583151299999997],
              [-6.9079465, 33.571917200000001],
              [-6.8929776, 33.574280700000003],
              [-6.8803992, 33.572131800000001],
              [-6.8733482, 33.568938699999997],
              [-6.8623362, 33.568405900000002],
              [-6.8534182, 33.572081699999998],
              [-6.8269267, 33.568566799999999],
              [-6.8164597, 33.571813599999999],
              [-6.8102541, 33.570941099999999],
              [-6.8031559, 33.567973199999997],
              [-6.7963753, 33.561865400000002],
              [-6.7837152, 33.562591400000002],
              [-6.7735486, 33.562276699999998],
              [-6.7716947, 33.558267800000003],
              [-6.7692785, 33.553632899999997],
              [-6.767519, 33.549756000000002],
              [-6.7615237, 33.548450500000001],
              [-6.7569361, 33.542720500000001],
              [-6.7724284, 33.513322000000002],
              [-6.7820737, 33.502653199999997],
              [-6.7822705, 33.502483400000003],
              [-6.7872064, 33.498225300000001],
              [-6.7891547, 33.496544399999998],
              [-6.7983064, 33.498747199999997],
              [-6.8094215, 33.4940876],
              [-6.8240942, 33.485440599999997],
              [-6.827579, 33.478663400000002],
              [-6.8282313, 33.462919200000002],
              [-6.8245663, 33.452818499999999],
              [-6.8140845, 33.446042300000002],
              [-6.8127887, 33.445204500000003],
              [-6.809797, 33.443270300000002],
              [-6.8038124, 33.435803900000003],
              [-6.7963323, 33.427234900000002],
              [-6.7964932, 33.421197800000002],
              [-6.8050313, 33.415060500000003],
              [-6.8071856, 33.407888700000001],
              [-6.8090224, 33.397076200000001],
              [-6.8080182, 33.3833883],
              [-6.8089538, 33.368243300000003],
              [-6.8058982, 33.341358499999998],
              [-6.8032456, 33.330096599999997],
              [-6.8014951, 33.322663300000002],
              [-6.8011431, 33.302859400000003],
              [-6.7974266, 33.275588499999998],
              [-6.7986026, 33.260403199999999],
              [-6.7987056, 33.2494862],
              [-6.8011003, 33.239824200000001],
              [-6.8050742, 33.235681800000002],
              [-6.8034434, 33.233075900000003],
              [-6.7992634, 33.228488200000001],
              [-6.7960277, 33.223907500000003],
              [-6.7914699, 33.221471299999997],
              [-6.7797969, 33.215145300000003],
              [-6.7679609, 33.213852699999997],
              [-6.7575501, 33.212779900000001],
              [-6.7354911, 33.214506200000002],
              [-6.7155545, 33.214236300000003],
              [-6.7080271, 33.213787500000002],
              [-6.696779, 33.213974200000003],
              [-6.6821521, 33.2120259],
              [-6.6770451, 33.212460299999996],
              [-6.6704232, 33.212665000000001],
              [-6.6646898, 33.212417299999998],
              [-6.6543666, 33.208990900000003],
              [-6.6410176, 33.204733300000001],
              [-6.6325677, 33.2026757],
              [-6.6131125, 33.193767000000001],
              [-6.6037071, 33.194168500000004],
              [-6.5926201, 33.191329899999999],
              [-6.5714834, 33.181604100000001],
              [-6.5750023, 33.176992200000001],
              [-6.5599349, 33.175242900000001],
              [-6.5503274, 33.164015599999999],
              [-6.5472846, 33.158195599999999],
              [-6.5412001, 33.148829900000003],
              [-6.531745, 33.148943799999998],
              [-6.5243508, 33.1569596],
              [-6.5202694, 33.175349099999998],
              [-6.5096866, 33.187338400000002],
              [-6.4946791, 33.186084999999999],
              [-6.472685, 33.196708200000003],
              [-6.4608153, 33.218036099999999],
              [-6.4475545, 33.2210483],
              [-6.4308175, 33.225255799999999],
              [-6.4176425, 33.2227751],
              [-6.4070424, 33.226738500000003],
              [-6.3838101, 33.255999899999999],
              [-6.3814743, 33.2748803],
              [-6.3970311, 33.295092400000001],
              [-6.4118112, 33.322626200000002],
              [-6.3918598, 33.347430299999999],
              [-6.4033997, 33.369836900000003],
              [-6.3777492, 33.3894716],
              [-6.3733633, 33.409327500000003],
              [-6.360523, 33.418440599999997],
              [-6.360374, 33.448338800000002],
              [-6.3603494, 33.453280900000003],
              [-6.3602958, 33.464021799999998],
              [-6.3602526, 33.472685200000001],
              [-6.360215, 33.480234400000001],
              [-6.3527735, 33.482611200000001],
              [-6.3449843, 33.478849199999999],
              [-6.3312128, 33.478033099999998],
              [-6.3217972, 33.469728099999998],
              [-6.3152011, 33.464042999999997],
              [-6.302378, 33.464196999999999],
              [-6.295018, 33.462449800000002],
              [-6.2791255, 33.451525099999998],
              [-6.262616, 33.447775999999998],
              [-6.2512219, 33.451210000000003],
              [-6.2374032, 33.445939099999997],
              [-6.2293995, 33.445645399999997],
              [-6.2208078, 33.4468952],
              [-6.2136281, 33.447135099999997],
              [-6.2026084, 33.439643500000003],
              [-6.1972988, 33.439084999999999],
              [-6.1993127, 33.435641699999998],
              [-6.2061394, 33.430446799999999],
              [-6.2067151, 33.427926399999997],
              [-6.2092293, 33.416918199999998],
              [-6.2111648, 33.409950899999998],
              [-6.2124265, 33.400532300000002],
              [-6.2081993, 33.388371499999998],
              [-6.209328, 33.375678800000003],
              [-6.2148297, 33.368188400000001],
              [-6.208066, 33.366407600000002],
              [-6.211139, 33.362389100000001],
              [-6.2110914, 33.355769600000002],
              [-6.2136769, 33.350324399999998],
              [-6.2088533, 33.345307200000001],
              [-6.2031519, 33.342496599999997],
              [-6.1963516, 33.3447776],
              [-6.1900434, 33.346017099999997],
              [-6.1834861, 33.345568999999998],
              [-6.1777655, 33.348026500000003],
              [-6.1687661, 33.343059500000003],
              [-6.1633566, 33.338067000000002],
              [-6.1565352, 33.336717100000001],
              [-6.1495443, 33.337301500000002],
              [-6.1418582, 33.3360682],
              [-6.1366071, 33.338080300000001],
              [-6.1293115, 33.342110099999999],
              [-6.1212348, 33.339686399999998],
              [-6.1158189, 33.337664400000001],
              [-6.1029443, 33.336653300000002],
              [-6.0941466, 33.3293459],
              [-6.0908765, 33.3150379],
              [-6.0812634, 33.309435899999997],
              [-6.0699595, 33.305698700000001],
              [-6.0624454, 33.3054232],
              [-6.0550556, 33.305152100000001],
              [-6.0423821, 33.304687199999996],
              [-6.0358077, 33.304996299999999],
              [-6.0313586, 33.305205399999998],
              [-6.0255116, 33.305480199999998],
              [-6.0176305, 33.305850700000001],
              [-6.0147118, 33.305987700000003],
              [-6.0105857, 33.306181799999997],
              [-6.0012971, 33.306618299999997],
              [-5.9948405, 33.306921699999997],
              [-5.9937421, 33.312946799999999],
              [-5.9929442, 33.317323600000002],
              [-5.9920939, 33.321987800000002],
              [-5.9797269, 33.320351299999999],
              [-5.9756608, 33.3197039],
              [-5.9706196, 33.3233958],
              [-5.9671284, 33.328461599999997],
              [-5.9659096, 33.330205200000002],
              [-5.9645611, 33.331649200000001],
              [-5.963324, 33.332032900000002],
              [-5.9618982, 33.331489699999999],
              [-5.9610173, 33.3301406],
              [-5.96037, 33.327623500000001],
              [-5.9556657, 33.324458999999997],
              [-5.9526134, 33.322566500000001],
              [-5.9506973, 33.320629199999999],
              [-5.9477683, 33.319202799999999],
              [-5.9459505, 33.319741700000002],
              [-5.9428176, 33.3242671],
              [-5.9395132, 33.327085599999997],
              [-5.9386119, 33.327901400000002],
              [-5.9375969, 33.330855999999997],
              [-5.9355027, 33.332080499999996],
              [-5.9335479, 33.331809800000002],
              [-5.9323034, 33.328466200000001],
              [-5.9280119, 33.327883499999999],
              [-5.9260484, 33.325936400000003],
              [-5.925351, 33.318298200000001],
              [-5.9238061, 33.317753099999997],
              [-5.9221325, 33.318802099999999],
              [-5.9221325, 33.321289],
              [-5.9219951, 33.324523599999999],
              [-5.9172936, 33.328817600000001],
              [-5.913249, 33.32987],
              [-5.911144, 33.329174299999998],
              [-5.9168968, 33.3187949],
              [-5.9154054, 33.317019799999997],
              [-5.9120044, 33.317166800000003],
              [-5.9106991, 33.3139732],
              [-5.9082927, 33.314816899999997],
              [-5.9069236, 33.314482499999997],
              [-5.9052488, 33.314306700000003],
              [-5.9017906, 33.314636700000001],
              [-5.8978853, 33.313675500000002],
              [-5.8912763, 33.313971500000001],
              [-5.8869423, 33.315285799999998],
              [-5.8849467, 33.314702099999998],
              [-5.8796069, 33.310483599999998],
              [-5.8785093, 33.311201799999999],
              [-5.8772294, 33.311368600000002],
              [-5.8753582, 33.3111131],
              [-5.8741368, 33.3118616],
              [-5.8729336, 33.31212],
              [-5.868617, 33.312752099999997],
              [-5.8672012, 33.310856600000001],
              [-5.8657452, 33.310839600000001],
              [-5.8640294, 33.313614600000001],
              [-5.8624226, 33.313564399999997],
              [-5.8603344, 33.310765199999999],
              [-5.8590687, 33.3099968],
              [-5.8560861, 33.3100022],
              [-5.8548304, 33.311310400000004],
              [-5.8515157, 33.310212900000003],
              [-5.8487365, 33.311168700000003],
              [-5.8451615, 33.310928400000002],
              [-5.842827, 33.311093399999997],
              [-5.8379325, 33.309960099999998],
              [-5.8341842, 33.310790300000001],
              [-5.8322928, 33.310930999999997],
              [-5.8315342, 33.310562500000003],
              [-5.8308121, 33.307699599999999],
              [-5.8299357, 33.306699799999997],
              [-5.8264487, 33.305356600000003],
              [-5.8121369, 33.309059400000002],
              [-5.8042791, 33.316615900000002],
              [-5.7961767, 33.322116999999999],
              [-5.7881172, 33.327728800000003],
              [-5.7741697, 33.334075400000003],
              [-5.7837356, 33.338152000000001],
              [-5.7943743, 33.336169300000002],
              [-5.7957477, 33.342543800000001],
              [-5.7999447, 33.346788599999996],
              [-5.8037212, 33.351008],
              [-5.8086908, 33.353951100000003],
              [-5.8137302, 33.356483300000001],
              [-5.8170566, 33.361009099999997],
              [-5.8160651, 33.362837200000001],
              [-5.8137584, 33.364591699999998],
              [-5.8108402, 33.3690468],
              [-5.8098961, 33.372543],
              [-5.8075379, 33.375069600000003],
              [-5.8064306, 33.385728999999998],
              [-5.8061089, 33.398911099999999],
              [-5.8096923, 33.410056599999997],
              [-5.8162154, 33.419513600000002],
              [-5.8162154, 33.426981699999999],
              [-5.8066881, 33.432579599999997],
              [-5.800444, 33.443824599999999],
              [-5.7947748, 33.452930500000001],
              [-5.794174, 33.458516099999997],
              [-5.7893332, 33.464917499999999],
              [-5.7867581, 33.470199299999997],
              [-5.787912, 33.470973499999999],
              [-5.7876361, 33.4725848],
              [-5.7859303, 33.473965800000002],
              [-5.7854266, 33.475158100000002],
              [-5.7878117, 33.476225700000001],
              [-5.78744, 33.478264799999998],
              [-5.7881904, 33.4784164],
              [-5.7909596, 33.478024499999997],
              [-5.7914991, 33.478354699999997],
              [-5.7916505, 33.479784700000003],
              [-5.7921354, 33.480410200000001],
              [-5.7930838, 33.480369899999999],
              [-5.795029, 33.47842],
              [-5.7973067, 33.478646400000002],
              [-5.7984139, 33.478276800000003],
              [-5.8008279, 33.476474500000002],
              [-5.8013204, 33.4768629],
              [-5.802684, 33.477863399999997],
              [-5.8043148, 33.4785337],
              [-5.8065228, 33.479238799999997],
              [-5.8105535, 33.481126099999997],
              [-5.8129354, 33.482455799999997],
              [-5.813254, 33.484010300000001],
              [-5.8141584, 33.485723800000002],
              [-5.815961, 33.485921500000003],
              [-5.8165832, 33.486494200000003],
              [-5.8156606, 33.487527700000001],
              [-5.8151456, 33.4882542],
              [-5.8163864, 33.4890422],
              [-5.8169909, 33.489290400000002],
              [-5.8186163, 33.489461300000002],
              [-5.8203598, 33.489017500000003],
              [-5.8214808, 33.488493099999999],
              [-5.8223553, 33.488791999999997],
              [-5.8233156, 33.489609799999997],
              [-5.823985, 33.4905574],
              [-5.8236696, 33.492220699999997],
              [-5.821776, 33.493043900000004],
              [-5.8203297, 33.493298899999999],
              [-5.8191548, 33.492532099999998],
              [-5.8184715, 33.492494499999999],
              [-5.8168193, 33.493458099999998],
              [-5.8160683, 33.495599200000001],
              [-5.8145018, 33.498134800000003],
              [-5.8146091, 33.498837999999999],
              [-5.7797201, 33.514940000000003],
              [-5.7834447, 33.53593],
              [-5.7804406, 33.554236000000003],
              [-5.7861054, 33.572366500000001],
              [-5.8043058, 33.606317799999999],
              [-5.7917703, 33.610878399999997],
              [-5.7776031, 33.652098799999997],
              [-5.7854566, 33.6672157],
              [-5.7631886, 33.691225099999997],
              [-5.7417095, 33.706277999999998],
              [-5.7355297, 33.731457800000001],
              [-5.732286, 33.752910300000003],
              [-5.7204413, 33.769543499999997],
              [-5.7167714, 33.777785700000003],
              [-5.714587, 33.785686599999998],
              [-5.7225693, 33.791386099999997],
              [-5.7279766, 33.798608000000002],
              [-5.7340706, 33.804510000000001],
              [-5.7435978, 33.808525199999998],
              [-5.7532108, 33.812222900000002],
              [-5.7633603, 33.814872299999998],
              [-5.7584584, 33.8236141],
              [-5.7554426, 33.831596699999999],
              [-5.7532314, 33.837449399999997],
              [-5.7674664, 33.850783],
              [-5.7750238, 33.853637800000001],
              [-5.782564, 33.853053199999998],
              [-5.7840574, 33.8544895],
              [-5.7850725, 33.856304199999997],
              [-5.785845, 33.858157400000003],
              [-5.7900077, 33.855662700000003],
              [-5.7932693, 33.858121699999998],
              [-5.7980329, 33.862754500000001],
              [-5.8023674, 33.865747900000002],
              [-5.8051376, 33.869564199999999],
              [-5.8074743, 33.874905400000003],
              [-5.8059959, 33.877545599999998],
              [-5.8024339, 33.881963599999999],
              [-5.7994491, 33.885059599999998],
              [-5.7979707, 33.887058199999998],
              [-5.800846, 33.891689399999997],
              [-5.7991058, 33.893182099999997],
              [-5.7959301, 33.894393200000003],
              [-5.7895206, 33.895646499999998],
              [-5.7779806, 33.895856700000003],
              [-5.7787209, 33.899450899999998],
              [-5.7807487, 33.902841899999999],
              [-5.7815469, 33.9040404],
              [-5.7809525, 33.905050199999998],
              [-5.7808152, 33.906466000000002],
              [-5.780886, 33.908921800000002],
              [-5.7800964, 33.910724000000002],
              [-5.7793925, 33.912474500000002],
              [-5.7774227, 33.914627400000001],
              [-5.7778863, 33.916551200000001],
              [-5.7736483, 33.9178967],
              [-5.7737278, 33.921784500000001],
              [-5.7740001, 33.928692099999999],
              [-5.7680757, 33.929598400000003],
              [-5.7629259, 33.927889200000003],
              [-5.7591085, 33.932074900000003],
              [-5.7528859, 33.937949799999998],
              [-5.7570486, 33.941047300000001],
              [-5.755332, 33.942400200000002],
              [-5.7542591, 33.944963600000001],
              [-5.7525769, 33.946230999999997],
              [-5.7677501, 33.964770000000001],
              [-5.7761401, 33.981070000000003],
              [-5.7902901, 33.991100000000003],
              [-5.7965175, 33.994405399999998],
              [-5.800937, 34.001803199999998],
              [-5.8080395, 34.007036499999998],
              [-5.8134039, 34.013500399999998],
              [-5.8102282, 34.020194799999999],
              [-5.7987011, 34.024231899999997],
              [-5.8052285, 34.034396700000002],
              [-5.8093033, 34.040037599999998],
              [-5.8111395, 34.043486000000001],
              [-5.8122988, 34.045663300000001],
              [-5.8319669, 34.049855600000001],
              [-5.8542501, 34.064459999999997],
              [-5.8733673, 34.0920092],
              [-5.883109, 34.103192800000002],
              [-5.8803023, 34.109617299999996],
              [-5.87987, 34.120229899999998],
              [-5.8560037, 34.142656000000002],
              [-5.8414605, 34.147930600000002],
              [-5.8143929, 34.157746500000002],
              [-5.8244236, 34.171807000000001],
              [-5.8294833, 34.188585600000003],
              [-5.7951725, 34.190605499999997],
              [-5.7933787, 34.211028800000001],
              [-5.7698482, 34.166473799999999],
              [-5.7679729, 34.162887300000001],
              [-5.7636985, 34.163022300000002],
              [-5.7625827, 34.167141299999997],
              [-5.7582912, 34.167993500000001],
              [-5.7509784, 34.167077399999997],
              [-5.7514247, 34.159684300000002],
              [-5.7440432, 34.159236800000002],
              [-5.730409, 34.162550000000003],
              [-5.7078268, 34.1736845],
              [-5.7013348, 34.173957100000003],
              [-5.70658, 34.144869900000003],
              [-5.70455, 34.120939900000003],
              [-5.68403, 34.118529899999999],
              [-5.6516763, 34.119491400000001],
              [-5.6483259, 34.119414200000001],
              [-5.6419088, 34.125577200000002],
              [-5.6268884, 34.121861199999998],
              [-5.6112629, 34.122767099999997],
              [-5.6044694, 34.120145200000003],
              [-5.6021606, 34.1145742],
              [-5.5944873, 34.107403900000001],
              [-5.5893504, 34.107144400000003],
              [-5.58198, 34.107829899999999],
              [-5.5583956, 34.120636900000001],
              [-5.5421264, 34.129202200000002],
              [-5.5345733, 34.1371593],
              [-5.5343604, 34.147643000000002],
              [-5.5314834, 34.159882500000002],
              [-5.5247886, 34.1591722],
              [-5.5216987, 34.157094800000003],
              [-5.5125925, 34.153977900000001],
              [-5.5035197, 34.151139100000002],
              [-5.497959, 34.153808900000001],
              [-5.4930962, 34.156143499999999],
              [-5.4908855, 34.157204900000004],
              [-5.4833879, 34.153856300000001],
              [-5.4751467, 34.150175400000002],
              [-5.4690029, 34.147431300000001],
              [-5.4688748, 34.156080299999999],
              [-5.4641584, 34.165103700000003],
              [-5.4564508, 34.165071699999999],
              [-5.443112, 34.184536399999999],
              [-5.4640976, 34.191338100000003],
              [-5.4697667, 34.2037367],
              [-5.473715, 34.212062899999999],
              [-5.4639989, 34.226190000000003],
              [-5.4509097, 34.234716300000002],
              [-5.4361985, 34.233499399999999],
              [-5.4327567, 34.241961000000003],
              [-5.429641, 34.250255099999997],
              [-5.4263966, 34.248839699999998],
              [-5.4227395, 34.248063399999999],
              [-5.4211301, 34.2499666],
              [-5.420937, 34.253242499999999],
              [-5.4225463, 34.256741699999999],
              [-5.4266447, 34.259307999999997],
              [-5.4298849, 34.261671999999997],
              [-5.4294772, 34.264628199999997],
              [-5.4297776, 34.267323699999999],
              [-5.4323096, 34.270763799999997],
              [-5.4317471, 34.2723339],
              [-5.4323253, 34.2736515],
              [-5.433262, 34.274751700000003],
              [-5.4339304, 34.275206400000002],
              [-5.4343488, 34.277208399999999],
              [-5.4355613, 34.279406100000003],
              [-5.4367252, 34.279595800000003],
              [-5.4387305, 34.279762499999997],
              [-5.4387712, 34.282907700000003],
              [-5.4402754, 34.285672699999999],
              [-5.4407562, 34.289346999999999],
              [-5.4411928, 34.292758800000001],
              [-5.4436013, 34.294255],
              [-5.4447998, 34.293127499999997],
              [-5.4453288, 34.292702900000002],
              [-5.4460636, 34.293271099999998],
              [-5.4464102, 34.294800000000002],
              [-5.4471408, 34.297003500000002],
              [-5.4473565, 34.297852599999999],
              [-5.4467653, 34.2987939],
              [-5.4447354, 34.298960600000001],
              [-5.4433193, 34.2998531],
              [-5.4430467, 34.300837799999996],
              [-5.4440809, 34.3020706],
              [-5.4476096, 34.3040053],
              [-5.4511158, 34.306685299999998],
              [-5.4595337, 34.310409200000002],
              [-5.4611044, 34.312267599999998],
              [-5.4605636, 34.314112600000001],
              [-5.4610218, 34.315769699999997],
              [-5.4620582, 34.317441799999997],
              [-5.4628542, 34.319105],
              [-5.4642619, 34.319759900000001],
              [-5.4659517, 34.319349600000002],
              [-5.4669827, 34.318770100000002],
              [-5.4675697, 34.317707599999999],
              [-5.4676769, 34.316358100000002],
              [-5.4687165, 34.314983699999999],
              [-5.4695941, 34.314241099999997],
              [-5.4712206, 34.3135543],
              [-5.4718922, 34.313602099999997],
              [-5.4734608, 34.315713799999997],
              [-5.4766826, 34.321418600000001],
              [-5.4786739, 34.3228753],
              [-5.4805042, 34.325009799999997],
              [-5.4823625, 34.3258844],
              [-5.4842561, 34.325232200000002],
              [-5.487464, 34.323198699999999],
              [-5.4880498, 34.321925499999999],
              [-5.4885638, 34.319911400000002],
              [-5.4886448, 34.3179832],
              [-5.4870955, 34.315693899999999],
              [-5.4865527, 34.314776700000003],
              [-5.4869485, 34.3140505],
              [-5.4876615, 34.313342900000002],
              [-5.4886737, 34.313128900000002],
              [-5.490062, 34.313070000000003],
              [-5.4909659, 34.313410300000001],
              [-5.4914326, 34.313882599999999],
              [-5.4918436, 34.315408099999999],
              [-5.4926359, 34.316134300000002],
              [-5.4947677, 34.316993400000001],
              [-5.4977873, 34.317565000000002],
              [-5.5003118, 34.318555600000003],
              [-5.5013954, 34.320130300000002],
              [-5.5031572, 34.322083200000002],
              [-5.5042847, 34.322864699999997],
              [-5.5047697, 34.323794200000002],
              [-5.5048855, 34.328173],
              [-5.5044446, 34.333125600000002],
              [-5.5022226, 34.336520499999999],
              [-5.5006906, 34.337964599999999],
              [-5.4933724, 34.339800099999998],
              [-5.492424, 34.340448600000002],
              [-5.4921472, 34.341794200000002],
              [-5.4930935, 34.344140899999999],
              [-5.4980437, 34.3463244],
              [-5.5028814, 34.348792299999999],
              [-5.5067245, 34.3500917],
              [-5.5081117, 34.3528819],
              [-5.5138881, 34.356352200000003],
              [-5.5171411, 34.356487700000002],
              [-5.5180508, 34.357606699999998],
              [-5.5179844, 34.358055],
              [-5.5184849, 34.358690000000003],
              [-5.520055, 34.3597386],
              [-5.5195036, 34.360393600000002],
              [-5.518641, 34.360881599999999],
              [-5.5183577, 34.3632481],
              [-5.5187355, 34.364595100000003],
              [-5.520261, 34.3650831],
              [-5.5271318, 34.365156599999999],
              [-5.5327485, 34.362295600000003],
              [-5.5325562, 34.364054299999999],
              [-5.5305821, 34.370643200000004],
              [-5.5288655, 34.375460500000003],
              [-5.5257756, 34.377373200000001],
              [-5.5196816, 34.378294099999998],
              [-5.51539, 34.378010699999997],
              [-5.509987, 34.376487699999998],
              [-5.5067211, 34.371351599999997],
              [-5.5049187, 34.372130900000002],
              [-5.5023481, 34.372307999999997],
              [-5.5020005, 34.370005599999999],
              [-5.4998547, 34.369722199999998],
              [-5.495649, 34.3708557],
              [-5.4907609, 34.373122700000003],
              [-5.4839803, 34.3766648],
              [-5.479088, 34.379215000000002],
              [-5.4700757, 34.382650499999997],
              [-5.4635526, 34.387396299999999],
              [-5.4556211, 34.394212199999998],
              [-5.4171525, 34.401826700000001],
              [-5.3938795, 34.413521600000003],
              [-5.3896524, 34.426379500000003],
              [-5.3867341, 34.429919300000002],
              [-5.3838159, 34.435405799999998],
              [-5.3796102, 34.440007000000001],
              [-5.3774644, 34.441458099999998],
              [-5.3739454, 34.438909799999998],
              [-5.3717138, 34.437848000000002],
              [-5.3659631, 34.438839000000002],
              [-5.3607274, 34.440750199999997],
              [-5.3572942, 34.4435462],
              [-5.3535177, 34.447120699999999],
              [-5.3478528, 34.450730399999998],
              [-5.3436471, 34.455719999999999],
              [-5.3409864, 34.4605678],
              [-5.3406431, 34.463150900000002],
              [-5.3453637, 34.465946099999996],
              [-5.3455354, 34.468599699999999],
              [-5.3389264, 34.474897300000002],
              [-5.3352357, 34.480522200000003],
              [-5.3429605, 34.482008],
              [-5.3445913, 34.482821600000001],
              [-5.3425313, 34.488446099999997],
              [-5.3383256, 34.495449499999999],
              [-5.331545, 34.503513400000003],
              [-5.3235628, 34.509843699999998],
              [-5.3181554, 34.513627499999998],
              [-5.3151513, 34.515897600000002],
              [-5.3260518, 34.518790199999998],
              [-5.33309, 34.522361500000002],
              [-5.3390123, 34.524977900000003],
              [-5.3439046, 34.527346799999997],
              [-5.3481962, 34.527700400000001],
              [-5.3579809, 34.5308116],
              [-5.365019, 34.532791500000002],
              [-5.3721429, 34.534346999999997],
              [-5.3802968, 34.535336899999997],
              [-5.3893949, 34.5371399],
              [-5.3924848, 34.535336899999997],
              [-5.3876783, 34.530139900000002],
              [-5.3869916, 34.522679699999998],
              [-5.394855, 34.521907499999998],
              [-5.3974356, 34.523295500000003],
              [-5.3994027, 34.527334400000001],
              [-5.4089235, 34.533620499999998],
              [-5.419706, 34.537254799999999],
              [-5.4251884, 34.536927800000001],
              [-5.4277333, 34.536729800000003],
              [-5.4290487, 34.538377199999999],
              [-5.4305507, 34.540392199999999],
              [-5.4296495, 34.540781000000003],
              [-5.4286624, 34.541558700000003],
              [-5.4305507, 34.542760600000001],
              [-5.4313232, 34.543821100000002],
              [-5.4306794, 34.545129000000003],
              [-5.4297782, 34.546507599999998],
              [-5.429392, 34.548593099999998],
              [-5.4293491, 34.550360400000002],
              [-5.4299499, 34.551350100000001],
              [-5.4326536, 34.552551899999997],
              [-5.4344989, 34.555485500000003],
              [-5.4352714, 34.558277599999997],
              [-5.4344131, 34.560610199999999],
              [-5.4306365, 34.562200599999997],
              [-5.4302932, 34.564391700000002],
              [-5.4320098, 34.565840700000003],
              [-5.430894, 34.568773800000002],
              [-5.4299928, 34.571282799999999],
              [-5.429392, 34.573614999999997],
              [-5.4271604, 34.576123899999999],
              [-5.4237701, 34.577360599999999],
              [-5.4219676, 34.578526599999996],
              [-5.4188777, 34.581565300000001],
              [-5.4158307, 34.583261299999997],
              [-5.4127408, 34.586653099999999],
              [-5.4155732, 34.589903499999998],
              [-5.4137186, 34.594873700000001],
              [-5.4173793, 34.598632500000001],
              [-5.4193834, 34.602896200000004],
              [-5.4220442, 34.608282899999999],
              [-5.4266791, 34.611532400000002],
              [-5.4346313, 34.6163782],
              [-5.4391803, 34.619235400000001],
              [-5.4438974, 34.620210700000001],
              [-5.4448177, 34.623623600000002],
              [-5.4500791, 34.622579999999999],
              [-5.4525682, 34.622085599999998],
              [-5.4569026, 34.622332800000002],
              [-5.4594089, 34.625424600000002],
              [-5.4614946, 34.628442200000002],
              [-5.4654428, 34.629466299999997],
              [-5.4706355, 34.629289700000001],
              [-5.4752275, 34.629784100000002],
              [-5.4792787, 34.631286600000003],
              [-5.4834844, 34.632063500000001],
              [-5.4871579, 34.631832199999998],
              [-5.4892608, 34.631267200000003],
              [-5.4909345, 34.632079400000002],
              [-5.492179, 34.635469200000003],
              [-5.4941961, 34.638541099999998],
              [-5.5000068, 34.642515000000003],
              [-5.5057403, 34.644578699999997],
              [-5.5105039, 34.645143599999997],
              [-5.5141517, 34.645002400000003],
              [-5.51713, 34.642903400000002],
              [-5.5215074, 34.638913600000002],
              [-5.5256273, 34.638313400000001],
              [-5.5283996, 34.638611699999998],
              [-5.530717, 34.642389700000003],
              [-5.5324765, 34.646767599999997],
              [-5.5376693, 34.657076099999998],
              [-5.5395576, 34.663747700000002],
              [-5.5408021, 34.665971499999998],
              [-5.540845, 34.668124599999999],
              [-5.5423213, 34.669202900000002],
              [-5.5470678, 34.667842200000003],
              [-5.5488874, 34.668743999999997],
              [-5.5508872, 34.667101000000002],
              [-5.5520889, 34.667877500000003],
              [-5.5519172, 34.671172400000003],
              [-5.5491038, 34.672812299999997],
              [-5.5494074, 34.675197300000001],
              [-5.5508443, 34.6788539],
              [-5.5514022, 34.685170900000003],
              [-5.5523893, 34.689475999999999],
              [-5.5518314, 34.6936398],
              [-5.5597278, 34.693181099999997],
              [-5.5680534, 34.693322199999997],
              [-5.575821, 34.693286899999997],
              [-5.5752202, 34.696182100000001],
              [-5.5757352, 34.699428099999999],
              [-5.5788423, 34.705302500000002],
              [-5.5795976, 34.7077545],
              [-5.5828592, 34.710294500000003],
              [-5.5881807, 34.7147395],
              [-5.5900861, 34.719836800000003],
              [-5.5900861, 34.7216004],
              [-5.5933305, 34.719395800000001],
              [-5.5984803, 34.717138200000001],
              [-5.6034757, 34.7165915],
              [-5.6041452, 34.719960200000003],
              [-5.604746, 34.722641000000003],
              [-5.608265, 34.720877299999998],
              [-5.6132432, 34.721723900000001],
              [-5.6191655, 34.723910799999999],
              [-5.6241609, 34.724563400000001],
              [-5.6299802, 34.725321700000002],
              [-5.6342889, 34.726044799999997],
              [-5.6337739, 34.7278083],
              [-5.6329843, 34.731529299999998],
              [-5.6304094, 34.733927600000001],
              [-5.6292936, 34.736255200000002],
              [-5.6317998, 34.737683500000003],
              [-5.6359197, 34.738247800000003],
              [-5.6393358, 34.739358699999997],
              [-5.6442281, 34.740063999999997],
              [-5.6504079, 34.738159600000003],
              [-5.652382, 34.736396300000003],
              [-5.6622525, 34.735832000000002],
              [-5.6666299, 34.734350800000001],
              [-5.667677, 34.729783500000003],
              [-5.668348, 34.726394200000001],
              [-5.6730236, 34.725209100000001],
              [-5.6740965, 34.720733000000003],
              [-5.6793579, 34.7161367],
              [-5.6825043, 34.712693399999999],
              [-5.696838, 34.712552299999999],
              [-5.7050778, 34.711282300000001],
              [-5.7145792, 34.704748600000002],
              [-5.7099658, 34.713465999999997],
              [-5.7105838, 34.718644500000003],
              [-5.7171241, 34.720404700000003],
              [-5.7193436, 34.727062799999999],
              [-5.7285053, 34.730474700000002],
              [-5.7374316, 34.729910400000001],
              [-5.7441694, 34.726552699999999],
              [-5.7509586, 34.729804600000001],
              [-5.7538468, 34.735334799999997],
              [-5.7606188, 34.736935899999999],
              [-5.7762314, 34.734844500000001],
              [-5.7881906, 34.746181100000001],
              [-5.7948575, 34.750752800000001],
              [-5.7863594, 34.761855199999999],
              [-5.7836987, 34.771655799999998],
              [-5.7815529, 34.777859999999997],
              [-5.7829713, 34.780713300000002],
              [-5.7832717, 34.782511],
              [-5.7873057, 34.788749500000002],
              [-5.7879924, 34.793648400000002],
              [-5.7870482, 34.798300300000001],
              [-5.7847737, 34.801859499999999],
              [-5.7838296, 34.803868100000003],
              [-5.7862757, 34.806264300000002],
              [-5.7859324, 34.808942299999998],
              [-5.7839583, 34.812078200000002],
              [-5.7849454, 34.814897000000002],
              [-5.784645, 34.819301000000003],
              [-5.7841493, 34.824957300000001],
              [-5.7869624, 34.831102799999996],
              [-5.7908642, 34.835227099999997],
              [-5.7864482, 34.840577600000003],
              [-5.7909973, 34.850668200000001],
              [-5.7843883, 34.852834100000003],
              [-5.7809551, 34.861356299999997],
              [-5.7744706, 34.874106099999999],
              [-5.7849419, 34.877824099999998],
              [-5.7911689, 34.887537000000002],
              [-5.7866199, 34.899892100000002],
              [-5.7876885, 34.908595900000002],
              [-5.7833583, 34.914089400000002],
              [-5.7817258, 34.919186199999999],
              [-5.7811267, 34.921056900000004],
              [-5.7813075, 34.927892700000001],
              [-5.7873701, 34.931469900000003],
              [-5.8074001, 34.937049899999998],
              [-5.8332195, 34.943635700000002],
              [-5.8575482, 34.9371151],
              [-5.8783128, 34.933983900000001],
              [-5.8843601, 34.934519899999998],
              [-5.919448, 34.940962800000001],
              [-5.9367258, 34.950383299999999],
              [-5.9620372, 34.969980800000002],
              [-5.9681767, 34.972966499999998],
              [-6.0070039, 34.991844700000001],
              [-6.0263845, 34.976479400000002],
              [-6.0537701, 34.976479900000001],
              [-6.0608276, 34.9840771],
              [-6.0668399, 34.990548500000003],
              [-6.0579951, 34.998163499999997],
              [-6.0747964, 35.007795399999999],
              [-6.0848815, 35.011018700000001],
              [-6.0901301, 35.0127831],
              [-6.1002109, 35.015517699999997],
              [-6.1117852, 35.012639],
              [-6.1267669, 35.010456300000001],
              [-6.1385214, 34.997028],
              [-6.151396, 34.994426400000002],
              [-6.1646998, 34.992317],
              [-6.1821234, 34.989504400000001],
              [-6.185857, 34.988875],
              [-6.1900627, 34.990211000000002],
              [-6.1929381, 34.991613899999997],
              [-6.196543, 34.992317],
              [-6.2037957, 34.992601700000002],
              [-6.2071431, 34.995062799999999],
              [-6.2140524, 34.996957700000003],
              [-6.2188589, 34.994918599999998],
              [-6.2339651, 34.998715400000002],
              [-6.2434003, 35.001387299999998],
              [-6.2438357, 34.999207599999998],
              [-6.245209, 34.996465499999999],
              [-6.2460673, 34.994145199999998],
              [-6.2470543, 34.992390800000003],
              [-6.2483847, 34.990277800000001],
              [-6.2492361, 34.9881472],
              [-6.2509955, 34.984195200000002],
              [-6.2526656, 34.981045799999997],
              [-6.2530297, 34.980399499999997],
              [-6.2530204, 34.979769300000001],
              [-6.2536443, 34.979783400000002],
              [-6.2544759, 34.978260900000002],
              [-6.2546999, 34.976210799999997],
              [-6.2553235, 34.976224999999999],
              [-6.2553665, 34.9749415],
              [-6.2556883, 34.974641400000003],
              [-6.2558605, 34.974181899999998],
              [-6.2578516, 34.970630399999997],
              [-6.2588641, 34.968341199999998],
              [-6.258742, 34.9673108],
              [-6.2593657, 34.967324900000001],
              [-6.2592445, 34.966294499999997],
              [-6.2609319, 34.962479199999997],
              [-6.2626644, 34.960350499999997],
              [-6.2640162, 34.957276499999999],
              [-6.2647447, 34.955932900000001],
              [-6.2651245, 34.955336699999997],
              [-6.2655311, 34.954588399999999],
              [-6.2656609, 34.953851499999999],
              [-6.2660461, 34.952966799999999],
              [-6.266472, 34.9520488],
              [-6.2671436, 34.950526600000003],
              [-6.2680685, 34.947481199999999],
              [-6.2687723, 34.946060099999997],
              [-6.2699804, 34.943440199999998],
              [-6.2716165, 34.939866000000002],
              [-6.2726615, 34.937606500000001],
              [-6.2732205, 34.936538800000001],
              [-6.2740927, 34.933326600000001],
              [-6.274877, 34.930947400000001],
              [-6.2758869, 34.9301897],
              [-6.2783148, 34.922794099999997],
              [-6.2789382, 34.922808099999997],
              [-6.2794827, 34.920508099999999],
              [-6.2816773, 34.915419300000003],
              [-6.2823007, 34.915433299999997],
              [-6.282343, 34.914149600000002],
              [-6.2829663, 34.914163600000002],
              [-6.28605, 34.905754799999997],
              [-6.2869933, 34.905519099999999],
              [-6.2872258, 34.903212199999999],
              [-6.2892806, 34.898606000000001],
              [-6.2916993, 34.893183499999999],
              [-6.2964958, 34.883787099999999],
              [-6.2968821, 34.882510500000002],
              [-6.2973187, 34.880985199999998],
              [-6.2976801, 34.879908200000003],
              [-6.2981046, 34.879189799999999],
              [-6.2983218, 34.878758500000004],
              [-6.2985498, 34.878395400000002],
              [-6.2988029, 34.8780255],
              [-6.2987322, 34.8778893],
              [-6.2987483, 34.8777793],
              [-6.2988046, 34.877695699999997],
              [-6.2988797, 34.8776011],
              [-6.2989065, 34.877477900000002],
              [-6.2988878, 34.8773634],
              [-6.2988288, 34.877301799999998],
              [-6.29881, 34.877226999999998],
              [-6.2988019, 34.877057600000001],
              [-6.2988337, 34.8769974],
              [-6.2989548, 34.876738500000002],
              [-6.2990085, 34.876487699999998],
              [-6.2988744, 34.876003599999997],
              [-6.298987, 34.875449000000003],
              [-6.2993572, 34.874722900000002],
              [-6.2997166, 34.874106699999999],
              [-6.3000258, 34.873695599999998],
              [-6.3005266, 34.8732969],
              [-6.300812, 34.873002200000002],
              [-6.3011561, 34.872560100000001],
              [-6.3013927, 34.8720778],
              [-6.301559, 34.871482299999997],
              [-6.3016153, 34.870953200000002],
              [-6.3015966, 34.870528499999999],
              [-6.3015673, 34.870293199999999],
              [-6.3015135, 34.870045599999997],
              [-6.3012653, 34.869808200000001],
              [-6.3009665, 34.869606599999997],
              [-6.3006714, 34.869514100000004],
              [-6.3003461, 34.869578300000001],
              [-6.300448, 34.869999999999997],
              [-6.3005212, 34.870403199999998],
              [-6.3004944, 34.870759700000001],
              [-6.300389, 34.870963799999998],
              [-6.298917, 34.873026099999997],
              [-6.2964304, 34.8734866],
              [-6.2948791, 34.873892099999999],
              [-6.2937133, 34.8746212],
              [-6.2923466, 34.875452000000003],
              [-6.2915526, 34.875958300000001],
              [-6.2904403, 34.876027100000002],
              [-6.289019, 34.875700100000003],
              [-6.2879249, 34.874036799999999],
              [-6.2874247, 34.8732392],
              [-6.286194, 34.871212800000002],
              [-6.2847973, 34.869661700000002],
              [-6.2833146, 34.868648499999999],
              [-6.2817192, 34.8688413],
              [-6.2806377, 34.868566700000002],
              [-6.2797719, 34.8686151],
              [-6.2763569, 34.868671399999997],
              [-6.2752025, 34.868509500000002],
              [-6.2749407, 34.867994500000002],
              [-6.2744568, 34.86797],
              [-6.2735824, 34.866981299999999],
              [-6.2731394, 34.866813100000002],
              [-6.2709088, 34.865965500000001],
              [-6.2686032, 34.866343999999998],
              [-6.2708558, 34.865465800000003],
              [-6.2724088, 34.8656297],
              [-6.2724258, 34.865116200000003],
              [-6.270544, 34.863799],
              [-6.2696213, 34.863266400000001],
              [-6.2685989, 34.864148499999999],
              [-6.2662429, 34.8634652],
              [-6.2656724, 34.863289799999997],
              [-6.265478, 34.862997100000001],
              [-6.2652518, 34.862742500000003],
              [-6.2650491, 34.8624802],
              [-6.2669622, 34.861906599999998],
              [-6.267239, 34.861308800000003],
              [-6.2664022, 34.860638899999998],
              [-6.2663496, 34.859909100000003],
              [-6.2661822, 34.859223299999996],
              [-6.2664569, 34.858091999999999],
              [-6.265193, 34.857036399999998],
              [-6.2643583, 34.855495699999999],
              [-6.262307, 34.855196399999997],
              [-6.2594349, 34.853569499999999],
              [-6.261354, 34.850794200000003],
              [-6.2618556, 34.849305700000002],
              [-6.261632, 34.8486026],
              [-6.2612727, 34.848144400000002],
              [-6.2605368, 34.847167300000002],
              [-6.2603276, 34.846403500000001],
              [-6.2603983, 34.845535499999997],
              [-6.2600175, 34.844415900000001],
              [-6.2568936, 34.843135599999997],
              [-6.2560281, 34.8427808],
              [-6.2538721, 34.841828499999998],
              [-6.2532434, 34.838625399999998],
              [-6.2539814, 34.837490699999996],
              [-6.2557761, 34.8366203],
              [-6.2565538, 34.836243000000003],
              [-6.2569406, 34.832702699999999],
              [-6.2570469, 34.831730299999997],
              [-6.2571007, 34.831237899999998],
              [-6.2572258, 34.8300926],
              [-6.2588839, 34.827037799999999],
              [-6.260336, 34.8255385],
              [-6.2618322, 34.823455000000003],
              [-6.2656839, 34.820617200000001],
              [-6.2674054, 34.819018200000002],
              [-6.2680635, 34.818043600000003],
              [-6.2683752, 34.817563499999999],
              [-6.2686928, 34.817762399999999],
              [-6.2690041, 34.817769400000003],
              [-6.2696801, 34.816153100000001],
              [-6.27122, 34.8149935],
              [-6.2722384, 34.815230399999997],
              [-6.2721236, 34.816740799999998],
              [-6.2722628, 34.8169276],
              [-6.2722462, 34.817239800000003],
              [-6.2726936, 34.817501],
              [-6.2729999, 34.817465499999997],
              [-6.2737072, 34.816847500000002],
              [-6.2750453, 34.814051399999997],
              [-6.277227, 34.812519799999997],
              [-6.2766784, 34.810645399999999],
              [-6.2781708, 34.8107951],
              [-6.2802854, 34.811590099999997],
              [-6.2810493, 34.812320399999997],
              [-6.2801513, 34.813030300000001],
              [-6.281467, 34.813412900000003],
              [-6.2786539, 34.817535200000002],
              [-6.2762464, 34.8181729],
              [-6.2747642, 34.819070600000003],
              [-6.2727687, 34.818626600000002],
              [-6.2702045, 34.820410699999996],
              [-6.2700446, 34.820923299999997],
              [-6.2693242, 34.821217500000003],
              [-6.2683237, 34.8216143],
              [-6.2676955, 34.821936200000003],
              [-6.2664177, 34.824770700000002],
              [-6.2661945, 34.827413700000001],
              [-6.2652424, 34.828505499999999],
              [-6.2641277, 34.828888599999999],
              [-6.2635231, 34.829496300000002],
              [-6.2636046, 34.831234299999998],
              [-6.2626884, 34.831949000000002],
              [-6.2613232, 34.835461799999997],
              [-6.2598152, 34.836655100000002],
              [-6.260298, 34.8378236],
              [-6.259728, 34.838769399999997],
              [-6.2594998, 34.839148000000002],
              [-6.2587064, 34.839329200000002],
              [-6.2587901, 34.839624399999998],
              [-6.2590441, 34.840069499999998],
              [-6.2587005, 34.8404138],
              [-6.2589054, 34.840744000000001],
              [-6.2581632, 34.841355800000002],
              [-6.2573618, 34.842282599999997],
              [-6.2579342, 34.842821700000002],
              [-6.2585294, 34.842950299999998],
              [-6.2590462, 34.843043999999999],
              [-6.2593246, 34.843488299999997],
              [-6.2603382, 34.843367600000001],
              [-6.2610024, 34.842309399999998],
              [-6.2612494, 34.841200000000001],
              [-6.2610803, 34.840056699999998],
              [-6.2626836, 34.838160199999997],
              [-6.2636374, 34.8364987],
              [-6.2667265, 34.833161199999999],
              [-6.2667254, 34.832513200000001],
              [-6.266912, 34.832319599999998],
              [-6.267687, 34.831515500000002],
              [-6.2690163, 34.830322600000002],
              [-6.2695468, 34.8295417],
              [-6.2714761, 34.828796199999999],
              [-6.2720911, 34.828598399999997],
              [-6.2739743, 34.828707000000001],
              [-6.2759621, 34.829073600000001],
              [-6.2795289, 34.830868299999999],
              [-6.282945, 34.832598699999998],
              [-6.2844864, 34.834001999999998],
              [-6.2845871, 34.834571799999999],
              [-6.2856274, 34.836539100000003],
              [-6.2878657, 34.837867699999997],
              [-6.2877037, 34.838987799999998],
              [-6.2879838, 34.840325900000003],
              [-6.2876082, 34.841975099999999],
              [-6.2869195, 34.844448999999997],
              [-6.2869339, 34.845772400000001],
              [-6.2872247, 34.847138100000002],
              [-6.2877214, 34.847567599999998],
              [-6.2876023, 34.849392600000002],
              [-6.2874926, 34.850353200000001],
              [-6.2877273, 34.8526217],
              [-6.287807, 34.854401000000003],
              [-6.2877348, 34.855356499999999],
              [-6.2873512, 34.856465300000004],
              [-6.2870031, 34.857149399999997],
              [-6.2858788, 34.858257299999998],
              [-6.2854749, 34.858487799999999],
              [-6.2851469, 34.859674099999999],
              [-6.2858158, 34.861198199999997],
              [-6.2866604, 34.8624267],
              [-6.2863369, 34.863886399999998],
              [-6.2857462, 34.864384100000002],
              [-6.2845156, 34.864452],
              [-6.2830449, 34.864454500000001],
              [-6.2825656, 34.864829700000001],
              [-6.2825388, 34.866605100000001],
              [-6.2831973, 34.866973899999998],
              [-6.2835827, 34.867189600000003],
              [-6.2864983, 34.867621399999997],
              [-6.2881571, 34.868690399999998],
              [-6.288783, 34.869093599999999],
              [-6.2891181, 34.869343399999998],
              [-6.2905197, 34.870691999999998],
              [-6.2926984, 34.8730914],
              [-6.2936066, 34.873209799999998],
              [-6.2944107, 34.8726579],
              [-6.2942804, 34.871626399999997],
              [-6.2942654, 34.871181],
              [-6.294438, 34.870772000000002],
              [-6.2946643, 34.870798800000003],
              [-6.2949376, 34.870831099999997],
              [-6.2950522, 34.871087600000003],
              [-6.2950871, 34.871298799999998],
              [-6.2950737, 34.871510100000002],
              [-6.2950549, 34.871760999999999],
              [-6.2950281, 34.871906199999998],
              [-6.2950173, 34.871948600000003],
              [-6.294961, 34.871921],
              [-6.2949223, 34.871924499999999],
              [-6.2949422, 34.871953499999996],
              [-6.295012, 34.872005799999997],
              [-6.2950998, 34.872152700000001],
              [-6.2951582, 34.872293999999997],
              [-6.2951575, 34.8724822],
              [-6.2951343, 34.8726202],
              [-6.2951924, 34.872647200000003],
              [-6.2954069, 34.872640599999997],
              [-6.2959166, 34.872444799999997],
              [-6.2964745, 34.8722973],
              [-6.2974507, 34.872098600000001],
              [-6.2978933, 34.871993699999997],
              [-6.2983279, 34.871857200000001],
              [-6.2991228, 34.871481899999999],
              [-6.2994437, 34.871137599999997],
              [-6.2995375, 34.870941799999997],
              [-6.2995423, 34.870740400000003],
              [-6.2984512, 34.870451099999997],
              [-6.2975526, 34.870559100000001],
              [-6.2975199, 34.869981099999997],
              [-6.2976465, 34.869704800000001],
              [-6.2980052, 34.869358200000001],
              [-6.2981691, 34.869174200000003],
              [-6.2990172, 34.869130699999999],
              [-6.2998669, 34.869043900000001],
              [-6.3008324, 34.8685349],
              [-6.3011006, 34.868231199999997],
              [-6.3014117, 34.867958299999998],
              [-6.3017014, 34.867738199999998],
              [-6.3017848, 34.8678217],
              [-6.3018409, 34.8681299],
              [-6.302215, 34.868284299999999],
              [-6.3028709, 34.868394000000002],
              [-6.3032804, 34.868302800000002],
              [-6.3037077, 34.867958299999998],
              [-6.3038785, 34.867668899999998],
              [-6.3043565, 34.866947099999997],
              [-6.3047181, 34.866068599999998],
              [-6.3050078, 34.865409300000003],
              [-6.3055496, 34.864553600000001],
              [-6.3060088, 34.863683899999998],
              [-6.3063435, 34.862115099999997],
              [-6.3067598, 34.860858],
              [-6.3071908, 34.859983],
              [-6.314726, 34.845338300000002],
              [-6.3153483, 34.8446973],
              [-6.3158107, 34.843717300000002],
              [-6.3160963, 34.842904699999998],
              [-6.3164953, 34.842205399999997],
              [-6.3175338, 34.840505],
              [-6.3181356, 34.839672499999999],
              [-6.31879, 34.8384316],
              [-6.3194981, 34.836945200000002],
              [-6.3203329, 34.835174299999998],
              [-6.3300178, 34.8165999],
              [-6.3310999, 34.8145241],
              [-6.3312881, 34.814080799999999],
              [-6.3317638, 34.813254000000001],
              [-6.3318444, 34.812731700000001],
              [-6.3322741, 34.812243799999997],
              [-6.3321248, 34.811720700000002],
              [-6.3327474, 34.811734299999998],
              [-6.3330751, 34.811227600000002],
              [-6.3325756, 34.8107489],
              [-6.3331331, 34.809430599999999],
              [-6.3337557, 34.809444200000002],
              [-6.3430469, 34.792941200000001],
              [-6.3440146, 34.790657400000001],
              [-6.3443344, 34.7901539],
              [-6.3446782, 34.789387499999997],
              [-6.345365, 34.787849199999997],
              [-6.3459809, 34.787017499999997],
              [-6.3461698, 34.7865939],
              [-6.3479774, 34.783174899999999],
              [-6.3479796, 34.782059199999999],
              [-6.348348, 34.781760300000002],
              [-6.3491758, 34.780236700000003],
              [-6.3525328, 34.772621800000003],
              [-6.3536272, 34.771166700000002],
              [-6.3542425, 34.769905999999999],
              [-6.3554163, 34.7659813],
              [-6.3554472, 34.765916699999998],
              [-6.3559458, 34.764874200000001],
              [-6.3572563, 34.762490499999998],
              [-6.360366, 34.7557543],
              [-6.3605779, 34.755249300000003],
              [-6.3609158, 34.754644499999998],
              [-6.3610409, 34.753829799999998],
              [-6.3614378, 34.753537299999998],
              [-6.3619077, 34.7524716],
              [-6.3633995, 34.7498407],
              [-6.364111, 34.749197899999999],
              [-6.3648694, 34.746897199999999],
              [-6.3673923, 34.742439900000001],
              [-6.3681524, 34.741265599999998],
              [-6.3686583, 34.740324600000001],
              [-6.3688037, 34.739305700000003],
              [-6.3698369, 34.737524000000001],
              [-6.3717117, 34.733243299999998],
              [-6.3761127, 34.725470600000001],
              [-6.3779259, 34.721582300000001],
              [-6.3791033, 34.719318100000002],
              [-6.3801199, 34.717677899999998],
              [-6.3810506, 34.716469699999998],
              [-6.3831347, 34.712540300000001],
              [-6.3842795, 34.709996799999999],
              [-6.3865057, 34.706591099999997],
              [-6.3877556, 34.704861899999997],
              [-6.3874418, 34.703952100000002],
              [-6.3904244, 34.698654500000004],
              [-6.3902554, 34.698132700000002],
              [-6.3910494, 34.696888100000002],
              [-6.3916808, 34.695744900000001],
              [-6.3938759, 34.692251200000001],
              [-6.3949188, 34.690598399999999],
              [-6.3957711, 34.689397900000003],
              [-6.3971181, 34.687628199999999],
              [-6.3974668, 34.686487499999998],
              [-6.3993808, 34.682980200000003],
              [-6.4001404, 34.681621100000001],
              [-6.4011591, 34.679581300000002],
              [-6.4018871, 34.678372600000003],
              [-6.4022873, 34.677596999999999],
              [-6.4028436, 34.676596500000002],
              [-6.4053321, 34.672258300000003],
              [-6.4056756, 34.671622999999997],
              [-6.4063366, 34.669967499999998],
              [-6.4068751, 34.668933099999997],
              [-6.4093823, 34.665234599999998],
              [-6.4099005, 34.664502599999999],
              [-6.410894, 34.662754499999998],
              [-6.4113002, 34.661537000000003],
              [-6.4130392, 34.659213100000002],
              [-6.4157536, 34.654406799999997],
              [-6.4169735, 34.652503099999997],
              [-6.4175029, 34.650620600000003],
              [-6.4181037, 34.649670899999997],
              [-6.4188371, 34.648478099999998],
              [-6.4194294, 34.647024700000003],
              [-6.4202581, 34.645697400000003],
              [-6.4210279, 34.6449189],
              [-6.4216416, 34.643943100000001],
              [-6.422081, 34.642839199999997],
              [-6.4228379, 34.641752199999999],
              [-6.4237214, 34.640790799999998],
              [-6.4245786, 34.638391800000001],
              [-6.4250752, 34.6372225],
              [-6.425401, 34.637078799999998],
              [-6.4259605, 34.636957000000002],
              [-6.4256134, 34.636420299999997],
              [-6.4261445, 34.635523800000001],
              [-6.4265131, 34.635319799999998],
              [-6.426675, 34.6347126],
              [-6.4281854, 34.632149699999999],
              [-6.4287188, 34.631170400000002],
              [-6.4296539, 34.629868500000001],
              [-6.4313319, 34.626501400000002],
              [-6.4318114, 34.625685400000002],
              [-6.4330681, 34.623833599999998],
              [-6.4334052, 34.623092399999997],
              [-6.4342935, 34.621772100000001],
              [-6.435043, 34.620468899999999],
              [-6.4355613, 34.619715999999997],
              [-6.4358524, 34.618988299999998],
              [-6.4362219, 34.618445299999998],
              [-6.4368797, 34.617631199999998],
              [-6.4372011, 34.6169242],
              [-6.4409624, 34.6108373],
              [-6.4413376, 34.6098353],
              [-6.4418653, 34.608654199999997],
              [-6.442397, 34.607704599999998],
              [-6.4432562, 34.606540000000003],
              [-6.4470641, 34.600122800000001],
              [-6.4480002, 34.599166400000001],
              [-6.4480822, 34.598242599999999],
              [-6.4486605, 34.5978955],
              [-6.4537793, 34.589702099999997],
              [-6.4537149, 34.5884815],
              [-6.4545553, 34.5879987],
              [-6.4589739, 34.580640000000002],
              [-6.45881, 34.580289299999997],
              [-6.4597807, 34.579629099999998],
              [-6.4598899, 34.579162599999997],
              [-6.460425, 34.578871700000001],
              [-6.4604848, 34.577954699999999],
              [-6.4611005, 34.577087400000003],
              [-6.4629358, 34.573498399999998],
              [-6.4640427, 34.572266900000002],
              [-6.4681336, 34.5654149],
              [-6.4734105, 34.555247299999998],
              [-6.4738653, 34.554579799999999],
              [-6.474535, 34.553985900000001],
              [-6.4754429, 34.552209400000002],
              [-6.4764747, 34.551456899999998],
              [-6.4768161, 34.550436099999999],
              [-6.4774367, 34.550449],
              [-6.4777368, 34.548668599999999],
              [-6.4787179, 34.546515499999998],
              [-6.4794458, 34.545608899999998],
              [-6.4832267, 34.539311300000001],
              [-6.4841971, 34.538285199999997],
              [-6.4841515, 34.537397499999997],
              [-6.484872, 34.536543700000003],
              [-6.4856431, 34.535716600000001],
              [-6.4863429, 34.533970500000002],
              [-6.4872717, 34.533180799999997],
              [-6.4887457, 34.530642],
              [-6.4889822, 34.5298199],
              [-6.489885, 34.528866899999997],
              [-6.4898764, 34.528357999999997],
              [-6.4902184, 34.5281029],
              [-6.4920705, 34.524326000000002],
              [-6.4928544, 34.523018499999999],
              [-6.4941723, 34.521090800000003],
              [-6.4947928, 34.520489099999999],
              [-6.4954619, 34.519213200000003],
              [-6.497243, 34.516428400000002],
              [-6.4976779, 34.515920800000004],
              [-6.4983588, 34.515423800000001],
              [-6.4980679, 34.513793800000002],
              [-6.5042799, 34.504382200000002],
              [-6.5052485, 34.5029751],
              [-6.505687, 34.502276100000003],
              [-6.5062171, 34.501710299999999],
              [-6.5068419, 34.500261500000001],
              [-6.5075267, 34.499424500000003],
              [-6.5079068, 34.498710199999998],
              [-6.5084953, 34.498159800000003],
              [-6.5097945, 34.496040299999997],
              [-6.5104478, 34.495116299999999],
              [-6.5136603, 34.490143199999999],
              [-6.5147781, 34.489300399999998],
              [-6.5145277, 34.4885193],
              [-6.5150126, 34.487416500000002],
              [-6.5159388, 34.486761600000001],
              [-6.5165134, 34.484191899999999],
              [-6.5175472, 34.482831900000001],
              [-6.5192144, 34.479930199999998],
              [-6.5199209, 34.479122400000001],
              [-6.5219213, 34.475887100000001],
              [-6.5258341, 34.469150399999997],
              [-6.5267661, 34.467956200000003],
              [-6.5316492, 34.4606055],
              [-6.532604, 34.4589225],
              [-6.5331201, 34.458336000000003],
              [-6.5335792, 34.457816600000001],
              [-6.5341506, 34.457267799999997],
              [-6.5347702, 34.456356599999999],
              [-6.5351752, 34.455410499999999],
              [-6.5354842, 34.454816899999997],
              [-6.5361724, 34.454014800000003],
              [-6.5370372, 34.452644999999997],
              [-6.5374803, 34.451728899999999],
              [-6.5387978, 34.450381899999996],
              [-6.539733, 34.4489479],
              [-6.5413174, 34.4469067],
              [-6.5423182, 34.445402899999998],
              [-6.5428731, 34.444899599999999],
              [-6.5432857, 34.444137699999999],
              [-6.5437207, 34.443793200000002],
              [-6.5441069, 34.443297700000002],
              [-6.5445028, 34.442649600000003],
              [-6.54538, 34.440993200000001],
              [-6.5468305, 34.4391824],
              [-6.5481225, 34.437811500000002],
              [-6.5490551, 34.436254900000002],
              [-6.5500571, 34.435281000000003],
              [-6.5510469, 34.433245300000003],
              [-6.5516479, 34.432714900000001],
              [-6.5524869, 34.431732500000003],
              [-6.5531399, 34.430693499999997],
              [-6.5536236, 34.429956699999998],
              [-6.5549156, 34.428184000000002],
              [-6.5555246, 34.427539799999998],
              [-6.5567364, 34.426065000000001],
              [-6.5574225, 34.425109599999999],
              [-6.5581341, 34.424137299999998],
              [-6.5584739, 34.423684399999999],
              [-6.5590936, 34.423128800000001],
              [-6.5597275, 34.422374900000001],
              [-6.5600679, 34.421606599999997],
              [-6.5613288, 34.420191199999998],
              [-6.5619944, 34.419332599999997],
              [-6.5629526, 34.417767900000001],
              [-6.5636183, 34.4167956],
              [-6.5647836, 34.415090999999997],
              [-6.5655444, 34.4145216],
              [-6.5661222, 34.4136253],
              [-6.5665187, 34.412999399999997],
              [-6.5670461, 34.412501900000002],
              [-6.5674778, 34.411990699999997],
              [-6.5695142, 34.409326100000001],
              [-6.5706952, 34.407943600000003],
              [-6.5709521, 34.407448799999997],
              [-6.5713444, 34.406928700000002],
              [-6.5721849, 34.405806200000001],
              [-6.572452, 34.405326000000002],
              [-6.5728059, 34.4046454],
              [-6.5746145, 34.402533099999999],
              [-6.5752106, 34.401866699999999],
              [-6.5755693, 34.4012855],
              [-6.5759485, 34.400819499999997],
              [-6.5764135, 34.4002397],
              [-6.5784129, 34.397242900000002],
              [-6.5792787, 34.396367400000003],
              [-6.5800254, 34.3957707],
              [-6.5804578, 34.395091700000002],
              [-6.5810089, 34.394273499999997],
              [-6.5813269, 34.393631800000001],
              [-6.5818601, 34.392970499999997],
              [-6.5829995, 34.391607899999997],
              [-6.5850219, 34.389227200000001],
              [-6.585523, 34.388196200000003],
              [-6.5860286, 34.387806599999998],
              [-6.5864818, 34.387187300000001],
              [-6.5870611, 34.3863488],
              [-6.5874478, 34.385921500000002],
              [-6.5884139, 34.384656],
              [-6.5914463, 34.380562300000001],
              [-6.5924408, 34.379339600000002],
              [-6.5932512, 34.378070899999997],
              [-6.5941355, 34.376890199999998],
              [-6.5951756, 34.375796200000003],
              [-6.5971147, 34.373007899999998],
              [-6.602275, 34.365914799999999],
              [-6.6032201, 34.364640700000002],
              [-6.6061372, 34.360851599999997],
              [-6.6074393, 34.359607099999998],
              [-6.6083852, 34.358069200000003],
              [-6.6087589, 34.357154199999997],
              [-6.6099917, 34.356045199999997],
              [-6.6106475, 34.354773199999997],
              [-6.611439, 34.354238799999997],
              [-6.6116129, 34.353507299999997],
              [-6.6120076, 34.3527968],
              [-6.6125789, 34.352217600000003],
              [-6.6136003, 34.351144400000003],
              [-6.6141773, 34.350473999999998],
              [-6.6186643, 34.344664399999999],
              [-6.6196443, 34.343386099999996],
              [-6.622679, 34.3394993],
              [-6.6235044, 34.338322300000002],
              [-6.6237675, 34.337679700000002],
              [-6.6243661, 34.3369134],
              [-6.6251783, 34.335662900000003],
              [-6.6260824, 34.334775100000002],
              [-6.6264073, 34.333867599999998],
              [-6.6270546, 34.333252299999998],
              [-6.6275982, 34.332749],
              [-6.6280123, 34.332243099999999],
              [-6.629958, 34.329330499999998],
              [-6.6305899, 34.328695699999997],
              [-6.6318946, 34.326671599999997],
              [-6.6328433, 34.325656100000003],
              [-6.6332625, 34.325031099999997],
              [-6.633808, 34.3243899],
              [-6.6348933, 34.323045899999997],
              [-6.6351037, 34.322359300000002],
              [-6.6358884, 34.321528499999999],
              [-6.636385, 34.320842399999997],
              [-6.637505, 34.319579300000001],
              [-6.6377965, 34.318851600000002],
              [-6.6383069, 34.318567000000002],
              [-6.6387015, 34.317754999999998],
              [-6.6392036, 34.317309700000003],
              [-6.640188, 34.315550799999997],
              [-6.6408837, 34.315019499999998],
              [-6.64137, 34.314258000000002],
              [-6.6416621, 34.313655699999998],
              [-6.6421948, 34.312871899999998],
              [-6.6431652, 34.310952200000003],
              [-6.643777, 34.311221000000003],
              [-6.6439044, 34.310593500000003],
              [-6.6439783, 34.310164],
              [-6.6447771, 34.308670800000002],
              [-6.6453961, 34.308682699999999],
              [-6.645405, 34.308649699999997],
              [-6.6457414, 34.307404699999999],
              [-6.6463604, 34.307416600000003],
              [-6.6467057, 34.306138400000002],
              [-6.647278, 34.306149400000002],
              [-6.6473247, 34.306150299999999],
              [-6.6473604, 34.304866199999999],
              [-6.6482959, 34.3046273],
              [-6.6486341, 34.303606000000002],
              [-6.649253, 34.303617899999999],
              [-6.6495983, 34.3023399],
              [-6.6502171, 34.302351600000001],
              [-6.6502387, 34.301581200000001],
              [-6.6507231, 34.301029499999999],
              [-6.6512099, 34.300058300000003],
              [-6.651398, 34.299867599999999],
              [-6.6516889, 34.299553500000002],
              [-6.6531789, 34.297983299999999],
              [-6.6544188, 34.2960086],
              [-6.6557206, 34.293720899999997],
              [-6.6563396, 34.293732800000001],
              [-6.6563538, 34.293219100000002],
              [-6.656698, 34.292791899999997],
              [-6.6571493, 34.292463499999997],
              [-6.6579855, 34.290774399999997],
              [-6.6590369, 34.289504200000003],
              [-6.6599166, 34.288274700000002],
              [-6.6608495, 34.2873953],
              [-6.6615474, 34.285988600000003],
              [-6.6627771, 34.284862699999998],
              [-6.6636481, 34.283058799999999],
              [-6.6647079, 34.282157599999998],
              [-6.6650495, 34.281051699999999],
              [-6.6655179, 34.280662],
              [-6.6661233, 34.279388699999998],
              [-6.6670254, 34.278899600000003],
              [-6.667577, 34.277243300000002],
              [-6.668271, 34.276488100000002],
              [-6.6693374, 34.274963300000003],
              [-6.6700661, 34.273362800000001],
              [-6.6709083, 34.272501900000002],
              [-6.671907, 34.271630700000003],
              [-6.6721392, 34.270908800000001],
              [-6.6729691, 34.269759000000001],
              [-6.6738572, 34.268576400000001],
              [-6.6745685, 34.267515099999997],
              [-6.6752455, 34.2659235],
              [-6.6754949, 34.265248700000001],
              [-6.6777622, 34.265193099999998],
              [-6.679665, 34.265739799999999],
              [-6.6812415, 34.266286299999997],
              [-6.68269, 34.266845400000001],
              [-6.6829527, 34.267200500000001],
              [-6.6840161, 34.267239199999999],
              [-6.6841151, 34.266904799999999],
              [-6.684258, 34.266727000000003],
              [-6.683462, 34.266299400000001],
              [-6.6830243, 34.2664112],
              [-6.6818066, 34.265996800000003],
              [-6.6792026, 34.265201599999997],
              [-6.6778872, 34.264912600000002],
              [-6.6764699, 34.264832800000001],
              [-6.6738789, 34.2648656],
              [-6.6721484, 34.265087200000004],
              [-6.6694662, 34.2656299],
              [-6.6681492, 34.266024600000001],
              [-6.6666767, 34.266576800000003],
              [-6.6632059, 34.268084999999999],
              [-6.660543, 34.269225300000002],
              [-6.6598251, 34.269791099999999],
              [-6.6590935, 34.270603600000001],
              [-6.6585067, 34.271228999999998],
              [-6.6558019, 34.272866800000003],
              [-6.6537817, 34.274347200000001],
              [-6.6526659, 34.273887899999998],
              [-6.6514186, 34.272853699999999],
              [-6.6504806, 34.272244399999998],
              [-6.6495594, 34.271866199999998],
              [-6.6491581, 34.271886100000003],
              [-6.6486816, 34.271185500000001],
              [-6.6480322, 34.270519299999997],
              [-6.648118, 34.270235599999999],
              [-6.6488261, 34.269880999999998],
              [-6.6495814, 34.269489100000001],
              [-6.6512079, 34.268710599999999],
              [-6.652018, 34.268353300000001],
              [-6.6533568, 34.267636600000003],
              [-6.6537464, 34.267474399999998],
              [-6.65413, 34.267398900000003],
              [-6.6545591, 34.267323400000002],
              [-6.6548593, 34.267238900000002],
              [-6.6552091, 34.267185099999999],
              [-6.6554215, 34.267447699999998],
              [-6.6557837, 34.267451899999998],
              [-6.6560216, 34.267368599999998],
              [-6.6565869, 34.2671074],
              [-6.6569581, 34.266835399999998],
              [-6.6571903, 34.2666538],
              [-6.6581, 34.266418299999998],
              [-6.6592683, 34.265790699999997],
              [-6.6599841, 34.265252699999998],
              [-6.6603703, 34.265075299999999],
              [-6.6608252, 34.264718899999998],
              [-6.6617908, 34.264151400000003],
              [-6.6629674, 34.2637681],
              [-6.663643, 34.263451099999997],
              [-6.6650266, 34.261954199999998],
              [-6.6658892, 34.2615622],
              [-6.6661592, 34.261601599999999],
              [-6.666254, 34.261615399999997],
              [-6.6676702, 34.2613494],
              [-6.6707869, 34.261083999999997],
              [-6.6713157, 34.261038900000003],
              [-6.6721577, 34.260903399999997],
              [-6.6728971, 34.260849100000001],
              [-6.6738668, 34.260794400000002],
              [-6.6746277, 34.260798100000002],
              [-6.6755914, 34.260775500000001],
              [-6.6765632, 34.2608046],
              [-6.6775819, 34.260857799999997],
              [-6.6785987, 34.2609748],
              [-6.6796526, 34.261121199999998],
              [-6.6806082, 34.261304500000001],
              [-6.6811626, 34.261411299999999],
              [-6.6818509, 34.261605699999997],
              [-6.682209, 34.261706099999998],
              [-6.6827821, 34.261843900000002],
              [-6.68328, 34.262070100000003],
              [-6.6838738, 34.262290900000004],
              [-6.6843939, 34.262418400000001],
              [-6.6850872, 34.262599899999998],
              [-6.6852071, 34.262804299999999],
              [-6.685455, 34.262873900000002],
              [-6.6855837, 34.262863699999997],
              [-6.686138, 34.263059300000002],
              [-6.6864444, 34.263115499999998],
              [-6.6866129, 34.263020900000001],
              [-6.6866174, 34.262903399999999],
              [-6.6863838, 34.262726999999998],
              [-6.6860063, 34.262577399999998],
              [-6.6855338, 34.262385799999997],
              [-6.6851527, 34.262143899999998],
              [-6.6845859, 34.262110200000002],
              [-6.6835093, 34.261723000000003],
              [-6.6822178, 34.261401999999997],
              [-6.6811822, 34.261149899999999],
              [-6.6800742, 34.260928499999999],
              [-6.6792193, 34.2607809],
              [-6.6789359, 34.260742499999999],
              [-6.6796541, 34.257411599999998],
              [-6.6799727, 34.256154299999999],
              [-6.6801934, 34.255577600000002],
              [-6.6806267, 34.2548818],
              [-6.6810866, 34.253793100000003],
              [-6.681855, 34.251989399999999],
              [-6.682923, 34.248704600000003],
              [-6.6839659, 34.245951699999999],
              [-6.6845377, 34.243438599999998],
              [-6.6849132, 34.2423632],
              [-6.6856229, 34.240504700000002],
              [-6.6874881, 34.236744000000002],
              [-6.6881886, 34.234795599999998],
              [-6.6894419, 34.231213099999998],
              [-6.6922513, 34.225808899999997],
              [-6.6927963, 34.223610999999998],
              [-6.6943444, 34.220836900000002],
              [-6.6958615, 34.217066500000001],
              [-6.6976173, 34.2131531],
              [-6.6994905, 34.209335699999997],
              [-6.7002877, 34.2078846],
              [-6.7011228, 34.206704000000002],
              [-6.7024189, 34.203462600000002],
              [-6.7032392, 34.202269100000002],
              [-6.7040352, 34.200662999999999],
              [-6.7053935, 34.198626099999998],
              [-6.7067325, 34.196184000000002],
              [-6.7080789, 34.193471199999998],
              [-6.7100687, 34.1904264],
              [-6.7117031, 34.187459500000003],
              [-6.7123689, 34.185754600000003],
              [-6.7133023, 34.184568900000002],
              [-6.7158617, 34.180342899999999],
              [-6.7185761, 34.176116200000003],
              [-6.7197643, 34.174418600000003],
              [-6.7221419, 34.171183200000002],
              [-6.7228285, 34.1701075],
              [-6.7243653, 34.168060099999998],
              [-6.7260741, 34.165280000000003],
              [-6.7281339, 34.162497100000003],
              [-6.7307041, 34.158765600000002],
              [-6.7321534, 34.156661499999998],
              [-6.7329082, 34.1554346],
              [-6.7349161, 34.153034300000002],
              [-6.7360657, 34.1514308],
              [-6.7382357, 34.148808000000002],
              [-6.7396352, 34.146826599999997],
              [-6.7400105, 34.146014200000003],
              [-6.7422548, 34.142972100000001],
              [-6.7439814, 34.141114899999998],
              [-6.7442716, 34.140206900000003],
              [-6.7452388, 34.139346400000001],
              [-6.7456744, 34.138588499999997],
              [-6.7472195, 34.136382400000002],
              [-6.7482107, 34.135141699999998],
              [-6.7492712, 34.1339416],
              [-6.7503968, 34.133099999999999],
              [-6.7519542, 34.130721100000002],
              [-6.752956, 34.129806500000001],
              [-6.7537039, 34.127846900000002],
              [-6.7550772, 34.126437899999999],
              [-6.7599615, 34.121447199999999],
              [-6.7602968, 34.120947100000002],
              [-6.7623325, 34.117403299999999],
              [-6.7633276, 34.1160657],
              [-6.7640143, 34.115551799999999],
              [-6.7650764, 34.113641200000004],
              [-6.7657416, 34.112882999999997],
              [-6.7661708, 34.1126565],
              [-6.766439, 34.112396699999998],
              [-6.7662673, 34.1121737],
              [-6.7669003, 34.110979399999998],
              [-6.7675602, 34.110999],
              [-6.7679196, 34.110589900000001],
              [-6.7688702, 34.1098553],
              [-6.7695718, 34.109468],
              [-6.7701405, 34.1093081],
              [-6.7706769, 34.109509299999999],
              [-6.7710326, 34.109386200000003],
              [-6.7704548, 34.109052699999999],
              [-6.770516, 34.108640100000002],
              [-6.7709029, 34.1087414],
              [-6.7712026, 34.108578799999997],
              [-6.7714628, 34.108066600000001],
              [-6.7710524, 34.107022399999998],
              [-6.7713206, 34.106605299999998],
              [-6.7716639, 34.106009299999997],
              [-6.7721789, 34.105243899999998],
              [-6.7731445, 34.104638100000003],
              [-6.7740554, 34.104387600000003],
              [-6.7754453, 34.104196600000002],
              [-6.7764994, 34.104309399999998],
              [-6.7767907, 34.1035854],
              [-6.7763653, 34.103045199999997],
              [-6.7765794, 34.102326499999997],
              [-6.7762838, 34.1018708],
              [-6.776095, 34.1010463],
              [-6.7762521, 34.100718100000002],
              [-6.7766539, 34.100658099999997],
              [-6.7765112, 34.100273899999998],
              [-6.7768674, 34.100164200000002],
              [-6.7775305, 34.1002072],
              [-6.7781752, 34.098916199999998],
              [-6.7796044, 34.097487299999997],
              [-6.7814272, 34.095278200000003],
              [-6.7823284, 34.094789599999999],
              [-6.7828756, 34.095037900000001],
              [-6.7823821, 34.094476800000002],
              [-6.7822951, 34.093864699999997],
              [-6.7821889, 34.093356],
              [-6.7828718, 34.093344399999999],
              [-6.7831974, 34.093092599999999],
              [-6.7834689, 34.092825099999999],
              [-6.7839719, 34.092554900000003],
              [-6.7840171, 34.092437699999998],
              [-6.7839485, 34.091934899999998],
              [-6.7834764, 34.091602999999999],
              [-6.78353, 34.091449699999998],
              [-6.7843111, 34.091446599999998],
              [-6.7848363, 34.091325300000001],
              [-6.7845396, 34.090895799999998],
              [-6.7842591, 34.090664699999998],
              [-6.7848717, 34.090371099999999],
              [-6.7853582, 34.0905208],
              [-6.7849393, 34.089947700000003],
              [-6.7848942, 34.089616300000003],
              [-6.7856651, 34.088948100000003],
              [-6.7862321, 34.088562500000002],
              [-6.7871135, 34.088036899999999],
              [-6.7876606, 34.087867199999998],
              [-6.7872851, 34.087176399999997],
              [-6.7884868, 34.085822700000001],
              [-6.7894309, 34.084826999999997],
              [-6.7902248, 34.084740799999999],
              [-6.7903107, 34.0843992],
              [-6.790654, 34.083514200000003],
              [-6.7913299, 34.082821099999997],
              [-6.7918986, 34.082764500000003],
              [-6.7925605, 34.082084000000002],
              [-6.7922971, 34.081482800000003],
              [-6.7925439, 34.081045199999998],
              [-6.79354, 34.080937200000001],
              [-6.794195, 34.080618600000001],
              [-6.7944211, 34.079936799999999],
              [-6.7959843, 34.079194000000001],
              [-6.796587, 34.077967899999997],
              [-6.7962652, 34.077364500000002],
              [-6.7968231, 34.0767557],
              [-6.7973745, 34.076813999999999],
              [-6.7981583, 34.076653999999998],
              [-6.7985468, 34.075642500000001],
              [-6.8001034, 34.075156399999997],
              [-6.8004453, 34.073877600000003],
              [-6.8016933, 34.073385999999999],
              [-6.8016465, 34.072666599999998],
              [-6.8025134, 34.072631000000001],
              [-6.8024963, 34.071927199999998],
              [-6.8032175, 34.071807999999997],
              [-6.8035227, 34.072474499999998],
              [-6.804189, 34.0724029],
              [-6.8041085, 34.071843999999999],
              [-6.803593, 34.071121499999997],
              [-6.8045551, 34.069938899999997],
              [-6.805158, 34.070749499999998],
              [-6.8059854, 34.070947599999997],
              [-6.8057709, 34.070508500000003],
              [-6.8064254, 34.0709491],
              [-6.8071764, 34.070915300000003],
              [-6.807719, 34.071358799999999],
              [-6.8079993, 34.071429600000002],
              [-6.8076101, 34.070454900000001],
              [-6.808528, 34.0701812],
              [-6.8074071, 34.070078799999997],
              [-6.8071871, 34.069646400000003],
              [-6.8078051, 34.069489799999999],
              [-6.8079776, 34.069215399999997],
              [-6.8077718, 34.068821900000003],
              [-6.8075841, 34.068688999999999],
              [-6.8071818, 34.068440199999998],
              [-6.8073604, 34.068288699999997],
              [-6.8075817, 34.068384899999998],
              [-6.8078282, 34.068416200000001],
              [-6.8077764, 34.0682653],
              [-6.807289, 34.067920899999997],
              [-6.8077033, 34.067840199999999],
              [-6.80786, 34.067914799999997],
              [-6.8080679, 34.067722600000003],
              [-6.8083145, 34.067710499999997],
              [-6.8084596, 34.067822700000001],
              [-6.8084016, 34.068022900000003],
              [-6.80874, 34.068331299999997],
              [-6.8088996, 34.068107099999999],
              [-6.8090011, 34.067862699999999],
              [-6.8093625, 34.067759700000003],
              [-6.8094508, 34.067642399999997],
              [-6.8096732, 34.067702500000003],
              [-6.8099101, 34.067798699999997],
              [-6.8100745, 34.067794599999999],
              [-6.8102099, 34.067570400000001],
              [-6.810089, 34.0674542],
              [-6.8098472, 34.067466199999998],
              [-6.8097167, 34.067173799999999],
              [-6.8094391, 34.066582400000001],
              [-6.8092477, 34.0666011],
              [-6.8090833, 34.066513],
              [-6.8090971, 34.066127899999998],
              [-6.8086336, 34.066040299999997],
              [-6.8081366, 34.066113000000001],
              [-6.8080438, 34.066052399999997],
              [-6.8080099, 34.065787999999998],
              [-6.8080341, 34.065447599999999],
              [-6.8081115, 34.065227299999997],
              [-6.8082807, 34.065030999999998],
              [-6.8085128, 34.064942899999998],
              [-6.8087019, 34.065031500000003],
              [-6.8090156, 34.064922899999999],
              [-6.8092864, 34.064999],
              [-6.8094254, 34.065371499999998],
              [-6.809765, 34.065611799999999],
              [-6.8097594, 34.064885699999998],
              [-6.8099337, 34.064809599999997],
              [-6.8102073, 34.065279500000003],
              [-6.8105694, 34.065446299999998],
              [-6.8110629, 34.065460899999998],
              [-6.8113177, 34.065187700000003],
              [-6.8112534, 34.064938099999999],
              [-6.8108465, 34.064588499999999],
              [-6.8103763, 34.064285599999998],
              [-6.8104111, 34.064141599999999],
              [-6.8109047, 34.064226300000001],
              [-6.8114133, 34.063950400000003],
              [-6.8112229, 34.063379900000001],
              [-6.8117426, 34.063527399999998],
              [-6.8120999, 34.063495699999997],
              [-6.811999, 34.063085200000003],
              [-6.8123665, 34.062992299999998],
              [-6.812733, 34.0629913],
              [-6.8131965, 34.063597899999998],
              [-6.8136782, 34.064056800000003],
              [-6.8142789, 34.064108099999999],
              [-6.8141904, 34.0638176],
              [-6.8143915, 34.063488700000001],
              [-6.8139317, 34.063160500000002],
              [-6.8140852, 34.062835499999998],
              [-6.8141684, 34.062392099999997],
              [-6.8140329, 34.062046500000001],
              [-6.8143215, 34.061872299999997],
              [-6.8151568, 34.063037899999998],
              [-6.8158536, 34.063121899999999],
              [-6.8165671, 34.0623687],
              [-6.8168119, 34.061835299999998],
              [-6.8168069, 34.060738999999998],
              [-6.8177772, 34.060310600000001],
              [-6.8186589, 34.059425599999997],
              [-6.8183638, 34.059216800000002],
              [-6.8185301, 34.058687900000002],
              [-6.8191953, 34.058892299999997],
              [-6.8205724, 34.057335000000002],
              [-6.8209656, 34.057336800000002],
              [-6.8213625, 34.056812399999998],
              [-6.8213304, 34.056492400000003],
              [-6.8217668, 34.055984100000003],
              [-6.8223314, 34.055334100000003],
              [-6.8225734, 34.055128199999999],
              [-6.8226099, 34.054960199999996],
              [-6.822752, 34.054829699999999],
              [-6.8228691, 34.054818599999997],
              [-6.8230439, 34.054640399999997],
              [-6.8230919, 34.054498799999998],
              [-6.8232167, 34.054474900000002],
              [-6.8233012, 34.054556099999999],
              [-6.8233876, 34.0545331],
              [-6.8233627, 34.054467000000002],
              [-6.823428, 34.054420800000003],
              [-6.8234645, 34.054288800000002],
              [-6.8235662, 34.0541774],
              [-6.8236757, 34.0542108],
              [-6.8238926, 34.054154199999999],
              [-6.824088, 34.053866999999997],
              [-6.8242648, 34.053826800000003],
              [-6.8240652, 34.053471700000003],
              [-6.8238391, 34.053367199999997],
              [-6.8241335, 34.0532301],
              [-6.8243725, 34.053358699999997],
              [-6.8245249, 34.053251000000003],
              [-6.8245026, 34.052795000000003],
              [-6.8247665, 34.052440900000001],
              [-6.8247814, 34.052191399999998],
              [-6.8250564, 34.052148299999999],
              [-6.8251048, 34.051791000000001],
              [-6.8253055, 34.051821799999999],
              [-6.8255806, 34.051473799999997],
              [-6.8259077, 34.051427599999997],
              [-6.8259783, 34.051024200000001],
              [-6.8258259, 34.050820899999998],
              [-6.8258333, 34.050669999999997],
              [-6.8260229, 34.050663800000002],
              [-6.8262608, 34.0505931],
              [-6.8263872, 34.050820899999998],
              [-6.8265529, 34.050682199999997],
              [-6.8268499, 34.050456099999998],
              [-6.8271452, 34.050358600000003],
              [-6.8274521, 34.050015399999999],
              [-6.8275946, 34.049880799999997],
              [-6.8276591, 34.049653499999998],
              [-6.827868, 34.0495053],
              [-6.8276414, 34.049427700000003],
              [-6.8275874, 34.049263699999997],
              [-6.8277169, 34.049228100000001],
              [-6.8279256, 34.049165500000001],
              [-6.8282098, 34.049046199999999],
              [-6.8282674, 34.048840400000003],
              [-6.8282134, 34.048628800000003],
              [-6.8283573, 34.0485246],
              [-6.8285336, 34.048226399999997],
              [-6.8286667, 34.0483397],
              [-6.8289401, 34.0481877],
              [-6.8289186, 34.048023800000003],
              [-6.8287603, 34.047981999999998],
              [-6.8286739, 34.047874700000001],
              [-6.8288358, 34.047663100000001],
              [-6.8290445, 34.047642099999997],
              [-6.8293491, 34.0476235],
              [-6.8294726, 34.047525899999997],
              [-6.8295913, 34.047293400000001],
              [-6.8295069, 34.0471778],
              [-6.8297562, 34.046697100000003],
              [-6.8300327, 34.0466379],
              [-6.8304327, 34.0467364],
              [-6.8307235, 34.046807600000001],
              [-6.8309166, 34.046696500000003],
              [-6.830702, 34.046376500000001],
              [-6.8308415, 34.046109800000004],
              [-6.830674, 34.045852500000002],
              [-6.8323169, 34.043541599999998],
              [-6.8335457, 34.041932299999999],
              [-6.8335685, 34.041462799999998],
              [-6.8338335, 34.040955699999998],
              [-6.8339437, 34.040695599999999],
              [-6.8339156, 34.040036299999997],
              [-6.8338523, 34.039710399999997],
              [-6.8339129, 34.039375300000003],
              [-6.8340068, 34.039297500000004],
              [-6.8341527, 34.039257900000003],
              [-6.8378509, 34.039585099999996],
              [-6.837963, 34.0396553],
              [-6.8381545, 34.039694900000001],
              [-6.8382785, 34.039671300000002],
              [-6.8383021, 34.039573099999998],
              [-6.8382594, 34.039472400000001],
              [-6.8374916, 34.039364300000003],
              [-6.8367126, 34.0392504],
              [-6.8366287, 34.039231100000002],
              [-6.8366209, 34.039054299999997],
              [-6.8366439, 34.038794699999997],
              [-6.8367163, 34.038589299999998],
              [-6.8366793, 34.03848],
              [-6.836534, 34.038407499999998],
              [-6.836417, 34.038420899999998],
              [-6.8363414, 34.038562200000001],
              [-6.8363648, 34.038631299999999],
              [-6.836221, 34.038941800000003],
              [-6.836056, 34.039264600000003],
              [-6.8339896, 34.039068100000001],
              [-6.8338724, 34.039097900000002],
              [-6.8337884, 34.039163500000001],
              [-6.8337042, 34.039264799999998],
              [-6.8336348, 34.039375399999997],
              [-6.8335475, 34.039649599999997],
              [-6.8335867, 34.039814499999999],
              [-6.8336894, 34.039980700000001],
              [-6.8336768, 34.040575699999998],
              [-6.8336682, 34.040729300000002],
              [-6.833586, 34.040920499999999],
              [-6.833497, 34.041068199999998],
              [-6.833435, 34.041148200000002],
              [-6.8334057, 34.041099500000001],
              [-6.8333633, 34.040954999999997],
              [-6.8332459, 34.040827899999996],
              [-6.8332638, 34.040753600000002],
              [-6.8331448, 34.0406969],
              [-6.8331914, 34.040540100000001],
              [-6.8330942, 34.0404172],
              [-6.8330027, 34.040247800000003],
              [-6.8329134, 34.039769],
              [-6.8329262, 34.039560399999999],
              [-6.8329526, 34.039331199999999],
              [-6.8330862, 34.039011600000002],
              [-6.8332858, 34.038775999999999],
              [-6.8335299, 34.038708900000003],
              [-6.8357009, 34.038910100000003],
              [-6.835731, 34.038886400000003],
              [-6.8357416, 34.038853400000001],
              [-6.8357408, 34.038813500000003],
              [-6.8357186, 34.038782900000001],
              [-6.8334532, 34.038573900000003],
              [-6.8332767, 34.038619599999997],
              [-6.8330873, 34.038727600000001],
              [-6.8329065, 34.038879600000001],
              [-6.8328601, 34.039033699999997],
              [-6.832664, 34.038961],
              [-6.8324882, 34.038754099999998],
              [-6.832243, 34.038299600000002],
              [-6.832103, 34.037877899999998],
              [-6.8320675, 34.037443199999998],
              [-6.8320838, 34.037141800000001],
              [-6.8321146, 34.036871300000001],
              [-6.8321578, 34.036620999999997],
              [-6.8322502, 34.036386200000003],
              [-6.8323426, 34.036197199999997],
              [-6.8324597, 34.036008299999999],
              [-6.8326754, 34.0357427],
              [-6.8328972, 34.035518099999997],
              [-6.8330944, 34.035323900000002],
              [-6.8333409, 34.035196399999997],
              [-6.8338708, 34.034981899999998],
              [-6.834521, 34.035832999999997],
              [-6.8348726, 34.036196400000001],
              [-6.8354578, 34.036771299999998],
              [-6.8355123, 34.036760600000001],
              [-6.8355313, 34.036721499999999],
              [-6.8349804, 34.036146600000002],
              [-6.834651, 34.035801200000002],
              [-6.834404, 34.035489499999997],
              [-6.8340741, 34.035051099999997],
              [-6.8339339, 34.034767700000003],
              [-6.8337425, 34.034376600000002],
              [-6.8335885, 34.033968100000003],
              [-6.8334189, 34.033377899999998],
              [-6.8333277, 34.032960000000003],
              [-6.8332927, 34.032533299999997],
              [-6.8332902, 34.032448799999997],
              [-6.8332472, 34.032379900000002],
              [-6.8331534, 34.032373200000002],
              [-6.8331292, 34.032428899999999],
              [-6.8331373, 34.032557699999998],
              [-6.8331695, 34.032919999999997],
              [-6.8331882, 34.0331446],
              [-6.8331802, 34.033173400000003],
              [-6.8331265, 34.0331823],
              [-6.8330562, 34.033182699999998],
              [-6.8327675, 34.033206300000003],
              [-6.8324931, 34.0331659],
              [-6.8323407, 34.033149000000002],
              [-6.8322573, 34.033125499999997],
              [-6.8322248, 34.0330935],
              [-6.8321455, 34.032953599999999],
              [-6.8320317, 34.0328509],
              [-6.8318325, 34.032701000000003],
              [-6.8316712, 34.032538700000003],
              [-6.8315784, 34.0324247],
              [-6.8315052, 34.032299999999999],
              [-6.8312918, 34.032040600000002],
              [-6.8311779, 34.031781199999998],
              [-6.8310896, 34.031506999999998],
              [-6.8310884, 34.031508299999999],
              [-6.8310743, 34.031459400000003],
              [-6.8310865, 34.031402100000001],
              [-6.8311556, 34.031338099999999],
              [-6.8316089, 34.031260600000003],
              [-6.8318589, 34.031215199999998],
              [-6.8329383, 34.030994499999998],
              [-6.8329871, 34.030975900000001],
              [-6.8329891, 34.0309423],
              [-6.8329545, 34.030878199999997],
              [-6.8329119, 34.030869799999998],
              [-6.8319603, 34.031064499999999],
              [-6.8313121, 34.031189900000001],
              [-6.8312145, 34.031174700000001],
              [-6.8312389, 34.031056800000002],
              [-6.8312044, 34.030972599999998],
              [-6.8311942, 34.030780499999999],
              [-6.8311983, 34.030672699999997],
              [-6.8311698, 34.030553099999999],
              [-6.8310661, 34.030369499999999],
              [-6.8309055, 34.030094900000002],
              [-6.8307531, 34.029813599999997],
              [-6.8306271, 34.029601300000003],
              [-6.8304644, 34.029377199999999],
              [-6.8303161, 34.0292593],
              [-6.8302368, 34.0292526],
              [-6.8301656, 34.029343599999997],
              [-6.8300864, 34.029468199999997],
              [-6.8300254, 34.029597899999999],
              [-6.8300031, 34.029615100000001],
              [-6.8299705, 34.029624900000002],
              [-6.829928, 34.029624599999998],
              [-6.8298973, 34.029606399999999],
              [-6.8298254, 34.029558399999999],
              [-6.8297395, 34.0295317],
              [-6.8296993, 34.0295129],
              [-6.8296698, 34.029486200000001],
              [-6.8302156, 34.028960499999997],
              [-6.8302203, 34.028906599999999],
              [-6.8301975, 34.0288732],
              [-6.8301533, 34.028857700000003],
              [-6.8301069, 34.028862500000002],
              [-6.8297535, 34.029200099999997],
              [-6.8294852, 34.029463999999997],
              [-6.8294344, 34.029492300000001],
              [-6.8293982, 34.029487799999998],
              [-6.8293191, 34.029430099999999],
              [-6.829248, 34.029372299999999],
              [-6.8291407, 34.029304500000002],
              [-6.8290268, 34.0292478],
              [-6.8289247, 34.0291377],
              [-6.8288134, 34.029038800000002],
              [-6.8286283, 34.028909900000002],
              [-6.8284218, 34.0288021],
              [-6.82823, 34.028732099999999],
              [-6.8281388, 34.028726499999998],
              [-6.8280436, 34.028729800000001],
              [-6.8279377, 34.028806500000002],
              [-6.8278787, 34.0288854],
              [-6.8277928, 34.028941000000003],
              [-6.8276913, 34.028927299999999],
              [-6.8276028, 34.028893799999999],
              [-6.8274107, 34.028825500000004],
              [-6.8275317, 34.028457099999997],
              [-6.8281151, 34.028589400000001],
              [-6.8281344, 34.028588599999999],
              [-6.8281472, 34.028577200000001],
              [-6.8281517, 34.028560400000003],
              [-6.8281419, 34.028547199999998],
              [-6.8275667, 34.028419499999998],
              [-6.8275386, 34.028375400000002],
              [-6.8255313, 34.028112800000002],
              [-6.8252345, 34.028073999999997],
              [-6.8248125, 34.028037099999999],
              [-6.8238693, 34.0279417],
              [-6.8236578, 34.027930400000002],
              [-6.8239257, 34.0273805],
              [-6.8241417, 34.026818800000001],
              [-6.8247615, 34.026820899999997],
              [-6.8271571, 34.027370500000004],
              [-6.8278362, 34.027526299999998],
              [-6.8279146, 34.027544399999996],
              [-6.8284103, 34.027647100000003],
              [-6.829401, 34.027821099999997],
              [-6.8302359, 34.0279247],
              [-6.8302217, 34.027977499999999],
              [-6.8303134, 34.028011900000003],
              [-6.831096, 34.028262599999998],
              [-6.8310801, 34.028295499999999],
              [-6.831068, 34.028320700000002],
              [-6.8314681, 34.028450599999999],
              [-6.8315821, 34.028363800000001],
              [-6.8321459, 34.028751900000003],
              [-6.8321398, 34.028782499999998],
              [-6.8326481, 34.0290915],
              [-6.8325834, 34.029162999999997],
              [-6.8334708, 34.0300364],
              [-6.8340046, 34.029685600000001],
              [-6.8346424, 34.030271599999999],
              [-6.8346368, 34.030394800000003],
              [-6.834653, 34.0305161],
              [-6.8347221, 34.030689600000002],
              [-6.8347506, 34.030873200000002],
              [-6.8347018, 34.030997900000003],
              [-6.8347079, 34.031019800000003],
              [-6.8347221, 34.031034900000002],
              [-6.8347445, 34.031038299999999],
              [-6.8347933, 34.031013000000002],
              [-6.8348319, 34.031132599999999],
              [-6.8347872, 34.031356700000003],
              [-6.8347486, 34.031629600000002],
              [-6.8347395, 34.031717399999998],
              [-6.8347395, 34.031737900000003],
              [-6.8347356, 34.031763400000003],
              [-6.8347241, 34.031752500000003],
              [-6.8347079, 34.0317455],
              [-6.8346652, 34.031747500000002],
              [-6.8346439, 34.031757599999999],
              [-6.8346307, 34.031774499999997],
              [-6.8346262, 34.031787600000001],
              [-6.8346293, 34.031804200000003],
              [-6.834637, 34.031820799999998],
              [-6.8346516, 34.031832999999999],
              [-6.834674, 34.031841900000003],
              [-6.8346956, 34.031841900000003],
              [-6.8347164, 34.031836200000001],
              [-6.8347318, 34.031826600000002],
              [-6.8347156, 34.031997799999999],
              [-6.8346851, 34.032095499999997],
              [-6.8346973, 34.032336399999998],
              [-6.8346302, 34.032607599999999],
              [-6.8346116, 34.033124299999997],
              [-6.8346444, 34.033214100000002],
              [-6.8347461, 34.033281500000001],
              [-6.8349549, 34.033533400000003],
              [-6.8352982, 34.033729000000001],
              [-6.8356692, 34.033790500000002],
              [-6.8355673, 34.034394300000002],
              [-6.8355392, 34.034976499999999],
              [-6.835567, 34.035092800000001],
              [-6.8356204, 34.035204899999997],
              [-6.8357387, 34.035341799999998],
              [-6.8358402, 34.035459500000002],
              [-6.8360266, 34.035632200000002],
              [-6.8364544, 34.0360145],
              [-6.8364767, 34.035991099999997],
              [-6.8365029, 34.035963899999999],
              [-6.8360751, 34.035560199999999],
              [-6.8358404, 34.035300499999998],
              [-6.8357406, 34.035153700000002],
              [-6.8357035, 34.035038999999998],
              [-6.8356996, 34.034755099999998],
              [-6.8357527, 34.034480899999998],
              [-6.8357933, 34.034234400000003],
              [-6.8363259, 34.034027500000001],
              [-6.8369355, 34.033971200000003],
              [-6.8373681, 34.033937600000002],
              [-6.8380845, 34.033904],
              [-6.8387266, 34.033954399999999],
              [-6.8392465, 34.034191300000003],
              [-6.8391901, 34.035726799999999],
              [-6.8392115, 34.036224599999997],
              [-6.8392866, 34.036562500000002],
              [-6.8394154, 34.036887],
              [-6.8395709, 34.037162600000002],
              [-6.8395709, 34.037255999999999],
              [-6.8397855, 34.037469299999998],
              [-6.8397891, 34.037581099999997],
              [-6.8397867, 34.037664700000001],
              [-6.8398092, 34.037737800000002],
              [-6.839883, 34.037801899999998],
              [-6.8399602, 34.037841899999997],
              [-6.8400637, 34.037845400000002],
              [-6.8402009, 34.037816200000002],
              [-6.8402751, 34.037761199999998],
              [-6.8403255, 34.037546300000002],
              [-6.8398298, 34.036810899999999],
              [-6.8397081, 34.036564300000002],
              [-6.839596, 34.036193500000003],
              [-6.8395512, 34.035435399999997],
              [-6.8395538, 34.034739399999999],
              [-6.839604, 34.0337003],
              [-6.8395387, 34.0328436],
              [-6.8400982, 34.032792499999999],
              [-6.8403255, 34.0328442],
              [-6.8405186, 34.032786399999999],
              [-6.8407171, 34.032710799999997],
              [-6.8407922, 34.032590800000001],
              [-6.8419615, 34.032009000000002],
              [-6.8421572, 34.031860600000002],
              [-6.8424929, 34.031772500000002],
              [-6.8427559, 34.031846700000003],
              [-6.842795, 34.031698300000002],
              [-6.8429965, 34.031503499999999],
              [-6.8432986, 34.031517399999998],
              [-6.8436735, 34.031591599999999],
              [-6.843791, 34.031943900000002],
              [-6.843838, 34.032035200000003],
              [-6.8440465, 34.032044300000003],
              [-6.844084, 34.032173200000003],
              [-6.8441565, 34.032179900000003],
              [-6.8442296, 34.032233099999999],
              [-6.8443174, 34.032217699999997],
              [-6.844304, 34.032044300000003],
              [-6.8444236, 34.0319413],
              [-6.8445756, 34.031874100000003],
              [-6.8447176, 34.031823699999997],
              [-6.8448392, 34.031697700000002],
              [-6.8449406, 34.031725700000003],
              [-6.8449981, 34.031686499999999],
              [-6.8451704, 34.031652899999997],
              [-6.8452042, 34.031565999999998],
              [-6.8452853, 34.0315212],
              [-6.8452211, 34.031445599999998],
              [-6.8450792, 34.031431599999998],
              [-6.8449812, 34.0313588],
              [-6.8449237, 34.031297199999997],
              [-6.844819, 34.0313084],
              [-6.8446094, 34.031067499999999],
              [-6.8446061, 34.0309387],
              [-6.8446838, 34.030851900000002],
              [-6.8449339, 34.030893900000002],
              [-6.8449981, 34.030835099999997],
              [-6.8450488, 34.030706299999999],
              [-6.8452305, 34.0305459],
              [-6.8452342, 34.030411100000002],
              [-6.8454648, 34.030293299999997],
              [-6.8456137, 34.030353300000002],
              [-6.8455976, 34.030405500000001],
              [-6.8456901, 34.030408799999996],
              [-6.8457706, 34.030344399999997],
              [-6.8458162, 34.030351000000003],
              [-6.8458511, 34.0304],
              [-6.8459758, 34.030422199999997],
              [-6.8460804, 34.0304778],
              [-6.8462574, 34.030508900000001],
              [-6.8463327, 34.030561400000003],
              [-6.8463703, 34.030470299999998],
              [-6.8464834, 34.030484399999999],
              [-6.8465197, 34.0303939],
              [-6.8465772, 34.030374399999999],
              [-6.8466841, 34.030374700000003],
              [-6.8466204, 34.030088300000003],
              [-6.8464809, 34.029768199999999],
              [-6.8464595, 34.029563699999997],
              [-6.8465721, 34.029234700000003],
              [-6.8467214, 34.029055],
              [-6.8467954, 34.028892300000003],
              [-6.8466505, 34.028625499999997],
              [-6.8466881, 34.028327599999997],
              [-6.8469873, 34.0281272],
              [-6.8472406, 34.0280196],
              [-6.8475535, 34.027864299999997],
              [-6.8476749, 34.028067200000002],
              [-6.8478129, 34.028555400000002],
              [-6.8479138, 34.028661100000001],
              [-6.8480319, 34.0286811],
              [-6.8480614, 34.028761099999997],
              [-6.8481391, 34.028743300000002],
              [-6.8484087, 34.0288507],
              [-6.8486408, 34.028626299999999],
              [-6.8483072, 34.028322000000003],
              [-6.8484788, 34.028166400000003],
              [-6.8486129, 34.028224199999997],
              [-6.8488543, 34.028286399999999],
              [-6.8491011, 34.028077500000002],
              [-6.8488168, 34.0279174],
              [-6.8489187, 34.027850800000003],
              [-6.8489026, 34.0277441],
              [-6.8491333, 34.027828499999998],
              [-6.8494552, 34.027837400000003],
              [-6.8495946, 34.027681800000003],
              [-6.8493908, 34.027597299999996],
              [-6.8492835, 34.027499499999998],
              [-6.8494283, 34.027339499999997],
              [-6.8496261, 34.0274681],
              [-6.8502974, 34.027441699999997],
              [-6.8504583, 34.027348400000001],
              [-6.8508606, 34.027072699999998],
              [-6.8507855, 34.026939400000003],
              [-6.8507963, 34.026801499999998],
              [-6.8507587, 34.026699299999997],
              [-6.8508553, 34.0265792],
              [-6.8510269, 34.0265348],
              [-6.8510752, 34.0263214],
              [-6.8509411, 34.025987899999997],
              [-6.8506782, 34.025663399999999],
              [-6.8507963, 34.025632199999997],
              [-6.8511128, 34.025858999999997],
              [-6.8512952, 34.026063499999999],
              [-6.8515473, 34.026099100000003],
              [-6.8519764, 34.026081300000001],
              [-6.8524968, 34.025939000000001],
              [-6.8525558, 34.025663399999999],
              [-6.8524646, 34.025556700000003],
              [-6.8526258, 34.025366499999997],
              [-6.8528562, 34.025360999999997],
              [-6.8530225, 34.025320999999998],
              [-6.8530493, 34.025165399999999],
              [-6.8530654, 34.024849799999998],
              [-6.8532424, 34.024760800000003],
              [-6.8532049, 34.024645200000002],
              [-6.8532547, 34.024586999999997],
              [-6.8537145, 34.0246408],
              [-6.8535589, 34.024396299999999],
              [-6.8536407, 34.024296200000002],
              [-6.8536877, 34.0238716],
              [-6.8537467, 34.023235800000002],
              [-6.8538647, 34.023151400000003],
              [-6.8541598, 34.023164700000002],
              [-6.8542188, 34.0230046],
              [-6.8539023, 34.022809000000002],
              [-6.8542241, 34.0227468],
              [-6.8544236, 34.022795199999997],
              [-6.8545386, 34.022922299999998],
              [-6.8550751, 34.022763500000003],
              [-6.8550623, 34.022703499999999],
              [-6.8549303, 34.022615299999998],
              [-6.8552752, 34.022445900000001],
              [-6.8553391, 34.022145899999998],
              [-6.8555397, 34.022050399999998],
              [-6.8557315, 34.021799299999998],
              [-6.8558236, 34.0217563],
              [-6.8557695, 34.021610899999999],
              [-6.855824, 34.021529299999997],
              [-6.8557248, 34.021471499999997],
              [-6.8556497, 34.021289199999998],
              [-6.856001, 34.021035699999999],
              [-6.8564858, 34.020811399999999],
              [-6.8568121, 34.020963000000002],
              [-6.8567628, 34.020542200000001],
              [-6.8569184, 34.020422199999999],
              [-6.8571544, 34.020408799999998],
              [-6.857208, 34.0205111],
              [-6.8573904, 34.020413300000001],
              [-6.8574172, 34.020231000000003],
              [-6.8579054, 34.019964199999997],
              [-6.8582112, 34.019728600000001],
              [-6.8583423, 34.019455999999998],
              [-6.858518, 34.019287900000002],
              [-6.8584977, 34.018985399999998],
              [-6.8579536, 34.018450399999999],
              [-6.858219, 34.0182523],
              [-6.8582544, 34.018299200000001],
              [-6.8584538, 34.018265599999999],
              [-6.8586937, 34.017912600000002],
              [-6.8591312, 34.017571099999998],
              [-6.8597391, 34.017882700000001],
              [-6.859977, 34.017789800000003],
              [-6.8603096, 34.017411799999998],
              [-6.8602613, 34.017211799999998],
              [-6.8608588, 34.016869300000003],
              [-6.863115, 34.014462199999997],
              [-6.865086, 34.012668400000003],
              [-6.8662506, 34.011052599999999],
              [-6.8676689, 34.010243299999999],
              [-6.8686259, 34.009005899999998],
              [-6.8691512, 34.008326500000003],
              [-6.8720495, 34.006414200000002],
              [-6.8726322, 34.005838900000001],
              [-6.8728224, 34.005614899999998],
              [-6.8730565, 34.005346299999999],
              [-6.8731957, 34.00515],
              [-6.8733441, 34.004922999999998],
              [-6.873366, 34.004575199999998],
              [-6.8734693, 34.004430900000003],
              [-6.8736761, 34.004142100000003],
              [-6.8739593, 34.003771299999997],
              [-6.8751002, 34.002832499999997],
              [-6.8752618, 34.002560699999997],
              [-6.8754466, 34.002436400000001],
              [-6.8757314, 34.0023871],
              [-6.8760009, 34.002377099999997],
              [-6.8762037, 34.0024023],
              [-6.876359, 34.002480900000002],
              [-6.8764523, 34.002431299999998],
              [-6.8765454, 34.002367],
              [-6.8766027, 34.002239500000002],
              [-6.8766996, 34.002143400000001],
              [-6.8767524, 34.0020563],
              [-6.8767218, 34.002031799999997],
              [-6.8766599, 34.002027400000003],
              [-6.8766078, 34.002031199999998],
              [-6.8765466, 34.001973800000002],
              [-6.8765142, 34.001892499999997],
              [-6.876512, 34.001821],
              [-6.876563, 34.001768900000002],
              [-6.8766318, 34.001751800000001],
              [-6.8767203, 34.0017432],
              [-6.8767793, 34.0017663],
              [-6.876799, 34.001831299999999],
              [-6.8768369, 34.001854600000001],
              [-6.8769087, 34.001798600000001],
              [-6.8769404, 34.001746199999999],
              [-6.8769784, 34.0016836],
              [-6.8780691, 34.000580200000002],
              [-6.8784906, 34.000169200000002],
              [-6.878967, 34.000442499999998],
              [-6.879628, 33.999612999999997],
              [-6.8795574, 33.998729900000001],
              [-6.8806325, 33.9979589],
              [-6.8811983, 33.997005899999998],
              [-6.8826864, 33.996384300000003],
              [-6.8829502, 33.995526599999998],
              [-6.8839902, 33.9940049],
              [-6.8855968, 33.993193400000003],
              [-6.8864112, 33.992528900000003],
              [-6.8875473, 33.992745800000002],
              [-6.8882452, 33.992232299999998],
              [-6.8886742, 33.991277199999999],
              [-6.888697, 33.990334599999997],
              [-6.8904872, 33.988431300000002],
              [-6.891984, 33.986826800000003],
              [-6.8930728, 33.9857023],
              [-6.8952804, 33.985084800000003],
              [-6.8956915, 33.983246399999999],
              [-6.8978294, 33.981787699999998],
              [-6.8981568, 33.981022400000001],
              [-6.8993964, 33.980786500000001],
              [-6.8997352, 33.97972],
              [-6.900555, 33.978848599999999],
              [-6.901297, 33.978763299999997],
              [-6.9019451, 33.977489400000003],
              [-6.9034994, 33.977001999999999],
              [-6.903833, 33.975979700000003],
              [-6.9063373, 33.974480499999999],
              [-6.9110864, 33.969200399999998],
              [-6.9111265, 33.968634999999999],
              [-6.9113434, 33.968072300000003],
              [-6.9123166, 33.967603799999999],
              [-6.9126795, 33.967393000000001],
              [-6.91368, 33.964997799999999],
              [-6.91429, 33.964593600000001],
              [-6.9148846, 33.964422800000001],
              [-6.9152273, 33.9640956],
              [-6.9165806, 33.9628345],
              [-6.9174353, 33.962077000000001],
              [-6.917568, 33.961906200000001],
              [-6.9177847, 33.961599200000002],
              [-6.9182997, 33.961207600000002],
              [-6.918834, 33.961158699999999],
              [-6.9190486, 33.960785000000001],
              [-6.919585, 33.9604468],
              [-6.9203575, 33.960161999999997],
              [-6.92113, 33.960161999999997],
              [-6.9215377, 33.960161999999997],
              [-6.9216235, 33.959592499999999],
              [-6.9220741, 33.9596637],
              [-6.9219454, 33.959378999999998],
              [-6.9223338, 33.959267699999998],
              [-6.9224389, 33.958969600000003],
              [-6.9229968, 33.9590052],
              [-6.9226342, 33.958698200000001],
              [-6.9229324, 33.958506900000003],
              [-6.923147, 33.957990700000003],
              [-6.9235762, 33.957652600000003],
              [-6.9240466, 33.9574961],
              [-6.9262169, 33.956057999999999],
              [-6.9273565, 33.954532499999999],
              [-6.9281099, 33.954290999999998],
              [-6.9293853, 33.952896799999998],
              [-6.9300029, 33.952523999999997],
              [-6.931471, 33.951553799999999],
              [-6.9347135, 33.949005399999997],
              [-6.9359899, 33.947228099999997],
              [-6.9347508, 33.9474643],
              [-6.9357251, 33.945424799999998],
              [-6.9369704, 33.944931699999998],
              [-6.9389302, 33.942489100000003],
              [-6.9406592, 33.941377000000003],
              [-6.941473, 33.940410800000002],
              [-6.9445219, 33.938633699999997],
              [-6.9455113, 33.937305299999998],
              [-6.9459729, 33.936754999999998],
              [-6.9463632, 33.936296400000003],
              [-6.9469146, 33.935833000000002],
              [-6.9472414, 33.935668900000003],
              [-6.9478756, 33.934953899999996],
              [-6.9485552, 33.934451600000003],
              [-6.9492348, 33.934212600000002],
              [-6.9498783, 33.933840199999999],
              [-6.9509733, 33.9328942],
              [-6.9517713, 33.932115000000003],
              [-6.952004, 33.932028199999998],
              [-6.952536, 33.9318296],
              [-6.9529833, 33.9318077],
              [-6.9536437, 33.931759599999999],
              [-6.9540793, 33.931920099999999],
              [-6.9543157, 33.932197100000003],
              [-6.9544179, 33.932465899999997],
              [-6.9541559, 33.932800499999999],
              [-6.9531387, 33.933576899999998],
              [-6.95299, 33.933752699999999],
              [-6.9529438, 33.9338403],
              [-6.9529775, 33.933940700000001],
              [-6.9531003, 33.933936099999997],
              [-6.955826, 33.932118000000003],
              [-6.9566312, 33.931458800000001],
              [-6.9587957, 33.9296699],
              [-6.9601878, 33.9292102],
              [-6.9619066, 33.927884599999999],
              [-6.9621469, 33.927228100000001],
              [-6.9637138, 33.924631300000001],
              [-6.9634993, 33.924149700000001],
              [-6.963455, 33.923996699999996],
              [-6.963444, 33.923958800000001],
              [-6.9635115, 33.923586800000002],
              [-6.9637963, 33.923250199999998],
              [-6.9642025, 33.922950299999997],
              [-6.9646098, 33.922772799999997],
              [-6.9647691, 33.922703400000003],
              [-6.965216, 33.922585599999998],
              [-6.9656356, 33.922513100000003],
              [-6.9661065, 33.922562900000003],
              [-6.9676819, 33.9237441],
              [-6.968029, 33.924494600000003],
              [-6.9691727, 33.923170399999997],
              [-6.9713796, 33.921862599999997],
              [-6.9714236, 33.921620400000002],
              [-6.9715343, 33.921493599999998],
              [-6.9715487, 33.921362299999998],
              [-6.9714809, 33.921206099999999],
              [-6.9713992, 33.921111099999997],
              [-6.9712358, 33.921095100000002],
              [-6.9704696, 33.921247700000002],
              [-6.9702024, 33.921301399999997],
              [-6.9699598, 33.921305599999997],
              [-6.9697839, 33.9212907],
              [-6.9696898, 33.921207899999999],
              [-6.9696495, 33.920981500000003],
              [-6.9697046, 33.920743899999998],
              [-6.9699648, 33.920291200000001],
              [-6.9701364, 33.919991199999998],
              [-6.9706216, 33.919261300000002],
              [-6.9708157, 33.919194599999997],
              [-6.9709981, 33.919204899999997],
              [-6.9710552, 33.919190999999998],
              [-6.9713036, 33.919130600000003],
              [-6.9715041, 33.919065199999999],
              [-6.9716317, 33.918981500000001],
              [-6.9716954, 33.918809600000003],
              [-6.9717614, 33.918644399999998],
              [-6.9718796, 33.918547599999997],
              [-6.9720444, 33.918459599999998],
              [-6.9722576, 33.918379999999999],
              [-6.9725254, 33.918334399999999],
              [-6.9728155, 33.918333599999997],
              [-6.9729592, 33.9183886],
              [-6.9731964, 33.918593100000002],
              [-6.9734335, 33.918786300000001],
              [-6.9737362, 33.918878399999997],
              [-6.9740388, 33.918925199999997],
              [-6.9741568, 33.918869700000002],
              [-6.9741888, 33.918797599999998],
              [-6.9741752, 33.918709499999999],
              [-6.9741072, 33.918624000000001],
              [-6.9740119, 33.918588800000002],
              [-6.9734755, 33.918526100000001],
              [-6.9726124, 33.917576400000002],
              [-6.9736833, 33.916955199999997],
              [-6.9747289, 33.916963099999997],
              [-6.9748757, 33.916573100000001],
              [-6.9751588, 33.916398100000002],
              [-6.9753613, 33.916364999999999],
              [-6.9756535, 33.916416300000002],
              [-6.9759116, 33.916524199999998],
              [-6.9761248, 33.916678300000001],
              [-6.9762382, 33.916919399999998],
              [-6.9762881, 33.917096600000001],
              [-6.9762971, 33.917251],
              [-6.9759894, 33.917330499999998],
              [-6.9757673, 33.917353200000001],
              [-6.9754975, 33.917602299999999],
              [-6.9761201, 33.918530099999998],
              [-6.97514, 33.919145499999999],
              [-6.975199, 33.919340400000003],
              [-6.9775057, 33.918498100000001],
              [-6.9784601, 33.917413500000002],
              [-6.9789581, 33.916589500000001],
              [-6.9796722, 33.9155309],
              [-6.980227, 33.915068400000003],
              [-6.9804483, 33.912245300000002],
              [-6.9819113, 33.910798300000003],
              [-6.9835281, 33.909840500000001],
              [-6.9861963, 33.909284200000002],
              [-6.9878752, 33.909226699999998],
              [-6.9894087, 33.909185299999997],
              [-6.9894873, 33.9088888],
              [-6.9893759, 33.908540600000002],
              [-6.9892338, 33.9079196],
              [-6.9895557, 33.907433300000001],
              [-6.990532, 33.906704499999996],
              [-6.9911436, 33.906163999999997],
              [-6.9914547, 33.905831399999997],
              [-6.991959, 33.904978800000002],
              [-6.9924418, 33.904585599999997],
              [-6.9927207, 33.904408400000001],
              [-6.9930211, 33.9042575],
              [-6.9934932, 33.904140400000003],
              [-6.9937507, 33.903982800000001],
              [-6.9945661, 33.903516600000003],
              [-6.9947399, 33.9034446],
              [-6.9952152, 33.903262900000001],
              [-6.995301, 33.902895399999998],
              [-6.9954909, 33.902368600000003],
              [-6.9962291, 33.901297200000002],
              [-6.9972452, 33.901100800000002],
              [-6.9978062, 33.901312699999998],
              [-6.9987288, 33.902136900000002],
              [-6.9996424, 33.902301100000003],
              [-7.0009084, 33.901942300000002],
              [-7.0024392, 33.901039900000001],
              [-7.0014574, 33.896912100000002],
              [-7.0017556, 33.896661399999999],
              [-7.0036813, 33.895042099999998],
              [-7.0045739, 33.895037600000002],
              [-7.0056245, 33.893353500000003],
              [-7.0060503, 33.893230000000003],
              [-7.0066113, 33.893213199999998],
              [-7.0070033, 33.893325400000002],
              [-7.007098, 33.8934657],
              [-7.0071791, 33.893633999999999],
              [-7.0075508, 33.893656399999998],
              [-7.0104841, 33.891389799999999],
              [-7.0122684, 33.890845599999999],
              [-7.0132011, 33.8903295],
              [-7.0139446, 33.890475299999999],
              [-7.0144582, 33.889358899999998],
              [-7.0152857, 33.8888654],
              [-7.0159425, 33.888203300000001],
              [-7.0165174, 33.887882099999999],
              [-7.0171976, 33.887112799999997],
              [-7.0181641, 33.886372899999998],
              [-7.0188164, 33.886061300000001],
              [-7.0199659, 33.885954099999999],
              [-7.0211035, 33.885350299999999],
              [-7.0216261, 33.885097799999997],
              [-7.0215514, 33.884471099999999],
              [-7.0221237, 33.883755000000001],
              [-7.0229615, 33.883376400000003],
              [-7.0237576, 33.883424499999997],
              [-7.0244129, 33.883782699999998],
              [-7.0240645, 33.884491799999999],
              [-7.0249354, 33.884188899999998],
              [-7.0250018, 33.883872199999999],
              [-7.0252838, 33.883927300000003],
              [-7.025458, 33.8837069],
              [-7.0258146, 33.883741399999998],
              [-7.0259971, 33.8834728],
              [-7.026279, 33.883438300000002],
              [-7.0262625, 33.883245600000002],
              [-7.0262542, 33.883059699999997],
              [-7.0263703, 33.882956399999998],
              [-7.0265029, 33.883087099999997],
              [-7.0275229, 33.882427100000001],
              [-7.0281445, 33.881661899999997],
              [-7.0290984, 33.880910399999998],
              [-7.0290982, 33.880657999999997],
              [-7.0282893, 33.880643800000001],
              [-7.0280387, 33.880122499999999],
              [-7.0282819, 33.879575199999998],
              [-7.0285872, 33.879104900000002],
              [-7.0288318, 33.878237400000003],
              [-7.029311, 33.877416199999999],
              [-7.0304253, 33.876944000000002],
              [-7.0310591, 33.876879899999999],
              [-7.0313974, 33.877215],
              [-7.0323241, 33.8771542],
              [-7.0332639, 33.877495400000001],
              [-7.0334872, 33.877639799999997],
              [-7.0331616, 33.878405600000001],
              [-7.033901, 33.878586599999998],
              [-7.0350153, 33.878178200000001],
              [-7.0353103, 33.877943299999998],
              [-7.0357914, 33.877420100000002],
              [-7.0368814, 33.877321100000003],
              [-7.0374696, 33.8763784],
              [-7.0385648, 33.876781000000001],
              [-7.0403642, 33.876387899999997],
              [-7.0407587, 33.876124300000001],
              [-7.0415566, 33.875491400000001],
              [-7.0421819, 33.874703699999998],
              [-7.0417807, 33.874068999999999],
              [-7.0419612, 33.873592199999997],
              [-7.0432323, 33.872729100000001],
              [-7.0440597, 33.872327900000002],
              [-7.0450119, 33.872169499999998],
              [-7.045467, 33.873175199999999],
              [-7.0453659, 33.873723200000001],
              [-7.0456417, 33.873806299999998],
              [-7.0458176, 33.873266899999997],
              [-7.0452647, 33.8720316],
              [-7.0457358, 33.871872199999999],
              [-7.0465892, 33.871580199999997],
              [-7.0471816, 33.871398399999997],
              [-7.0478861, 33.873033700000001],
              [-7.0480678, 33.873020400000001],
              [-7.0481539, 33.872924599999997],
              [-7.0480799, 33.872732200000002],
              [-7.0476331, 33.871830500000002],
              [-7.0473705, 33.8712746],
              [-7.0479062, 33.871125599999999],
              [-7.0496514, 33.870588099999999],
              [-7.0510428, 33.870150700000003],
              [-7.0522219, 33.8699856],
              [-7.052037, 33.8713184],
              [-7.0519436, 33.871461400000001],
              [-7.0519156, 33.871831700000001],
              [-7.051979, 33.871938499999999],
              [-7.0518615, 33.872864499999999],
              [-7.0515862, 33.872987299999998],
              [-7.0514619, 33.873042699999999],
              [-7.0512753, 33.873100299999997],
              [-7.0506391, 33.873296400000001],
              [-7.0502797, 33.873407399999998],
              [-7.0502506, 33.873188399999997],
              [-7.0495673, 33.8734258],
              [-7.0496359, 33.873533999999999],
              [-7.0485733, 33.8738867],
              [-7.0484267, 33.873558500000001],
              [-7.0482348, 33.873613200000001],
              [-7.0482709, 33.873807399999997],
              [-7.0483667, 33.874037999999999],
              [-7.0481222, 33.874109900000001],
              [-7.0479239, 33.874051700000003],
              [-7.0478401, 33.874161100000002],
              [-7.0477806, 33.874274999999997],
              [-7.0478243, 33.874441699999998],
              [-7.0520778, 33.873052399999999],
              [-7.0521686, 33.872790700000003],
              [-7.0524267, 33.871258900000001],
              [-7.0524758, 33.870739800000003],
              [-7.053453, 33.870652900000003],
              [-7.0550727, 33.8705462],
              [-7.0569976, 33.870559],
              [-7.0583141, 33.870782200000001],
              [-7.0589978, 33.870883300000003],
              [-7.0593929, 33.870558500000001],
              [-7.059651, 33.869987600000002],
              [-7.0604433, 33.869827100000002],
              [-7.0617098, 33.869842599999998],
              [-7.0618069, 33.870050399999997],
              [-7.0620668, 33.870438900000003],
              [-7.0621537, 33.870372199999998],
              [-7.0618146, 33.869895200000002],
              [-7.062265, 33.869884599999999],
              [-7.0634207, 33.869925899999998],
              [-7.0636219, 33.870566500000002],
              [-7.0627186, 33.871829300000002],
              [-7.0635932, 33.872415699999998],
              [-7.064081, 33.8721794],
              [-7.0640671, 33.871557199999998],
              [-7.0644701, 33.871354599999997],
              [-7.0653419, 33.871370400000004],
              [-7.0670613, 33.870052299999998],
              [-7.0698486, 33.868111900000002],
              [-7.0725031, 33.866570299999999],
              [-7.073935, 33.865343199999998],
              [-7.075938, 33.8644392],
              [-7.0766202, 33.863896799999999],
              [-7.0785803, 33.862660400000003],
              [-7.0791313, 33.861912699999998],
              [-7.0796528, 33.861636099999998],
              [-7.0806941, 33.860909100000001],
              [-7.081408, 33.860675399999998],
              [-7.0819487, 33.859900600000003],
              [-7.0834926, 33.858844599999998],
              [-7.0850683, 33.858150299999998],
              [-7.0850799, 33.857636399999997],
              [-7.0856956, 33.857646000000003],
              [-7.0856351, 33.856668200000001],
              [-7.0863191, 33.857072899999999],
              [-7.0869033, 33.856386499999999],
              [-7.08737, 33.856083099999999],
              [-7.0878912, 33.855881099999998],
              [-7.0881991, 33.855885899999997],
              [-7.0880105, 33.855280700000002],
              [-7.0895613, 33.854206699999999],
              [-7.0941468, 33.851609199999999],
              [-7.0955112, 33.850681199999997],
              [-7.0966501, 33.849848999999999],
              [-7.0970418, 33.849651000000001],
              [-7.0972772, 33.849344600000002],
              [-7.0976214, 33.849094200000003],
              [-7.0988394, 33.848340800000003],
              [-7.0986869, 33.847617700000001],
              [-7.0991688, 33.847141200000003],
              [-7.1003121, 33.847199199999999],
              [-7.10156, 33.846553700000001],
              [-7.101964, 33.846333100000003],
              [-7.1027169, 33.846001399999999],
              [-7.1047803, 33.844320400000001],
              [-7.1056046, 33.843170399999998],
              [-7.1072943, 33.842046199999999],
              [-7.1082691, 33.841517099999997],
              [-7.1116725, 33.8394111],
              [-7.1124695, 33.838722199999999],
              [-7.1132289, 33.838282200000002],
              [-7.113633, 33.8380692],
              [-7.1141694, 33.837710000000001],
              [-7.1147356, 33.837327999999999],
              [-7.1157256, 33.836778500000001],
              [-7.1169209, 33.836041199999997],
              [-7.118228, 33.835017800000003],
              [-7.1188378, 33.8345123],
              [-7.1191628, 33.834360599999997],
              [-7.1196286, 33.834220600000002],
              [-7.1197598, 33.833528999999999],
              [-7.1202445, 33.833022100000001],
              [-7.1213117, 33.8324474],
              [-7.1219963, 33.832278600000002],
              [-7.1225906, 33.832501600000001],
              [-7.1234063, 33.832575300000002],
              [-7.1237929, 33.832647799999997],
              [-7.1241653, 33.832601599999997],
              [-7.124917, 33.832414499999999],
              [-7.1260185, 33.8317561],
            ],
          ],
          [
            [
              [-7.0233909, 33.884775900000001],
              [-7.0234847, 33.884960900000003],
              [-7.0231446, 33.885418600000001],
              [-7.0225464, 33.885895699999999],
              [-7.0222414, 33.885895699999999],
              [-7.0219599, 33.885691199999997],
              [-7.0221476, 33.885418600000001],
              [-7.0220068, 33.8852823],
              [-7.022605, 33.884795400000002],
              [-7.0229335, 33.884941499999996],
              [-7.0233909, 33.884775900000001],
            ],
          ],
          [
            [
              [-7.0221358, 33.886168400000003],
              [-7.0217019, 33.886811000000002],
              [-7.0211154, 33.887142099999998],
              [-7.0206931, 33.887580300000003],
              [-7.0204702, 33.887473100000001],
              [-7.0208104, 33.886976599999997],
              [-7.021045, 33.886382599999997],
              [-7.0216197, 33.885739899999997],
              [-7.0221358, 33.886168400000003],
            ],
          ],
          [
            [
              [-6.99509, 33.904002300000002],
              [-6.9931278, 33.904857100000001],
              [-6.9924876, 33.9055848],
              [-6.992084, 33.906324099999999],
              [-6.9918335, 33.906774599999999],
              [-6.9904891, 33.9076764],
              [-6.9899406, 33.909765999999998],
              [-6.9915551, 33.907941200000003],
              [-6.9923206, 33.907155699999997],
              [-6.9925015, 33.907213499999997],
              [-6.9928912, 33.907178799999997],
              [-6.9927938, 33.906878499999998],
              [-6.9930304, 33.906762999999998],
              [-6.9931417, 33.906878499999998],
              [-6.9935314, 33.906843899999998],
              [-6.9932809, 33.906520499999999],
              [-6.9944221, 33.906231699999999],
              [-6.9956468, 33.905238300000001],
              [-6.9970803, 33.904094800000003],
              [-6.9976927, 33.903505699999997],
              [-6.9973029, 33.903124400000003],
              [-6.9964958, 33.903239999999997],
              [-6.9957303, 33.903517200000003],
              [-6.99509, 33.904002300000002],
            ],
          ],
          [
            [
              [-6.8470639, 34.028268300000001],
              [-6.8471548, 34.028412099999997],
              [-6.847281, 34.028361799999999],
              [-6.8473045, 34.028273599999999],
              [-6.8471235, 34.028227700000002],
              [-6.8470639, 34.028268300000001],
            ],
          ],
          [
            [
              [-6.846778, 34.030374700000003],
              [-6.8467944, 34.0305015],
              [-6.8468859, 34.030625000000001],
              [-6.8471405, 34.030617800000002],
              [-6.8470922, 34.030437599999999],
              [-6.846778, 34.030374700000003],
            ],
          ],
          [
            [
              [-6.8454116, 34.030707100000001],
              [-6.8454054, 34.030802000000001],
              [-6.8452471, 34.030822000000001],
              [-6.8453935, 34.030927499999997],
              [-6.8454915, 34.031067499999999],
              [-6.8456503, 34.031101200000002],
              [-6.8458636, 34.030930699999999],
              [-6.8455793, 34.030779099999997],
              [-6.8454116, 34.030707100000001],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      region: "Guelmim-Oued Noun",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-11.779999999999999, 28.210000000000001],
              [-11.715, 28.240000000000002],
              [-11.585000000000001, 28.275000000000002],
              [-11.465, 28.335000000000001],
              [-11.450000000000001, 28.350000000000001],
              [-11.445, 28.375],
              [-11.43, 28.390000000000001],
              [-11.42, 28.390000000000001],
              [-11.390000000000001, 28.425000000000001],
              [-11.390000000000001, 28.440000000000001],
              [-11.355, 28.460000000000001],
              [-11.359999999999999, 28.484999999999999],
              [-11.345000000000001, 28.484999999999999],
              [-11.315, 28.525000000000002],
              [-11.27, 28.550000000000001],
              [-11.220000000000001, 28.600000000000001],
              [-11.210000000000001, 28.620000000000001],
              [-11.17, 28.640000000000001],
              [-11.140000000000001, 28.675000000000001],
              [-11.115, 28.685000000000002],
              [-11.075000000000001, 28.73],
              [-11.075000000000001, 28.740000000000002],
              [-11.050000000000001, 28.760000000000002],
              [-11.01, 28.77],
              [-10.950000000000001, 28.805],
              [-10.9, 28.82],
              [-10.765000000000001, 28.885000000000002],
              [-10.620000000000001, 28.960000000000001],
              [-10.505000000000001, 29.030000000000001],
              [-10.460000000000001, 29.074999999999999],
              [-10.460000000000001, 29.085000000000001],
              [-10.44, 29.094999999999999],
              [-10.425000000000001, 29.129999999999999],
              [-10.4, 29.150000000000002],
              [-10.380000000000001, 29.185000000000002],
              [-10.355, 29.210000000000001],
              [-10.34, 29.215],
              [-10.33, 29.234999999999999],
              [-10.215, 29.310000000000002],
              [-10.200000000000001, 29.350000000000001],
              [-10.19, 29.355],
              [-10.195, 29.365000000000002],
              [-10.115, 29.445],
              [-10.095000000000001, 29.490000000000002],
              [-10.07, 29.515000000000001],
              [-10.075000000000001, 29.530000000000001],
              [-10.055, 29.555],
              [-10.06, 29.565000000000001],
              [-10.050000000000001, 29.585000000000001],
              [-10.01, 29.629999999999999],
              [-9.995000000000001, 29.600000000000001],
              [-10, 29.585000000000001],
              [-9.99, 29.580000000000002],
              [-9.995000000000001, 29.560000000000002],
              [-9.99, 29.555],
              [-10.005000000000001, 29.535],
              [-9.995000000000001, 29.504999999999999],
              [-9.945, 29.530000000000001],
              [-9.904999999999999, 29.535],
              [-9.890000000000001, 29.560000000000002],
              [-9.885, 29.535],
              [-9.895, 29.504999999999999],
              [-9.915000000000001, 29.48],
              [-9.885, 29.460000000000001],
              [-9.880000000000001, 29.435000000000002],
              [-9.870000000000001, 29.440000000000001],
              [-9.859999999999999, 29.425000000000001],
              [-9.845000000000001, 29.420000000000002],
              [-9.795, 29.425000000000001],
              [-9.775, 29.435000000000002],
              [-9.775, 29.445],
              [-9.745000000000001, 29.455000000000002],
              [-9.73, 29.469999999999999],
              [-9.710000000000001, 29.465],
              [-9.705, 29.445],
              [-9.700000000000001, 29.449999999999999],
              [-9.654999999999999, 29.43],
              [-9.66, 29.400000000000002],
              [-9.65, 29.400000000000002],
              [-9.640000000000001, 29.405000000000001],
              [-9.635, 29.43],
              [-9.640000000000001, 29.455000000000002],
              [-9.654999999999999, 29.469999999999999],
              [-9.645, 29.469999999999999],
              [-9.615, 29.435000000000002],
              [-9.595000000000001, 29.435000000000002],
              [-9.59, 29.469999999999999],
              [-9.575000000000001, 29.484999999999999],
              [-9.56, 29.484999999999999],
              [-9.545, 29.455000000000002],
              [-9.525, 29.449999999999999],
              [-9.529999999999999, 29.435000000000002],
              [-9.5, 29.420000000000002],
              [-9.49, 29.460000000000001],
              [-9.48, 29.440000000000001],
              [-9.460000000000001, 29.449999999999999],
              [-9.425000000000001, 29.43],
              [-9.42, 29.455000000000002],
              [-9.41, 29.460000000000001],
              [-9.404999999999999, 29.449999999999999],
              [-9.4, 29.465],
              [-9.385, 29.465],
              [-9.359999999999999, 29.504999999999999],
              [-9.33, 29.510000000000002],
              [-9.279999999999999, 29.5],
              [-9.24, 29.465],
              [-9.215, 29.455000000000002],
              [-9.205, 29.43],
              [-9.16, 29.390000000000001],
              [-9.17, 29.359999999999999],
              [-9.140000000000001, 29.265000000000001],
              [-9.109999999999999, 29.245000000000001],
              [-9.085000000000001, 29.185000000000002],
              [-9.085000000000001, 29.16],
              [-9.16, 29.109999999999999],
              [-9.140000000000001, 29.080000000000002],
              [-9.17, 29.050000000000001],
              [-9.205, 29.039999999999999],
              [-9.210000000000001, 29.055],
              [-9.175000000000001, 29.09],
              [-9.245000000000001, 29.07],
              [-9.234999999999999, 29.025000000000002],
              [-9.25, 28.984999999999999],
              [-9.225, 28.975000000000001],
              [-9.225, 28.955000000000002],
              [-9.24, 28.940000000000001],
              [-9.215, 28.859999999999999],
              [-9.18, 28.855],
              [-9.200000000000001, 28.844999999999999],
              [-9.205, 28.795000000000002],
              [-9.18, 28.780000000000001],
              [-9.135, 28.725000000000001],
              [-9, 28.715],
              [-8.975, 28.685000000000002],
              [-8.970000000000001, 28.645],
              [-8.92, 28.600000000000001],
              [-8.885, 28.545000000000002],
              [-8.67, 28.310000000000002],
              [-8.67, 27.135000000000002],
              [-8.755000000000001, 27.145],
              [-8.875, 27.185000000000002],
              [-8.970000000000001, 27.280000000000001],
              [-9.06, 27.315000000000001],
              [-9.17, 27.41],
              [-9.290000000000001, 27.469999999999999],
              [-9.404999999999999, 27.425000000000001],
              [-9.545, 27.400000000000002],
              [-9.605, 27.445],
              [-9.765000000000001, 27.535],
              [-9.815, 27.59],
              [-9.815, 27.615000000000002],
              [-9.859999999999999, 27.66],
              [-9.9, 27.675000000000001],
              [-9.915000000000001, 27.650000000000002],
              [-9.965, 27.635000000000002],
              [-9.984999999999999, 27.640000000000001],
              [-10.035, 27.670000000000002],
              [-10.040000000000001, 27.690000000000001],
              [-10.06, 27.710000000000001],
              [-10.09, 27.710000000000001],
              [-10.109999999999999, 27.699999999999999],
              [-10.135, 27.715],
              [-10.210000000000001, 27.800000000000001],
              [-10.25, 27.810000000000002],
              [-10.305, 27.815000000000001],
              [-10.720000000000001, 27.77],
              [-10.755000000000001, 27.785],
              [-10.785, 27.785],
              [-10.870000000000001, 27.775000000000002],
              [-10.9, 27.760000000000002],
              [-11.040000000000001, 27.754999999999999],
              [-11.154999999999999, 27.740000000000002],
              [-11.19, 27.740000000000002],
              [-11.26, 27.77],
              [-11.404999999999999, 27.805],
              [-11.484999999999999, 27.805],
              [-11.550000000000001, 27.835000000000001],
              [-11.585000000000001, 27.865000000000002],
              [-11.665000000000001, 27.990000000000002],
              [-11.700000000000001, 28.060000000000002],
              [-11.710000000000001, 28.125],
              [-11.745000000000001, 28.150000000000002],
              [-11.77, 28.18],
              [-11.779999999999999, 28.210000000000001],
            ],
            [
              [-11.355, 28.475000000000001],
              [-11.35, 28.475000000000001],
              [-11.35, 28.48],
              [-11.355, 28.475000000000001],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      region: "Beni Mellal-Khenifra",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-7.32, 31.68],
              [-7.32, 31.695],
              [-7.305, 31.695],
              [-7.305, 31.705000000000002],
              [-7.32, 31.715],
              [-7.285, 31.734999999999999],
              [-7.275, 31.75],
              [-7.265000000000001, 31.740000000000002],
              [-7.26, 31.765000000000001],
              [-7.23, 31.760000000000002],
              [-7.23, 31.775000000000002],
              [-7.215, 31.760000000000002],
              [-7.205, 31.765000000000001],
              [-7.175, 31.745000000000001],
              [-7.105, 31.760000000000002],
              [-7.05, 31.760000000000002],
              [-7.025, 31.775000000000002],
              [-7.045, 31.789999999999999],
              [-7.035, 31.830000000000002],
              [-7.02, 31.82],
              [-7, 31.82],
              [-7.015000000000001, 31.845000000000002],
              [-7.025, 31.845000000000002],
              [-7.02, 31.859999999999999],
              [-7.03, 31.859999999999999],
              [-7.055, 31.895],
              [-7.05, 31.905000000000001],
              [-7.07, 31.91],
              [-7.07, 31.965],
              [-7.055, 31.975000000000001],
              [-7.06, 31.984999999999999],
              [-7.11, 32.020000000000003],
              [-7.12, 32.085000000000001],
              [-7.13, 32.100000000000001],
              [-7.13, 32.130000000000003],
              [-7.105, 32.215000000000003],
              [-7.115, 32.240000000000002],
              [-7.100000000000001, 32.305],
              [-7.115, 32.325000000000003],
              [-7.125, 32.314999999999998],
              [-7.135, 32.32],
              [-7.105, 32.355000000000004],
              [-7.04, 32.375],
              [-6.99, 32.5],
              [-7.015000000000001, 32.555],
              [-6.995, 32.560000000000002],
              [-6.985, 32.634999999999998],
              [-7.015000000000001, 32.655000000000001],
              [-7.02, 32.670000000000002],
              [-7.055, 32.700000000000003],
              [-7.045, 32.740000000000002],
              [-7.02, 32.770000000000003],
              [-7.035, 32.770000000000003],
              [-7.03, 32.789999999999999],
              [-7.005, 32.825000000000003],
              [-7, 32.844999999999999],
              [-7, 32.939999999999998],
              [-7.01, 32.945],
              [-7.005, 32.954999999999998],
              [-6.98, 32.950000000000003],
              [-6.955, 32.969999999999999],
              [-6.925, 33.049999999999997],
              [-6.93, 33.055],
              [-6.905, 33.075000000000003],
              [-6.915, 33.100000000000001],
              [-6.895, 33.105000000000004],
              [-6.895, 33.140000000000001],
              [-6.87, 33.200000000000003],
              [-6.850000000000001, 33.219999999999999],
              [-6.805, 33.234999999999999],
              [-6.78, 33.215000000000003],
              [-6.68, 33.210000000000001],
              [-6.67, 33.215000000000003],
              [-6.57, 33.18],
              [-6.575, 33.174999999999997],
              [-6.56, 33.174999999999997],
              [-6.54, 33.149999999999999],
              [-6.53, 33.149999999999999],
              [-6.51, 33.185000000000002],
              [-6.475000000000001, 33.195],
              [-6.46, 33.219999999999999],
              [-6.405, 33.225000000000001],
              [-6.385, 33.255000000000003],
              [-6.38, 33.274999999999999],
              [-6.41, 33.325000000000003],
              [-6.390000000000001, 33.344999999999999],
              [-6.405, 33.369999999999997],
              [-6.38, 33.390000000000001],
              [-6.375, 33.410000000000004],
              [-6.36, 33.420000000000002],
              [-6.355, 33.484999999999999],
              [-6.33, 33.480000000000004],
              [-6.315, 33.465000000000003],
              [-6.3, 33.465000000000003],
              [-6.28, 33.450000000000003],
              [-6.195, 33.439999999999998],
              [-6.21, 33.414999999999999],
              [-6.215, 33.369999999999997],
              [-6.215, 33.350000000000001],
              [-6.205, 33.340000000000003],
              [-6.18, 33.350000000000001],
              [-6.155, 33.335000000000001],
              [-6.115, 33.340000000000003],
              [-6.07, 33.305],
              [-5.995, 33.305],
              [-5.99, 33.32],
              [-5.975000000000001, 33.32],
              [-5.965, 33.329999999999998],
              [-5.95, 33.32],
              [-5.94, 33.329999999999998],
              [-5.93, 33.329999999999998],
              [-5.925, 33.32],
              [-5.91, 33.329999999999998],
              [-5.915, 33.314999999999998],
              [-5.86, 33.314999999999998],
              [-5.825, 33.305],
              [-5.775, 33.335000000000001],
              [-5.785, 33.340000000000003],
              [-5.795, 33.335000000000001],
              [-5.795, 33.344999999999999],
              [-5.815, 33.355000000000004],
              [-5.805, 33.384999999999998],
              [-5.815, 33.424999999999997],
              [-5.785, 33.469999999999999],
              [-5.785, 33.454999999999998],
              [-5.77, 33.445],
              [-5.775, 33.439999999999998],
              [-5.755, 33.43],
              [-5.75, 33.414999999999999],
              [-5.735, 33.420000000000002],
              [-5.68, 33.395000000000003],
              [-5.66, 33.390000000000001],
              [-5.655, 33.395000000000003],
              [-5.635, 33.384999999999998],
              [-5.625, 33.390000000000001],
              [-5.615, 33.380000000000003],
              [-5.595, 33.390000000000001],
              [-5.58, 33.384999999999998],
              [-5.57, 33.375],
              [-5.575, 33.369999999999997],
              [-5.55, 33.365000000000002],
              [-5.54, 33.350000000000001],
              [-5.545, 33.344999999999999],
              [-5.525, 33.340000000000003],
              [-5.515, 33.32],
              [-5.505, 33.325000000000003],
              [-5.51, 33.314999999999998],
              [-5.5, 33.305],
              [-5.51, 33.255000000000003],
              [-5.49, 33.234999999999999],
              [-5.485, 33.195],
              [-5.46, 33.170000000000002],
              [-5.42, 33.155000000000001],
              [-5.365, 33.155000000000001],
              [-5.295, 33.174999999999997],
              [-5.28, 33.170000000000002],
              [-5.285, 33.130000000000003],
              [-5.3, 33.100000000000001],
              [-5.33, 33.085000000000001],
              [-5.32, 33.035000000000004],
              [-5.29, 33.030000000000001],
              [-5.29, 33.005000000000003],
              [-5.305, 32.984999999999999],
              [-5.28, 32.969999999999999],
              [-5.24, 32.924999999999997],
              [-5.275, 32.859999999999999],
              [-5.255, 32.869999999999997],
              [-5.25, 32.855000000000004],
              [-5.29, 32.840000000000003],
              [-5.295, 32.82],
              [-5.31, 32.810000000000002],
              [-5.31, 32.799999999999997],
              [-5.3, 32.795000000000002],
              [-5.32, 32.765000000000001],
              [-5.32, 32.615000000000002],
              [-5.33, 32.615000000000002],
              [-5.345, 32.600000000000001],
              [-5.34, 32.575000000000003],
              [-5.355, 32.515000000000001],
              [-5.4, 32.524999999999999],
              [-5.405, 32.515000000000001],
              [-5.395, 32.484999999999999],
              [-5.41, 32.465000000000003],
              [-5.445, 32.454999999999998],
              [-5.535, 32.454999999999998],
              [-5.535, 32.420000000000002],
              [-5.525, 32.390000000000001],
              [-5.525, 32.369999999999997],
              [-5.535, 32.359999999999999],
              [-5.475000000000001, 32.344999999999999],
              [-5.51, 32.295000000000002],
              [-5.485, 32.259999999999998],
              [-5.45, 32.240000000000002],
              [-5.445, 32.215000000000003],
              [-5.51, 32.225000000000001],
              [-5.535, 32.240000000000002],
              [-5.565, 32.240000000000002],
              [-5.585, 32.219999999999999],
              [-5.68, 32.215000000000003],
              [-5.745, 32.145000000000003],
              [-5.795, 32.130000000000003],
              [-5.845, 32.130000000000003],
              [-5.86, 32.119999999999997],
              [-5.87, 32.100000000000001],
              [-5.865, 32.085000000000001],
              [-5.89, 31.984999999999999],
              [-5.825, 31.965],
              [-5.805, 31.940000000000001],
              [-5.8, 31.93],
              [-5.815, 31.900000000000002],
              [-5.825, 31.895],
              [-5.825, 31.870000000000001],
              [-5.815, 31.859999999999999],
              [-5.92, 31.82],
              [-5.965, 31.75],
              [-6.03, 31.715],
              [-6.140000000000001, 31.685000000000002],
              [-6.26, 31.635000000000002],
              [-6.425, 31.580000000000002],
              [-6.445, 31.550000000000001],
              [-6.455, 31.545000000000002],
              [-6.48, 31.555],
              [-6.495, 31.535],
              [-6.565, 31.510000000000002],
              [-6.635, 31.460000000000001],
              [-6.7, 31.470000000000002],
              [-6.72, 31.359999999999999],
              [-6.76, 31.345000000000002],
              [-6.995, 31.350000000000001],
              [-7.01, 31.34],
              [-7.04, 31.390000000000001],
              [-7.06, 31.370000000000001],
              [-7.125, 31.365000000000002],
              [-7.140000000000001, 31.379999999999999],
              [-7.155, 31.43],
              [-7.175, 31.414999999999999],
              [-7.185, 31.43],
              [-7.17, 31.465],
              [-7.155, 31.470000000000002],
              [-7.140000000000001, 31.530000000000001],
              [-7.13, 31.535],
              [-7.135, 31.560000000000002],
              [-7.195, 31.560000000000002],
              [-7.21, 31.574999999999999],
              [-7.22, 31.609999999999999],
              [-7.265000000000001, 31.620000000000001],
              [-7.27, 31.675000000000001],
              [-7.315, 31.670000000000002],
              [-7.32, 31.68],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      region: "L'oriental",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-4.125, 33.664999999999999],
              [-4.09, 33.664999999999999],
              [-4.08, 33.674999999999997],
              [-4.105, 33.68],
              [-4.03, 33.715000000000003],
              [-3.99, 33.719999999999999],
              [-3.96, 33.734999999999999],
              [-3.97, 33.770000000000003],
              [-3.965, 33.780000000000001],
              [-3.975, 33.780000000000001],
              [-3.965, 33.799999999999997],
              [-3.925, 33.835000000000001],
              [-3.905, 33.875],
              [-3.9, 33.890000000000001],
              [-3.91, 33.914999999999999],
              [-3.895, 33.969999999999999],
              [-3.825, 34.009999999999998],
              [-3.855, 34.030000000000001],
              [-3.84, 34.055],
              [-3.84, 34.094999999999999],
              [-3.83, 34.119999999999997],
              [-3.84, 34.125],
              [-3.83, 34.134999999999998],
              [-3.83, 34.164999999999999],
              [-3.815, 34.210000000000001],
              [-3.895, 34.225000000000001],
              [-3.88, 34.230000000000004],
              [-3.89, 34.240000000000002],
              [-3.885, 34.259999999999998],
              [-3.91, 34.259999999999998],
              [-3.915, 34.305],
              [-3.86, 34.305],
              [-3.835, 34.340000000000003],
              [-3.845, 34.344999999999999],
              [-3.84, 34.369999999999997],
              [-3.86, 34.369999999999997],
              [-3.84, 34.395000000000003],
              [-3.845, 34.405000000000001],
              [-3.835, 34.410000000000004],
              [-3.855, 34.410000000000004],
              [-3.84, 34.439999999999998],
              [-3.85, 34.445],
              [-3.845, 34.454999999999998],
              [-3.8, 34.469999999999999],
              [-3.78, 34.490000000000002],
              [-3.755, 34.539999999999999],
              [-3.745, 34.545000000000002],
              [-3.76, 34.560000000000002],
              [-3.755, 34.560000000000002],
              [-3.76, 34.57],
              [-3.77, 34.57],
              [-3.76, 34.594999999999999],
              [-3.775, 34.619999999999997],
              [-3.75, 34.615000000000002],
              [-3.735, 34.625],
              [-3.73, 34.640000000000001],
              [-3.72, 34.640000000000001],
              [-3.705, 34.625],
              [-3.645, 34.630000000000003],
              [-3.635, 34.675000000000004],
              [-3.59, 34.700000000000003],
              [-3.615, 34.719999999999999],
              [-3.62, 34.740000000000002],
              [-3.59, 34.810000000000002],
              [-3.605, 34.844999999999999],
              [-3.625, 34.850000000000001],
              [-3.75, 34.844999999999999],
              [-3.765, 34.859999999999999],
              [-3.76, 34.880000000000003],
              [-3.815, 34.884999999999998],
              [-3.83, 34.910000000000004],
              [-3.805, 34.984999999999999],
              [-3.815, 35.009999999999998],
              [-3.815, 35.115000000000002],
              [-3.805, 35.149999999999999],
              [-3.815, 35.160000000000004],
              [-3.805, 35.164999999999999],
              [-3.805, 35.175000000000004],
              [-3.825, 35.200000000000003],
              [-3.775, 35.215000000000003],
              [-3.76, 35.244999999999997],
              [-3.76, 35.265000000000001],
              [-3.75, 35.274999999999999],
              [-3.71, 35.289999999999999],
              [-3.675, 35.285000000000004],
              [-3.67, 35.270000000000003],
              [-3.655, 35.259999999999998],
              [-3.575, 35.219999999999999],
              [-3.535, 35.219999999999999],
              [-3.5, 35.200000000000003],
              [-3.45, 35.204999999999998],
              [-3.425, 35.189999999999998],
              [-3.335, 35.185000000000002],
              [-3.29, 35.210000000000001],
              [-3.195, 35.225000000000001],
              [-3.175, 35.234999999999999],
              [-3.145, 35.265000000000001],
              [-3.145, 35.274999999999999],
              [-3.135, 35.274999999999999],
              [-3.135, 35.285000000000004],
              [-3.125, 35.285000000000004],
              [-3.11, 35.270000000000003],
              [-3.075, 35.285000000000004],
              [-3.025, 35.350000000000001],
              [-3.025, 35.365000000000002],
              [-3.015, 35.369999999999997],
              [-3.005, 35.395000000000003],
              [-3.01, 35.405000000000001],
              [-2.995, 35.414999999999999],
              [-2.995, 35.435000000000002],
              [-2.96, 35.439999999999998],
              [-2.95, 35.425000000000004],
              [-2.965, 35.414999999999999],
              [-2.965, 35.405000000000001],
              [-2.955, 35.399999999999999],
              [-2.965, 35.390000000000001],
              [-2.96, 35.380000000000003],
              [-2.97, 35.359999999999999],
              [-2.96, 35.355000000000004],
              [-2.955, 35.32],
              [-2.97, 35.300000000000004],
              [-2.965, 35.289999999999999],
              [-2.97, 35.280000000000001],
              [-2.96, 35.280000000000001],
              [-2.95, 35.265000000000001],
              [-2.92, 35.270000000000003],
              [-2.915, 35.274999999999999],
              [-2.92, 35.259999999999998],
              [-2.93, 35.255000000000003],
              [-2.93, 35.234999999999999],
              [-2.92, 35.219999999999999],
              [-2.9, 35.215000000000003],
              [-2.9, 35.204999999999998],
              [-2.905, 35.210000000000001],
              [-2.92, 35.204999999999998],
              [-2.925, 35.185000000000002],
              [-2.89, 35.134999999999998],
              [-2.86, 35.119999999999997],
              [-2.81, 35.100000000000001],
              [-2.805, 35.105000000000004],
              [-2.75, 35.100000000000001],
              [-2.745, 35.115000000000002],
              [-2.755, 35.125],
              [-2.76, 35.119999999999997],
              [-2.77, 35.134999999999998],
              [-2.76, 35.134999999999998],
              [-2.74, 35.119999999999997],
              [-2.655, 35.090000000000003],
              [-2.565, 35.079999999999998],
              [-2.485, 35.100000000000001],
              [-2.42, 35.149999999999999],
              [-2.405, 35.134999999999998],
              [-2.295, 35.115000000000002],
              [-2.275, 35.100000000000001],
              [-2.21, 35.085000000000001],
              [-2.21, 35.060000000000002],
              [-2.22, 35.045000000000002],
              [-2.185, 35.030000000000001],
              [-2.18, 35.015000000000001],
              [-2.125, 34.994999999999997],
              [-2.125, 34.984999999999999],
              [-2.08, 34.945],
              [-2.05, 34.935000000000002],
              [-2.045, 34.925000000000004],
              [-1.975, 34.935000000000002],
              [-1.97, 34.875],
              [-1.955, 34.875],
              [-1.94, 34.859999999999999],
              [-1.905, 34.850000000000001],
              [-1.89, 34.829999999999998],
              [-1.89, 34.805],
              [-1.86, 34.805],
              [-1.84, 34.785000000000004],
              [-1.81, 34.780000000000001],
              [-1.815, 34.774999999999999],
              [-1.74, 34.744999999999997],
              [-1.77, 34.725000000000001],
              [-1.775, 34.695],
              [-1.785, 34.695],
              [-1.8, 34.68],
              [-1.8, 34.670000000000002],
              [-1.85, 34.615000000000002],
              [-1.82, 34.609999999999999],
              [-1.815, 34.594999999999999],
              [-1.79, 34.575000000000003],
              [-1.79, 34.560000000000002],
              [-1.735, 34.505000000000003],
              [-1.685, 34.494999999999997],
              [-1.78, 34.395000000000003],
              [-1.77, 34.369999999999997],
              [-1.73, 34.344999999999999],
              [-1.73, 34.335000000000001],
              [-1.705, 34.310000000000002],
              [-1.705, 34.219999999999999],
              [-1.65, 34.109999999999999],
              [-1.66, 34.035000000000004],
              [-1.7, 33.869999999999997],
              [-1.67, 33.774999999999999],
              [-1.73, 33.734999999999999],
              [-1.74, 33.700000000000003],
              [-1.7, 33.674999999999997],
              [-1.67, 33.68],
              [-1.645, 33.674999999999997],
              [-1.63, 33.630000000000003],
              [-1.6, 33.615000000000002],
              [-1.595, 33.605000000000004],
              [-1.6, 33.600000000000001],
              [-1.595, 33.560000000000002],
              [-1.605, 33.555],
              [-1.59, 33.524999999999999],
              [-1.6, 33.505000000000003],
              [-1.62, 33.490000000000002],
              [-1.62, 33.450000000000003],
              [-1.63, 33.43],
              [-1.65, 33.420000000000002],
              [-1.665, 33.380000000000003],
              [-1.66, 33.299999999999997],
              [-1.67, 33.280000000000001],
              [-1.625, 33.219999999999999],
              [-1.605, 33.215000000000003],
              [-1.6, 33.195],
              [-1.58, 33.170000000000002],
              [-1.575, 33.145000000000003],
              [-1.525, 33.100000000000001],
              [-1.495, 33.090000000000003],
              [-1.47, 33.07],
              [-1.46, 33.039999999999999],
              [-1.47, 33.035000000000004],
              [-1.475, 33.020000000000003],
              [-1.47, 33.005000000000003],
              [-1.48, 32.980000000000004],
              [-1.495, 32.965000000000003],
              [-1.51, 32.969999999999999],
              [-1.545, 32.960000000000001],
              [-1.38, 32.740000000000002],
              [-1.27, 32.695],
              [-1.125, 32.594999999999999],
              [-1, 32.520000000000003],
              [-1, 32.505000000000003],
              [-1.015, 32.505000000000003],
              [-1.11, 32.420000000000002],
              [-1.115, 32.424999999999997],
              [-1.12, 32.414999999999999],
              [-1.155, 32.420000000000002],
              [-1.2, 32.405000000000001],
              [-1.21, 32.395000000000003],
              [-1.205, 32.384999999999998],
              [-1.22, 32.359999999999999],
              [-1.22, 32.344999999999999],
              [-1.25, 32.329999999999998],
              [-1.245, 32.314999999999998],
              [-1.235, 32.314999999999998],
              [-1.24, 32.259999999999998],
              [-1.25, 32.25],
              [-1.24, 32.204999999999998],
              [-1.255, 32.200000000000003],
              [-1.26, 32.189999999999998],
              [-1.275, 32.189999999999998],
              [-1.28, 32.174999999999997],
              [-1.3, 32.164999999999999],
              [-1.265, 32.160000000000004],
              [-1.235, 32.174999999999997],
              [-1.19, 32.170000000000002],
              [-1.155, 32.130000000000003],
              [-1.15, 32.109999999999999],
              [-1.185, 32.105000000000004],
              [-1.2, 32.085000000000001],
              [-1.24, 32.075000000000003],
              [-1.26, 32.075000000000003],
              [-1.27, 32.085000000000001],
              [-1.35, 32.090000000000003],
              [-1.41, 32.085000000000001],
              [-1.485, 32.094999999999999],
              [-1.5, 32.105000000000004],
              [-1.58, 32.090000000000003],
              [-1.64, 32.109999999999999],
              [-1.675, 32.109999999999999],
              [-1.69, 32.119999999999997],
              [-1.75, 32.119999999999997],
              [-1.765, 32.130000000000003],
              [-1.845, 32.149999999999999],
              [-1.875, 32.145000000000003],
              [-1.905, 32.164999999999999],
              [-1.92, 32.164999999999999],
              [-1.925, 32.149999999999999],
              [-1.945, 32.160000000000004],
              [-1.97, 32.155000000000001],
              [-1.98, 32.170000000000002],
              [-2.005, 32.18],
              [-2.03, 32.18],
              [-2.13, 32.145000000000003],
              [-2.18, 32.140000000000001],
              [-2.28, 32.170000000000002],
              [-2.29, 32.149999999999999],
              [-2.315, 32.160000000000004],
              [-2.32, 32.155000000000001],
              [-2.405, 32.160000000000004],
              [-2.43, 32.149999999999999],
              [-2.435, 32.160000000000004],
              [-2.465, 32.160000000000004],
              [-2.485, 32.149999999999999],
              [-2.525, 32.149999999999999],
              [-2.56, 32.140000000000001],
              [-2.59, 32.115000000000002],
              [-2.665, 32.119999999999997],
              [-2.73, 32.109999999999999],
              [-2.86, 32.115000000000002],
              [-2.885, 32.105000000000004],
              [-2.92, 32.075000000000003],
              [-2.93, 32.024999999999999],
              [-2.92, 32.020000000000003],
              [-2.915, 32],
              [-2.88, 31.955000000000002],
              [-2.885, 31.935000000000002],
              [-2.84, 31.885000000000002],
              [-2.835, 31.855],
              [-2.845, 31.845000000000002],
              [-2.845, 31.830000000000002],
              [-2.82, 31.815000000000001],
              [-2.825, 31.805],
              [-2.82, 31.795000000000002],
              [-2.985, 31.77],
              [-3, 31.760000000000002],
              [-3.25, 31.715],
              [-3.255, 31.73],
              [-3.155, 31.795000000000002],
              [-3.115, 31.84],
              [-3.18, 31.830000000000002],
              [-3.185, 31.815000000000001],
              [-3.195, 31.815000000000001],
              [-3.245, 31.905000000000001],
              [-3.27, 31.970000000000002],
              [-3.26, 32.090000000000003],
              [-3.195, 32.174999999999997],
              [-3.295, 32.134999999999998],
              [-3.335, 32.105000000000004],
              [-3.375, 32.109999999999999],
              [-3.485, 32.145000000000003],
              [-3.54, 32.145000000000003],
              [-3.565, 32.125],
              [-3.615, 32.109999999999999],
              [-3.635, 32.119999999999997],
              [-3.645, 32.134999999999998],
              [-3.635, 32.155000000000001],
              [-3.585, 32.200000000000003],
              [-3.6, 32.204999999999998],
              [-3.675, 32.195],
              [-3.755, 32.200000000000003],
              [-3.745, 32.244999999999997],
              [-3.755, 32.259999999999998],
              [-3.775, 32.265000000000001],
              [-3.815, 32.255000000000003],
              [-3.82, 32.265000000000001],
              [-3.81, 32.280000000000001],
              [-3.81, 32.314999999999998],
              [-3.82, 32.344999999999999],
              [-3.855, 32.405000000000001],
              [-3.915, 32.450000000000003],
              [-3.935, 32.480000000000004],
              [-3.94, 32.505000000000003],
              [-3.92, 32.535000000000004],
              [-4.005, 32.579999999999998],
              [-4.02, 32.609999999999999],
              [-4.015, 32.640000000000001],
              [-3.98, 32.689999999999998],
              [-3.945, 32.719999999999999],
              [-3.87, 32.825000000000003],
              [-3.8, 32.895000000000003],
              [-3.75, 32.924999999999997],
              [-3.725, 32.950000000000003],
              [-3.715, 32.980000000000004],
              [-3.685, 33.024999999999999],
              [-3.605, 33.094999999999999],
              [-3.57, 33.109999999999999],
              [-3.55, 33.094999999999999],
              [-3.435, 33.055],
              [-3.34, 33.060000000000002],
              [-3.03, 33.115000000000002],
              [-2.94, 33.149999999999999],
              [-2.905, 33.174999999999997],
              [-2.9, 33.200000000000003],
              [-2.905, 33.215000000000003],
              [-3, 33.32],
              [-3.12, 33.405000000000001],
              [-3.255, 33.515000000000001],
              [-3.245, 33.530000000000001],
              [-3.23, 33.535000000000004],
              [-3.215, 33.564999999999998],
              [-3.19, 33.57],
              [-3.12, 33.634999999999998],
              [-3.125, 33.660000000000004],
              [-3.03, 33.730000000000004],
              [-3.045, 33.765000000000001],
              [-3.215, 33.759999999999998],
              [-3.33, 33.785000000000004],
              [-3.38, 33.829999999999998],
              [-3.42, 33.850000000000001],
              [-3.43, 33.865000000000002],
              [-3.515, 33.910000000000004],
              [-3.54, 33.869999999999997],
              [-3.65, 33.740000000000002],
              [-3.695, 33.730000000000004],
              [-3.71, 33.700000000000003],
              [-3.735, 33.68],
              [-3.805, 33.655000000000001],
              [-3.855, 33.594999999999999],
              [-3.795, 33.555],
              [-3.84, 33.509999999999998],
              [-3.845, 33.535000000000004],
              [-3.875, 33.57],
              [-3.89, 33.57],
              [-3.9, 33.549999999999997],
              [-3.93, 33.545000000000002],
              [-3.945, 33.515000000000001],
              [-3.985, 33.515000000000001],
              [-4.01, 33.524999999999999],
              [-4.03, 33.515000000000001],
              [-4.06, 33.524999999999999],
              [-4.075, 33.545000000000002],
              [-4.11, 33.560000000000002],
              [-4.085, 33.640000000000001],
              [-4.11, 33.640000000000001],
              [-4.125, 33.664999999999999],
            ],
          ],
          [
            [
              [-2.77, 35.134999999999998],
              [-2.785, 35.140000000000001],
              [-2.785, 35.149999999999999],
              [-2.775, 35.145000000000003],
              [-2.77, 35.134999999999998],
            ],
          ],
          [
            [
              [-2.92, 35.259999999999998],
              [-2.915000000000004, 35.255000000000003],
              [-2.92, 35.255000000000003],
              [-2.92, 35.259999999999998],
            ],
          ],
          [
            [
              [-2.785, 35.149999999999999],
              [-2.79, 35.149999999999999],
              [-2.79, 35.155000000000001],
              [-2.785, 35.155000000000001],
              [-2.785, 35.149999999999999],
            ],
          ],
          [
            [
              [-2.79, 35.155000000000001],
              [-2.8, 35.155000000000001],
              [-2.8, 35.160000000000004],
              [-2.795, 35.160000000000004],
              [-2.79, 35.155000000000001],
            ],
          ],
          [
            [
              [-2.8, 35.160000000000004],
              [-2.81, 35.160000000000004],
              [-2.815, 35.170000000000002],
              [-2.805, 35.170000000000002],
              [-2.8, 35.160000000000004],
            ],
          ],
          [
            [
              [-2.815, 35.170000000000002],
              [-2.82, 35.170000000000002],
              [-2.82, 35.175000000000004],
              [-2.815, 35.175000000000004],
              [-2.815, 35.170000000000002],
            ],
          ],
          [
            [
              [-2.82, 35.175000000000004],
              [-2.83, 35.18],
              [-2.83, 35.185000000000002],
              [-2.825, 35.185000000000002],
              [-2.82, 35.175000000000004],
            ],
          ],
          [
            [
              [-2.83, 35.185000000000002],
              [-2.835, 35.185000000000002],
              [-2.835, 35.189999999999998],
              [-2.83, 35.185000000000002],
            ],
          ],
          [
            [
              [-2.845, 35.195],
              [-2.84, 35.195],
              [-2.84, 35.189999999999998],
              [-2.845, 35.189999999999998],
              [-2.845, 35.195],
            ],
          ],
          [
            [
              [-2.845, 35.195],
              [-2.855, 35.195],
              [-2.86, 35.200000000000003],
              [-2.855, 35.204999999999998],
              [-2.845, 35.195],
            ],
          ],
          [
            [
              [-2.915, 35.254999999999995],
              [-2.905, 35.25],
              [-2.905, 35.244999999999997],
              [-2.915, 35.244999999999997],
              [-2.915, 35.254999999999995],
            ],
          ],
          [
            [
              [-2.905, 35.244999999999997],
              [-2.9, 35.244999999999997],
              [-2.9, 35.234999999999999],
              [-2.905, 35.234999999999999],
              [-2.905, 35.244999999999997],
            ],
          ],
          [
            [
              [-2.9, 35.234999999999999],
              [-2.895, 35.240000000000002],
              [-2.895, 35.234999999999999],
              [-2.9, 35.234999999999999],
            ],
          ],
          [
            [
              [-2.895, 35.234999999999999],
              [-2.89, 35.234999999999999],
              [-2.89, 35.230000000000004],
              [-2.895, 35.234999999999999],
            ],
          ],
          [
            [
              [-2.89, 35.230000000000004],
              [-2.885, 35.230000000000004],
              [-2.88, 35.225000000000001],
              [-2.885, 35.225000000000001],
              [-2.89, 35.230000000000004],
            ],
          ],
          [
            [
              [-2.88, 35.225000000000001],
              [-2.865, 35.215000000000003],
              [-2.865, 35.210000000000001],
              [-2.875, 35.210000000000001],
              [-2.88, 35.225000000000001],
            ],
          ],
          [
            [
              [-2.865, 35.210000000000001],
              [-2.86, 35.210000000000001],
              [-2.86, 35.204999999999998],
              [-2.865, 35.210000000000001],
            ],
          ],
          [
            [
              [-2.93, 35.270000000000003],
              [-2.93, 35.274999999999999],
              [-2.925, 35.274999999999999],
              [-2.93, 35.270000000000003],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      region: "Souss-Massa",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-10.0098068, 29.630642999999999],
              [-10.0054132, 29.629838199999998],
              [-10.0037825, 29.625324299999999],
              [-10.0033104, 29.618049500000001],
              [-9.9927647, 29.600937699999999],
              [-9.994512800000001, 29.596781700000001],
              [-9.997044799999999, 29.593647199999999],
              [-9.997430899999999, 29.590213899999998],
              [-9.9994052, 29.5875643],
              [-9.997516900000001, 29.585474399999999],
              [-9.992879800000001, 29.581410200000001],
              [-9.988716999999999, 29.579021600000001],
              [-9.989918599999999, 29.5743188],
              [-9.9917661, 29.568753600000001],
              [-9.993051400000001, 29.562075799999999],
              [-9.9925386, 29.556733900000001],
              [-9.9916395, 29.552552800000001],
              [-9.993442, 29.548296799999999],
              [-9.9956736, 29.544861999999998],
              [-10.0026259, 29.537170700000001],
              [-10.0025444, 29.536753000000001],
              [-9.9996218, 29.521786299999999],
              [-9.9930986, 29.506922500000002],
              [-9.9813785, 29.5106611],
              [-9.966233600000001, 29.516334000000001],
              [-9.958337200000001, 29.520665999999999],
              [-9.9496804, 29.525847500000001],
              [-9.943488500000001, 29.529553499999999],
              [-9.9430665, 29.529778400000001],
              [-9.930871399999999, 29.536274599999999],
              [-9.9068389, 29.537469399999999],
              [-9.9027189, 29.537320099999999],
              [-9.8891577, 29.558525700000001],
              [-9.8857245, 29.5480728],
              [-9.885677899999999, 29.547670100000001],
              [-9.883922, 29.532540699999998],
              [-9.8877217, 29.521016599999999],
              [-9.888232500000001, 29.5194671],
              [-9.892934199999999, 29.5052044],
              [-9.8960241, 29.5046815],
              [-9.9005732, 29.500871799999999],
              [-9.9024924, 29.4988511],
              [-9.905894699999999, 29.495268899999999],
              [-9.9125424, 29.4867557],
              [-9.914220200000001, 29.481372499999999],
              [-9.9096712, 29.477711299999999],
              [-9.9045214, 29.472331400000002],
              [-9.8947368, 29.4706127],
              [-9.893449199999999, 29.466278599999999],
              [-9.887784399999999, 29.461421099999999],
              [-9.8848661, 29.458058099999999],
              [-9.883836199999999, 29.452154],
              [-9.8852095, 29.447370599999999],
              [-9.885467, 29.441615299999999],
              [-9.8806604, 29.437130400000001],
              [-9.871905699999999, 29.438924400000001],
              [-9.863751799999999, 29.430477499999999],
              [-9.8623786, 29.428309599999999],
              [-9.858166300000001, 29.426218599999999],
              [-9.852587400000001, 29.423228300000002],
              [-9.8463218, 29.4208359],
              [-9.838081900000001, 29.423153500000002],
              [-9.8300997, 29.423901099999998],
              [-9.820233500000001, 29.424054399999999],
              [-9.812075200000001, 29.4226302],
              [-9.8050371, 29.423826399999999],
              [-9.7936216, 29.4252468],
              [-9.783150300000001, 29.4283866],
              [-9.779373700000001, 29.432572799999999],
              [-9.7762881, 29.436164600000001],
              [-9.772936400000001, 29.443486199999999],
              [-9.7590319, 29.4477464],
              [-9.7444407, 29.4537257],
              [-9.7377845, 29.465507299999999],
              [-9.7319437, 29.463990299999999],
              [-9.7305362, 29.468149],
              [-9.727703699999999, 29.468149],
              [-9.7193781, 29.466878600000001],
              [-9.711052499999999, 29.466878600000001],
              [-9.7095977, 29.462847],
              [-9.7111383, 29.457537200000001],
              [-9.708481900000001, 29.452309400000001],
              [-9.707361799999999, 29.447298100000001],
              [-9.703503700000001, 29.447451300000001],
              [-9.698950399999999, 29.449839300000001],
              [-9.694234, 29.446928100000001],
              [-9.688049899999999, 29.443560900000001],
              [-9.6812693, 29.440870100000001],
              [-9.6762911, 29.4409448],
              [-9.6688238, 29.4409448],
              [-9.664017299999999, 29.436459899999999],
              [-9.657241000000001, 29.431829],
              [-9.659049100000001, 29.4241484],
              [-9.6591249, 29.423826399999999],
              [-9.6618715, 29.416574399999998],
              [-9.661528199999999, 29.408649100000002],
              [-9.6598974, 29.399900500000001],
              [-9.6558805, 29.404345899999999],
              [-9.653631799999999, 29.4046114],
              [-9.650627699999999, 29.402143800000001],
              [-9.6440187, 29.403863600000001],
              [-9.638444, 29.402596200000001],
              [-9.641014699999999, 29.4063312],
              [-9.639469699999999, 29.414256699999999],
              [-9.6374999, 29.4184473],
              [-9.634491499999999, 29.4218078],
              [-9.632693400000001, 29.425998100000001],
              [-9.636980599999999, 29.428610800000001],
              [-9.6384399, 29.433245599999999],
              [-9.6383539, 29.443187200000001],
              [-9.6398989, 29.451707800000001],
              [-9.641619800000001, 29.457242000000001],
              [-9.6484004, 29.461501800000001],
              [-9.651829299999999, 29.465384],
              [-9.653116799999999, 29.4685226],
              [-9.645906999999999, 29.469568800000001],
              [-9.6392981, 29.4658324],
              [-9.635150100000001, 29.460892300000001],
              [-9.634889299999999, 29.4605818],
              [-9.631830799999999, 29.456939299999998],
              [-9.6239387, 29.448348200000002],
              [-9.621359399999999, 29.4405711],
              [-9.614664700000001, 29.4369084],
              [-9.6106306, 29.436609399999998],
              [-9.602820100000001, 29.436086100000001],
              [-9.596902, 29.436388900000001],
              [-9.591919499999999, 29.4410943],
              [-9.5883146, 29.4537257],
              [-9.587628, 29.463441100000001],
              [-9.5882288, 29.472408300000001],
              [-9.586259099999999, 29.4778667],
              [-9.5754444, 29.4833921],
              [-9.562398200000001, 29.4867542],
              [-9.5550166, 29.473902800000001],
              [-9.5434295, 29.455822099999999],
              [-9.526864099999999, 29.448643400000002],
              [-9.5283233, 29.434740600000001],
              [-9.5132171, 29.428016499999998],
              [-9.5003425, 29.422480700000001],
              [-9.497681699999999, 29.430778700000001],
              [-9.4960509, 29.438403399999999],
              [-9.493218499999999, 29.446105899999999],
              [-9.4914161, 29.449241399999998],
              [-9.490471899999999, 29.459708200000001],
              [-9.4864379, 29.455369900000001],
              [-9.4827472, 29.4504372],
              [-9.481802999999999, 29.445429499999999],
              [-9.479399799999999, 29.441243799999999],
              [-9.4713317, 29.442514500000001],
              [-9.4594871, 29.4480492],
              [-9.4447242, 29.445952699999999],
              [-9.4369136, 29.440350599999999],
              [-9.4254981, 29.431899999999999],
              [-9.4210349, 29.4394536],
              [-9.4190608, 29.4424435],
              [-9.4196616, 29.4453548],
              [-9.4205199, 29.449544100000001],
              [-9.420605800000001, 29.456341500000001],
              [-9.409705300000001, 29.460978699999998],
              [-9.407988599999999, 29.457312999999999],
              [-9.4065295, 29.451483499999998],
              [-9.4020663, 29.455896800000001],
              [-9.3996631, 29.464490999999999],
              [-9.384471, 29.4644163],
              [-9.381209500000001, 29.470095600000001],
              [-9.3783771, 29.480332400000002],
              [-9.373802100000001, 29.486830300000001],
              [-9.373484700000001, 29.487280899999998],
              [-9.3710814, 29.492660099999998],
              [-9.3699656, 29.497437600000001],
              [-9.3671332, 29.5014006],
              [-9.3651591, 29.5021439],
              [-9.358378500000001, 29.503339100000002],
              [-9.3522845, 29.504384900000002],
              [-9.341726700000001, 29.502564],
              [-9.337408399999999, 29.505585700000001],
              [-9.3337155, 29.507027300000001],
              [-9.328938600000001, 29.508717499999999],
              [-9.3208705, 29.5073729],
              [-9.312544900000001, 29.506330899999998],
              [-9.306107600000001, 29.502745300000001],
              [-9.304305299999999, 29.498707599999999],
              [-9.290228900000001, 29.498184699999999],
              [-9.2782985, 29.497885799999999],
              [-9.268427900000001, 29.487576099999998],
              [-9.259759000000001, 29.482723400000001],
              [-9.2473136, 29.476148200000001],
              [-9.242764299999999, 29.472106],
              [-9.2377647, 29.467121800000001],
              [-9.227744, 29.460224199999999],
              [-9.2174443, 29.453498100000001],
              [-9.209977, 29.445725100000001],
              [-9.2073163, 29.4403434],
              [-9.206114599999999, 29.432120900000001],
              [-9.194269999999999, 29.421056799999999],
              [-9.181824499999999, 29.4090943],
              [-9.171038299999999, 29.399879899999998],
              [-9.170559300000001, 29.399470900000001],
              [-9.1615685, 29.391147799999999],
              [-9.157792000000001, 29.385763300000001],
              [-9.1593369, 29.379331400000002],
              [-9.165001699999999, 29.368710400000001],
              [-9.16961, 29.3595799],
              [-9.155882200000001, 29.315689500000001],
              [-9.1503891, 29.296966900000001],
              [-9.1448959, 29.2864121],
              [-9.139574400000001, 29.2671937],
              [-9.129982800000001, 29.257856400000001],
              [-9.1203483, 29.248626900000001],
              [-9.1112503, 29.243845199999999],
              [-9.1102203, 29.236153600000002],
              [-9.0986843, 29.221972600000001],
              [-9.0958007, 29.218427599999998],
              [-9.085586899999999, 29.184896200000001],
              [-9.085071900000001, 29.1728235],
              [-9.085586899999999, 29.161997400000001],
              [-9.091312, 29.155165],
              [-9.1289531, 29.129088200000002],
              [-9.159938, 29.1114262],
              [-9.1611396, 29.1037322],
              [-9.1383945, 29.0821504],
              [-9.146119199999999, 29.071419800000001],
              [-9.171954299999999, 29.052202300000001],
              [-9.195385999999999, 29.037780300000001],
              [-9.206028999999999, 29.041682399999999],
              [-9.2091189, 29.0525287],
              [-9.175816599999999, 29.090659899999999],
              [-9.2457686, 29.070748399999999],
              [-9.2344054, 29.0421403],
              [-9.234049199999999, 29.0269364],
              [-9.2429627, 29.004775599999999],
              [-9.2492755, 28.985767200000002],
              [-9.2401955, 28.9827458],
              [-9.224264399999999, 28.977444299999998],
              [-9.2239918, 28.9733704],
              [-9.222869599999999, 28.956594599999999],
              [-9.2246256, 28.954717899999999],
              [-9.2377257, 28.940715999999998],
              [-9.234892, 28.9271201],
              [-9.2264789, 28.895440900000001],
              [-9.215557, 28.8598462],
              [-9.1810958, 28.8539788],
              [-9.2020857, 28.842852300000001],
              [-9.2059739, 28.7954273],
              [-9.179812699999999, 28.779735800000001],
              [-9.1535613, 28.748932700000001],
              [-9.1344289, 28.726821900000001],
              [-9.065953199999999, 28.717503499999999],
              [-9.001193900000001, 28.714025800000002],
              [-8.973187299999999, 28.685567599999999],
              [-8.967814300000001, 28.661861600000002],
              [-8.970526599999999, 28.644974999999999],
              [-8.918307199999999, 28.598194899999999],
              [-8.88316, 28.544939899999999],
              [-8.8018544, 28.454025999999999],
              [-8.77205, 28.4206799],
              [-8.67022, 28.312439900000001],
              [-8.668873400000001, 28.3110599],
              [-8.668696000000001, 28.347517],
              [-8.668716, 28.390771999999998],
              [-8.668597, 28.428743000000001],
              [-8.668148, 28.460847999999999],
              [-8.668626, 28.494395999999998],
              [-8.668492000000001, 28.574352000000001],
              [-8.668683, 28.649744999999999],
              [-8.668749399999999, 28.667192499999999],
              [-8.660850999999999, 28.673525300000001],
              [-8.645382, 28.685317099999999],
              [-8.630436700000001, 28.697933200000001],
              [-8.614525799999999, 28.713624800000002],
              [-8.5986472, 28.730428799999999],
              [-8.5913086, 28.7370248],
              [-8.587837499999999, 28.739460900000001],
              [-8.584800400000001, 28.7414512],
              [-8.5818326, 28.742914899999999],
              [-8.580017, 28.743888999999999],
              [-8.578927500000001, 28.7445089],
              [-8.577942800000001, 28.745223800000002],
              [-8.5762015, 28.7460135],
              [-8.5734093, 28.747745699999999],
              [-8.5656123, 28.7511361],
              [-8.5606352, 28.753069400000001],
              [-8.556172399999999, 28.754836399999999],
              [-8.5555781, 28.755071699999998],
              [-8.546465899999999, 28.759209599999998],
              [-8.5341571, 28.763663900000001],
              [-8.5174144, 28.769986100000001],
              [-8.513730199999999, 28.771471300000002],
              [-8.5114862, 28.772314399999999],
              [-8.510828500000001, 28.772579499999999],
              [-8.509881699999999, 28.772884300000001],
              [-8.5086592, 28.773324800000001],
              [-8.5074661, 28.7736728],
              [-8.5057569, 28.774355],
              [-8.503564600000001, 28.7749101],
              [-8.501215, 28.775575100000001],
              [-8.496000799999999, 28.776906199999999],
              [-8.485408, 28.780261800000002],
              [-8.4757134, 28.784359599999998],
              [-8.4663915, 28.788729199999999],
              [-8.462092999999999, 28.790544100000002],
              [-8.4581584, 28.7920251],
              [-8.433772400000001, 28.8071059],
              [-8.4283018, 28.810816899999999],
              [-8.425633599999999, 28.813289300000001],
              [-8.424042500000001, 28.8148968],
              [-8.422283, 28.8164263],
              [-8.4194827, 28.819207800000001],
              [-8.4105001, 28.832392200000001],
              [-8.398507800000001, 28.843056700000002],
              [-8.385114099999999, 28.8560211],
              [-8.366793400000001, 28.872393599999999],
              [-8.351672199999999, 28.885337499999999],
              [-8.348084800000001, 28.887855200000001],
              [-8.3324482, 28.900741499999999],
              [-8.321732300000001, 28.907723399999998],
              [-8.316223600000001, 28.911228900000001],
              [-8.3141362, 28.9127756],
              [-8.3120227, 28.9140695],
              [-8.309561199999999, 28.9157452],
              [-8.307967100000001, 28.9168713],
              [-8.305971599999999, 28.918539599999999],
              [-8.305123999999999, 28.919158500000002],
              [-8.304046899999999, 28.920204699999999],
              [-8.3030978, 28.921015000000001],
              [-8.3004432, 28.922841500000001],
              [-8.2973433, 28.924082599999998],
              [-8.2937683, 28.926571200000001],
              [-8.2876013, 28.930410200000001],
              [-8.281004299999999, 28.934402599999999],
              [-8.2753271, 28.9381959],
              [-8.271403899999999, 28.9409828],
              [-8.2688633, 28.942829],
              [-8.264375899999999, 28.945929199999998],
              [-8.2625627, 28.9468976],
              [-8.2604921, 28.947796100000001],
              [-8.2580244, 28.948746700000001],
              [-8.2559855, 28.950087799999999],
              [-8.253335399999999, 28.951998700000001],
              [-8.2492906, 28.954431599999999],
              [-8.2435185, 28.958962100000001],
              [-8.237904800000001, 28.963089400000001],
              [-8.2340561, 28.965761199999999],
              [-8.2267644, 28.970789499999999],
              [-8.224256499999999, 28.972035200000001],
              [-8.221910599999999, 28.972981699999998],
              [-8.218132900000001, 28.9758399],
              [-8.2132755, 28.978521300000001],
              [-8.2073258, 28.981387600000001],
              [-8.2019746, 28.984442600000001],
              [-8.198345399999999, 28.986205999999999],
              [-8.1947071, 28.987560599999998],
              [-8.191549699999999, 28.988890399999999],
              [-8.188793199999999, 28.9900199],
              [-8.1847256, 28.9918102],
              [-8.179528599999999, 28.993425200000001],
              [-8.170288299999999, 28.997937100000001],
              [-8.164861500000001, 29.001576799999999],
              [-8.159936200000001, 29.003968400000002],
              [-8.155742500000001, 29.006361200000001],
              [-8.1513524, 29.009017199999999],
              [-8.1485758, 29.0109736],
              [-8.142493699999999, 29.014792400000001],
              [-8.138408699999999, 29.0174196],
              [-8.136459500000001, 29.018937399999999],
              [-8.134168900000001, 29.020316600000001],
              [-8.1314943, 29.022488800000001],
              [-8.129066, 29.023981299999999],
              [-8.1282967, 29.024381099999999],
              [-8.126674299999999, 29.025231600000001],
              [-8.124142600000001, 29.027356300000001],
              [-8.118260599999999, 29.0312114],
              [-8.114451900000001, 29.034077499999999],
              [-8.1097682, 29.036916699999999],
              [-8.1030696, 29.040661],
              [-8.0960702, 29.045194200000001],
              [-8.088881199999999, 29.048824400000001],
              [-8.0827986, 29.053086100000002],
              [-8.0774437, 29.056722600000001],
              [-8.0745687, 29.058511800000002],
              [-8.070613099999999, 29.060689400000001],
              [-8.066847299999999, 29.063240799999999],
              [-8.0661928, 29.0634935],
              [-8.0648195, 29.064429799999999],
              [-8.0639827, 29.065153299999999],
              [-8.0627441, 29.065718799999999],
              [-8.0619228, 29.066393999999999],
              [-8.060713099999999, 29.066971599999999],
              [-8.059712599999999, 29.067899499999999],
              [-8.0591317, 29.0683328],
              [-8.05776, 29.0690971],
              [-8.0569232, 29.069581400000001],
              [-8.0556786, 29.070170300000001],
              [-8.054469599999999, 29.070584799999999],
              [-8.0535275, 29.071429899999998],
              [-8.052282, 29.072336],
              [-8.0500706, 29.074127399999998],
              [-8.047245999999999, 29.076121799999999],
              [-8.0446148, 29.077644100000001],
              [-8.042625299999999, 29.078935099999999],
              [-8.040051699999999, 29.080239899999999],
              [-8.034350399999999, 29.082473700000001],
              [-8.026755700000001, 29.086098700000001],
              [-8.0192596, 29.088876599999999],
              [-8.0166536, 29.090265899999999],
              [-8.015624499999999, 29.090646400000001],
              [-8.009709000000001, 29.092975599999999],
              [-8.002266199999999, 29.096655299999998],
              [-7.9981968, 29.0995685],
              [-7.9909412, 29.103902399999999],
              [-7.9859478, 29.1076777],
              [-7.9793657, 29.111402099999999],
              [-7.9733724, 29.114867100000001],
              [-7.9702974, 29.116368300000001],
              [-7.9661036, 29.118137999999998],
              [-7.9640114, 29.119183],
              [-7.9626381, 29.119759500000001],
              [-7.9609913, 29.120467099999999],
              [-7.959532, 29.120987299999999],
              [-7.9579174, 29.121671500000001],
              [-7.9568132, 29.122400899999999],
              [-7.9558182, 29.1231483],
              [-7.95511, 29.1241655],
              [-7.954157, 29.125893699999999],
              [-7.9522901, 29.128991200000002],
              [-7.9515659, 29.129895600000001],
              [-7.9498225, 29.131948000000001],
              [-7.9479504, 29.133953500000001],
              [-7.9443543, 29.138087200000001],
              [-7.9402041, 29.142909],
              [-7.9359217, 29.1475656],
              [-7.9318597, 29.151912400000001],
              [-7.9271466, 29.156804300000001],
              [-7.9214174, 29.161444800000002],
              [-7.9205162, 29.162092699999999],
              [-7.9194798, 29.163148899999999],
              [-7.9177025, 29.1642109],
              [-7.9160675, 29.165699700000001],
              [-7.9140896, 29.167150800000002],
              [-7.9122979, 29.168629500000002],
              [-7.9097053, 29.171150000000001],
              [-7.90661, 29.173195100000001],
              [-7.9057892, 29.1739614],
              [-7.90374, 29.174858400000002],
              [-7.9021908, 29.175519300000001],
              [-7.9005825, 29.1763507],
              [-7.8982104, 29.1781048],
              [-7.8956076, 29.180155200000002],
              [-7.8932553, 29.181854900000001],
              [-7.8924149, 29.182499799999999],
              [-7.8912988, 29.1831833],
              [-7.8900659, 29.183875199999999],
              [-7.8885663, 29.184327799999998],
              [-7.8867369, 29.185291700000001],
              [-7.8861898, 29.185694399999999],
              [-7.8854388, 29.186040999999999],
              [-7.8844731, 29.1862891],
              [-7.8835398, 29.1867622],
              [-7.8827941, 29.1870619],
              [-7.8821128, 29.187530299999999],
              [-7.8807772, 29.1879986],
              [-7.8801709, 29.188406000000001],
              [-7.8795541, 29.189056999999998],
              [-7.8787815, 29.189717399999999],
              [-7.8781969, 29.189993699999999],
              [-7.8772795, 29.1906727],
              [-7.8767913, 29.1911925],
              [-7.8763193, 29.191674899999999],
              [-7.8751498, 29.192531899999999],
              [-7.8737497, 29.193412299999999],
              [-7.873004, 29.193773],
              [-7.8719469, 29.194224299999998],
              [-7.870502, 29.194988599999999],
              [-7.8687962, 29.195810900000001],
              [-7.8625788, 29.1982708],
              [-7.856369, 29.200285300000001],
              [-7.8505861, 29.201894800000002],
              [-7.8437733, 29.203630199999999],
              [-7.8362095, 29.204086199999999],
              [-7.8301799, 29.204871900000001],
              [-7.8277803, 29.205780000000001],
              [-7.8264606, 29.206276299999999],
              [-7.8233921, 29.208552099999999],
              [-7.8220475, 29.210876599999999],
              [-7.8210426, 29.216062300000001],
              [-7.820549, 29.2193021],
              [-7.8192693, 29.221709600000001],
              [-7.8178883, 29.223159899999999],
              [-7.8144946, 29.225856799999999],
              [-7.8082376, 29.230821299999999],
              [-7.80403, 29.233872300000002],
              [-7.7975455, 29.238211199999999],
              [-7.7924969, 29.2424812],
              [-7.7858987, 29.246797300000001],
              [-7.7802537, 29.2505688],
              [-7.7732321, 29.255103800000001],
              [-7.7684017, 29.257728700000001],
              [-7.7626865, 29.260213400000001],
              [-7.756415, 29.264272099999999],
              [-7.7492579, 29.2676412],
              [-7.7447738, 29.270389000000002],
              [-7.7393261, 29.272314300000001],
              [-7.7322714, 29.2758173],
              [-7.7280566, 29.277362700000001],
              [-7.7219152, 29.278774200000001],
              [-7.715014, 29.2810098],
              [-7.7085374, 29.282457000000001],
              [-7.7021066, 29.284749900000001],
              [-7.6951138, 29.287602199999998],
              [-7.689056, 29.290212499999999],
              [-7.6832376, 29.292310700000002],
              [-7.6774057, 29.293325100000001],
              [-7.6719727, 29.294537999999999],
              [-7.6646226, 29.295704700000002],
              [-7.6564166, 29.2957207],
              [-7.652199, 29.295136400000001],
              [-7.6482439, 29.295531400000002],
              [-7.6434445, 29.2955042],
              [-7.639339, 29.297047200000002],
              [-7.6372862, 29.2980421],
              [-7.6344467, 29.300976899999998],
              [-7.6329947, 29.304332200000001],
              [-7.6301337, 29.311792100000002],
              [-7.6279177, 29.317700899999998],
              [-7.624469, 29.324085400000001],
              [-7.6217966, 29.328519499999999],
              [-7.6198003, 29.3336012],
              [-7.6180731, 29.338584600000001],
              [-7.6175999, 29.343378300000001],
              [-7.6169157, 29.349459800000002],
              [-7.618307, 29.355929499999998],
              [-7.6169, 29.360145599999999],
              [-7.6136128, 29.365390399999999],
              [-7.6102231, 29.367440899999998],
              [-7.6048292, 29.369411899999999],
              [-7.5973607, 29.371830800000001],
              [-7.5912036, 29.372862099999999],
              [-7.5859709, 29.372762699999999],
              [-7.5798982, 29.372230999999999],
              [-7.5729371, 29.371240799999999],
              [-7.5671602, 29.369499000000001],
              [-7.5599624, 29.367811700000001],
              [-7.5528822, 29.366422],
              [-7.5461314, 29.3648913],
              [-7.5388871, 29.3618734],
              [-7.5306847, 29.360651699999998],
              [-7.5239794, 29.360197500000002],
              [-7.5166149, 29.359924199999998],
              [-7.5100474, 29.360062299999999],
              [-7.5028501, 29.360391199999999],
              [-7.4951842, 29.3600943],
              [-7.488727, 29.360130300000002],
              [-7.4852183, 29.360671499999999],
              [-7.4800117, 29.3614237],
              [-7.4732928, 29.362078499999999],
              [-7.467009, 29.363321500000001],
              [-7.4608364, 29.364411100000002],
              [-7.45276, 29.3664813],
              [-7.4450428, 29.368434700000002],
              [-7.4402581, 29.369693999999999],
              [-7.4337008, 29.372546100000001],
              [-7.4273103, 29.373755500000001],
              [-7.4189231, 29.376214699999998],
              [-7.4127081, 29.377673999999999],
              [-7.4065238, 29.377674299999999],
              [-7.4031484, 29.377500099999999],
              [-7.398132, 29.376249399999999],
              [-7.3929782, 29.375976099999999],
              [-7.3884084, 29.377483300000002],
              [-7.382812, 29.379509299999999],
              [-7.3775597, 29.381546499999999],
              [-7.374501, 29.382492500000001],
              [-7.3712708, 29.383335200000001],
              [-7.3645597, 29.3850649],
              [-7.3581759, 29.386626400000001],
              [-7.3508895, 29.388596199999999],
              [-7.3451886, 29.392088600000001],
              [-7.3405024, 29.3958233],
              [-7.3346186, 29.401222700000002],
              [-7.330227, 29.406136],
              [-7.325406, 29.410734999999999],
              [-7.320544, 29.415293999999999],
              [-7.315499, 29.419703999999999],
              [-7.310491, 29.424142],
              [-7.305603, 29.428685999999999],
              [-7.300722, 29.433232],
              [-7.295874, 29.437808],
              [-7.290875, 29.442257000000001],
              [-7.286023, 29.446829000000001],
              [-7.2837169, 29.449288599999999],
              [-7.2815, 29.451653],
              [-7.276878, 29.456403000000002],
              [-7.272029, 29.460981],
              [-7.267155, 29.465536],
              [-7.262114, 29.469950999999998],
              [-7.256894, 29.474204],
              [-7.251404, 29.478182],
              [-7.24567, 29.481898000000001],
              [-7.239821, 29.485465000000001],
              [-7.233678, 29.48864],
              [-7.227331, 29.491488],
              [-7.220785, 29.493969],
              [-7.214163, 29.496296000000001],
              [-7.207401, 29.498282],
              [-7.200667, 29.500344999999999],
              [-7.193953, 29.502455999999999],
              [-7.187451, 29.505025],
              [-7.181187, 29.508019999999998],
              [-7.174935, 29.511028],
              [-7.168734, 29.514118],
              [-7.162609, 29.517323999999999],
              [-7.156506, 29.520606000000001],
              [-7.149831, 29.522714000000001],
              [-7.142811, 29.522068999999998],
              [-7.135857, 29.520502],
              [-7.128749, 29.520520999999999],
              [-7.121919, 29.522310000000001],
              [-7.114881, 29.523349],
              [-7.107806, 29.522559999999999],
              [-7.100938, 29.520858],
              [-7.094219, 29.518744999999999],
              [-7.08747, 29.516694999999999],
              [-7.080967, 29.514286999999999],
              [-7.075218, 29.510552000000001],
              [-7.069257, 29.507114999999999],
              [-7.063194, 29.503795],
              [-7.056558, 29.501601999999998],
              [-7.049561, 29.500335],
              [-7.042605, 29.498896999999999],
              [-7.035469, 29.498785999999999],
              [-7.028811, 29.496607999999998],
              [-7.022626, 29.493584999999999],
              [-7.015734, 29.495252000000001],
              [-7.009803, 29.498773],
              [-7.003493, 29.501626999999999],
              [-6.996506, 29.502941],
              [-6.989397, 29.503928999999999],
              [-6.982449, 29.503336999999998],
              [-6.977363, 29.499054999999998],
              [-6.9718, 29.495179],
              [-6.96478, 29.494418],
              [-6.957831, 29.495813999999999],
              [-6.950954, 29.497510999999999],
              [-6.943824, 29.497363],
              [-6.936769, 29.496504000000002],
              [-6.929668, 29.497246000000001],
              [-6.922542, 29.497664],
              [-6.915404, 29.497439],
              [-6.908631, 29.495615999999998],
              [-6.902598, 29.492246000000002],
              [-6.896942, 29.488420000000001],
              [-6.890948, 29.484998000000001],
              [-6.884082, 29.483488000000001],
              [-6.876937, 29.483243999999999],
              [-6.86985, 29.483965000000001],
              [-6.862761, 29.484756000000001],
              [-6.856058, 29.482707000000001],
              [-6.851287, 29.478025899999999],
              [-6.846479, 29.473417900000001],
              [-6.840571, 29.4699499],
              [-6.833752, 29.468079899999999],
              [-6.82672, 29.4669679],
              [-6.819701, 29.4658309],
              [-6.812709, 29.464543899999999],
              [-6.805628, 29.463829],
              [-6.798489, 29.463905],
              [-6.791347, 29.463771999999999],
              [-6.784371, 29.462465000000002],
              [-6.77725, 29.462101000000001],
              [-6.770137, 29.462629],
              [-6.763188, 29.464003900000002],
              [-6.756979, 29.467086900000002],
              [-6.751534, 29.4711429],
              [-6.746554, 29.475615900000001],
              [-6.742349, 29.480662899999999],
              [-6.738343, 29.485836899999999],
              [-6.733987, 29.490787900000001],
              [-6.729287, 29.495494900000001],
              [-6.725049, 29.500513900000001],
              [-6.720969, 29.505694900000002],
              [-6.714663, 29.508500900000001],
              [-6.707915, 29.510556900000001],
              [-6.70129, 29.512896900000001],
              [-6.694669, 29.5152389],
              [-6.687803, 29.5169669],
              [-6.680759, 29.517983900000001],
              [-6.673616, 29.518277900000001],
              [-6.666485, 29.517987900000001],
              [-6.659331, 29.517672900000001],
              [-6.652555, 29.519467899999999],
              [-6.645593, 29.520272899999998],
              [-6.638447, 29.5198109],
              [-6.631289, 29.519715900000001],
              [-6.624114, 29.521201900000001],
              [-6.624115, 29.527011900000002],
              [-6.61874, 29.529753899999999],
              [-6.611472, 29.528557899999999],
              [-6.604455, 29.527367900000002],
              [-6.597596, 29.526589600000001],
              [-6.5900772, 29.525894999999998],
              [-6.5839832, 29.524177300000002],
              [-6.576001, 29.522982299999999],
              [-6.5646713, 29.522982299999999],
              [-6.5594013, 29.5238263],
              [-6.5477283, 29.525394599999998],
              [-6.5456414, 29.524270099999999],
              [-6.5450406, 29.521973500000001],
              [-6.5466392, 29.5178096],
              [-6.5457702, 29.5165772],
              [-6.5447188, 29.515998400000001],
              [-6.5410679, 29.515345400000001],
              [-6.5363043, 29.517119300000001],
              [-6.5276439, 29.521511],
              [-6.5229576, 29.524774799999999],
              [-6.5212495, 29.5265336],
              [-6.5182584, 29.529591799999999],
              [-6.5133477, 29.532877200000001],
              [-6.5118456, 29.53368],
              [-6.5113576, 29.534748499999999],
              [-6.5120656, 29.536522099999999],
              [-6.5112018, 29.538403299999999],
              [-6.5095496, 29.539710100000001],
              [-6.5055855, 29.540927799999999],
              [-6.4950626, 29.5465804],
              [-6.4874237, 29.551583099999998],
              [-6.4773472, 29.555413399999999],
              [-6.4681976, 29.560169200000001],
              [-6.4585502, 29.563177899999999],
              [-6.4485081, 29.565641599999999],
              [-6.4385427, 29.5871645],
              [-6.4277753, 29.609978999999999],
              [-6.4205183, 29.628289599999999],
              [-6.4054593, 29.646850400000002],
              [-6.4060129, 29.662987699999999],
              [-6.4218058, 29.680654799999999],
              [-6.4249429, 29.699172399999998],
              [-6.4127077, 29.723925699999999],
              [-6.3902673, 29.745155400000002],
              [-6.3973912, 29.764454700000002],
              [-6.4039144, 29.790603399999998],
              [-6.4031419, 29.806095599999999],
              [-6.4062318, 29.827765899999999],
              [-6.4064034, 29.848240400000002],
              [-6.4140424, 29.872729799999998],
              [-6.4169134, 29.8904529],
              [-6.4209947, 29.9024216],
              [-6.4194497, 29.921839299999998],
              [-6.422368, 29.945120599999999],
              [-6.4299211, 29.958655499999999],
              [-6.4295777, 29.964604399999999],
              [-6.431762, 29.972976599999999],
              [-6.4004858, 29.999484800000001],
              [-6.3570844, 30.011821600000001],
              [-6.3536511, 30.030757300000001],
              [-6.3652898, 30.039384099999999],
              [-6.3878547, 30.039770399999998],
              [-6.3979709, 30.046570200000001],
              [-6.479127, 30.026008699999998],
              [-6.5078342, 30.075095999999998],
              [-6.4883077, 30.128344500000001],
              [-6.5027794, 30.1486409],
              [-6.5896915, 30.229285000000001],
              [-6.5570072, 30.259366799999999],
              [-6.5107445, 30.290654799999999],
              [-6.5122207, 30.315909300000001],
              [-6.5486, 30.341859899999999],
              [-6.57577, 30.374279900000001],
              [-6.614193, 30.4135901],
              [-6.6382294, 30.415504899999998],
              [-6.6441002, 30.427273199999998],
              [-6.6390877, 30.439050999999999],
              [-6.6422053, 30.441265300000001],
              [-6.6488896, 30.449832300000001],
              [-6.6573353, 30.461618600000001],
              [-6.6711884, 30.461019199999999],
              [-6.6893716, 30.4474904],
              [-6.7347031, 30.4366311],
              [-6.7802792, 30.429001199999998],
              [-6.8109293, 30.423287599999998],
              [-6.8325108, 30.4603337],
              [-6.8612405, 30.457381600000001],
              [-6.8547281, 30.438224600000002],
              [-6.8607148, 30.401287400000001],
              [-6.8557495, 30.372867100000001],
              [-6.8561629, 30.361036800000001],
              [-6.8827232, 30.331827000000001],
              [-6.8986405, 30.347560699999999],
              [-6.9245485, 30.355189599999999],
              [-7.0224642, 30.334641999999999],
              [-7.0497497, 30.345423700000001],
              [-7.0885195, 30.3539268],
              [-7.1207403, 30.361932899999999],
              [-7.1596989, 30.376617700000001],
              [-7.1706852, 30.370249399999999],
              [-7.1795343, 30.351341900000001],
              [-7.1810559, 30.349894599999999],
              [-7.1935506, 30.338009],
              [-7.2317966, 30.2948421],
              [-7.2477611, 30.271494700000002],
              [-7.2624468, 30.251493100000001],
              [-7.2790378, 30.228951200000001],
              [-7.2954573, 30.2132504],
              [-7.2942299, 30.1933483],
              [-7.2960752, 30.177997900000001],
              [-7.2984871, 30.164500400000001],
              [-7.2953285, 30.153858400000001],
              [-7.2924789, 30.147772499999999],
              [-7.2946419, 30.136928300000001],
              [-7.2881531, 30.122007100000001],
              [-7.2961525, 30.112971699999999],
              [-7.3015169, 30.103445399999998],
              [-7.3203138, 30.114798199999999],
              [-7.3314332, 30.126858599999998],
              [-7.3532832, 30.158781900000001],
              [-7.3692949, 30.1893119],
              [-7.4224627, 30.1869452],
              [-7.4304922, 30.192412999999998],
              [-7.4701846, 30.213123800000002],
              [-7.4904535, 30.197913799999998],
              [-7.5099199, 30.2056693],
              [-7.5150944, 30.209378099999999],
              [-7.5377634, 30.2256246],
              [-7.5570067, 30.2168101],
              [-7.5723232, 30.210349799999999],
              [-7.5905665, 30.2072419],
              [-7.5910787, 30.207468500000001],
              [-7.5924071, 30.208056200000001],
              [-7.6049861, 30.213620800000001],
              [-7.6258816, 30.212204199999999],
              [-7.6265296, 30.217336700000001],
              [-7.6277675, 30.222642],
              [-7.628847, 30.227267399999999],
              [-7.6247272, 30.2279348],
              [-7.6066555, 30.231932],
              [-7.5961455, 30.236833600000001],
              [-7.5889744, 30.2410529],
              [-7.5818504, 30.247503699999999],
              [-7.5839962, 30.255881800000001],
              [-7.5812496, 30.260700799999999],
              [-7.5778876, 30.266508399999999],
              [-7.5777306, 30.266779700000001],
              [-7.5779022, 30.274266600000001],
              [-7.5795802, 30.281612200000001],
              [-7.58602, 30.291209899999998],
              [-7.608356, 30.3166081],
              [-7.6237172, 30.385557899999998],
              [-7.625303, 30.392672999999998],
              [-7.6265045, 30.3980633],
              [-7.6281569, 30.405476400000001],
              [-7.6367433, 30.396711799999999],
              [-7.6456912, 30.392054999999999],
              [-7.6542528, 30.387212900000002],
              [-7.6643894, 30.383899599999999],
              [-7.6740477, 30.380432899999999],
              [-7.6792939, 30.378549799999998],
              [-7.6833729, 30.391167299999999],
              [-7.6853536, 30.395386500000001],
              [-7.6872955, 30.401192600000002],
              [-7.6876938, 30.4023833],
              [-7.6878288, 30.402787],
              [-7.6882132, 30.4039362],
              [-7.6884993, 30.404792100000002],
              [-7.6887185, 30.4053395],
              [-7.689765, 30.4079537],
              [-7.6909883, 30.411010099999999],
              [-7.6925333, 30.424170199999999],
              [-7.6903274, 30.431837600000001],
              [-7.6902924, 30.431996699999999],
              [-7.6897399, 30.434502200000001],
              [-7.6863878, 30.4497009],
              [-7.6834696, 30.451819499999999],
              [-7.6776975, 30.4560198],
              [-7.6730704, 30.456894900000002],
              [-7.6689634, 30.4576718],
              [-7.6654229, 30.4583415],
              [-7.6547964, 30.463692600000002],
              [-7.6331351, 30.474599600000001],
              [-7.6252221, 30.477044899999999],
              [-7.6214668, 30.478205299999999],
              [-7.6114576, 30.481297999999999],
              [-7.6037111, 30.489691000000001],
              [-7.5958747, 30.498180699999999],
              [-7.594057, 30.500149799999999],
              [-7.5827503, 30.512397400000001],
              [-7.5708039, 30.525336200000002],
              [-7.568725, 30.525540599999999],
              [-7.5561274, 30.526779099999999],
              [-7.5443681, 30.527934999999999],
              [-7.5296418, 30.535829499999998],
              [-7.5175536, 30.542309100000001],
              [-7.5157864, 30.543256400000001],
              [-7.51811, 30.544248700000001],
              [-7.5225113, 30.546128299999999],
              [-7.5275667, 30.555471199999999],
              [-7.5354622, 30.564251899999999],
              [-7.5411957, 30.572632299999999],
              [-7.5550145, 30.5976845],
              [-7.5697173, 30.643767199999999],
              [-7.5568427, 30.651084900000001],
              [-7.5588211, 30.6820813],
              [-7.5597892, 30.6852549],
              [-7.5656335, 30.704411499999999],
              [-7.5657562, 30.7048138],
              [-7.56961, 30.721424299999999],
              [-7.5707386, 30.7294223],
              [-7.5805581, 30.730413800000001],
              [-7.5985631, 30.732231500000001],
              [-7.6173619, 30.734129299999999],
              [-7.6737802, 30.7373887],
              [-7.7098463, 30.740905600000001],
              [-7.7074811, 30.743236799999998],
              [-7.6967553, 30.753806900000001],
              [-7.6862839, 30.7537995],
              [-7.6819924, 30.760636900000002],
              [-7.6830224, 30.767237699999999],
              [-7.6764992, 30.765150599999998],
              [-7.6706627, 30.7727504],
              [-7.6678303, 30.791155700000001],
              [-7.6795033, 30.817625499999998],
              [-7.671276, 30.8330603],
              [-7.6686028, 30.838074599999999],
              [-7.6676793, 30.8386648],
              [-7.6301506, 30.8626416],
              [-7.650235, 30.861447999999999],
              [-7.6671437, 30.860140300000001],
              [-7.708885, 30.851123699999999],
              [-7.73369, 30.861022899999998],
              [-7.735795, 30.887887800000001],
              [-7.7318458, 30.9047904],
              [-7.7316473, 30.905639399999998],
              [-7.7301881, 30.939853800000002],
              [-7.7277677, 30.952233700000001],
              [-7.7240494, 30.959731900000001],
              [-7.7203759, 30.990540500000002],
              [-7.7207859, 31.0198654],
              [-7.7144364, 31.0379942],
              [-7.7303322, 31.056017600000001],
              [-7.7271995, 31.066200800000001],
              [-7.7386837, 31.078955700000002],
              [-7.7456102, 31.090356499999999],
              [-7.7512407, 31.099477499999999],
              [-7.7523822, 31.105320200000001],
              [-7.7500219, 31.112110399999999],
              [-7.74864, 31.1176657],
              [-7.746314, 31.122236000000001],
              [-7.7440052, 31.1271956],
              [-7.7401943, 31.1301785],
              [-7.7327371, 31.1336507],
              [-7.731793, 31.134627900000002],
              [-7.7349773, 31.135423100000001],
              [-7.7397838, 31.136798800000001],
              [-7.7587009, 31.142172599999999],
              [-7.7698246, 31.142896199999999],
              [-7.7834716, 31.1625817],
              [-7.7932563, 31.154281999999998],
              [-7.824935, 31.166585999999999],
              [-7.8096842, 31.145346100000001],
              [-7.886949, 31.106757699999999],
              [-7.9031195, 31.0802613],
              [-7.9095, 31.058800000000002],
              [-7.9163491, 31.036832799999999],
              [-7.9244781, 31.033305800000001],
              [-7.9271142, 31.0319307],
              [-7.92827, 31.007909999999999],
              [-7.93764, 30.982230000000001],
              [-7.94699, 30.959779999999999],
              [-7.9602095, 30.940949700000001],
              [-7.96334, 30.936489999999999],
              [-7.97854, 30.92239],
              [-8.014562099999999, 30.908904199999998],
              [-8.031033900000001, 30.914833600000001],
              [-8.059889999999999, 30.925219999999999],
              [-8.10782, 30.928080000000001],
              [-8.1362357, 30.938772199999999],
              [-8.1524374, 30.934101399999999],
              [-8.164086599999999, 30.930743100000001],
              [-8.194291399999999, 30.9220346],
              [-8.2215943, 30.914162000000001],
              [-8.2336314, 30.9038392],
              [-8.2415726, 30.897028299999999],
              [-8.25028, 30.889559999999999],
              [-8.256649599999999, 30.888069900000001],
              [-8.2671154, 30.8856216],
              [-8.2803729, 30.882520299999999],
              [-8.2817659, 30.882194399999999],
              [-8.2934716, 30.880612200000002],
              [-8.307188699999999, 30.8787582],
              [-8.315921100000001, 30.877578],
              [-8.3224438, 30.8766964],
              [-8.326433400000001, 30.875778700000001],
              [-8.330641, 30.873798499999999],
              [-8.334039600000001, 30.872198900000001],
              [-8.349038500000001, 30.864846400000001],
              [-8.3520048, 30.8645575],
              [-8.364960099999999, 30.8632955],
              [-8.37912, 30.863659999999999],
              [-8.386971300000001, 30.863041299999999],
              [-8.396614400000001, 30.864584799999999],
              [-8.405279, 30.870972299999998],
              [-8.414111, 30.8722174],
              [-8.447509999999999, 30.86429],
              [-8.4841332, 30.871409700000001],
              [-8.5084605, 30.8701303],
              [-8.509208599999999, 30.870090999999999],
              [-8.538369599999999, 30.873885000000001],
              [-8.538707499999999, 30.873978900000001],
              [-8.5684533, 30.882242300000001],
              [-8.58911, 30.880230000000001],
              [-8.601452999999999, 30.880533499999999],
              [-8.6042919, 30.880603300000001],
              [-8.6219644, 30.8846399],
              [-8.646501499999999, 30.8884455],
              [-8.661431800000001, 30.9014746],
              [-8.669450299999999, 30.897879400000001],
              [-8.6903264, 30.879720800000001],
              [-8.706099999999999, 30.865998399999999],
              [-8.713636899999999, 30.862572400000001],
              [-8.7275191, 30.8562619],
              [-8.7247725, 30.838650300000001],
              [-8.726832399999999, 30.830174899999999],
              [-8.725373299999999, 30.8194138],
              [-8.7282057, 30.8140328],
              [-8.741766999999999, 30.810715600000002],
              [-8.742325299999999, 30.811250000000001],
              [-8.7428717, 30.811772900000001],
              [-8.7440201, 30.812871999999999],
              [-8.7477751, 30.816465399999998],
              [-8.7602206, 30.8379133],
              [-8.7629672, 30.8533145],
              [-8.764512099999999, 30.872838900000001],
              [-8.7612033, 30.8886875],
              [-8.7699645, 30.898765099999999],
              [-8.7718463, 30.912770299999998],
              [-8.775193700000001, 30.917777600000001],
              [-8.787896699999999, 30.9266501],
              [-8.7821031, 30.937399200000002],
              [-8.7913847, 30.941843299999999],
              [-8.797487200000001, 30.949138399999999],
              [-8.8003625, 30.958618900000001],
              [-8.805761199999999, 30.963491399999999],
              [-8.810953899999999, 30.967601800000001],
              [-8.8191551, 30.973474599999999],
              [-8.8262018, 30.974927999999998],
              [-8.830522500000001, 30.972252699999999],
              [-8.8415088, 30.968271300000001],
              [-8.852177599999999, 30.9605839],
              [-8.865499399999999, 30.9494428],
              [-8.8780961, 30.940656799999999],
              [-8.8963454, 30.928404100000002],
              [-8.9164195, 30.914924200000002],
              [-8.954813700000001, 30.904358599999998],
              [-8.9860925, 30.8900741],
              [-8.9918668, 30.873612399999999],
              [-8.9963321, 30.846489299999998],
              [-8.996723899999999, 30.8314515],
              [-8.996836500000001, 30.827130499999999],
              [-8.996881399999999, 30.8254044],
              [-9.0014842, 30.824243200000002],
              [-9.021240199999999, 30.819258999999999],
              [-9.0400071, 30.822192699999999],
              [-9.0780344, 30.826467600000001],
              [-9.103925200000001, 30.826854600000001],
              [-9.1328716, 30.827893799999998],
              [-9.151589400000001, 30.821887799999999],
              [-9.168386399999999, 30.8276848],
              [-9.19173, 30.817515799999999],
              [-9.214834400000001, 30.8111508],
              [-9.241834600000001, 30.8035937],
              [-9.246499999999999, 30.80236],
              [-9.2511785, 30.8089756],
              [-9.258881799999999, 30.8193582],
              [-9.2708335, 30.830651199999998],
              [-9.272185500000001, 30.831928699999999],
              [-9.2966473, 30.843941099999999],
              [-9.335185299999999, 30.888193699999999],
              [-9.3404419, 30.881455800000001],
              [-9.3459141, 30.874440799999999],
              [-9.345993099999999, 30.866624600000002],
              [-9.346109, 30.855135300000001],
              [-9.3486669, 30.855840300000001],
              [-9.3560052, 30.857862900000001],
              [-9.3669642, 30.860883399999999],
              [-9.375208300000001, 30.863155599999999],
              [-9.385482, 30.865987100000002],
              [-9.406351900000001, 30.861496500000001],
              [-9.4096864, 30.854264700000002],
              [-9.414235400000001, 30.8492468],
              [-9.4280542, 30.843823199999999],
              [-9.4431604, 30.838473100000002],
              [-9.451074, 30.842150400000001],
              [-9.4658067, 30.848156199999998],
              [-9.4731539, 30.850470000000001],
              [-9.4809731, 30.854364199999999],
              [-9.492989400000001, 30.857484700000001],
              [-9.5121553, 30.869398100000002],
              [-9.522708099999999, 30.862469000000001],
              [-9.5333255, 30.863846800000001],
              [-9.5387843, 30.8675526],
              [-9.534046500000001, 30.8821683],
              [-9.54302, 30.8845144],
              [-9.549817900000001, 30.8729969],
              [-9.5584696, 30.8616733],
              [-9.560382799999999, 30.857830400000001],
              [-9.5632926, 30.851985599999999],
              [-9.566651, 30.845239200000002],
              [-9.5696379, 30.841436699999999],
              [-9.575766099999999, 30.840054899999998],
              [-9.581190700000001, 30.84308],
              [-9.5878461, 30.852451200000001],
              [-9.593102200000001, 30.857724099999999],
              [-9.6044448, 30.856722099999999],
              [-9.608766299999999, 30.848141500000001],
              [-9.6185382, 30.835834699999999],
              [-9.6260011, 30.823062100000001],
              [-9.627173600000001, 30.820608100000001],
              [-9.6337473, 30.806848800000001],
              [-9.638643999999999, 30.799941],
              [-9.648132499999999, 30.8014966],
              [-9.6541193, 30.807003600000002],
              [-9.6602175, 30.804084199999998],
              [-9.669289900000001, 30.798248999999998],
              [-9.6775339, 30.792774600000001],
              [-9.6825574, 30.790023600000001],
              [-9.6881, 30.78595],
              [-9.697668800000001, 30.7889926],
              [-9.708410499999999, 30.790393600000002],
              [-9.715594400000001, 30.791440600000001],
              [-9.738774100000001, 30.843605799999999],
              [-9.734195, 30.873140599999999],
              [-9.766879299999999, 30.8842824],
              [-9.768982100000001, 30.892421500000001],
              [-9.768402500000001, 30.896441899999999],
              [-9.7658407, 30.9142096],
              [-9.770990599999999, 30.927728500000001],
              [-9.77211, 30.928078200000002],
              [-9.795113300000001, 30.9352676],
              [-9.817904, 30.938130999999998],
              [-9.8177018, 30.9378329],
              [-9.817594, 30.9373507],
              [-9.817664000000001, 30.936853800000002],
              [-9.817879400000001, 30.936480199999998],
              [-9.818104999999999, 30.936325100000001],
              [-9.8184247, 30.936355899999999],
              [-9.818179000000001, 30.936062100000001],
              [-9.8186079, 30.935873699999998],
              [-9.8187651, 30.935670300000002],
              [-9.819092599999999, 30.935483900000001],
              [-9.819077500000001, 30.935234300000001],
              [-9.8186771, 30.9351916],
              [-9.818597199999999, 30.935050100000002],
              [-9.818660700000001, 30.934919900000001],
              [-9.819059899999999, 30.934944000000002],
              [-9.818999399999999, 30.933941999999998],
              [-9.8188453, 30.933503099999999],
              [-9.818587000000001, 30.933231599999999],
              [-9.818644600000001, 30.9328495],
              [-9.8182562, 30.933015000000001],
              [-9.818066099999999, 30.932500399999999],
              [-9.8187538, 30.931009800000002],
              [-9.818294399999999, 30.928798499999999],
              [-9.817017699999999, 30.928319399999999],
              [-9.8169459, 30.9266462],
              [-9.8171812, 30.926545300000001],
              [-9.817513999999999, 30.926402800000002],
              [-9.817946299999999, 30.925477900000001],
              [-9.8169813, 30.9240748],
              [-9.817932900000001, 30.9235778],
              [-9.8175376, 30.920573099999999],
              [-9.8189742, 30.919831299999998],
              [-9.8185997, 30.919264299999998],
              [-9.819201100000001, 30.919193],
              [-9.8203405, 30.917244400000001],
              [-9.819513799999999, 30.916732199999998],
              [-9.8196812, 30.9139211],
              [-9.8208726, 30.913870500000002],
              [-9.8197086, 30.913102800000001],
              [-9.8197735, 30.912087499999998],
              [-9.8202949, 30.911916300000001],
              [-9.819533699999999, 30.9109604],
              [-9.8188098, 30.910510200000001],
              [-9.8178594, 30.9088216],
              [-9.817595499999999, 30.908299700000001],
              [-9.817771499999999, 30.907714200000001],
              [-9.816993099999999, 30.9073077],
              [-9.8177951, 30.907167399999999],
              [-9.8171803, 30.9063771],
              [-9.8165891, 30.906346800000001],
              [-9.8165441, 30.9060126],
              [-9.8170156, 30.905881900000001],
              [-9.8167715, 30.905626000000002],
              [-9.8160854, 30.9057706],
              [-9.8160787, 30.904999100000001],
              [-9.8154824, 30.905002899999999],
              [-9.815776100000001, 30.9044867],
              [-9.814830199999999, 30.9045351],
              [-9.814033500000001, 30.902847600000001],
              [-9.8115427, 30.897570300000002],
              [-9.810128799999999, 30.895836800000001],
              [-9.8095848, 30.895151800000001],
              [-9.808362300000001, 30.893005299999999],
              [-9.807872, 30.8921739],
              [-9.8081262, 30.8893819],
              [-9.809384, 30.889097599999999],
              [-9.810357099999999, 30.887410899999999],
              [-9.811470399999999, 30.886747799999998],
              [-9.8113411, 30.885705000000002],
              [-9.812447799999999, 30.8850357],
              [-9.8112253, 30.883096999999999],
              [-9.8114972, 30.881318100000001],
              [-9.8117, 30.880854200000002],
              [-9.8124197, 30.8777638],
              [-9.812477299999999, 30.875545500000001],
              [-9.812647200000001, 30.875060999999999],
              [-9.812995000000001, 30.8740703],
              [-9.814022, 30.873382200000002],
              [-9.8145823, 30.872051899999999],
              [-9.815334399999999, 30.871880999999998],
              [-9.815367699999999, 30.8712239],
              [-9.81622, 30.8709621],
              [-9.816138, 30.8696698],
              [-9.816426099999999, 30.868170599999999],
              [-9.8176439, 30.867303499999998],
              [-9.816835599999999, 30.865823500000001],
              [-9.8170316, 30.8652914],
              [-9.8178491, 30.864866299999999],
              [-9.818702099999999, 30.8628094],
              [-9.8203399, 30.8589661],
              [-9.821179799999999, 30.857945600000001],
              [-9.821197400000001, 30.8565124],
              [-9.8225157, 30.8555849],
              [-9.8228671, 30.854886],
              [-9.823151599999999, 30.854859900000001],
              [-9.8238418, 30.8541092],
              [-9.824749499999999, 30.8525116],
              [-9.824438199999999, 30.851661700000001],
              [-9.825230700000001, 30.8514339],
              [-9.825256, 30.8503796],
              [-9.826526400000001, 30.849994899999999],
              [-9.827095399999999, 30.847678999999999],
              [-9.8261369, 30.8462228],
              [-9.8264896, 30.8458504],
              [-9.825108, 30.845024899999999],
              [-9.824623600000001, 30.844868900000002],
              [-9.8245325, 30.8449876],
              [-9.824191799999999, 30.844965599999998],
              [-9.8240979, 30.844861999999999],
              [-9.8238699, 30.844977100000001],
              [-9.8238162, 30.845262699999999],
              [-9.8236875, 30.8453087],
              [-9.8236258, 30.845212],
              [-9.8235346, 30.845253400000001],
              [-9.823510499999999, 30.8453433],
              [-9.8233549, 30.8453409],
              [-9.8231734, 30.845415500000001],
              [-9.8229273, 30.845409100000001],
              [-9.822680200000001, 30.845204200000001],
              [-9.822312699999999, 30.8448037],
              [-9.8220607, 30.844469799999999],
              [-9.8218567, 30.8439856],
              [-9.8215451, 30.8431541],
              [-9.8213287, 30.841947600000001],
              [-9.82147, 30.841084299999999],
              [-9.821566799999999, 30.840397899999999],
              [-9.8220309, 30.840038700000001],
              [-9.822358299999999, 30.8400021],
              [-9.822782399999999, 30.839967300000001],
              [-9.822808800000001, 30.839794000000001],
              [-9.8227438, 30.839643899999999],
              [-9.8228843, 30.839289999999998],
              [-9.8226987, 30.838979299999998],
              [-9.8226154, 30.838516800000001],
              [-9.8225789, 30.8380352],
              [-9.822790299999999, 30.837811899999998],
              [-9.822661099999999, 30.837514800000001],
              [-9.822422299999999, 30.837231299999999],
              [-9.822412099999999, 30.837617900000001],
              [-9.8222164, 30.837662300000002],
              [-9.8222269, 30.837836500000002],
              [-9.822350500000001, 30.837969999999999],
              [-9.822039, 30.838023700000001],
              [-9.822157000000001, 30.837927100000002],
              [-9.8220676, 30.837817000000001],
              [-9.8219745, 30.837869399999999],
              [-9.821979900000001, 30.8379774],
              [-9.821867299999999, 30.838000399999999],
              [-9.8217564, 30.837540700000002],
              [-9.821443800000001, 30.837385600000001],
              [-9.821349700000001, 30.837454699999999],
              [-9.8213902, 30.8375281],
              [-9.821588800000001, 30.837615400000001],
              [-9.8217471, 30.838137100000001],
              [-9.821629400000001, 30.838064599999999],
              [-9.8214918, 30.838274699999999],
              [-9.821497300000001, 30.838384600000001],
              [-9.8211434, 30.838400700000001],
              [-9.820805399999999, 30.838700200000002],
              [-9.8204042, 30.839049299999999],
              [-9.819945000000001, 30.839456299999998],
              [-9.819885899999999, 30.8397881],
              [-9.8196949, 30.8399605],
              [-9.819399600000001, 30.840022999999999],
              [-9.8191563, 30.840117100000001],
              [-9.818935400000001, 30.840103299999999],
              [-9.8187271, 30.840003599999999],
              [-9.8181531, 30.840114700000001],
              [-9.8172301, 30.840874599999999],
              [-9.816379700000001, 30.8413237],
              [-9.8155801, 30.841533500000001],
              [-9.813542399999999, 30.8407406],
              [-9.8126354, 30.840561300000001],
              [-9.8111417, 30.8397021],
              [-9.810323199999999, 30.8390165],
              [-9.8101229, 30.838721799999998],
              [-9.810071600000001, 30.838258799999998],
              [-9.8092764, 30.8373493],
              [-9.807387, 30.835143800000001],
              [-9.8068674, 30.8340402],
              [-9.8066388, 30.833494999999999],
              [-9.806502200000001, 30.832608100000002],
              [-9.8066365, 30.831891800000001],
              [-9.806592999999999, 30.831225799999999],
              [-9.806820099999999, 30.830392400000001],
              [-9.8061626, 30.829173900000001],
              [-9.8052686, 30.8285138],
              [-9.8051934, 30.827969100000001],
              [-9.805198600000001, 30.8267992],
              [-9.8054966, 30.826096700000001],
              [-9.805888100000001, 30.825954400000001],
              [-9.805536699999999, 30.825810799999999],
              [-9.8048181, 30.824322899999999],
              [-9.806051800000001, 30.823304100000001],
              [-9.8056166, 30.822012999999998],
              [-9.805689599999999, 30.821353800000001],
              [-9.8055039, 30.821059000000002],
              [-9.8055717, 30.820610800000001],
              [-9.8053197, 30.820163300000001],
              [-9.805217000000001, 30.819610900000001],
              [-9.8051017, 30.8193944],
              [-9.8052736, 30.819026099999999],
              [-9.805171, 30.818707499999999],
              [-9.805225200000001, 30.818389],
              [-9.805155299999999, 30.817508],
              [-9.805530900000001, 30.8168027],
              [-9.805483300000001, 30.8166391],
              [-9.806081000000001, 30.815057400000001],
              [-9.806750299999999, 30.8131366],
              [-9.806815200000001, 30.812463999999999],
              [-9.807577200000001, 30.8117789],
              [-9.807981399999999, 30.810673900000001],
              [-9.8075989, 30.8098116],
              [-9.8089019, 30.806291999999999],
              [-9.809854700000001, 30.805026099999999],
              [-9.8106518, 30.804661200000002],
              [-9.8112253, 30.803634599999999],
              [-9.8113443, 30.8031954],
              [-9.8116871, 30.802667499999998],
              [-9.812415100000001, 30.8021989],
              [-9.8128619, 30.802269899999999],
              [-9.8131188, 30.801641199999999],
              [-9.813425199999999, 30.801104599999999],
              [-9.8125959, 30.8005277],
              [-9.814055, 30.797655599999999],
              [-9.814830199999999, 30.7977639],
              [-9.8149616, 30.795079300000001],
              [-9.8152759, 30.794374699999999],
              [-9.8158537, 30.792758599999999],
              [-9.8150978, 30.7919342],
              [-9.8153258, 30.791307],
              [-9.8160484, 30.790086800000001],
              [-9.8164035, 30.789300099999998],
              [-9.8175252, 30.7876881],
              [-9.819230599999999, 30.785419399999999],
              [-9.8202005, 30.784192999999998],
              [-9.8218967, 30.782235700000001],
              [-9.8227856, 30.782206200000001],
              [-9.8228124, 30.7813062],
              [-9.823495299999999, 30.781300600000002],
              [-9.823569900000001, 30.7800498],
              [-9.8242061, 30.779297199999998],
              [-9.824909399999999, 30.778681899999999],
              [-9.8261684, 30.777862899999999],
              [-9.826859300000001, 30.777708499999999],
              [-9.8276951, 30.777253600000002],
              [-9.8288946, 30.777366600000001],
              [-9.8285722, 30.776672900000001],
              [-9.828042099999999, 30.776382099999999],
              [-9.8284821, 30.776139700000002],
              [-9.8279478, 30.775661700000001],
              [-9.828518000000001, 30.7754981],
              [-9.8279944, 30.7734807],
              [-9.8285362, 30.773356700000001],
              [-9.828066400000001, 30.773002300000002],
              [-9.8279955, 30.772047300000001],
              [-9.8281285, 30.771656],
              [-9.8285556, 30.771026299999999],
              [-9.828752400000001, 30.769939000000001],
              [-9.828931300000001, 30.769653600000002],
              [-9.8290702, 30.7685709],
              [-9.8292603, 30.7674062],
              [-9.8296627, 30.766998699999998],
              [-9.8294991, 30.766741100000001],
              [-9.8293543, 30.766359399999999],
              [-9.8294104, 30.766035599999999],
              [-9.8296188, 30.7648355],
              [-9.829782700000001, 30.7647622],
              [-9.8302599, 30.764567],
              [-9.8302642, 30.764332899999999],
              [-9.830781699999999, 30.763704199999999],
              [-9.830727, 30.763285700000001],
              [-9.830489500000001, 30.763060599999999],
              [-9.8301891, 30.763042200000001],
              [-9.829802900000001, 30.762673400000001],
              [-9.829805, 30.762306599999999],
              [-9.8298994, 30.7620465],
              [-9.830114, 30.762065],
              [-9.8305775, 30.762002299999999],
              [-9.830897200000001, 30.761437999999998],
              [-9.831392900000001, 30.761283200000001],
              [-9.8314766, 30.760608300000001],
              [-9.831972199999999, 30.760324300000001],
              [-9.832272700000001, 30.7598634],
              [-9.832656699999999, 30.758709100000001],
              [-9.8330237, 30.757669100000001],
              [-9.8335794, 30.756902],
              [-9.834008600000001, 30.756902],
              [-9.834201699999999, 30.756514800000001],
              [-9.8341824, 30.756064899999998],
              [-9.834437700000001, 30.755592799999999],
              [-9.834845400000001, 30.755592799999999],
              [-9.8348476, 30.755161300000001],
              [-9.8351141, 30.754420700000001],
              [-9.8357353, 30.754116400000001],
              [-9.836069999999999, 30.753246499999999],
              [-9.836858100000001, 30.752006900000001],
              [-9.8376681, 30.750912],
              [-9.8383868, 30.749955499999999],
              [-9.838214000000001, 30.749257799999999],
              [-9.839256000000001, 30.748146299999998],
              [-9.839926999999999, 30.747109900000002],
              [-9.8403676, 30.746434000000001],
              [-9.841156700000001, 30.746324099999999],
              [-9.8421413, 30.745049000000002],
              [-9.8431947, 30.7429427],
              [-9.8439058, 30.742113499999999],
              [-9.844477700000001, 30.741425599999999],
              [-9.8447627, 30.741006800000001],
              [-9.8459921, 30.739319900000002],
              [-9.847941499999999, 30.736871099999998],
              [-9.8484999, 30.736732799999999],
              [-9.8490374, 30.735378099999998],
              [-9.849321099999999, 30.7354837],
              [-9.849618899999999, 30.7352089],
              [-9.851091500000001, 30.733355700000001],
              [-9.8512527, 30.7328972],
              [-9.8516847, 30.7323956],
              [-9.8514815, 30.731031699999999],
              [-9.851973900000001, 30.730429000000001],
              [-9.851339400000001, 30.7298346],
              [-9.851368799999999, 30.729536199999998],
              [-9.8519027, 30.729437600000001],
              [-9.852025899999999, 30.729132400000001],
              [-9.8515292, 30.728170500000001],
              [-9.850922499999999, 30.727988799999999],
              [-9.8502847, 30.728194999999999],
              [-9.850243499999999, 30.7278555],
              [-9.8494119, 30.726879400000001],
              [-9.849178999999999, 30.725498699999999],
              [-9.8493201, 30.724510500000001],
              [-9.848968899999999, 30.723126499999999],
              [-9.848808500000001, 30.723018700000001],
              [-9.848982599999999, 30.722718499999999],
              [-9.8496393, 30.722323299999999],
              [-9.8495299, 30.721910900000001],
              [-9.8501409, 30.721715499999998],
              [-9.850175200000001, 30.721274099999999],
              [-9.850367800000001, 30.720465699999998],
              [-9.8511285, 30.719347899999999],
              [-9.851929800000001, 30.718443700000002],
              [-9.852086999999999, 30.718270100000002],
              [-9.8524847, 30.717622200000001],
              [-9.853072600000001, 30.7168727],
              [-9.8538987, 30.715961],
              [-9.854487199999999, 30.715297799999998],
              [-9.855258600000001, 30.714457500000002],
              [-9.855476400000001, 30.714226499999999],
              [-9.8557956, 30.713849199999999],
              [-9.857878100000001, 30.711561199999998],
              [-9.8599707, 30.709172599999999],
              [-9.861732399999999, 30.707583700000001],
              [-9.862018900000001, 30.707060599999998],
              [-9.8631046, 30.7065704],
              [-9.864284700000001, 30.705675599999999],
              [-9.8651672, 30.7050363],
              [-9.865489, 30.704734599999998],
              [-9.8657583, 30.704259100000002],
              [-9.8665582, 30.704052900000001],
              [-9.8669551, 30.703485100000002],
              [-9.867486299999999, 30.703332799999998],
              [-9.8677566, 30.7037531],
              [-9.868122, 30.702950399999999],
              [-9.8679551, 30.702325600000002],
              [-9.869339099999999, 30.701229600000001],
              [-9.8708846, 30.7007218],
              [-9.871496, 30.701098699999999],
              [-9.8729757, 30.700189099999999],
              [-9.8730052, 30.698712499999999],
              [-9.8736918, 30.6985806],
              [-9.8734982, 30.697170499999999],
              [-9.873915500000001, 30.695907099999999],
              [-9.8744196, 30.695293400000001],
              [-9.875445300000001, 30.693486400000001],
              [-9.8757512, 30.692776800000001],
              [-9.8764121, 30.6914482],
              [-9.8774066, 30.690553300000001],
              [-9.877821600000001, 30.688139499999998],
              [-9.8779447, 30.6868807],
              [-9.8785062, 30.6865223],
              [-9.879365699999999, 30.685311800000001],
              [-9.8795191, 30.6846429],
              [-9.880038900000001, 30.684352700000002],
              [-9.879747099999999, 30.683764499999999],
              [-9.8793709, 30.683430600000001],
              [-9.879033700000001, 30.683283299999999],
              [-9.879020799999999, 30.6828644],
              [-9.8792065, 30.6823537],
              [-9.8799891, 30.681912199999999],
              [-9.880779199999999, 30.680583500000001],
              [-9.880376399999999, 30.679843000000002],
              [-9.8803223, 30.6789752],
              [-9.8801839, 30.678737600000002],
              [-9.8805272, 30.678367600000001],
              [-9.880614100000001, 30.677119099999999],
              [-9.881172400000001, 30.676601000000002],
              [-9.881173, 30.675995199999999],
              [-9.8815705, 30.6740657],
              [-9.882724400000001, 30.672644200000001],
              [-9.88316, 30.672845800000001],
              [-9.883433, 30.672087999999999],
              [-9.8838101, 30.6709739],
              [-9.8843063, 30.670860000000001],
              [-9.883942100000001, 30.669813099999999],
              [-9.884584200000001, 30.668023300000002],
              [-9.8847983, 30.6678161],
              [-9.884611599999999, 30.666881700000001],
              [-9.8854205, 30.667225500000001],
              [-9.8851003, 30.6659349],
              [-9.885818, 30.665517300000001],
              [-9.8858937, 30.664052699999999],
              [-9.886147899999999, 30.663064299999998],
              [-9.8863754, 30.661281800000001],
              [-9.886609200000001, 30.661522699999999],
              [-9.8874917, 30.659923299999999],
              [-9.887301300000001, 30.6585103],
              [-9.886673099999999, 30.656342800000001],
              [-9.886431699999999, 30.655847600000001],
              [-9.887070100000001, 30.6553234],
              [-9.8866753, 30.6531378],
              [-9.887311499999999, 30.651880299999998],
              [-9.887730400000001, 30.6504589],
              [-9.8878635, 30.649877400000001],
              [-9.888251, 30.649989900000001],
              [-9.888385299999999, 30.649603800000001],
              [-9.8882829, 30.649456900000001],
              [-9.888296499999999, 30.649263000000001],
              [-9.888480899999999, 30.649227799999998],
              [-9.888515099999999, 30.6490045],
              [-9.8882692, 30.648605],
              [-9.888597000000001, 30.647659099999998],
              [-9.8884673, 30.647394800000001],
              [-9.8886585, 30.646789600000002],
              [-9.8890273, 30.646160999999999],
              [-9.888877000000001, 30.645831999999999],
              [-9.889157000000001, 30.6452092],
              [-9.889654999999999, 30.644436200000001],
              [-9.889595099999999, 30.6442169],
              [-9.8889624, 30.64433],
              [-9.889284, 30.6441555],
              [-9.8893004, 30.6437873],
              [-9.889061399999999, 30.642518299999999],
              [-9.889218, 30.642072500000001],
              [-9.889116, 30.641401900000002],
              [-9.8893346, 30.641290300000001],
              [-9.8894711, 30.6413902],
              [-9.890413499999999, 30.641418699999999],
              [-9.8905911, 30.640955399999999],
              [-9.8906116, 30.6406028],
              [-9.890069499999999, 30.640395900000001],
              [-9.889901399999999, 30.6402444],
              [-9.8898399, 30.6397567],
              [-9.889908200000001, 30.638898900000001],
              [-9.8901159, 30.6383543],
              [-9.890154000000001, 30.638076300000002],
              [-9.889996999999999, 30.637911800000001],
              [-9.8896692, 30.638146800000001],
              [-9.889427100000001, 30.638016199999999],
              [-9.8893141, 30.637712000000001],
              [-9.889170699999999, 30.6371714],
              [-9.889232099999999, 30.636907000000001],
              [-9.8891229, 30.636613199999999],
              [-9.889047100000001, 30.636166299999999],
              [-9.889191200000001, 30.635186999999998],
              [-9.8894302, 30.6346566],
              [-9.8892773, 30.6344992],
              [-9.889365400000001, 30.6340684],
              [-9.889191200000001, 30.633945600000001],
              [-9.889068200000001, 30.6329408],
              [-9.889232099999999, 30.632541199999999],
              [-9.889198, 30.6320829],
              [-9.889412099999999, 30.631978199999999],
              [-9.8896487, 30.631465899999998],
              [-9.8897102, 30.6311368],
              [-9.889628200000001, 30.630925300000001],
              [-9.8897479, 30.630642000000002],
              [-9.8897853, 30.630132],
              [-9.8896341, 30.6295982],
              [-9.8894024, 30.629534400000001],
              [-9.8895845, 30.629359900000001],
              [-9.889391399999999, 30.629269399999998],
              [-9.889000899999999, 30.629250899999999],
              [-9.8886048, 30.6286573],
              [-9.8886117, 30.6285281],
              [-9.8884136, 30.628240099999999],
              [-9.888181400000001, 30.628181399999999],
              [-9.8880064, 30.627870300000001],
              [-9.8878536, 30.627952199999999],
              [-9.8874712, 30.627664200000002],
              [-9.887484000000001, 30.6274625],
              [-9.887081999999999, 30.627070700000001],
              [-9.8869112, 30.6272059],
              [-9.886788299999999, 30.627053100000001],
              [-9.886788299999999, 30.626847399999999],
              [-9.886617599999999, 30.626629999999999],
              [-9.8862503, 30.6264383],
              [-9.886146399999999, 30.6266359],
              [-9.885415699999999, 30.626453699999999],
              [-9.8847191, 30.625989499999999],
              [-9.883926900000001, 30.625883699999999],
              [-9.8836469, 30.626007099999999],
              [-9.883373799999999, 30.626007099999999],
              [-9.883013500000001, 30.62593],
              [-9.8815174, 30.6249115],
              [-9.879895299999999, 30.6243914],
              [-9.879733099999999, 30.624961299999999],
              [-9.8794222, 30.624973000000001],
              [-9.8785165, 30.624775199999998],
              [-9.878246600000001, 30.624933599999999],
              [-9.8766105, 30.625135799999999],
              [-9.875799499999999, 30.624868299999999],
              [-9.875056000000001, 30.624856600000001],
              [-9.873947599999999, 30.624403000000001],
              [-9.872257899999999, 30.623798099999998],
              [-9.8711743, 30.623788999999999],
              [-9.870938300000001, 30.623477399999999],
              [-9.8699329, 30.623402599999999],
              [-9.869498999999999, 30.623728],
              [-9.868756899999999, 30.623297900000001],
              [-9.866769100000001, 30.623417],
              [-9.865823600000001, 30.6239028],
              [-9.8651477, 30.623588699999999],
              [-9.8610519, 30.623565500000002],
              [-9.8598759, 30.623891199999999],
              [-9.853401, 30.6253685],
              [-9.8523602, 30.625659299999999],
              [-9.852083800000001, 30.625493800000001],
              [-9.8484038, 30.6261464],
              [-9.846000200000001, 30.626940399999999],
              [-9.844739799999999, 30.626796200000001],
              [-9.8434934, 30.627297299999999],
              [-9.8434116, 30.627031800000001],
              [-9.8414743, 30.6279659],
              [-9.841564099999999, 30.628383400000001],
              [-9.8413053, 30.628454399999999],
              [-9.8411364, 30.628239300000001],
              [-9.8406565, 30.62828],
              [-9.839751700000001, 30.628643499999999],
              [-9.839273199999999, 30.628673800000001],
              [-9.838900900000001, 30.628314199999998],
              [-9.8389597, 30.627936399999999],
              [-9.838755300000001, 30.627950999999999],
              [-9.838998, 30.6277422],
              [-9.838925700000001, 30.627723899999999],
              [-9.838176300000001, 30.628218499999999],
              [-9.8380422, 30.628206599999999],
              [-9.8377391, 30.628259700000001],
              [-9.837776699999999, 30.628144299999999],
              [-9.8377123, 30.628098099999999],
              [-9.8372098, 30.628350399999999],
              [-9.8371517, 30.628386599999999],
              [-9.8370012, 30.628185500000001],
              [-9.8367074, 30.628013299999999],
              [-9.8365498, 30.628081000000002],
              [-9.8362047, 30.6280538],
              [-9.836187900000001, 30.6277385],
              [-9.835520799999999, 30.627982299999999],
              [-9.8357622, 30.6276689],
              [-9.835222399999999, 30.627957800000001],
              [-9.8349075, 30.628173799999999],
              [-9.834298199999999, 30.628148899999999],
              [-9.8340207, 30.627712899999999],
              [-9.8336238, 30.6277379],
              [-9.833447100000001, 30.6276957],
              [-9.8334385, 30.627594999999999],
              [-9.8332543, 30.627657899999999],
              [-9.8331407, 30.627737799999998],
              [-9.832947600000001, 30.627666300000001],
              [-9.832315299999999, 30.627696100000001],
              [-9.831494599999999, 30.6277884],
              [-9.8309468, 30.6275567],
              [-9.8302447, 30.627259800000001],
              [-9.827178999999999, 30.627254099999998],
              [-9.826370499999999, 30.626943600000001],
              [-9.8256718, 30.626675299999999],
              [-9.824557, 30.6264216],
              [-9.823823900000001, 30.625847199999999],
              [-9.8226113, 30.625777200000002],
              [-9.8208319, 30.625222300000001],
              [-9.8202531, 30.6250727],
              [-9.819436100000001, 30.624960999999999],
              [-9.8186733, 30.624333199999999],
              [-9.8172064, 30.623678999999999],
              [-9.816647700000001, 30.6234298],
              [-9.815586100000001, 30.622407299999999],
              [-9.8150078, 30.621981699999999],
              [-9.8145556, 30.621693199999999],
              [-9.814289499999999, 30.621473000000002],
              [-9.8137176, 30.621066299999999],
              [-9.812931900000001, 30.620496899999999],
              [-9.8115013, 30.6202331],
              [-9.8095073, 30.619079500000002],
              [-9.8090318, 30.619089899999999],
              [-9.8037352, 30.615191200000002],
              [-9.8018448, 30.614546300000001],
              [-9.798881400000001, 30.614473],
              [-9.7951686, 30.613549599999999],
              [-9.794368199999999, 30.6132858],
              [-9.790962, 30.611820099999999],
              [-9.790008200000001, 30.611644200000001],
              [-9.787484900000001, 30.6111188],
              [-9.786995900000001, 30.610877899999998],
              [-9.783829300000001, 30.609295199999998],
              [-9.780441100000001, 30.6046975],
              [-9.779428299999999, 30.603320199999999],
              [-9.778851599999999, 30.6026475],
              [-9.7780673, 30.6018796],
              [-9.7775754, 30.601151000000002],
              [-9.777219199999999, 30.600828700000001],
              [-9.776638200000001, 30.600218300000002],
              [-9.7763405, 30.5996448],
              [-9.775512300000001, 30.5986124],
              [-9.774795599999999, 30.5977988],
              [-9.7741513, 30.596930700000001],
              [-9.7736234, 30.5961803],
              [-9.773240400000001, 30.595802599999999],
              [-9.772555000000001, 30.595580399999999],
              [-9.7715256, 30.595184700000001],
              [-9.7709507, 30.594760099999998],
              [-9.769400600000001, 30.5943532],
              [-9.768442, 30.594151400000001],
              [-9.7671805, 30.592539800000001],
              [-9.7650559, 30.5904636],
              [-9.762412899999999, 30.588054899999999],
              [-9.7598605, 30.5855155],
              [-9.758329, 30.584101],
              [-9.7573756, 30.582773599999999],
              [-9.756796599999999, 30.582187300000001],
              [-9.7563859, 30.581771700000001],
              [-9.7553641, 30.580607700000002],
              [-9.7547807, 30.579795399999998],
              [-9.7537251, 30.5780967],
              [-9.7526417, 30.576284999999999],
              [-9.751633099999999, 30.5752615],
              [-9.7519335, 30.5748167],
              [-9.7493698, 30.574939100000002],
              [-9.749140799999999, 30.574617199999999],
              [-9.748798499999999, 30.574650900000002],
              [-9.748133299999999, 30.574244499999999],
              [-9.747586099999999, 30.573644099999999],
              [-9.747028200000001, 30.5730252],
              [-9.746373800000001, 30.571768899999999],
              [-9.745086300000001, 30.568480300000001],
              [-9.7444962, 30.564988400000001],
              [-9.7441315, 30.563353200000002],
              [-9.743959800000001, 30.5621799],
              [-9.7429524, 30.559927999999999],
              [-9.743005500000001, 30.558761199999999],
              [-9.742855799999999, 30.5583186],
              [-9.742343399999999, 30.558033600000002],
              [-9.742254000000001, 30.557723200000002],
              [-9.7411517, 30.557171100000001],
              [-9.740804199999999, 30.556486199999998],
              [-9.7405656, 30.554296099999998],
              [-9.740219099999999, 30.5536213],
              [-9.739874500000001, 30.552196800000001],
              [-9.7403516, 30.551719899999998],
              [-9.739819600000001, 30.55125],
              [-9.739411, 30.550961300000001],
              [-9.7384237, 30.5510947],
              [-9.7379274, 30.550870499999998],
              [-9.7379178, 30.550516900000002],
              [-9.737323099999999, 30.5505675],
              [-9.7366279, 30.550197099999998],
              [-9.7353611, 30.550282200000002],
              [-9.734627, 30.5502921],
              [-9.7341584, 30.550444800000001],
              [-9.733586600000001, 30.550422000000001],
              [-9.7331237, 30.549958199999999],
              [-9.732739799999999, 30.549309099999999],
              [-9.7326997, 30.5491806],
              [-9.732407800000001, 30.5490514],
              [-9.731947, 30.549148299999999],
              [-9.731468700000001, 30.548969400000001],
              [-9.730912, 30.548557200000001],
              [-9.7307854, 30.548573300000001],
              [-9.730342500000001, 30.548629600000002],
              [-9.7287812, 30.547741599999998],
              [-9.7280506, 30.5469437],
              [-9.727762500000001, 30.5466762],
              [-9.727329599999999, 30.5462715],
              [-9.726794399999999, 30.5454057],
              [-9.7271163, 30.545255099999999],
              [-9.7270922, 30.545214600000001],
              [-9.727026499999999, 30.545220400000002],
              [-9.7270305, 30.545207099999999],
              [-9.727134400000001, 30.545174800000002],
              [-9.727123000000001, 30.545136100000001],
              [-9.727088200000001, 30.545113600000001],
              [-9.7269527, 30.5451534],
              [-9.7269004, 30.5452169],
              [-9.726810499999999, 30.545214600000001],
              [-9.726684499999999, 30.545154],
              [-9.726589300000001, 30.545174800000002],
              [-9.7265906, 30.5453145],
              [-9.726436400000001, 30.545364200000002],
              [-9.726354600000001, 30.5453492],
              [-9.7250228, 30.5457748],
              [-9.7234943, 30.546034500000001],
              [-9.7224273, 30.546337600000001],
              [-9.7208296, 30.546515599999999],
              [-9.7205061, 30.546381700000001],
              [-9.720466500000001, 30.546280700000001],
              [-9.720442800000001, 30.546253],
              [-9.72048, 30.546228299999999],
              [-9.7204885, 30.5461992],
              [-9.7204479, 30.5461803],
              [-9.720348, 30.5461922],
              [-9.7203357, 30.546215100000001],
              [-9.7203277, 30.5462335],
              [-9.7203049, 30.5462335],
              [-9.720269699999999, 30.546201499999999],
              [-9.718405000000001, 30.546424200000001],
              [-9.7162846, 30.546464400000001],
              [-9.7155743, 30.5459356],
              [-9.7154294, 30.5456076],
              [-9.715302899999999, 30.545567200000001],
              [-9.7139086, 30.545612299999998],
              [-9.713667600000001, 30.5456395],
              [-9.7135081, 30.545542999999999],
              [-9.7128549, 30.5451479],
              [-9.712486500000001, 30.5449251],
              [-9.7118374, 30.544789600000001],
              [-9.711565999999999, 30.544824699999999],
              [-9.711404099999999, 30.544830300000001],
              [-9.710963400000001, 30.544711400000001],
              [-9.710413000000001, 30.544717299999999],
              [-9.7087615, 30.5434187],
              [-9.7085065, 30.5432104],
              [-9.708232000000001, 30.542934800000001],
              [-9.707665799999999, 30.542873100000001],
              [-9.707295800000001, 30.542832799999999],
              [-9.7067213, 30.5427383],
              [-9.7061549, 30.542710599999999],
              [-9.705590600000001, 30.542705999999999],
              [-9.705138699999999, 30.54271],
              [-9.7042644, 30.542368700000001],
              [-9.703728, 30.541888199999999],
              [-9.702612200000001, 30.540215700000001],
              [-9.700676, 30.536506800000002],
              [-9.7000057, 30.535561399999999],
              [-9.698869800000001, 30.533959100000001],
              [-9.6964218, 30.53069],
              [-9.6958789, 30.529984899999999],
              [-9.6938703, 30.526284799999999],
              [-9.6925799, 30.524818799999998],
              [-9.6920775, 30.523791899999999],
              [-9.691517299999999, 30.522293000000001],
              [-9.690778, 30.521062400000002],
              [-9.6906923, 30.520919599999999],
              [-9.6901189, 30.5194309],
              [-9.689859200000001, 30.5186606],
              [-9.689701700000001, 30.5179601],
              [-9.689045999999999, 30.516192100000001],
              [-9.688590400000001, 30.514750599999999],
              [-9.6884385, 30.514209900000001],
              [-9.688266799999999, 30.5135988],
              [-9.6880922, 30.512722100000001],
              [-9.6880156, 30.512315000000001],
              [-9.687957000000001, 30.5118793],
              [-9.6878949, 30.511418200000001],
              [-9.687709, 30.510037199999999],
              [-9.6875616, 30.508941100000001],
              [-9.687636700000001, 30.508628399999999],
              [-9.6878581, 30.508441399999999],
              [-9.6880326, 30.5085826],
              [-9.6881156, 30.5085643],
              [-9.688137100000001, 30.508501800000001],
              [-9.6881731, 30.508474400000001],
              [-9.688251899999999, 30.508478],
              [-9.688326399999999, 30.508489099999998],
              [-9.688358300000001, 30.508424900000001],
              [-9.6882945, 30.5084065],
              [-9.688260400000001, 30.508267100000001],
              [-9.6880007, 30.5082469],
              [-9.6877789, 30.508017200000001],
              [-9.6877452, 30.507836099999999],
              [-9.687457200000001, 30.507963499999999],
              [-9.6872811, 30.507759100000001],
              [-9.6872939, 30.5074656],
              [-9.687306700000001, 30.5072492],
              [-9.6875281, 30.5070914],
              [-9.6876941, 30.507113400000001],
              [-9.687876599999999, 30.507228900000001],
              [-9.6882909, 30.507136899999999],
              [-9.6886606, 30.5070181],
              [-9.688720200000001, 30.506838299999998],
              [-9.6888097, 30.506629199999999],
              [-9.688618099999999, 30.5065779],
              [-9.6884137, 30.506548500000001],
              [-9.688268900000001, 30.506596200000001],
              [-9.687928899999999, 30.506688799999999],
              [-9.687689900000001, 30.5067539],
              [-9.6869192, 30.506775999999999],
              [-9.6865679, 30.506757],
              [-9.686063499999999, 30.505782700000001],
              [-9.685795300000001, 30.504238999999998],
              [-9.6855163, 30.503490299999999],
              [-9.6854429, 30.503294400000001],
              [-9.6848619, 30.5031113],
              [-9.684834, 30.502991399999999],
              [-9.6844435, 30.502797000000001],
              [-9.683944800000001, 30.502754599999999],
              [-9.683713900000001, 30.5025844],
              [-9.6837246, 30.5022886],
              [-9.6835047, 30.5022758],
              [-9.6830809, 30.502196099999999],
              [-9.6828234, 30.502482700000002],
              [-9.682287000000001, 30.502686000000001],
              [-9.681913399999999, 30.5026218],
              [-9.6809092, 30.5020819],
              [-9.6798868, 30.501159699999999],
              [-9.6796373, 30.500846500000002],
              [-9.679293599999999, 30.500314700000001],
              [-9.6787107, 30.499316100000001],
              [-9.6783137, 30.498352400000002],
              [-9.677509199999999, 30.495402299999999],
              [-9.6773793, 30.494950200000002],
              [-9.677144200000001, 30.4946743],
              [-9.6765832, 30.493363899999999],
              [-9.676272900000001, 30.4909444],
              [-9.6760898, 30.4895502],
              [-9.675949299999999, 30.488757700000001],
              [-9.676311200000001, 30.488632899999999],
              [-9.6762687, 30.488464100000002],
              [-9.675886999999999, 30.488581499999999],
              [-9.67563, 30.487772499999998],
              [-9.675536299999999, 30.486638800000001],
              [-9.6753333, 30.485821900000001],
              [-9.676223800000001, 30.485715599999999],
              [-9.6763672, 30.485757199999998],
              [-9.6764545, 30.485752600000001],
              [-9.676556400000001, 30.485697099999999],
              [-9.6766208, 30.4856278],
              [-9.6766101, 30.4855214],
              [-9.6765296, 30.4854336],
              [-9.676449099999999, 30.485392000000001],
              [-9.6763365, 30.485415100000001],
              [-9.676239900000001, 30.485489099999999],
              [-9.6752422, 30.4856093],
              [-9.6746, 30.4840865],
              [-9.674545999999999, 30.483486599999999],
              [-9.674471, 30.4831489],
              [-9.674383799999999, 30.482886700000002],
              [-9.674462, 30.482830499999999],
              [-9.6743702, 30.482519700000001],
              [-9.674487600000001, 30.482322400000001],
              [-9.675109900000001, 30.481716800000001],
              [-9.6748417, 30.481679799999998],
              [-9.6747988, 30.481346899999998],
              [-9.6749498, 30.481164799999998],
              [-9.674868500000001, 30.4806211],
              [-9.6746164, 30.480477799999999],
              [-9.674697699999999, 30.480221700000001],
              [-9.674653899999999, 30.479696499999999],
              [-9.6747076, 30.479442200000001],
              [-9.6747674, 30.479218500000002],
              [-9.6743168, 30.479176899999999],
              [-9.6740263, 30.479460700000001],
              [-9.6738394, 30.479486600000001],
              [-9.6734907, 30.479426499999999],
              [-9.6731655, 30.4792199],
              [-9.672911300000001, 30.479283200000001],
              [-9.6724763, 30.4791676],
              [-9.672043800000001, 30.478771500000001],
              [-9.671735699999999, 30.478411300000001],
              [-9.671719599999999, 30.478180099999999],
              [-9.6715842, 30.477897800000001],
              [-9.671455, 30.477520800000001],
              [-9.6706515, 30.475672599999999],
              [-9.670383899999999, 30.474629400000001],
              [-9.670448199999999, 30.474213299999999],
              [-9.6702122, 30.474074600000002],
              [-9.669965400000001, 30.473760200000001],
              [-9.669976200000001, 30.473436599999999],
              [-9.6695685, 30.4728818],
              [-9.6696328, 30.472410199999999],
              [-9.669193, 30.4718369],
              [-9.6691822, 30.471032399999999],
              [-9.668756800000001, 30.469668500000001],
              [-9.6685637, 30.469409500000001],
              [-9.6683383, 30.468988800000002],
              [-9.6680916, 30.468212000000001],
              [-9.668086199999999, 30.467476900000001],
              [-9.6680755, 30.466903500000001],
              [-9.6682901, 30.466006499999999],
              [-9.6683062, 30.465784599999999],
              [-9.6683097, 30.465567199999999],
              [-9.668274, 30.465086400000001],
              [-9.6683223, 30.464804300000001],
              [-9.6682632, 30.464526899999999],
              [-9.668252499999999, 30.464369699999999],
              [-9.668173100000001, 30.464230799999999],
              [-9.6680755, 30.464157],
              [-9.6680004, 30.463916600000001],
              [-9.6679773, 30.463783100000001],
              [-9.6678836, 30.463540900000002],
              [-9.667653700000001, 30.463471200000001],
              [-9.6675217, 30.463221600000001],
              [-9.667415200000001, 30.462917000000001],
              [-9.66718, 30.462661399999998],
              [-9.667002200000001, 30.462164600000001],
              [-9.6667425, 30.461874699999999],
              [-9.6665694, 30.4616805],
              [-9.666619000000001, 30.461353599999999],
              [-9.6666872, 30.461258099999998],
              [-9.666541499999999, 30.4608016],
              [-9.666471700000001, 30.460353000000001],
              [-9.6665575, 30.4602185],
              [-9.6665951, 30.459830499999999],
              [-9.666884899999999, 30.459823],
              [-9.6669062, 30.4597658],
              [-9.6664449, 30.459677899999999],
              [-9.6662464, 30.459566899999999],
              [-9.666085499999999, 30.459386599999998],
              [-9.665994299999999, 30.459123000000002],
              [-9.6659621, 30.458799299999999],
              [-9.666047900000001, 30.458618999999999],
              [-9.6661813, 30.458494999999999],
              [-9.665956700000001, 30.458313799999999],
              [-9.666058700000001, 30.458161199999999],
              [-9.666225000000001, 30.4581011],
              [-9.6662947, 30.457976299999999],
              [-9.6662196, 30.457892999999999],
              [-9.6659728, 30.4577913],
              [-9.6657797, 30.457758900000002],
              [-9.6655383, 30.457851399999999],
              [-9.6653935, 30.4576156],
              [-9.6653541, 30.457348199999998],
              [-9.665524400000001, 30.457109599999999],
              [-9.6658565, 30.457106],
              [-9.665890600000001, 30.457061899999999],
              [-9.665494600000001, 30.4568124],
              [-9.6656905, 30.456713300000001],
              [-9.665664899999999, 30.456636199999998],
              [-9.665234999999999, 30.4566552],
              [-9.6648858, 30.456390299999999],
              [-9.6648432, 30.456265500000001],
              [-9.6649539, 30.456173700000001],
              [-9.6649028, 30.4560967],
              [-9.6646771, 30.4560526],
              [-9.664489400000001, 30.455604399999999],
              [-9.664655399999999, 30.455519899999999],
              [-9.664668199999999, 30.455358499999999],
              [-9.6644383, 30.455233700000001],
              [-9.6642382, 30.454951099999999],
              [-9.6641113, 30.4545195],
              [-9.664222799999999, 30.454472899999999],
              [-9.6642431, 30.454347599999998],
              [-9.664192399999999, 30.454204900000001],
              [-9.664064, 30.454117499999999],
              [-9.663854199999999, 30.454146699999999],
              [-9.663753099999999, 30.454070900000001],
              [-9.6637565, 30.453951400000001],
              [-9.663959200000001, 30.4539136],
              [-9.663952500000001, 30.4538233],
              [-9.6639018, 30.453802899999999],
              [-9.6634996, 30.453831999999998],
              [-9.6634557, 30.453529],
              [-9.663391499999999, 30.453004700000001],
              [-9.663482699999999, 30.4528444],
              [-9.663601, 30.4527337],
              [-9.663411099999999, 30.452562],
              [-9.6633814, 30.452462799999999],
              [-9.663435399999999, 30.452416199999998],
              [-9.663313799999999, 30.452136500000002],
              [-9.6631448, 30.452037499999999],
              [-9.663006299999999, 30.4516442],
              [-9.662762900000001, 30.4514286],
              [-9.6627697, 30.451277099999999],
              [-9.6628846, 30.451102299999999],
              [-9.662784500000001, 30.450877999999999],
              [-9.6624453, 30.4507586],
              [-9.662425000000001, 30.450671199999999],
              [-9.6621884, 30.4504673],
              [-9.6622898, 30.4503041],
              [-9.662483, 30.4500867],
              [-9.6620262, 30.449686499999999],
              [-9.6616613, 30.449511699999999],
              [-9.6613238, 30.449312599999999],
              [-9.661463299999999, 30.448897299999999],
              [-9.661336800000001, 30.447186899999998],
              [-9.661374800000001, 30.446749199999999],
              [-9.6614112, 30.446400300000001],
              [-9.661199699999999, 30.445982999999998],
              [-9.6609651, 30.4457652],
              [-9.660789400000001, 30.4455496],
              [-9.660573100000001, 30.445368899999998],
              [-9.660241900000001, 30.445234899999999],
              [-9.6598542, 30.444915000000002],
              [-9.659708, 30.444687200000001],
              [-9.6587753, 30.444110299999998],
              [-9.6578084, 30.443733900000002],
              [-9.6574379, 30.443579700000001],
              [-9.6573084, 30.443371299999999],
              [-9.656875899999999, 30.443091500000001],
              [-9.656735400000001, 30.443014399999999],
              [-9.6565523, 30.4428199],
              [-9.656101, 30.441858100000001],
              [-9.655909400000001, 30.441641600000001],
              [-9.6557008, 30.4413442],
              [-9.6556199, 30.4409001],
              [-9.6554836, 30.440837699999999],
              [-9.655424, 30.4406137],
              [-9.655255, 30.440517700000001],
              [-9.654521300000001, 30.440487399999999],
              [-9.6540515, 30.440704],
              [-9.6535267, 30.440580400000002],
              [-9.6533549, 30.4406286],
              [-9.653215100000001, 30.440563099999999],
              [-9.6528396, 30.4401878],
              [-9.6524921, 30.440201500000001],
              [-9.652164600000001, 30.4399777],
              [-9.651644299999999, 30.439907000000002],
              [-9.651287699999999, 30.4393086],
              [-9.650894600000001, 30.43901],
              [-9.6502956, 30.438009399999999],
              [-9.6497493, 30.4372501],
              [-9.6494404, 30.4366199],
              [-9.649206100000001, 30.435998300000001],
              [-9.6482522, 30.433920100000002],
              [-9.6475749, 30.431829799999999],
              [-9.6486316, 30.430597299999999],
              [-9.650042300000001, 30.429082300000001],
              [-9.650266200000001, 30.4288469],
              [-9.6506478, 30.428433099999999],
              [-9.6509807, 30.4277762],
              [-9.6510493, 30.427485699999998],
              [-9.6510666, 30.426952100000001],
              [-9.650991100000001, 30.426562499999999],
              [-9.650893200000001, 30.4262953],
              [-9.6504154, 30.425597799999998],
              [-9.6476682, 30.422186199999999],
              [-9.647255299999999, 30.421672900000001],
              [-9.6466791, 30.421018700000001],
              [-9.646256599999999, 30.420612899999998],
              [-9.645690099999999, 30.4201409],
              [-9.641759499999999, 30.417490699999998],
              [-9.640666400000001, 30.416037899999999],
              [-9.640616, 30.4159571],
              [-9.6405394, 30.415894999999999],
              [-9.6404467, 30.415859099999999],
              [-9.640345699999999, 30.415850200000001],
              [-9.640246599999999, 30.415869199999999],
              [-9.640159499999999, 30.4159142],
              [-9.640093200000001, 30.4159805],
              [-9.640054599999999, 30.416061500000001],
              [-9.6400474, 30.416148799999998],
              [-9.6400726, 30.416233699999999],
              [-9.6401275, 30.4163073],
              [-9.6402064, 30.416362400000001],
              [-9.6403015, 30.416393100000001],
              [-9.6403654, 30.416394499999999],
              [-9.6408691, 30.4171315],
              [-9.6411699, 30.417604600000001],
              [-9.6411487, 30.417629000000002],
              [-9.641131, 30.417665599999999],
              [-9.6411239, 30.417705300000002],
              [-9.6411274, 30.4177541],
              [-9.641141599999999, 30.417799899999999],
              [-9.6411699, 30.417839600000001],
              [-9.641223, 30.417879200000002],
              [-9.641268999999999, 30.4179128],
              [-9.6413504, 30.417934200000001],
              [-9.641442400000001, 30.417946400000002],
              [-9.6415769, 30.417970799999999],
              [-9.641732599999999, 30.418044099999999],
              [-9.641888399999999, 30.4181326],
              [-9.6421113, 30.4182852],
              [-9.6423662, 30.4184713],
              [-9.6424051, 30.4184774],
              [-9.6454551, 30.420538400000002],
              [-9.6462249, 30.421253],
              [-9.6484548, 30.4238468],
              [-9.6485501, 30.423962800000002],
              [-9.648576800000001, 30.424007599999999],
              [-9.648586, 30.4240572],
              [-9.648576800000001, 30.424106900000002],
              [-9.6485501, 30.424151699999999],
              [-9.6483703, 30.424306699999999],
              [-9.648160499999999, 30.424451399999999],
              [-9.647887799999999, 30.424624600000001],
              [-9.647684, 30.424745999999999],
              [-9.6475192, 30.424833899999999],
              [-9.6474265, 30.424875400000001],
              [-9.647276, 30.4246923],
              [-9.647128500000001, 30.424782499999999],
              [-9.647278999999999, 30.4249656],
              [-9.6490201, 30.4270356],
              [-9.649088900000001, 30.4271192],
              [-9.6491984, 30.427052100000001],
              [-9.649404000000001, 30.427121499999998],
              [-9.649463600000001, 30.427152899999999],
              [-9.649509999999999, 30.427186299999999],
              [-9.6495807, 30.4272615],
              [-9.649665799999999, 30.427371999999998],
              [-9.649736600000001, 30.427474199999999],
              [-9.6497505, 30.4274977],
              [-9.649754700000001, 30.427523999999998],
              [-9.649748799999999, 30.427549899999999],
              [-9.649733299999999, 30.4275728],
              [-9.6497101, 30.427589999999999],
              [-9.6476194, 30.428898100000001],
              [-9.6472228, 30.4284313],
              [-9.6474137, 30.4283033],
              [-9.647339300000001, 30.428220799999998],
              [-9.647148400000001, 30.428348799999998],
              [-9.644901300000001, 30.4297641],
              [-9.6407899, 30.4248695],
              [-9.6407595, 30.424887399999999],
              [-9.6407404, 30.424863599999998],
              [-9.6406887, 30.424894299999998],
              [-9.640811599999999, 30.425047899999999],
              [-9.638446999999999, 30.426539999999999],
              [-9.6377007, 30.425712999999998],
              [-9.6384635, 30.425235000000001],
              [-9.6383744, 30.425131400000001],
              [-9.6383458, 30.425149099999999],
              [-9.6382663, 30.425053500000001],
              [-9.6382932, 30.425037],
              [-9.6382078, 30.424937700000001],
              [-9.6374636, 30.425394300000001],
              [-9.637451799999999, 30.425380100000002],
              [-9.6374239, 30.4253602],
              [-9.637393899999999, 30.425347899999998],
              [-9.6373707, 30.425342199999999],
              [-9.6366669, 30.424472000000002],
              [-9.638393799999999, 30.423146500000001],
              [-9.638416599999999, 30.423147499999999],
              [-9.638533000000001, 30.423050199999999],
              [-9.6385798, 30.4230865],
              [-9.638590600000001, 30.4230968],
              [-9.638593, 30.423111299999999],
              [-9.638593, 30.423123700000001],
              [-9.6385846, 30.4231382],
              [-9.638457000000001, 30.4232586],
              [-9.6385252, 30.4233248],
              [-9.6390224, 30.4229439],
              [-9.638954099999999, 30.4228776],
              [-9.6388923, 30.4229278],
              [-9.6388631, 30.422928500000001],
              [-9.6388281, 30.422947400000002],
              [-9.6388148, 30.422954399999998],
              [-9.6387997, 30.4229573],
              [-9.638784299999999, 30.422955999999999],
              [-9.6387701, 30.4229506],
              [-9.638758599999999, 30.422941600000001],
              [-9.638750999999999, 30.4229299],
              [-9.638748100000001, 30.422916699999998],
              [-9.6387502, 30.422903399999999],
              [-9.638757500000001, 30.422890899999999],
              [-9.6387839, 30.4228515],
              [-9.6388731, 30.422780299999999],
              [-9.6395771, 30.422242600000001],
              [-9.6397542, 30.422222999999999],
              [-9.6399127, 30.422171899999999],
              [-9.6400144, 30.422081800000001],
              [-9.640051400000001, 30.4219723],
              [-9.640034200000001, 30.421893900000001],
              [-9.639975, 30.421818399999999],
              [-9.6398309, 30.421779399999998],
              [-9.6397175, 30.421793999999998],
              [-9.639607399999999, 30.4218671],
              [-9.639438, 30.422005800000001],
              [-9.638317900000001, 30.422851000000001],
              [-9.6381327, 30.422668099999999],
              [-9.638124899999999, 30.422660700000002],
              [-9.638114699999999, 30.422655899999999],
              [-9.638103299999999, 30.4226542],
              [-9.638091899999999, 30.4226557],
              [-9.6380816, 30.4226603],
              [-9.6379488, 30.4227454],
              [-9.6379375, 30.4227487],
              [-9.6379257, 30.422748500000001],
              [-9.6379146, 30.4227451],
              [-9.6379053, 30.422738599999999],
              [-9.6378472, 30.422675900000002],
              [-9.637839400000001, 30.4226685],
              [-9.6378293, 30.422663700000001],
              [-9.637817800000001, 30.422661999999999],
              [-9.637806400000001, 30.422663499999999],
              [-9.637796099999999, 30.422668099999999],
              [-9.637788, 30.422675300000002],
              [-9.637693799999999, 30.422574600000001],
              [-9.6363541, 30.4234945],
              [-9.6338303, 30.4234382],
              [-9.6338276, 30.4237754],
              [-9.633727199999999, 30.4237748],
              [-9.633729799999999, 30.423437700000001],
              [-9.6332962, 30.423424000000001],
              [-9.633260399999999, 30.423422800000001],
              [-9.633267099999999, 30.423412500000001],
              [-9.633269800000001, 30.4234002],
              [-9.6332676, 30.4233878],
              [-9.633260699999999, 30.4233768],
              [-9.633249899999999, 30.423368400000001],
              [-9.633236500000001, 30.423363500000001],
              [-9.633037, 30.423365400000002],
              [-9.633019300000001, 30.422900599999998],
              [-9.6332027, 30.422902799999999],
              [-9.6332164, 30.422901599999999],
              [-9.633229, 30.422896900000001],
              [-9.633239100000001, 30.422889000000001],
              [-9.6332459, 30.422878699999998],
              [-9.6332486, 30.422866299999999],
              [-9.6332463, 30.422854000000001],
              [-9.633239400000001, 30.422842899999999],
              [-9.6332287, 30.4228345],
              [-9.6334523, 30.422834000000002],
              [-9.6335145, 30.421367499999999],
              [-9.636483500000001, 30.421428800000001],
              [-9.636491299999999, 30.421269899999999],
              [-9.636524100000001, 30.421253400000001],
              [-9.6365651, 30.421215700000001],
              [-9.6365946, 30.421170700000001],
              [-9.6365999, 30.421154699999999],
              [-9.6372941, 30.421178099999999],
              [-9.6373278, 30.421174000000001],
              [-9.6373582, 30.4211609],
              [-9.637382199999999, 30.4211402],
              [-9.6373973, 30.421113999999999],
              [-9.637402099999999, 30.421085000000001],
              [-9.6374093, 30.420945199999998],
              [-9.637552100000001, 30.420951899999999],
              [-9.6375577, 30.4209739],
              [-9.637597599999999, 30.421030399999999],
              [-9.6376566, 30.421072800000001],
              [-9.637728299999999, 30.4210964],
              [-9.6378051, 30.421098499999999],
              [-9.637878600000001, 30.4210791],
              [-9.637940800000001, 30.421040099999999],
              [-9.637984899999999, 30.420985900000002],
              [-9.6380061, 30.4209222],
              [-9.6380023, 30.420856000000001],
              [-9.6379737, 30.4207945],
              [-9.637923499999999, 30.420744299999999],
              [-9.6378571, 30.4207109],
              [-9.6377817, 30.420698000000002],
              [-9.6373348, 30.420672100000001],
              [-9.632376000000001, 30.4202485],
              [-9.631367300000001, 30.416616399999999],
              [-9.631287, 30.416336600000001],
              [-9.631271399999999, 30.416270300000001],
              [-9.6312312, 30.416212300000002],
              [-9.631170900000001, 30.416169100000001],
              [-9.6310974, 30.416145499999999],
              [-9.631019, 30.416144299999999],
              [-9.630944599999999, 30.416165700000001],
              [-9.6308826, 30.416207100000001],
              [-9.6308401, 30.416263900000001],
              [-9.6308218, 30.416329600000001],
              [-9.630829, 30.416394100000002],
              [-9.631619000000001, 30.419106500000002],
              [-9.6314698, 30.419141],
              [-9.6323986, 30.422224700000001],
              [-9.632363700000001, 30.423781099999999],
              [-9.631795800000001, 30.4237723],
              [-9.6318024, 30.423656300000001],
              [-9.6317816, 30.423655400000001],
              [-9.631787900000001, 30.423553900000002],
              [-9.6317758, 30.423553299999998],
              [-9.6317488, 30.423991300000001],
              [-9.631663400000001, 30.423989500000001],
              [-9.6316556, 30.423989299999999],
              [-9.6314338, 30.423984699999998],
              [-9.6314242, 30.4239845],
              [-9.6312002, 30.4239797],
              [-9.6311923, 30.423979599999999],
              [-9.6292168, 30.423937899999999],
              [-9.629068800000001, 30.422667300000001],
              [-9.630500700000001, 30.422212099999999],
              [-9.6307873, 30.422121499999999],
              [-9.6311032, 30.422025999999999],
              [-9.6310755, 30.421969799999999],
              [-9.6310152, 30.421989499999999],
              [-9.631020899999999, 30.422004900000001],
              [-9.6309533, 30.4220267],
              [-9.6309354, 30.421991599999998],
              [-9.630892299999999, 30.4219221],
              [-9.630832, 30.421852600000001],
              [-9.630749399999999, 30.4217826],
              [-9.630712600000001, 30.4217558],
              [-9.630667799999999, 30.421740400000001],
              [-9.630619599999999, 30.4217379],
              [-9.630398100000001, 30.421762699999999],
              [-9.630371999999999, 30.421766900000001],
              [-9.630352500000001, 30.421766900000001],
              [-9.6303321, 30.421762000000001],
              [-9.6303158, 30.421753599999999],
              [-9.630302500000001, 30.4217327],
              [-9.6302986, 30.4217157],
              [-9.630288699999999, 30.4217005],
              [-9.630273600000001, 30.421689000000001],
              [-9.630255099999999, 30.421682100000002],
              [-9.630235000000001, 30.4216807],
              [-9.630215400000001, 30.421685],
              [-9.630198399999999, 30.421694299999999],
              [-9.6301942, 30.421699],
              [-9.630145199999999, 30.421589000000001],
              [-9.627978499999999, 30.4222848],
              [-9.621172400000001, 30.424433499999999],
              [-9.6212476, 30.424629500000002],
              [-9.6209922, 30.424694899999999],
              [-9.620983900000001, 30.424697599999998],
              [-9.620937700000001, 30.424704299999998],
              [-9.620907799999999, 30.4247069],
              [-9.6208785, 30.4247014],
              [-9.6208527, 30.4246883],
              [-9.620832999999999, 30.424668799999999],
              [-9.620821299999999, 30.424645000000002],
              [-9.620597399999999, 30.424132400000001],
              [-9.620563799999999, 30.424047099999999],
              [-9.6205161, 30.423932499999999],
              [-9.620676400000001, 30.423878599999998],
              [-9.620635200000001, 30.423787600000001],
              [-9.620478800000001, 30.423840299999998],
              [-9.620422400000001, 30.423704300000001],
              [-9.6204207, 30.423700400000001],
              [-9.620187100000001, 30.423153800000001],
              [-9.6210471, 30.422872999999999],
              [-9.621507599999999, 30.422743100000002],
              [-9.6215151, 30.422740999999998],
              [-9.621613200000001, 30.422713300000002],
              [-9.6216481, 30.422797800000001],
              [-9.6217709, 30.422758399999999],
              [-9.621734500000001, 30.422674199999999],
              [-9.621460000000001, 30.4219817],
              [-9.6214555, 30.4219714],
              [-9.6213791, 30.421770299999999],
              [-9.6214051, 30.421763200000001],
              [-9.6213994, 30.4217473],
              [-9.6213873, 30.421751100000002],
              [-9.6213812, 30.4217364],
              [-9.621394199999999, 30.421732599999999],
              [-9.621388899999999, 30.4217175],
              [-9.621361200000001, 30.421725299999999],
              [-9.621287499999999, 30.421528500000001],
              [-9.6213269, 30.421517600000001],
              [-9.6213289, 30.421522100000001],
              [-9.621430399999999, 30.4214883],
              [-9.621428399999999, 30.421483800000001],
              [-9.621462599999999, 30.421472399999999],
              [-9.621495599999999, 30.4215594],
              [-9.621472799999999, 30.421565999999999],
              [-9.6214905, 30.421611599999999],
              [-9.6215133, 30.421605],
              [-9.6215387, 30.421670800000001],
              [-9.6215163, 30.4216771],
              [-9.621521400000001, 30.421690900000002],
              [-9.621535099999999, 30.421686699999999],
              [-9.621541300000001, 30.421701800000001],
              [-9.6215276, 30.421706],
              [-9.6215335, 30.421722200000001],
              [-9.621556099999999, 30.421716100000001],
              [-9.6215668, 30.421713100000002],
              [-9.6215496, 30.4216677],
              [-9.6214735, 30.421469299999998],
              [-9.6277233, 30.419520200000001],
              [-9.6274473, 30.418821300000001],
              [-9.628817400000001, 30.4183877],
              [-9.629080800000001, 30.418298100000001],
              [-9.629121400000001, 30.4182712],
              [-9.6291495, 30.418234200000001],
              [-9.6291619, 30.4181913],
              [-9.629158800000001, 30.418161900000001],
              [-9.6292685, 30.418127699999999],
              [-9.6292832, 30.418122],
              [-9.6292958, 30.418111799999998],
              [-9.6293036, 30.418098499999999],
              [-9.629305799999999, 30.418083800000002],
              [-9.6293022, 30.418069299999999],
              [-9.629293199999999, 30.418056700000001],
              [-9.6292797, 30.418047399999999],
              [-9.629263399999999, 30.4180426],
              [-9.629246200000001, 30.418042700000001],
              [-9.629230099999999, 30.418047900000001],
              [-9.6291104, 30.418084100000002],
              [-9.6291005, 30.418075200000001],
              [-9.6290548, 30.4180551],
              [-9.629005299999999, 30.418049100000001],
              [-9.628956199999999, 30.4180572],
              [-9.628855099999999, 30.418089899999998],
              [-9.6204632, 30.420742300000001],
              [-9.620233199999999, 30.4208064],
              [-9.620056200000001, 30.420849199999999],
              [-9.619957100000001, 30.420846099999999],
              [-9.619858000000001, 30.420836999999999],
              [-9.6197766, 30.4208034],
              [-9.619691700000001, 30.420751500000001],
              [-9.6195784, 30.420672199999998],
              [-9.617562899999999, 30.419056999999999],
              [-9.6175, 30.419017],
              [-9.617430199999999, 30.418984200000001],
              [-9.617351599999999, 30.418973699999999],
              [-9.6172735, 30.418986700000001],
              [-9.617210500000001, 30.419018000000001],
              [-9.6171618, 30.419064500000001],
              [-9.617132099999999, 30.419121700000002],
              [-9.6171246, 30.419184099999999],
              [-9.617139699999999, 30.419245400000001],
              [-9.617176199999999, 30.419299599999999],
              [-9.6172304, 30.4193414],
              [-9.617296899999999, 30.419366700000001],
              [-9.617369200000001, 30.419373],
              [-9.617446599999999, 30.419357399999999],
              [-9.617637200000001, 30.419505699999998],
              [-9.618023000000001, 30.419813900000001],
              [-9.618967899999999, 30.4205483],
              [-9.6189444, 30.420659199999999],
              [-9.618943, 30.420666499999999],
              [-9.618370499999999, 30.423390600000001],
              [-9.6182666, 30.423376000000001],
              [-9.6182608, 30.423375199999999],
              [-9.618255, 30.4233744],
              [-9.616882499999999, 30.4231646],
              [-9.6168411, 30.423158300000001],
              [-9.616831299999999, 30.4231567],
              [-9.616676999999999, 30.4231348],
              [-9.6170449, 30.421344600000001],
              [-9.617047100000001, 30.421334300000002],
              [-9.6171349, 30.420975599999998],
              [-9.617145600000001, 30.420919999999999],
              [-9.6171714, 30.4209274],
              [-9.617268599999999, 30.420736900000001],
              [-9.6172734, 30.4206869],
              [-9.6172583, 30.420638400000001],
              [-9.617224999999999, 30.420597300000001],
              [-9.6171776, 30.420568200000002],
              [-9.6171215, 30.420554800000001],
              [-9.6170635, 30.420558400000001],
              [-9.6170104, 30.420578800000001],
              [-9.6169683, 30.420613500000002],
              [-9.6167847, 30.4207663],
              [-9.616634299999999, 30.420914499999999],
              [-9.616604199999999, 30.4209608],
              [-9.616463700000001, 30.4213369],
              [-9.6164696, 30.421394800000002],
              [-9.616501, 30.421414800000001],
              [-9.616491099999999, 30.421636400000001],
              [-9.6164516, 30.421856399999999],
              [-9.616406100000001, 30.422045600000001],
              [-9.616398200000001, 30.422118900000001],
              [-9.616372500000001, 30.422209299999999],
              [-9.616332999999999, 30.422284300000001],
              [-9.616289500000001, 30.422335499999999],
              [-9.6162242, 30.422386599999999],
              [-9.616149099999999, 30.422437800000001],
              [-9.6160107, 30.422495699999999],
              [-9.615963199999999, 30.422514499999998],
              [-9.6159523, 30.422494799999999],
              [-9.6158736, 30.4225274],
              [-9.6158897, 30.422556400000001],
              [-9.615844600000001, 30.422591199999999],
              [-9.6158169, 30.422620200000001],
              [-9.615795200000001, 30.422650900000001],
              [-9.6157833, 30.422674700000002],
              [-9.615770400000001, 30.422693299999999],
              [-9.615454700000001, 30.422728899999999],
              [-9.6153212, 30.422734500000001],
              [-9.6150997, 30.422650300000001],
              [-9.6149076, 30.422568800000001],
              [-9.6147285, 30.4225463],
              [-9.614334400000001, 30.422521100000001],
              [-9.6139566, 30.422442400000001],
              [-9.613070799999999, 30.422161599999999],
              [-9.6121169, 30.421716799999999],
              [-9.6111465, 30.421182200000001],
              [-9.610356899999999, 30.4205547],
              [-9.6094448, 30.4196621],
              [-9.6090608, 30.419286199999998],
              [-9.608275799999999, 30.418356899999999],
              [-9.6057527, 30.414902099999999],
              [-9.6050053, 30.413130899999999],
              [-9.6045099, 30.411706899999999],
              [-9.604231800000001, 30.410842500000001],
              [-9.603461299999999, 30.409538399999999],
              [-9.6028935, 30.4085216],
              [-9.602062099999999, 30.405805900000001],
              [-9.6017531, 30.404743199999999],
              [-9.601070999999999, 30.4019519],
              [-9.600830800000001, 30.400684399999999],
              [-9.600808900000001, 30.400549399999999],
              [-9.6006634, 30.399953100000001],
              [-9.6004305, 30.398748000000001],
              [-9.6003246, 30.3975604],
              [-9.6002586, 30.397245099999999],
              [-9.600276600000001, 30.396960700000001],
              [-9.600336499999999, 30.3966247],
              [-9.600348500000001, 30.3964541],
              [-9.6002706, 30.3955856],
              [-9.6002347, 30.395290899999999],
              [-9.600240700000001, 30.395130600000002],
              [-9.600330599999999, 30.394086300000001],
              [-9.600360500000001, 30.3936314],
              [-9.6004205, 30.392949000000002],
              [-9.6004684, 30.392080400000001],
              [-9.600456400000001, 30.391480699999999],
              [-9.600486399999999, 30.3911601],
              [-9.600558299999999, 30.3907259],
              [-9.600606300000001, 30.390555299999999],
              [-9.600654199999999, 30.390286400000001],
              [-9.600750100000001, 30.389583300000002],
              [-9.60087, 30.389071399999999],
              [-9.6009479, 30.388776700000001],
              [-9.6009958, 30.3884303],
              [-9.6011097, 30.387825400000001],
              [-9.601235600000001, 30.387261899999999],
              [-9.6013375, 30.387034400000001],
              [-9.601475300000001, 30.386382900000001],
              [-9.601673099999999, 30.385715999999999],
              [-9.601756999999999, 30.3853954],
              [-9.6018709, 30.384930099999998],
              [-9.6019308, 30.3845992],
              [-9.602032700000001, 30.384195900000002],
              [-9.602338400000001, 30.383316900000001],
              [-9.6024463, 30.3831308],
              [-9.602536199999999, 30.382861900000002],
              [-9.602608099999999, 30.3824948],
              [-9.602686, 30.382179399999998],
              [-9.602746, 30.381941600000001],
              [-9.602783799999999, 30.381838500000001],
              [-9.6028599, 30.381631299999999],
              [-9.603009699999999, 30.3813469],
              [-9.6032075, 30.380959199999999],
              [-9.603333299999999, 30.380757500000001],
              [-9.6035851, 30.3804059],
              [-9.603854800000001, 30.380075000000001],
              [-9.604124499999999, 30.379754399999999],
              [-9.6042743, 30.379604499999999],
              [-9.604136499999999, 30.3792063],
              [-9.6040226, 30.378885700000001],
              [-9.6039387, 30.3784411],
              [-9.6039207, 30.378254900000002],
              [-9.6039327, 30.378032600000001],
              [-9.604010600000001, 30.377701600000002],
              [-9.6040586, 30.377520700000002],
              [-9.6041904, 30.3772983],
              [-9.604304300000001, 30.377096699999999],
              [-9.604442199999999, 30.376920899999998],
              [-9.6045441, 30.376791600000001],
              [-9.6046459, 30.3766727],
              [-9.6047478, 30.3765641],
              [-9.6048198, 30.376486499999999],
              [-9.604753799999999, 30.376403799999999],
              [-9.604634000000001, 30.376191800000001],
              [-9.604502099999999, 30.375865999999998],
              [-9.6044362, 30.3756126],
              [-9.604400200000001, 30.375364399999999],
              [-9.6043702, 30.375100700000001],
              [-9.604376200000001, 30.374697399999999],
              [-9.604531100000001, 30.373101699999999],
              [-9.6046519, 30.372675600000001],
              [-9.6048198, 30.372375600000002],
              [-9.605011599999999, 30.371972299999999],
              [-9.605287300000001, 30.371615500000001],
              [-9.605563, 30.371305199999998],
              [-9.6057068, 30.371222499999998],
              [-9.605909799999999, 30.371165900000001],
              [-9.6059182, 30.3710205],
              [-9.604646600000001, 30.370824899999999],
              [-9.604473799999999, 30.370799999999999],
              [-9.6044065, 30.370712999999999],
              [-9.604353700000001, 30.3705763],
              [-9.6043249, 30.370493499999998],
              [-9.604252900000001, 30.370402299999999],
              [-9.604195300000001, 30.370369199999999],
              [-9.604089699999999, 30.370394099999999],
              [-9.604017600000001, 30.3704231],
              [-9.6039504, 30.370369199999999],
              [-9.6038721, 30.370286499999999],
              [-9.603868200000001, 30.370145399999998],
              [-9.6039288, 30.369689600000001],
              [-9.6043878, 30.368207999999999],
              [-9.604435799999999, 30.367525700000002],
              [-9.6041972, 30.3667145],
              [-9.6038859, 30.366244399999999],
              [-9.603716800000001, 30.365576000000001],
              [-9.603736899999999, 30.365272999999998],
              [-9.604120099999999, 30.3645456],
              [-9.6044999, 30.364128000000001],
              [-9.6046426, 30.363938699999999],
              [-9.605286, 30.363088300000001],
              [-9.606193299999999, 30.362194200000001],
              [-9.606401399999999, 30.361458800000001],
              [-9.606881100000001, 30.3597641],
              [-9.6073714, 30.358171800000001],
              [-9.607889500000001, 30.356374800000001],
              [-9.6081973, 30.355416399999999],
              [-9.6086163, 30.354891500000001],
              [-9.6086028, 30.354320000000001],
              [-9.6088326, 30.353375100000001],
              [-9.6093057, 30.352756899999999],
              [-9.6094814, 30.351077199999999],
              [-9.6101843, 30.3485692],
              [-9.6101843, 30.347519299999998],
              [-9.610725, 30.3460845],
              [-9.610752099999999, 30.345489600000001],
              [-9.6111846, 30.343413099999999],
              [-9.6113479, 30.342430199999999],
              [-9.6116983, 30.341278200000001],
              [-9.6116577, 30.340554900000001],
              [-9.6121955, 30.339204599999999],
              [-9.6120941, 30.338247899999999],
              [-9.612614499999999, 30.3373788],
              [-9.612513099999999, 30.336206300000001],
              [-9.612831099999999, 30.335437500000001],
              [-9.614166000000001, 30.329615700000002],
              [-9.614133799999999, 30.3288656],
              [-9.614756, 30.327383900000001],
              [-9.6150296, 30.325763299999998],
              [-9.615478299999999, 30.324773],
              [-9.6158482, 30.322587500000001],
              [-9.6163483, 30.321315599999998],
              [-9.616442899999999, 30.3204405],
              [-9.6168485, 30.319437000000001],
              [-9.6171323, 30.318246800000001],
              [-9.6175514, 30.317289899999999],
              [-9.617591900000001, 30.316368099999998],
              [-9.6181597, 30.3149561],
              [-9.6179434, 30.3139176],
              [-9.618348900000001, 30.3132758],
              [-9.618186700000001, 30.3124939],
              [-9.619153499999999, 30.3064736],
              [-9.618944000000001, 30.3053493],
              [-9.6193946, 30.303719000000001],
              [-9.619244399999999, 30.302218400000001],
              [-9.619630600000001, 30.3004955],
              [-9.619553700000001, 30.298910299999999],
              [-9.6196284, 30.2982254],
              [-9.619641700000001, 30.298104899999998],
              [-9.619692799999999, 30.2973666],
              [-9.620094399999999, 30.2949351],
              [-9.620362500000001, 30.2943964],
              [-9.620217800000001, 30.292576799999999],
              [-9.620442199999999, 30.291360600000001],
              [-9.620732800000001, 30.290718699999999],
              [-9.620631400000001, 30.289767399999999],
              [-9.6210437, 30.287316199999999],
              [-9.6216911, 30.285269400000001],
              [-9.6222388, 30.281488700000001],
              [-9.6226281, 30.280179400000002],
              [-9.6227114, 30.2797275],
              [-9.623120999999999, 30.278997499999999],
              [-9.6232764, 30.278317999999999],
              [-9.6238379, 30.276498799999999],
              [-9.6246695, 30.273546799999998],
              [-9.6249334, 30.272431099999999],
              [-9.6251122, 30.271813399999999],
              [-9.625282500000001, 30.270930799999999],
              [-9.625468700000001, 30.270538599999998],
              [-9.626019700000001, 30.269366399999999],
              [-9.6263323, 30.268045999999998],
              [-9.6263735, 30.2678546],
              [-9.6266462, 30.267526499999999],
              [-9.6268151, 30.267436199999999],
              [-9.626701600000001, 30.267312700000002],
              [-9.6265731, 30.266874900000001],
              [-9.6267218, 30.266524700000002],
              [-9.6269787, 30.266509500000002],
              [-9.6272071, 30.266579100000001],
              [-9.6271383, 30.266224099999999],
              [-9.6274794, 30.265599699999999],
              [-9.627364399999999, 30.265375299999999],
              [-9.627590100000001, 30.265331199999999],
              [-9.6274113, 30.265070099999999],
              [-9.627485099999999, 30.264410099999999],
              [-9.627662900000001, 30.264336700000001],
              [-9.6276969, 30.264263100000001],
              [-9.627475499999999, 30.264185900000001],
              [-9.6275947, 30.2633695],
              [-9.627888499999999, 30.263244499999999],
              [-9.6279141, 30.262998100000001],
              [-9.627505299999999, 30.2627223],
              [-9.6277081, 30.2625764],
              [-9.627807799999999, 30.262256300000001],
              [-9.627667300000001, 30.261951100000001],
              [-9.627428800000001, 30.261822299999999],
              [-9.627316199999999, 30.261527900000001],
              [-9.6274424, 30.261066899999999],
              [-9.627803500000001, 30.260634499999998],
              [-9.6280477, 30.259395900000001],
              [-9.628206, 30.259263900000001],
              [-9.6284125, 30.259104000000001],
              [-9.628391000000001, 30.258930299999999],
              [-9.6285171, 30.2587565],
              [-9.6282277, 30.2585859],
              [-9.628469300000001, 30.258031899999999],
              [-9.629037500000001, 30.255013699999999],
              [-9.6291888, 30.2548022],
              [-9.6290233, 30.254691600000001],
              [-9.6283922, 30.254655],
              [-9.6282683, 30.254325600000001],
              [-9.628328099999999, 30.253976399999999],
              [-9.628521299999999, 30.2536731],
              [-9.628735000000001, 30.252747500000002],
              [-9.6291133, 30.252799100000001],
              [-9.628560800000001, 30.251682299999999],
              [-9.629006, 30.251413599999999],
              [-9.6288453, 30.250972900000001],
              [-9.6295947, 30.249406100000002],
              [-9.629705400000001, 30.248994199999999],
              [-9.630069499999999, 30.248841200000001],
              [-9.629002, 30.247831000000001],
              [-9.6293705, 30.245974799999999],
              [-9.6295748, 30.245737200000001],
              [-9.629735800000001, 30.245727899999999],
              [-9.629890899999999, 30.245566100000001],
              [-9.6298967, 30.245417400000001],
              [-9.6297143, 30.2452413],
              [-9.6297411, 30.245130100000001],
              [-9.629902100000001, 30.245055900000001],
              [-9.630084500000001, 30.245074500000001],
              [-9.630223900000001, 30.245042000000002],
              [-9.630247300000001, 30.2449394],
              [-9.630063, 30.2448242],
              [-9.630207800000001, 30.244722299999999],
              [-9.6303044, 30.2446296],
              [-9.630250800000001, 30.2444767],
              [-9.6301649, 30.2444211],
              [-9.6303366, 30.244305199999999],
              [-9.630406300000001, 30.244170799999999],
              [-9.630459999999999, 30.244004],
              [-9.630626299999999, 30.243911300000001],
              [-9.630679900000001, 30.243749099999999],
              [-9.630524299999999, 30.243670300000002],
              [-9.630535099999999, 30.243540500000002],
              [-9.630647700000001, 30.243517400000002],
              [-9.6305833, 30.243401500000001],
              [-9.630658499999999, 30.243313499999999],
              [-9.6306102, 30.243239299999999],
              [-9.6307711, 30.243114200000001],
              [-9.630658499999999, 30.2430308],
              [-9.6305833, 30.2429056],
              [-9.6306692, 30.2427527],
              [-9.6306531, 30.242636900000001],
              [-9.630781799999999, 30.2425256],
              [-9.6309535, 30.242414400000001],
              [-9.631010099999999, 30.242310700000001],
              [-9.6309159, 30.242219800000001],
              [-9.630436100000001, 30.2420048],
              [-9.6301679, 30.241694299999999],
              [-9.630361000000001, 30.241462599999998],
              [-9.6305011, 30.2410596],
              [-9.6306692, 30.241065800000001],
              [-9.6307765, 30.2410009],
              [-9.63076, 30.240690300000001],
              [-9.630677499999999, 30.2406562],
              [-9.630784800000001, 30.240456900000002],
              [-9.6306829, 30.2403364],
              [-9.6308784, 30.240231600000001],
              [-9.6310208, 30.240021299999999],
              [-9.6307236, 30.239108699999999],
              [-9.6310369, 30.238464100000002],
              [-9.631160299999999, 30.2380697],
              [-9.631314, 30.2377842],
              [-9.6313326, 30.237749900000001],
              [-9.6317504, 30.2373379],
              [-9.631588900000001, 30.237092199999999],
              [-9.631273999999999, 30.236666799999998],
              [-9.631308300000001, 30.236028099999999],
              [-9.631489699999999, 30.2355716],
              [-9.6317611, 30.234858800000001],
              [-9.632031700000001, 30.2339935],
              [-9.6324445, 30.233203700000001],
              [-9.632616499999999, 30.232718899999998],
              [-9.632562, 30.232503900000001],
              [-9.6326806, 30.231865200000001],
              [-9.6330939, 30.231106],
              [-9.633047400000001, 30.230949299999999],
              [-9.633319200000001, 30.230466400000001],
              [-9.633461, 30.230280799999999],
              [-9.633179999999999, 30.229898200000001],
              [-9.633164499999999, 30.229803199999999],
              [-9.6334687, 30.229365300000001],
              [-9.6336399, 30.228949100000001],
              [-9.6336917, 30.2282324],
              [-9.6332529, 30.227162100000001],
              [-9.633273300000001, 30.226518299999999],
              [-9.6339688, 30.225172300000001],
              [-9.634313499999999, 30.223461199999999],
              [-9.6349894, 30.221720900000001],
              [-9.6359829, 30.219676799999998],
              [-9.6362059, 30.218882499999999],
              [-9.637942900000001, 30.214957800000001],
              [-9.6386661, 30.213503500000002],
              [-9.6395245, 30.212236000000001],
              [-9.6400238, 30.210594499999999],
              [-9.641247999999999, 30.208427799999999],
              [-9.6416042, 30.206949099999999],
              [-9.6419894, 30.205926999999999],
              [-9.642124600000001, 30.205523899999999],
              [-9.642327399999999, 30.2053662],
              [-9.642395, 30.2052552],
              [-9.642356899999999, 30.2047724],
              [-9.6421361, 30.204205999999999],
              [-9.642246999999999, 30.204083799999999],
              [-9.642383799999999, 30.204109299999999],
              [-9.642376199999999, 30.203933899999999],
              [-9.6422711, 30.2037099],
              [-9.642476, 30.203557199999999],
              [-9.6426436, 30.203549899999999],
              [-9.642668499999999, 30.203464799999999],
              [-9.642486099999999, 30.2031843],
              [-9.6423588, 30.202971399999999],
              [-9.642388499999999, 30.202550500000001],
              [-9.642265200000001, 30.202114000000002],
              [-9.642107599999999, 30.2014259],
              [-9.641894799999999, 30.200873999999999],
              [-9.642424999999999, 30.200364100000002],
              [-9.642735800000001, 30.2004856],
              [-9.642921400000001, 30.200313099999999],
              [-9.643106299999999, 30.2003126],
              [-9.6431893, 30.200158099999999],
              [-9.643125400000001, 30.200038500000002],
              [-9.643423500000001, 30.1999374],
              [-9.6432191, 30.199764299999998],
              [-9.6428849, 30.1996337],
              [-9.6427669, 30.199677699999999],
              [-9.642745400000001, 30.199712300000002],
              [-9.642751199999999, 30.199954099999999],
              [-9.6426476, 30.200107200000001],
              [-9.642519200000001, 30.200040399999999],
              [-9.642643899999999, 30.1995118],
              [-9.642613000000001, 30.199498500000001],
              [-9.642578800000001, 30.199533200000001],
              [-9.642508400000001, 30.199657800000001],
              [-9.6424892, 30.199645],
              [-9.642473799999999, 30.199431400000002],
              [-9.642374500000001, 30.1994662],
              [-9.642453700000001, 30.1991567],
              [-9.6424161, 30.199128900000002],
              [-9.6423705, 30.1991254],
              [-9.642283300000001, 30.199324799999999],
              [-9.642208200000001, 30.199300099999999],
              [-9.641886100000001, 30.1996267],
              [-9.6416339, 30.1998657],
              [-9.641406699999999, 30.200037399999999],
              [-9.641006000000001, 30.200144999999999],
              [-9.640406, 30.200019999999999],
              [-9.6398948, 30.199504900000001],
              [-9.639669700000001, 30.199203799999999],
              [-9.639332599999999, 30.198712799999999],
              [-9.6389254, 30.197395799999999],
              [-9.638715700000001, 30.196085400000001],
              [-9.638521000000001, 30.195989000000001],
              [-9.638495199999999, 30.195685999999998],
              [-9.6388946, 30.195488900000001],
              [-9.638758599999999, 30.195239699999998],
              [-9.6384904, 30.195181000000002],
              [-9.6384252, 30.194939000000002],
              [-9.638522, 30.194740100000001],
              [-9.638970799999999, 30.194733100000001],
              [-9.6389818, 30.1945263],
              [-9.638645199999999, 30.194489300000001],
              [-9.638666300000001, 30.194220300000001],
              [-9.6389271, 30.1940703],
              [-9.6389686, 30.194420399999998],
              [-9.639253999999999, 30.1941165],
              [-9.6394667, 30.193401000000001],
              [-9.639574, 30.191921300000001],
              [-9.639780500000001, 30.1912047],
              [-9.639980100000001, 30.1908539],
              [-9.639723699999999, 30.190817500000001],
              [-9.639662, 30.190537200000001],
              [-9.639338, 30.190622999999999],
              [-9.639123700000001, 30.1901732],
              [-9.638865600000001, 30.190432399999999],
              [-9.6386457, 30.1901957],
              [-9.638978, 30.1897652],
              [-9.639073, 30.188511599999998],
              [-9.638625299999999, 30.1869552],
              [-9.6393643, 30.184682599999999],
              [-9.6403628, 30.182717],
              [-9.6409304, 30.179948100000001],
              [-9.6414001, 30.179264799999999],
              [-9.6413821, 30.178010199999999],
              [-9.6415913, 30.177129099999998],
              [-9.6420092, 30.176196699999998],
              [-9.642447199999999, 30.176144099999998],
              [-9.642347900000001, 30.175348499999998],
              [-9.6423302, 30.17436],
              [-9.6424442, 30.173474200000001],
              [-9.6423474, 30.172551599999998],
              [-9.642559800000001, 30.1715436],
              [-9.6421639, 30.17071],
              [-9.6426604, 30.1697709],
              [-9.642751799999999, 30.1691979],
              [-9.642481800000001, 30.169008600000002],
              [-9.6429233, 30.1686905],
              [-9.642883599999999, 30.168368399999999],
              [-9.6429312, 30.168069899999999],
              [-9.643371699999999, 30.167663699999999],
              [-9.643478500000001, 30.1661979],
              [-9.6440112, 30.164078100000001],
              [-9.6446854, 30.163071200000001],
              [-9.6449643, 30.162403300000001],
              [-9.645640200000001, 30.161095400000001],
              [-9.645972799999999, 30.160019299999998],
              [-9.6462196, 30.1592865],
              [-9.646305399999999, 30.158729900000001],
              [-9.6460843, 30.158118399999999],
              [-9.6460995, 30.157988599999999],
              [-9.646262500000001, 30.157746599999999],
              [-9.645940599999999, 30.157514599999999],
              [-9.646080100000001, 30.1570508],
              [-9.6461907, 30.1564607],
              [-9.646429100000001, 30.156445999999999],
              [-9.6466335, 30.156283999999999],
              [-9.646668200000001, 30.156072900000002],
              [-9.6464461, 30.155753900000001],
              [-9.646922999999999, 30.155562400000001],
              [-9.6470082, 30.155356300000001],
              [-9.6474747, 30.155435900000001],
              [-9.647229599999999, 30.1551501],
              [-9.646922999999999, 30.154885],
              [-9.6471274, 30.1546789],
              [-9.6469741, 30.1542371],
              [-9.647331700000001, 30.153898399999999],
              [-9.6471955, 30.153279900000001],
              [-9.647638300000001, 30.153132599999999],
              [-9.6479789, 30.152896999999999],
              [-9.6479108, 30.152263699999999],
              [-9.648387700000001, 30.151851400000002],
              [-9.6487623, 30.151439],
              [-9.648574999999999, 30.151115000000001],
              [-9.6484047, 30.150378700000001],
              [-9.6487097, 30.149556199999999],
              [-9.6494608, 30.148684100000001],
              [-9.649716099999999, 30.146637999999999],
              [-9.6498746, 30.146203],
              [-9.650175000000001, 30.1457762],
              [-9.650153599999999, 30.145126699999999],
              [-9.6504995, 30.144075399999998],
              [-9.6510275, 30.143736700000002],
              [-9.650942300000001, 30.143221199999999],
              [-9.651742799999999, 30.142131299999999],
              [-9.6520046, 30.141355799999999],
              [-9.6525721, 30.1409649],
              [-9.6528081, 30.140612399999998],
              [-9.6529369, 30.140111300000001],
              [-9.6526794, 30.139424699999999],
              [-9.652915399999999, 30.138552600000001],
              [-9.652593599999999, 30.137828899999999],
              [-9.6527867, 30.1370495],
              [-9.6530871, 30.136938099999998],
              [-9.6530013, 30.1362515],
              [-9.6533467, 30.135353200000001],
              [-9.654036100000001, 30.134990800000001],
              [-9.6545836, 30.134733600000001],
              [-9.654712, 30.134464699999999],
              [-9.6545363, 30.134154899999999],
              [-9.6540632, 30.1339796],
              [-9.653698199999999, 30.133938700000002],
              [-9.653799599999999, 30.133535299999998],
              [-9.654076699999999, 30.1334184],
              [-9.6539123, 30.132776700000001],
              [-9.6537031, 30.132630500000001],
              [-9.653666899999999, 30.1325021],
              [-9.6535663, 30.132414799999999],
              [-9.6535475, 30.132078400000001],
              [-9.6535989, 30.131826400000001],
              [-9.653487399999999, 30.131627600000002],
              [-9.653622500000001, 30.131528299999999],
              [-9.653700300000001, 30.131350000000001],
              [-9.653693499999999, 30.131209699999999],
              [-9.6536361, 30.131084000000001],
              [-9.653723899999999, 30.1310109],
              [-9.653876, 30.1309203],
              [-9.653970599999999, 30.130712800000001],
              [-9.654055100000001, 30.130595899999999],
              [-9.6540631, 30.130475799999999],
              [-9.6539132, 30.1302685],
              [-9.6540956, 30.129985000000001],
              [-9.6542105, 30.129821400000001],
              [-9.654197, 30.129660600000001],
              [-9.6541599, 30.129485200000001],
              [-9.6545316, 30.128912400000001],
              [-9.654782900000001, 30.1288363],
              [-9.6551232, 30.128797800000001],
              [-9.6551691, 30.128674],
              [-9.655029600000001, 30.128414100000001],
              [-9.654976, 30.1280754],
              [-9.654858000000001, 30.127792400000001],
              [-9.6543966, 30.127801699999999],
              [-9.6541069, 30.1275604],
              [-9.6538548, 30.1273424],
              [-9.6536402, 30.1271196],
              [-9.653645600000001, 30.126822700000002],
              [-9.653945999999999, 30.1263912],
              [-9.6540479, 30.125806600000001],
              [-9.6538602, 30.125143099999999],
              [-9.654090800000001, 30.124535300000002],
              [-9.6544127, 30.124113000000001],
              [-9.654310799999999, 30.123723300000002],
              [-9.654434200000001, 30.1233428],
              [-9.6547131, 30.123147899999999],
              [-9.654949200000001, 30.1228835],
              [-9.655008199999999, 30.1225819],
              [-9.6547131, 30.122131799999998],
              [-9.654831100000001, 30.121588899999999],
              [-9.6551423, 30.120920699999999],
              [-9.6552013, 30.1207259],
              [-9.655512399999999, 30.120401000000001],
              [-9.655491, 30.1201969],
              [-9.655651900000001, 30.119890600000002],
              [-9.655603599999999, 30.119714299999998],
              [-9.6559577, 30.119408100000001],
              [-9.6561231, 30.118846399999999],
              [-9.6578195, 30.1154069],
              [-9.6582069, 30.1147992],
              [-9.658832800000001, 30.113885799999998],
              [-9.659220299999999, 30.113241299999999],
              [-9.6594161, 30.112821400000001],
              [-9.659701399999999, 30.112438300000001],
              [-9.660118600000001, 30.111885900000001],
              [-9.660484800000001, 30.1112118],
              [-9.6609018, 30.110237999999999],
              [-9.661164599999999, 30.1100338],
              [-9.661578799999999, 30.1097109],
              [-9.6620133, 30.109325999999999],
              [-9.6621839, 30.108460600000001],
              [-9.6619156, 30.108251800000001],
              [-9.661953199999999, 30.107899100000001],
              [-9.6615348, 30.107430399999998],
              [-9.661604499999999, 30.107142700000001],
              [-9.661835200000001, 30.106843399999999],
              [-9.662440399999999, 30.10623],
              [-9.6627364, 30.106358400000001],
              [-9.6628799, 30.10615],
              [-9.6626814, 30.105300700000001],
              [-9.6626599, 30.105082599999999],
              [-9.662933499999999, 30.104850599999999],
              [-9.6632768, 30.104810000000001],
              [-9.663410900000001, 30.1048239],
              [-9.663512900000001, 30.104587200000001],
              [-9.663480699999999, 30.103872599999999],
              [-9.663786399999999, 30.1035802],
              [-9.6637127, 30.103189],
              [-9.6638131, 30.102200100000001],
              [-9.6638263, 30.102089200000002],
              [-9.663786399999999, 30.101672799999999],
              [-9.664070799999999, 30.1012226],
              [-9.663802499999999, 30.1008049],
              [-9.664043899999999, 30.1003176],
              [-9.663797199999999, 30.099992700000001],
              [-9.6641517, 30.099373400000001],
              [-9.664671999999999, 30.098931],
              [-9.6643072, 30.0987361],
              [-9.664537899999999, 30.0986154],
              [-9.6644842, 30.098536500000002],
              [-9.6645102, 30.098355399999999],
              [-9.6643823, 30.098211599999999],
              [-9.664462800000001, 30.098030600000001],
              [-9.664387700000001, 30.0979378],
              [-9.6645857, 30.097828199999999],
              [-9.6645325, 30.097520100000001],
              [-9.664784600000001, 30.097380900000001],
              [-9.664736400000001, 30.097260200000001],
              [-9.664854399999999, 30.0970467],
              [-9.664977800000001, 30.096963200000001],
              [-9.664863, 30.0968363],
              [-9.6650636, 30.096118499999999],
              [-9.6652621, 30.096118499999999],
              [-9.6653479, 30.095807499999999],
              [-9.6651977, 30.095440799999999],
              [-9.6656, 30.095055599999998],
              [-9.6656376, 30.094883899999999],
              [-9.6659487, 30.094610100000001],
              [-9.6659112, 30.0944012],
              [-9.666186, 30.094382],
              [-9.6661526, 30.094136599999999],
              [-9.6663028, 30.094113400000001],
              [-9.666404699999999, 30.0938348],
              [-9.666201900000001, 30.0936536],
              [-9.666141100000001, 30.093308499999999],
              [-9.6663236, 30.092805599999998],
              [-9.666695300000001, 30.09187],
              [-9.6671076, 30.091677000000001],
              [-9.667337399999999, 30.091443099999999],
              [-9.6669521, 30.0912267],
              [-9.666789899999999, 30.0910045],
              [-9.6666547, 30.090817399999999],
              [-9.666666299999999, 30.0906819],
              [-9.666898099999999, 30.090589300000001],
              [-9.666911600000001, 30.090472299999998],
              [-9.6667696, 30.090349499999999],
              [-9.666681799999999, 30.090127299999999],
              [-9.6669521, 30.089946000000001],
              [-9.6667629, 30.089817400000001],
              [-9.666756100000001, 30.089624400000002],
              [-9.666918300000001, 30.089600999999998],
              [-9.6670242, 30.089501899999998],
              [-9.666972400000001, 30.089168300000001],
              [-9.6669859, 30.088443099999999],
              [-9.669396900000001, 30.084646899999999],
              [-9.6697515, 30.0842201],
              [-9.6699983, 30.083848799999998],
              [-9.6703162, 30.083167],
              [-9.670826399999999, 30.082434200000002],
              [-9.6711481, 30.081976900000001],
              [-9.671749200000001, 30.081122199999999],
              [-9.672506500000001, 30.0800771],
              [-9.6729886, 30.0794119],
              [-9.6740631, 30.077686499999999],
              [-9.6744494, 30.077129500000002],
              [-9.6749644, 30.0763681],
              [-9.675550599999999, 30.07573],
              [-9.676337, 30.074540599999999],
              [-9.676729399999999, 30.073908899999999],
              [-9.6771634, 30.0733505],
              [-9.677525599999999, 30.072846899999998],
              [-9.6783001, 30.071762799999998],
              [-9.6787507, 30.071238900000001],
              [-9.679506099999999, 30.0700517],
              [-9.680085699999999, 30.0692044],
              [-9.681107900000001, 30.067553499999999],
              [-9.681731299999999, 30.066819500000001],
              [-9.682173499999999, 30.066293600000002],
              [-9.682535700000001, 30.065978600000001],
              [-9.6830224, 30.065328399999999],
              [-9.6840902, 30.064220599999999],
              [-9.6843694, 30.063773099999999],
              [-9.684813399999999, 30.063109300000001],
              [-9.6852211, 30.062645],
              [-9.6857481, 30.062117900000001],
              [-9.686015100000001, 30.061846500000001],
              [-9.686984499999999, 30.060663900000002],
              [-9.6870745, 30.060555099999998],
              [-9.6873465, 30.0602263],
              [-9.6877285, 30.0594128],
              [-9.6883079, 30.0587999],
              [-9.689080300000001, 30.057732000000001],
              [-9.689593, 30.057320300000001],
              [-9.690485799999999, 30.055568399999999],
              [-9.691136800000001, 30.055722100000001],
              [-9.6910188, 30.055322799999999],
              [-9.6913407, 30.0552207],
              [-9.6914587, 30.054886400000001],
              [-9.691868899999999, 30.0549681],
              [-9.692260900000001, 30.054418200000001],
              [-9.692342, 30.053634299999999],
              [-9.6929774, 30.053950199999999],
              [-9.6928152, 30.053388600000002],
              [-9.693288300000001, 30.052791899999999],
              [-9.6935316, 30.053107799999999],
              [-9.6938019, 30.052955699999998],
              [-9.693883, 30.052429199999999],
              [-9.694274999999999, 30.051914400000001],
              [-9.694491299999999, 30.0517389],
              [-9.694329099999999, 30.0510251],
              [-9.694923899999999, 30.0511187],
              [-9.6951131, 30.050358200000002],
              [-9.6955457, 30.0503465],
              [-9.6962495, 30.050658899999998],
              [-9.6964919, 30.050545400000001],
              [-9.6959512, 30.0498668],
              [-9.695248299999999, 30.049738099999999],
              [-9.695045500000001, 30.049340300000001],
              [-9.695302399999999, 30.049106200000001],
              [-9.695713700000001, 30.0490344],
              [-9.6956869, 30.0487511],
              [-9.6960034, 30.048681500000001],
              [-9.696636399999999, 30.049322199999999],
              [-9.697119199999999, 30.049136499999999],
              [-9.6965398, 30.047892099999999],
              [-9.696968999999999, 30.047557699999999],
              [-9.6961643, 30.046043900000001],
              [-9.69669, 30.045050199999999],
              [-9.697001200000001, 30.0447995],
              [-9.6970977, 30.044335100000001],
              [-9.6978595, 30.043647799999999],
              [-9.6977844, 30.043369200000001],
              [-9.6982672, 30.043016300000001],
              [-9.6985569, 30.042616899999999],
              [-9.6983959, 30.0424033],
              [-9.698867999999999, 30.041753199999999],
              [-9.6987822, 30.041298099999999],
              [-9.6992435, 30.0406108],
              [-9.6996941, 30.040564400000001],
              [-9.699200599999999, 30.0401186],
              [-9.699138400000001, 30.039643600000002],
              [-9.699619, 30.039134099999998],
              [-9.6999899, 30.039024399999999],
              [-9.700330599999999, 30.039319200000001],
              [-9.7006712, 30.039275],
              [-9.700585999999999, 30.038980200000001],
              [-9.700330599999999, 30.038508400000001],
              [-9.7001092, 30.038198699999999],
              [-9.700211299999999, 30.037741700000002],
              [-9.7006864, 30.037200899999998],
              [-9.7010629, 30.036739099999998],
              [-9.7014376, 30.036488500000001],
              [-9.7014376, 30.035972399999999],
              [-9.701693000000001, 30.035692300000001],
              [-9.7015227, 30.035338500000002],
              [-9.7018463, 30.034792899999999],
              [-9.7021443, 30.034366200000001],
              [-9.702783, 30.033716600000002],
              [-9.702933399999999, 30.0333197],
              [-9.702795, 30.032806000000001],
              [-9.703288499999999, 30.031775],
              [-9.703621099999999, 30.031505599999999],
              [-9.703417200000001, 30.0311898],
              [-9.7034816, 30.030548899999999],
              [-9.7041807, 30.029723700000002],
              [-9.704747599999999, 30.028393900000001],
              [-9.705307400000001, 30.027622300000001],
              [-9.7061692, 30.026638299999998],
              [-9.706804200000001, 30.024565899999999],
              [-9.708278399999999, 30.024043500000001],
              [-9.7082601, 30.021471699999999],
              [-9.7094404, 30.021208099999999],
              [-9.7094313, 30.019922300000001],
              [-9.7103179, 30.019917400000001],
              [-9.7104514, 30.0178592],
              [-9.7101522, 30.017346400000001],
              [-9.711173199999999, 30.0155405],
              [-9.7120598, 30.015535799999999],
              [-9.712191499999999, 30.013220400000002],
              [-9.713506199999999, 30.0111557],
              [-9.714390699999999, 30.010893800000002],
              [-9.714236100000001, 30.0098658],
              [-9.715258800000001, 30.008317099999999],
              [-9.715801799999999, 30.008053799999999],
              [-9.7161122, 30.008075999999999],
              [-9.716367699999999, 30.008033399999999],
              [-9.7165176, 30.0078356],
              [-9.716500999999999, 30.007254199999998],
              [-9.7162024, 30.006759500000001],
              [-9.7160867, 30.006509000000001],
              [-9.7163448, 30.006053600000001],
              [-9.7164421, 30.005800399999998],
              [-9.7164173, 30.005628699999999],
              [-9.7168844, 30.005341600000001],
              [-9.7170846, 30.005058300000002],
              [-9.7173181, 30.004977400000001],
              [-9.7175826, 30.004440599999999],
              [-9.7177186, 30.004105800000001],
              [-9.7178553, 30.0039038],
              [-9.718117700000001, 30.003693500000001],
              [-9.717995800000001, 30.003603200000001],
              [-9.7178719, 30.0035317],
              [-9.7177986, 30.003273499999999],
              [-9.718086700000001, 30.003102200000001],
              [-9.718298600000001, 30.002941],
              [-9.718466899999999, 30.0027945],
              [-9.718433900000001, 30.002551100000002],
              [-9.7186901, 30.002536800000001],
              [-9.718797500000001, 30.002286300000002],
              [-9.7189049, 30.002043],
              [-9.71874, 30.001718499999999],
              [-9.718881, 30.001602999999999],
              [-9.718946000000001, 30.001429300000002],
              [-9.719322699999999, 30.001300000000001],
              [-9.719445199999999, 30.001044700000001],
              [-9.719279999999999, 30.000665300000001],
              [-9.719379099999999, 30.000364699999999],
              [-9.7197076, 30.000219399999999],
              [-9.719912300000001, 30.000143000000001],
              [-9.7200734, 29.999942399999998],
              [-9.720313900000001, 29.9996182],
              [-9.720343099999999, 29.999617400000002],
              [-9.720486599999999, 29.999613199999999],
              [-9.721031, 29.9996318],
              [-9.7212304, 29.998790100000001],
              [-9.7210403, 29.998582500000001],
              [-9.721147800000001, 29.998374999999999],
              [-9.721437, 29.998396400000001],
              [-9.7216997, 29.997893099999999],
              [-9.7217164, 29.9978242],
              [-9.721784100000001, 29.997544699999999],
              [-9.721933399999999, 29.997295699999999],
              [-9.722585799999999, 29.997308499999999],
              [-9.722858499999999, 29.997079400000001],
              [-9.722833700000001, 29.996836099999999],
              [-9.7226684, 29.996649999999999],
              [-9.7224205, 29.9966571],
              [-9.722164299999999, 29.996807499999999],
              [-9.722118, 29.996448699999998],
              [-9.722100599999999, 29.996041399999999],
              [-9.722078399999999, 29.995515600000001],
              [-9.722063500000001, 29.9951638],
              [-9.722048600000001, 29.9948105],
              [-9.722156, 29.9943667],
              [-9.722403399999999, 29.993976499999999],
              [-9.7228374, 29.993873099999998],
              [-9.723123599999999, 29.992585399999999],
              [-9.724007, 29.992194300000001],
              [-9.7254871, 29.992572299999999],
              [-9.7250295, 29.9905173],
              [-9.725603700000001, 29.988199399999999],
              [-9.726332299999999, 29.986909499999999],
              [-9.726924, 29.987034399999999],
              [-9.7275122, 29.986645800000002],
              [-9.7275028, 29.985359899999999],
              [-9.7283873, 29.9850979],
              [-9.7280862, 29.9843279],
              [-9.7295642, 29.984448],
              [-9.7301523, 29.9840594],
              [-9.7298399, 29.981746399999999],
              [-9.7307243, 29.981484200000001],
              [-9.7307205, 29.9809698],
              [-9.7313113, 29.980966599999999],
              [-9.731301800000001, 29.979680599999998],
              [-9.731892800000001, 29.9796774],
              [-9.732151, 29.978804499999999],
              [-9.731853600000001, 29.978137100000001],
              [-9.7313805, 29.977938000000002],
              [-9.7310696, 29.9774931],
              [-9.731042499999999, 29.976977900000001],
              [-9.7327318, 29.973242899999999],
              [-9.7333245, 29.973496699999998],
              [-9.733463199999999, 29.97221],
              [-9.7346354, 29.9709176],
              [-9.7347711, 29.969373699999998],
              [-9.7368293, 29.968076199999999],
              [-9.736818, 29.966533200000001],
              [-9.7379994, 29.966526600000002],
              [-9.737988100000001, 29.964983400000001],
              [-9.7385787, 29.964980199999999],
              [-9.738864700000001, 29.963692500000001],
              [-9.739455299999999, 29.963689299999999],
              [-9.7394421, 29.961888900000002],
              [-9.7388513, 29.961892299999999],
              [-9.738849399999999, 29.961635099999999],
              [-9.740615999999999, 29.9608536],
              [-9.741194999999999, 29.959307200000001],
              [-9.7417859, 29.959303999999999],
              [-9.741627299999999, 29.9577615],
              [-9.7423555, 29.9564716],
              [-9.742946099999999, 29.9564682],
              [-9.741747699999999, 29.954160300000002],
              [-9.742633700000001, 29.9541553],
              [-9.742919499999999, 29.952867900000001],
              [-9.7435101, 29.9528645],
              [-9.7435063, 29.952350200000001],
              [-9.7440988, 29.9526039],
              [-9.7441026, 29.9531183],
              [-9.744691400000001, 29.9528578],
              [-9.744386499999999, 29.9515736],
              [-9.7452725, 29.9515685],
              [-9.7452763, 29.952082900000001],
              [-9.745571699999999, 29.952081199999999],
              [-9.7455506, 29.949252300000001],
              [-9.747025300000001, 29.948986699999999],
              [-9.7470138, 29.9474436],
              [-9.748193000000001, 29.9471797],
              [-9.7484787, 29.945892099999998],
              [-9.747888100000001, 29.945895400000001],
              [-9.7481758, 29.944864899999999],
              [-9.749355100000001, 29.9446011],
              [-9.7493474, 29.943572400000001],
              [-9.750528600000001, 29.943565599999999],
              [-9.7508161, 29.942535299999999],
              [-9.7522907, 29.942269599999999],
              [-9.752288800000001, 29.942012500000001],
              [-9.7516982, 29.9420158],
              [-9.7513855, 29.939703000000002],
              [-9.752568500000001, 29.9399534],
              [-9.752564700000001, 29.939438899999999],
              [-9.7534487, 29.939176799999998],
              [-9.7531398, 29.9373781],
              [-9.753435100000001, 29.937376400000002],
              [-9.753738, 29.938403399999999],
              [-9.754033400000001, 29.938401899999999],
              [-9.7537284, 29.937117600000001],
              [-9.754614200000001, 29.937112500000001],
              [-9.7543092, 29.935828300000001],
              [-9.7548998, 29.9358249],
              [-9.7545968, 29.934797799999998],
              [-9.7572563, 29.935039799999998],
              [-9.7573729, 29.933179500000001],
              [-9.757014399999999, 29.932849300000001],
              [-9.7568161, 29.932404099999999],
              [-9.7572349, 29.9322108],
              [-9.758118700000001, 29.931948500000001],
              [-9.7584689, 29.9316806],
              [-9.758309499999999, 29.931419500000001],
              [-9.7585947, 29.931030100000001],
              [-9.7587724, 29.930972199999999],
              [-9.7584003, 29.930146700000002],
              [-9.759286100000001, 29.930141599999999],
              [-9.759731199999999, 29.9301846],
              [-9.759768100000001, 29.929935400000002],
              [-9.759248599999999, 29.9292695],
              [-9.7598688, 29.929109400000002],
              [-9.7601862, 29.928462400000001],
              [-9.761780999999999, 29.928259799999999],
              [-9.760437899999999, 29.926276999999999],
              [-9.7610283, 29.926273599999998],
              [-9.760429999999999, 29.925248199999999],
              [-9.761313700000001, 29.924985899999999],
              [-9.761294299999999, 29.922414199999999],
              [-9.761886799999999, 29.9226679],
              [-9.761567899999999, 29.9195834],
              [-9.760384999999999, 29.919333200000001],
              [-9.761115800000001, 29.918299999999999],
              [-9.7613954, 29.916240899999998],
              [-9.760347899999999, 29.9144465],
              [-9.761722300000001, 29.914269000000001],
              [-9.762119200000001, 29.914436299999998],
              [-9.762487399999999, 29.9133213],
              [-9.7634282, 29.913062400000001],
              [-9.7636901, 29.911850300000001],
              [-9.7635065, 29.911335399999999],
              [-9.7631502, 29.911231000000001],
              [-9.763042499999999, 29.910824399999999],
              [-9.7661324, 29.909278499999999],
              [-9.7662548, 29.907867599999999],
              [-9.7672758, 29.9075123],
              [-9.766928399999999, 29.906566300000001],
              [-9.7671645, 29.905673700000001],
              [-9.7681795, 29.904060099999999],
              [-9.768732399999999, 29.903982899999999],
              [-9.7691579, 29.9029065],
              [-9.769600199999999, 29.902543699999999],
              [-9.769592299999999, 29.9020413],
              [-9.7702715, 29.901729799999998],
              [-9.7703851, 29.900753699999999],
              [-9.7711837, 29.900783300000001],
              [-9.771479899999999, 29.900472300000001],
              [-9.7712371, 29.899880599999999],
              [-9.772238, 29.899795999999998],
              [-9.772171800000001, 29.898987399999999],
              [-9.7726299, 29.899200799999999],
              [-9.7726259, 29.898686399999999],
              [-9.7735114, 29.8986813],
              [-9.7732063, 29.897397099999999],
              [-9.7737967, 29.897393699999999],
              [-9.774079800000001, 29.8958488],
              [-9.775258600000001, 29.895584800000002],
              [-9.7758349, 29.893781000000001],
              [-9.776720299999999, 29.893775900000001],
              [-9.7770095, 29.893002500000001],
              [-9.7775999, 29.892999100000001],
              [-9.7778791, 29.890939899999999],
              [-9.779063600000001, 29.891447200000002],
              [-9.7787585, 29.890163099999999],
              [-9.780525300000001, 29.889638300000001],
              [-9.780810499999999, 29.888350599999999],
              [-9.7802221, 29.888611300000001],
              [-9.779623900000001, 29.8875861],
              [-9.7805122, 29.887966200000001],
              [-9.781691800000001, 29.8878311],
              [-9.781392800000001, 29.8873183],
              [-9.782278099999999, 29.887313200000001],
              [-9.7823156, 29.887189800000002],
              [-9.7831435, 29.884736],
              [-9.784613, 29.883955799999999],
              [-9.783568600000001, 29.882418900000001],
              [-9.7837116, 29.881903600000001],
              [-9.784301900000001, 29.881900099999999],
              [-9.784603000000001, 29.8826699],
              [-9.7866608, 29.881628800000001],
              [-9.7865056, 29.8806011],
              [-9.7886658, 29.877429200000002],
              [-9.789395600000001, 29.877690099999999],
              [-9.7898779, 29.876957300000001],
              [-9.790409500000001, 29.8764854],
              [-9.791152500000001, 29.875148100000001],
              [-9.790465899999999, 29.8745972],
              [-9.7903007, 29.874039],
              [-9.7898458, 29.873463099999999],
              [-9.7889886, 29.873018500000001],
              [-9.7882794, 29.8724937],
              [-9.788061600000001, 29.8721043],
              [-9.7876496, 29.871821099999998],
              [-9.7874286, 29.8709001],
              [-9.788080900000001, 29.8705298],
              [-9.787806099999999, 29.8696479],
              [-9.7882149, 29.8683193],
              [-9.7882836, 29.867892300000001],
              [-9.788513200000001, 29.8674857],
              [-9.788906000000001, 29.866521800000001],
              [-9.7887889, 29.865388599999999],
              [-9.7894144, 29.864338199999999],
              [-9.7900378, 29.863419799999999],
              [-9.790642399999999, 29.8625726],
              [-9.790847899999999, 29.862144099999998],
              [-9.791182600000001, 29.861669599999999],
              [-9.791569900000001, 29.861166399999998],
              [-9.791932600000001, 29.860489000000001],
              [-9.793247900000001, 29.8587007],
              [-9.7949786, 29.8561652],
              [-9.796146800000001, 29.854827100000001],
              [-9.797066299999999, 29.8540466],
              [-9.7974955, 29.8539338],
              [-9.797734699999999, 29.853563300000001],
              [-9.798192800000001, 29.853154],
              [-9.7985273, 29.852237500000001],
              [-9.7990458, 29.851814900000001],
              [-9.799403999999999, 29.851203399999999],
              [-9.799979199999999, 29.8508016],
              [-9.8005738, 29.849910300000001],
              [-9.8011088, 29.849751000000001],
              [-9.8014548, 29.849390499999998],
              [-9.801958600000001, 29.849022399999999],
              [-9.802333900000001, 29.8486136],
              [-9.8028294, 29.847834200000001],
              [-9.8035014, 29.847071499999998],
              [-9.804333099999999, 29.846232700000002],
              [-9.8049879, 29.845739999999999],
              [-9.8054816, 29.844904700000001],
              [-9.8059821, 29.843791700000001],
              [-9.806156700000001, 29.8434037],
              [-9.806438099999999, 29.842866300000001],
              [-9.8073318, 29.8420171],
              [-9.8080751, 29.841468800000001],
              [-9.809025, 29.8406266],
              [-9.809981499999999, 29.8398726],
              [-9.810374599999999, 29.839562699999998],
              [-9.810810399999999, 29.838788399999999],
              [-9.811681, 29.8382237],
              [-9.812275, 29.837493599999998],
              [-9.8127443, 29.837170199999999],
              [-9.8130066, 29.8367173],
              [-9.8137373, 29.835949800000002],
              [-9.8146006, 29.8353322],
              [-9.8150748, 29.8347598],
              [-9.815597199999999, 29.834200800000001],
              [-9.8160907, 29.833614399999998],
              [-9.816959199999999, 29.833092300000001],
              [-9.817227900000001, 29.831808899999999],
              [-9.8172601, 29.831334200000001],
              [-9.817592700000001, 29.8305431],
              [-9.818116099999999, 29.830255999999999],
              [-9.818847999999999, 29.829621700000001],
              [-9.819728400000001, 29.828959999999999],
              [-9.820899199999999, 29.8277486],
              [-9.821337099999999, 29.826894599999999],
              [-9.8222705, 29.825498400000001],
              [-9.8230871, 29.824952100000001],
              [-9.823586000000001, 29.824383600000001],
              [-9.823781, 29.824161400000001],
              [-9.8242387, 29.823598799999999],
              [-9.8248411, 29.823021700000002],
              [-9.825056999999999, 29.822541099999999],
              [-9.825420299999999, 29.8217322],
              [-9.826082599999999, 29.821165300000001],
              [-9.826572799999999, 29.820723399999999],
              [-9.827314700000001, 29.818882200000001],
              [-9.827249399999999, 29.818387999999999],
              [-9.8275123, 29.8180063],
              [-9.827228, 29.817531599999999],
              [-9.827272499999999, 29.816727499999999],
              [-9.8273066, 29.816254700000002],
              [-9.8276299, 29.8158916],
              [-9.827685600000001, 29.815825499999999],
              [-9.8277319, 29.8157706],
              [-9.8278713, 29.815472700000001],
              [-9.8280055, 29.815277200000001],
              [-9.8278932, 29.815162600000001],
              [-9.8281557, 29.815165499999999],
              [-9.828257600000001, 29.815072399999998],
              [-9.828225399999999, 29.814969999999999],
              [-9.8284292, 29.814811800000001],
              [-9.8282039, 29.8146442],
              [-9.828391699999999, 29.814592999999999],
              [-9.8282737, 29.814420800000001],
              [-9.8283755, 29.8143642],
              [-9.828633099999999, 29.814490599999999],
              [-9.828675, 29.8144706],
              [-9.828707400000001, 29.814424899999999],
              [-9.8284346, 29.814239300000001],
              [-9.828606300000001, 29.814188099999999],
              [-9.828649199999999, 29.814062400000001],
              [-9.828774299999999, 29.8140161],
              [-9.8286438, 29.8138437],
              [-9.828772600000001, 29.813885599999999],
              [-9.8289683, 29.813850800000001],
              [-9.828863800000001, 29.8135598],
              [-9.8289192, 29.813504900000002],
              [-9.829014000000001, 29.813522500000001],
              [-9.829013399999999, 29.8133409],
              [-9.8288601, 29.8132947],
              [-9.8289603, 29.813299099999998],
              [-9.8290998, 29.813178099999998],
              [-9.828965699999999, 29.813122199999999],
              [-9.8291159, 29.813117600000002],
              [-9.8291465, 29.812959899999999],
              [-9.8289925, 29.8128849],
              [-9.8290366, 29.812794199999999],
              [-9.8291749, 29.8127499],
              [-9.8292112, 29.8125863],
              [-9.829186699999999, 29.8125231],
              [-9.829137299999999, 29.812396199999998],
              [-9.829105200000001, 29.812233200000001],
              [-9.829212500000001, 29.812247200000002],
              [-9.8292714, 29.8121334],
              [-9.8291588, 29.811972600000001],
              [-9.8293251, 29.811977200000001],
              [-9.829437799999999, 29.8118935],
              [-9.8294753, 29.811749200000001],
              [-9.8292983, 29.811563],
              [-9.829346599999999, 29.8113861],
              [-9.8294002, 29.811292999999999],
              [-9.829410899999999, 29.811162700000001],
              [-9.829534300000001, 29.811097499999999],
              [-9.829512899999999, 29.810953300000001],
              [-9.829679199999999, 29.810933599999998],
              [-9.829684500000001, 29.810822900000002],
              [-9.8298991, 29.8107717],
              [-9.8301941, 29.810478499999999],
              [-9.830277799999999, 29.810074400000001],
              [-9.8302377, 29.810017999999999],
              [-9.830513399999999, 29.809988100000002],
              [-9.830433899999999, 29.809864300000001],
              [-9.830498199999999, 29.809552400000001],
              [-9.830809199999999, 29.809114999999998],
              [-9.8309698, 29.8088941],
              [-9.831133700000001, 29.808701200000002],
              [-9.831458599999999, 29.808373100000001],
              [-9.831647999999999, 29.808144299999999],
              [-9.8325684, 29.807141000000001],
              [-9.8331038, 29.806627500000001],
              [-9.8337009, 29.806054799999998],
              [-9.8344475, 29.8052162],
              [-9.8347149, 29.804897199999999],
              [-9.8351259, 29.8046392],
              [-9.837090399999999, 29.802858199999999],
              [-9.8373743, 29.802767899999999],
              [-9.837475700000001, 29.802652399999999],
              [-9.837382099999999, 29.802263700000001],
              [-9.8374664, 29.801983100000001],
              [-9.8396835, 29.799922299999999],
              [-9.8411142, 29.7986054],
              [-9.843508399999999, 29.7966172],
              [-9.8444346, 29.796537000000001],
              [-9.8444868, 29.7960478],
              [-9.8453119, 29.795152099999999],
              [-9.847526800000001, 29.7932542],
              [-9.851540099999999, 29.789866700000001],
              [-9.852678600000001, 29.788594400000001],
              [-9.854699500000001, 29.786704199999999],
              [-9.856334, 29.7852642],
              [-9.8573188, 29.7843847],
              [-9.857833299999999, 29.783887499999999],
              [-9.8586025, 29.783208699999999],
              [-9.8590365, 29.782595499999999],
              [-9.8597508, 29.782043999999999],
              [-9.8613201, 29.780639999999998],
              [-9.862441199999999, 29.7796752],
              [-9.8639788, 29.778010699999999],
              [-9.8649191, 29.777094399999999],
              [-9.867197300000001, 29.7751606],
              [-9.869166399999999, 29.773116300000002],
              [-9.879385600000001, 29.763975500000001],
              [-9.8867069, 29.757744599999999],
              [-9.894403499999999, 29.751317499999999],
              [-9.894949199999999, 29.7505527],
              [-9.900574300000001, 29.746367100000001],
              [-9.902254900000001, 29.744794200000001],
              [-9.903658200000001, 29.744087199999999],
              [-9.9043642, 29.743651199999999],
              [-9.9058201, 29.742496599999999],
              [-9.9075747, 29.741067999999999],
              [-9.9120793, 29.737713800000002],
              [-9.9149063, 29.735707099999999],
              [-9.9167813, 29.734614499999999],
              [-9.9175928, 29.7340266],
              [-9.919098099999999, 29.7326142],
              [-9.920759500000001, 29.731214000000001],
              [-9.9227965, 29.7297753],
              [-9.924567100000001, 29.728807],
              [-9.925432000000001, 29.727956500000001],
              [-9.926016499999999, 29.727438100000001],
              [-9.928069600000001, 29.726395],
              [-9.9289185, 29.725332099999999],
              [-9.9300461, 29.724663100000001],
              [-9.9318013, 29.723126700000002],
              [-9.933633499999999, 29.7216849],
              [-9.9353836, 29.720685100000001],
              [-9.9364062, 29.719777799999999],
              [-9.936552499999999, 29.719648100000001],
              [-9.937426800000001, 29.718613099999999],
              [-9.9385958, 29.717576099999999],
              [-9.940381, 29.716255199999999],
              [-9.9409271, 29.715589900000001],
              [-9.9413444, 29.7151599],
              [-9.9429792, 29.713687],
              [-9.943951, 29.713084800000001],
              [-9.9448302, 29.7125117],
              [-9.9456139, 29.711867900000001],
              [-9.946483300000001, 29.7103185],
              [-9.947090299999999, 29.709975199999999],
              [-9.9477034, 29.709575900000001],
              [-9.9481213, 29.7092554],
              [-9.9485309, 29.708760699999999],
              [-9.9489848, 29.708094899999999],
              [-9.949589899999999, 29.707542700000001],
              [-9.9505415, 29.7068434],
              [-9.951018400000001, 29.706338299999999],
              [-9.952038200000001, 29.705207900000001],
              [-9.9536035, 29.703969399999998],
              [-9.955836700000001, 29.702610700000001],
              [-9.9567163, 29.702014800000001],
              [-9.9575727, 29.701395000000002],
              [-9.958766199999999, 29.7007139],
              [-9.9602007, 29.6997198],
              [-9.9604675, 29.699513199999998],
              [-9.9610933, 29.699028800000001],
              [-9.961428099999999, 29.698451500000001],
              [-9.962809399999999, 29.697206300000001],
              [-9.963729900000001, 29.696048399999999],
              [-9.9649369, 29.694841],
              [-9.9659198, 29.693974900000001],
              [-9.9656194, 29.692813000000001],
              [-9.9654267, 29.6920669],
              [-9.9666005, 29.690256900000001],
              [-9.967624900000001, 29.688640400000001],
              [-9.968950400000001, 29.6875228],
              [-9.969529700000001, 29.686489900000002],
              [-9.970997300000001, 29.6859647],
              [-9.9709924, 29.685450400000001],
              [-9.971581499999999, 29.6854461],
              [-9.971576600000001, 29.684931800000001],
              [-9.972165499999999, 29.684927500000001],
              [-9.972744799999999, 29.6838944],
              [-9.9733337, 29.683890000000002],
              [-9.973620800000001, 29.683116299999998],
              [-9.9747939, 29.682593300000001],
              [-9.976577000000001, 29.6799441],
              [-9.978295900000001, 29.679224000000001],
              [-9.978740999999999, 29.678600899999999],
              [-9.9793354, 29.677966099999999],
              [-9.9797785, 29.676908099999999],
              [-9.9824427, 29.676092400000002],
              [-9.982421, 29.6756925],
              [-9.981949500000001, 29.674078600000001],
              [-9.982843799999999, 29.6727338],
              [-9.983701, 29.672393700000001],
              [-9.9841756, 29.671229199999999],
              [-9.9852173, 29.669862599999998],
              [-9.984857699999999, 29.669622799999999],
              [-9.983950099999999, 29.669914500000001],
              [-9.9835014, 29.6694207],
              [-9.9833116, 29.668542500000001],
              [-9.983745499999999, 29.667773100000002],
              [-9.983669000000001, 29.667052699999999],
              [-9.9838889, 29.666783299999999],
              [-9.9838653, 29.666167099999999],
              [-9.9846442, 29.665548099999999],
              [-9.9840734, 29.665299099999999],
              [-9.984045500000001, 29.664455400000001],
              [-9.984671000000001, 29.664312800000001],
              [-9.9847834, 29.6638406],
              [-9.984674200000001, 29.663429900000001],
              [-9.9848985, 29.662917199999999],
              [-9.9853405, 29.6627428],
              [-9.985056200000001, 29.6619551],
              [-9.9852343, 29.6608941],
              [-9.985773999999999, 29.660251800000001],
              [-9.9863222, 29.660597599999999],
              [-9.987750200000001, 29.659618699999999],
              [-9.987721199999999, 29.658690799999999],
              [-9.9880646, 29.657982000000001],
              [-9.9890948, 29.6574229],
              [-9.9893839, 29.657785799999999],
              [-9.989975100000001, 29.657500899999999],
              [-9.9900167, 29.656713799999999],
              [-9.989432799999999, 29.656339299999999],
              [-9.9895622, 29.655747699999999],
              [-9.990954800000001, 29.655412999999999],
              [-9.991250900000001, 29.653944599999999],
              [-9.9918377, 29.652657999999999],
              [-9.9928141, 29.6523428],
              [-9.9924277, 29.651357399999998],
              [-9.992924199999999, 29.650261799999999],
              [-9.993551800000001, 29.6502844],
              [-9.994499899999999, 29.649783100000001],
              [-9.9944948, 29.649268800000002],
              [-9.9952527, 29.6484472],
              [-9.997032600000001, 29.647771200000001],
              [-9.998302000000001, 29.647182699999998],
              [-9.998587199999999, 29.646159099999998],
              [-9.999170100000001, 29.645633],
              [-9.999630099999999, 29.645594899999999],
              [-9.9996998, 29.64536],
              [-10.000225500000001, 29.645941799999999],
              [-10.000762, 29.6457385],
              [-10.000921200000001, 29.644076600000002],
              [-10.0020989, 29.644067700000001],
              [-10.0029507, 29.643712300000001],
              [-10.0029719, 29.6430322],
              [-10.0026647, 29.641748700000001],
              [-10.0038424, 29.641739600000001],
              [-10.0041291, 29.6409661],
              [-10.0047178, 29.6409615],
              [-10.00586, 29.639375300000001],
              [-10.0066425, 29.638763600000001],
              [-10.006637100000001, 29.6378488],
              [-10.007934199999999, 29.636714900000001],
              [-10.0089138, 29.636997399999998],
              [-10.008979200000001, 29.635994],
              [-10.0094631, 29.635771099999999],
              [-10.0095189, 29.635088499999998],
              [-10.0077803, 29.634816099999998],
              [-10.0075588, 29.634615100000001],
              [-10.007379200000001, 29.634452100000001],
              [-10.0073314, 29.634050500000001],
              [-10.007448399999999, 29.633594200000001],
              [-10.0076702, 29.633161999999999],
              [-10.0081664, 29.6328943],
              [-10.0087145, 29.632777900000001],
              [-10.009039599999999, 29.6327669],
              [-10.0091356, 29.632555799999999],
              [-10.009087600000001, 29.632332600000002],
              [-10.0093332, 29.6321975],
              [-10.009844299999999, 29.6320625],
              [-10.010381499999999, 29.6314326],
              [-10.0103215, 29.631093100000001],
              [-10.0098068, 29.630642999999999],
            ],
          ],
          [
            [
              [-9.688620200000001, 30.508762300000001],
              [-9.688701099999999, 30.508705500000001],
              [-9.688718100000001, 30.508657800000002],
              [-9.6886543, 30.5085789],
              [-9.688598900000001, 30.5085844],
              [-9.688545700000001, 30.5086376],
              [-9.6885627, 30.508732999999999],
              [-9.688620200000001, 30.508762300000001],
            ],
          ],
          [
            [
              [-9.6884563, 30.5085257],
              [-9.6884754, 30.508446899999999],
              [-9.688435, 30.508386300000002],
              [-9.6883924, 30.5084175],
              [-9.6883967, 30.508500099999999],
              [-9.688424299999999, 30.508540400000001],
              [-9.6884563, 30.5085257],
            ],
          ],
          [
            [
              [-9.6275838, 30.266559699999998],
              [-9.627612600000001, 30.2665218],
              [-9.627511200000001, 30.2665218],
              [-9.6274537, 30.266477999999999],
              [-9.627409800000001, 30.266477999999999],
              [-9.627350699999999, 30.266482400000001],
              [-9.6272848, 30.266508699999999],
              [-9.627303299999999, 30.266559699999998],
              [-9.6274318, 30.2665568],
              [-9.6275838, 30.266559699999998],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      region: "Laayoune-Sakia El Hamra",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-14.905000000000001, 24.685000000000002],
              [-14.890000000000001, 24.690000000000001],
              [-14.880000000000001, 24.705000000000002],
              [-14.885, 24.765000000000001],
              [-14.875, 24.780000000000001],
              [-14.875, 24.805],
              [-14.85, 24.844999999999999],
              [-14.85, 24.865000000000002],
              [-14.84, 24.870000000000001],
              [-14.83, 24.900000000000002],
              [-14.835000000000001, 24.945],
              [-14.82, 25],
              [-14.825000000000001, 25.004999999999999],
              [-14.82, 25.060000000000002],
              [-14.825000000000001, 25.074999999999999],
              [-14.84, 25.080000000000002],
              [-14.835000000000001, 25.105],
              [-14.845000000000001, 25.120000000000001],
              [-14.835000000000001, 25.16],
              [-14.845000000000001, 25.215],
              [-14.825000000000001, 25.245000000000001],
              [-14.83, 25.295000000000002],
              [-14.81, 25.32],
              [-14.815, 25.34],
              [-14.805, 25.344999999999999],
              [-14.790000000000001, 25.375],
              [-14.795, 25.400000000000002],
              [-14.785, 25.425000000000001],
              [-14.775, 25.43],
              [-14.77, 25.449999999999999],
              [-14.73, 25.484999999999999],
              [-14.710000000000001, 25.530000000000001],
              [-14.695, 25.545000000000002],
              [-14.685, 25.574999999999999],
              [-14.685, 25.625],
              [-14.665000000000001, 25.66],
              [-14.66, 25.699999999999999],
              [-14.640000000000001, 25.745000000000001],
              [-14.640000000000001, 25.77],
              [-14.609999999999999, 25.789999999999999],
              [-14.585000000000001, 25.830000000000002],
              [-14.57, 25.84],
              [-14.565, 25.865000000000002],
              [-14.540000000000001, 25.895],
              [-14.525, 25.940000000000001],
              [-14.515000000000001, 25.945],
              [-14.51, 25.975000000000001],
              [-14.5, 25.98],
              [-14.495000000000001, 26.025000000000002],
              [-14.484999999999999, 26.035],
              [-14.49, 26.045000000000002],
              [-14.484999999999999, 26.100000000000001],
              [-14.5, 26.135000000000002],
              [-14.484999999999999, 26.164999999999999],
              [-14.475, 26.170000000000002],
              [-14.470000000000001, 26.190000000000001],
              [-14.4, 26.265000000000001],
              [-14.365, 26.265000000000001],
              [-14.315, 26.280000000000001],
              [-14.290000000000001, 26.310000000000002],
              [-14.25, 26.330000000000002],
              [-14.24, 26.350000000000001],
              [-14.205, 26.379999999999999],
              [-14.185, 26.425000000000001],
              [-14.125, 26.425000000000001],
              [-14.07, 26.435000000000002],
              [-13.975, 26.469999999999999],
              [-13.925000000000001, 26.515000000000001],
              [-13.885, 26.535],
              [-13.870000000000001, 26.535],
              [-13.845000000000001, 26.560000000000002],
              [-13.800000000000001, 26.574999999999999],
              [-13.665000000000001, 26.650000000000002],
              [-13.56, 26.745000000000001],
              [-13.495000000000001, 26.879999999999999],
              [-13.49, 26.920000000000002],
              [-13.48, 26.93],
              [-13.48, 26.949999999999999],
              [-13.470000000000001, 26.960000000000001],
              [-13.465, 26.984999999999999],
              [-13.44, 27.025000000000002],
              [-13.43, 27.055],
              [-13.435, 27.060000000000002],
              [-13.42, 27.074999999999999],
              [-13.425000000000001, 27.100000000000001],
              [-13.415000000000001, 27.115000000000002],
              [-13.41, 27.164999999999999],
              [-13.375, 27.210000000000001],
              [-13.365, 27.240000000000002],
              [-13.32, 27.289999999999999],
              [-13.300000000000001, 27.330000000000002],
              [-13.300000000000001, 27.350000000000001],
              [-13.290000000000001, 27.355],
              [-13.275, 27.385000000000002],
              [-13.255000000000001, 27.460000000000001],
              [-13.23, 27.5],
              [-13.220000000000001, 27.555],
              [-13.205, 27.574999999999999],
              [-13.200000000000001, 27.620000000000001],
              [-13.165000000000001, 27.68],
              [-13.165000000000001, 27.695],
              [-13.130000000000001, 27.705000000000002],
              [-13.109999999999999, 27.725000000000001],
              [-13.06, 27.740000000000002],
              [-13.040000000000001, 27.760000000000002],
              [-13.030000000000001, 27.795000000000002],
              [-13.005000000000001, 27.815000000000001],
              [-13, 27.850000000000001],
              [-12.99, 27.855],
              [-12.984999999999999, 27.870000000000001],
              [-12.99, 27.875],
              [-12.984999999999999, 27.890000000000001],
              [-12.965, 27.905000000000001],
              [-12.955, 27.925000000000001],
              [-12.935, 27.93],
              [-12.935, 27.940000000000001],
              [-12.92, 27.955000000000002],
              [-12.859999999999999, 27.955000000000002],
              [-12.805, 27.969999999999999],
              [-12.75, 27.969999999999999],
              [-12.720000000000001, 27.98],
              [-12.66, 27.98],
              [-12.555, 27.995000000000001],
              [-12.535, 28.004999999999999],
              [-12.08, 28.085000000000001],
              [-12.050000000000001, 28.094999999999999],
              [-12.040000000000001, 28.109999999999999],
              [-12.02, 28.120000000000001],
              [-11.98, 28.129999999999999],
              [-11.955, 28.150000000000002],
              [-11.890000000000001, 28.175000000000001],
              [-11.870000000000001, 28.175000000000001],
              [-11.81, 28.205000000000002],
              [-11.779999999999999, 28.210000000000001],
              [-11.77, 28.18],
              [-11.745000000000001, 28.150000000000002],
              [-11.710000000000001, 28.125],
              [-11.700000000000001, 28.060000000000002],
              [-11.665000000000001, 27.990000000000002],
              [-11.585000000000001, 27.865000000000002],
              [-11.550000000000001, 27.835000000000001],
              [-11.484999999999999, 27.805],
              [-11.404999999999999, 27.805],
              [-11.26, 27.77],
              [-11.19, 27.740000000000002],
              [-11.154999999999999, 27.740000000000002],
              [-11.040000000000001, 27.754999999999999],
              [-10.9, 27.760000000000002],
              [-10.870000000000001, 27.775000000000002],
              [-10.785, 27.785],
              [-10.755000000000001, 27.785],
              [-10.720000000000001, 27.77],
              [-10.305, 27.815000000000001],
              [-10.25, 27.810000000000002],
              [-10.210000000000001, 27.800000000000001],
              [-10.135, 27.715],
              [-10.109999999999999, 27.699999999999999],
              [-10.09, 27.710000000000001],
              [-10.06, 27.710000000000001],
              [-10.040000000000001, 27.690000000000001],
              [-10.035, 27.670000000000002],
              [-9.984999999999999, 27.640000000000001],
              [-9.965, 27.635000000000002],
              [-9.915000000000001, 27.650000000000002],
              [-9.9, 27.675000000000001],
              [-9.859999999999999, 27.66],
              [-9.815, 27.615000000000002],
              [-9.815, 27.59],
              [-9.765000000000001, 27.535],
              [-9.605, 27.445],
              [-9.545, 27.400000000000002],
              [-9.404999999999999, 27.425000000000001],
              [-9.290000000000001, 27.469999999999999],
              [-9.17, 27.41],
              [-9.06, 27.315000000000001],
              [-8.970000000000001, 27.280000000000001],
              [-8.875, 27.185000000000002],
              [-8.755000000000001, 27.145],
              [-8.67, 27.135000000000002],
              [-8.665000000000001, 26],
              [-12, 26],
              [-12, 24.385000000000002],
              [-12.115, 24.414999999999999],
              [-12.265000000000001, 24.440000000000001],
              [-12.32, 24.435000000000002],
              [-12.359999999999999, 24.420000000000002],
              [-12.58, 24.379999999999999],
              [-12.685, 24.405000000000001],
              [-12.92, 24.490000000000002],
              [-13.120000000000001, 24.525000000000002],
              [-13.295, 24.515000000000001],
              [-13.515000000000001, 24.435000000000002],
              [-13.69, 24.390000000000001],
              [-13.81, 24.43],
              [-14.075000000000001, 24.484999999999999],
              [-14.305, 24.475000000000001],
              [-14.505000000000001, 24.414999999999999],
              [-14.565, 24.435000000000002],
              [-14.609999999999999, 24.475000000000001],
              [-14.620000000000001, 24.5],
              [-14.665000000000001, 24.525000000000002],
              [-14.785, 24.555],
              [-14.865, 24.620000000000001],
              [-14.905000000000001, 24.685000000000002],
            ],
          ],
          [
            [
              [-13.43, 27.09],
              [-13.435, 27.09],
              [-13.43, 27.094999999999999],
              [-13.43, 27.09],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      region: "Dakhla-Oued Ed-Dahab",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-17.105, 20.844999999999999],
              [-17.080000000000002, 20.875],
              [-17.080000000000002, 20.900000000000002],
              [-17.094999999999999, 20.914999999999999],
              [-17.094999999999999, 20.93],
              [-17.085000000000001, 20.940000000000001],
              [-17.085000000000001, 20.965],
              [-17.065000000000001, 20.990000000000002],
              [-17.059999999999999, 21.07],
              [-17.065000000000001, 21.09],
              [-17.055, 21.105],
              [-17.055, 21.135000000000002],
              [-17.045000000000002, 21.155000000000001],
              [-17.050000000000001, 21.205000000000002],
              [-17.030000000000001, 21.280000000000001],
              [-17.030000000000001, 21.350000000000001],
              [-17.015000000000001, 21.385000000000002],
              [-17.010000000000002, 21.449999999999999],
              [-16.990000000000002, 21.510000000000002],
              [-16.995000000000001, 21.525000000000002],
              [-16.98, 21.559999999999999],
              [-16.984999999999999, 21.620000000000001],
              [-16.975000000000001, 21.629999999999999],
              [-16.975000000000001, 21.670000000000002],
              [-16.984999999999999, 21.710000000000001],
              [-16.98, 21.725000000000001],
              [-16.949999999999999, 21.754999999999999],
              [-16.949999999999999, 21.77],
              [-16.955000000000002, 21.775000000000002],
              [-16.965, 21.77],
              [-16.955000000000002, 21.835000000000001],
              [-16.940000000000001, 21.855],
              [-16.93, 21.855],
              [-16.914999999999999, 21.870000000000001],
              [-16.914999999999999, 21.879999999999999],
              [-16.899999999999999, 21.895],
              [-16.899999999999999, 21.940000000000001],
              [-16.879999999999999, 21.965],
              [-16.865000000000002, 22.025000000000002],
              [-16.850000000000001, 22.039999999999999],
              [-16.809999999999999, 22.16],
              [-16.77, 22.18],
              [-16.77, 22.195],
              [-16.780000000000001, 22.205000000000002],
              [-16.765000000000001, 22.215],
              [-16.765000000000001, 22.225000000000001],
              [-16.73, 22.240000000000002],
              [-16.684999999999999, 22.295000000000002],
              [-16.649999999999999, 22.295000000000002],
              [-16.649999999999999, 22.280000000000001],
              [-16.640000000000001, 22.280000000000001],
              [-16.640000000000001, 22.27],
              [-16.605, 22.27],
              [-16.555, 22.285],
              [-16.510000000000002, 22.309999999999999],
              [-16.495000000000001, 22.324999999999999],
              [-16.495000000000001, 22.350000000000001],
              [-16.475000000000001, 22.359999999999999],
              [-16.449999999999999, 22.43],
              [-16.440000000000001, 22.504999999999999],
              [-16.420000000000002, 22.530000000000001],
              [-16.399999999999999, 22.535],
              [-16.359999999999999, 22.574999999999999],
              [-16.359999999999999, 22.609999999999999],
              [-16.344999999999999, 22.640000000000001],
              [-16.34, 22.675000000000001],
              [-16.344999999999999, 22.725000000000001],
              [-16.330000000000002, 22.775000000000002],
              [-16.289999999999999, 22.824999999999999],
              [-16.289999999999999, 22.850000000000001],
              [-16.300000000000001, 22.850000000000001],
              [-16.305, 22.865000000000002],
              [-16.289999999999999, 22.879999999999999],
              [-16.285, 22.905000000000001],
              [-16.280000000000001, 22.895],
              [-16.265000000000001, 22.895],
              [-16.254999999999999, 22.905000000000001],
              [-16.215, 22.914999999999999],
              [-16.164999999999999, 22.960000000000001],
              [-16.145, 22.995000000000001],
              [-16.140000000000001, 23.039999999999999],
              [-16.155000000000001, 23.074999999999999],
              [-16.170000000000002, 23.09],
              [-16.205000000000002, 23.085000000000001],
              [-16.190000000000001, 23.125],
              [-16.170000000000002, 23.135000000000002],
              [-16.149999999999999, 23.155000000000001],
              [-16.149999999999999, 23.164999999999999],
              [-16.125, 23.184999999999999],
              [-16.115000000000002, 23.205000000000002],
              [-16.115000000000002, 23.234999999999999],
              [-16.094999999999999, 23.25],
              [-16.080000000000002, 23.285],
              [-16.059999999999999, 23.305],
              [-16.065000000000001, 23.330000000000002],
              [-16.045000000000002, 23.34],
              [-15.985000000000001, 23.405000000000001],
              [-15.950000000000001, 23.475000000000001],
              [-15.975, 23.510000000000002],
              [-15.945, 23.525000000000002],
              [-15.915000000000001, 23.565000000000001],
              [-15.9, 23.57],
              [-15.9, 23.585000000000001],
              [-15.875, 23.615000000000002],
              [-15.875, 23.629999999999999],
              [-15.860000000000001, 23.629999999999999],
              [-15.85, 23.640000000000001],
              [-15.84, 23.664999999999999],
              [-15.800000000000001, 23.710000000000001],
              [-15.780000000000001, 23.754999999999999],
              [-15.76, 23.760000000000002],
              [-15.755000000000001, 23.775000000000002],
              [-15.745000000000001, 23.775000000000002],
              [-15.74, 23.785],
              [-15.735000000000001, 23.809999999999999],
              [-15.710000000000001, 23.82],
              [-15.710000000000001, 23.835000000000001],
              [-15.725, 23.835000000000001],
              [-15.73, 23.824999999999999],
              [-15.74, 23.830000000000002],
              [-15.735000000000001, 23.859999999999999],
              [-15.755000000000001, 23.900000000000002],
              [-15.765000000000001, 23.895],
              [-15.77, 23.900000000000002],
              [-15.765000000000001, 23.920000000000002],
              [-15.790000000000001, 23.900000000000002],
              [-15.795, 23.885000000000002],
              [-15.805, 23.879999999999999],
              [-15.82, 23.885000000000002],
              [-15.835000000000001, 23.865000000000002],
              [-15.845000000000001, 23.865000000000002],
              [-15.860000000000001, 23.835000000000001],
              [-15.880000000000001, 23.824999999999999],
              [-15.880000000000001, 23.809999999999999],
              [-15.895, 23.800000000000001],
              [-15.9, 23.77],
              [-15.91, 23.760000000000002],
              [-15.905000000000001, 23.75],
              [-15.915000000000001, 23.734999999999999],
              [-15.915000000000001, 23.715],
              [-15.925000000000001, 23.710000000000001],
              [-15.935, 23.684999999999999],
              [-15.970000000000001, 23.66],
              [-15.970000000000001, 23.650000000000002],
              [-15.985000000000001, 23.640000000000001],
              [-15.98, 23.629999999999999],
              [-16.004999999999999, 23.635000000000002],
              [-16, 23.66],
              [-15.985000000000001, 23.670000000000002],
              [-15.975, 23.690000000000001],
              [-15.965, 23.690000000000001],
              [-15.960000000000001, 23.725000000000001],
              [-15.94, 23.734999999999999],
              [-15.92, 23.760000000000002],
              [-15.925000000000001, 23.775000000000002],
              [-15.905000000000001, 23.82],
              [-15.865, 23.855],
              [-15.860000000000001, 23.870000000000001],
              [-15.835000000000001, 23.895],
              [-15.805, 23.91],
              [-15.805, 23.925000000000001],
              [-15.790000000000001, 23.925000000000001],
              [-15.75, 23.960000000000001],
              [-15.665000000000001, 23.995000000000001],
              [-15.66, 24.004999999999999],
              [-15.630000000000001, 24.015000000000001],
              [-15.620000000000001, 24.030000000000001],
              [-15.595000000000001, 24.039999999999999],
              [-15.575000000000001, 24.065000000000001],
              [-15.585000000000001, 24.074999999999999],
              [-15.58, 24.09],
              [-15.555, 24.100000000000001],
              [-15.540000000000001, 24.120000000000001],
              [-15.530000000000001, 24.120000000000001],
              [-15.52, 24.145],
              [-15.5, 24.155000000000001],
              [-15.48, 24.175000000000001],
              [-15.48, 24.185000000000002],
              [-15.465, 24.190000000000001],
              [-15.455, 24.210000000000001],
              [-15.44, 24.215],
              [-15.415000000000001, 24.245000000000001],
              [-15.365, 24.27],
              [-15.34, 24.300000000000001],
              [-15.33, 24.300000000000001],
              [-15.325000000000001, 24.315000000000001],
              [-15.275, 24.365000000000002],
              [-15.255000000000001, 24.405000000000001],
              [-15.225, 24.43],
              [-15.225, 24.440000000000001],
              [-15.19, 24.460000000000001],
              [-15.15, 24.5],
              [-15.015000000000001, 24.550000000000001],
              [-15, 24.57],
              [-14.99, 24.620000000000001],
              [-14.975, 24.640000000000001],
              [-14.950000000000001, 24.645],
              [-14.905000000000001, 24.685000000000002],
              [-14.865, 24.620000000000001],
              [-14.785, 24.555],
              [-14.665000000000001, 24.525000000000002],
              [-14.620000000000001, 24.5],
              [-14.609999999999999, 24.475000000000001],
              [-14.565, 24.435000000000002],
              [-14.505000000000001, 24.414999999999999],
              [-14.305, 24.475000000000001],
              [-14.075000000000001, 24.484999999999999],
              [-13.81, 24.43],
              [-13.69, 24.390000000000001],
              [-13.515000000000001, 24.435000000000002],
              [-13.295, 24.515000000000001],
              [-13.120000000000001, 24.525000000000002],
              [-12.92, 24.490000000000002],
              [-12.685, 24.405000000000001],
              [-12.58, 24.379999999999999],
              [-12.359999999999999, 24.420000000000002],
              [-12.32, 24.435000000000002],
              [-12.265000000000001, 24.440000000000001],
              [-12.115, 24.414999999999999],
              [-12, 24.385000000000002],
              [-12, 23.455000000000002],
              [-12.175000000000001, 23.41],
              [-12.345000000000001, 23.324999999999999],
              [-12.555, 23.295000000000002],
              [-12.675000000000001, 23.23],
              [-13, 23.025000000000002],
              [-13.105, 22.895],
              [-13.15, 22.760000000000002],
              [-13.08, 22.510000000000002],
              [-13, 21.34],
              [-16.955000000000002, 21.34],
              [-16.995000000000001, 21.125],
              [-17.010000000000002, 21.09],
              [-17.035, 21.059999999999999],
              [-17.039999999999999, 20.990000000000002],
              [-17.059999999999999, 20.955000000000002],
              [-17.07, 20.914999999999999],
              [-17.07, 20.855],
              [-17.050000000000001, 20.775000000000002],
              [-17.07, 20.795000000000002],
              [-17.080000000000002, 20.824999999999999],
              [-17.100000000000001, 20.830000000000002],
              [-17.105, 20.844999999999999],
            ],
          ],
          [
            [
              [-15.77, 23.900000000000002],
              [-15.77, 23.895],
              [-15.775, 23.895],
              [-15.775, 23.900000000000002],
              [-15.77, 23.900000000000002],
            ],
          ],
          [
            [
              [-15.74, 23.830000000000002],
              [-15.74, 23.82],
              [-15.745000000000001, 23.82],
              [-15.745000000000001, 23.830000000000002],
              [-15.74, 23.830000000000002],
            ],
          ],
          [
            [
              [-16.205000000000002, 23.085000000000001],
              [-16.205000000000002, 23.080000000000002],
              [-16.210000000000001, 23.080000000000002],
              [-16.205000000000002, 23.085000000000001],
            ],
          ],
          [
            [
              [-15.790000000000001, 23.734999999999999],
              [-15.795, 23.73],
              [-15.795, 23.734999999999999],
              [-15.790000000000001, 23.734999999999999],
            ],
          ],
          [
            [
              [-15.945, 23.66],
              [-15.94, 23.664999999999999],
              [-15.94, 23.66],
              [-15.950000000000001, 23.655000000000001],
              [-15.945, 23.66],
            ],
          ],
        ],
      },
    },
  ],
};

export { geojsonFeature };
