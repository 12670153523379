const LatLng = [29.7917, -8.0926];

const FilterObject = {
  codeName: "",
  codeNameToggler: false,
  region: "",
  regionToggler: false,
  address: "",
  addressToggler: false,
  ear: "",
  earToggler: false,
  rayon: 1,
  rayonToggler: false
};

const RayonObject = {
  rayon: 1,
  coordination: { lat: 29.7917, lng: -8.0926 },
  zoomLevel: 13.7,
  active: false,
};

export { LatLng, FilterObject, RayonObject };
