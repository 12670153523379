const regionPopupMarkup = (region, status) => {
  if (status) {
      return `
        <div class="popup-container">
            <table class="region-table">
                <tbody>
                    <tr>
                        <th class="popup popup-th">Region</th>
                        <th class="popup popup-th">Agents</th>
                        <th class="popup popup-th">Courtier</th>
                        <th class="popup popup-th">Population</th>
                        <th class="popup popup-th">Primes</th>
                        <th class="popup popup-th">Année</th>
                    </tr>
                    <tr>
                        <td class="popup popup-td">${region.region || "-"}</td>
                        <td class="popup popup-td">${region.agents || "-"}</td>
                        <td class="popup popup-td">${region.courtiers || "-"}</td>
                        <td class="popup popup-td">${region.population?.toLocaleString("fr-FR", { }) || "-"}</td>
                        <td class="popup popup-td">${region.primes?.toLocaleString("fr-FR", { }) || "-"}</td>
                        <td class="popup popup-td">${region.annee || "-"}</td>
                    </tr>
                </tbody>
            </table>
        </div>
      `;
  } else {
    return `<div class="region-container">${region}</div>`;
  }
};

export default regionPopupMarkup;
