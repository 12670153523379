import { Select, Tooltip } from "antd";

const FilterSelect = ({ Icon, ToggleFilter, FilterHandler, Title, placeholder, FieldToggler, FieldOptions }) => {
    return (
        <section className="filter-section">
            <span className="filter-icon address" onClick={ToggleFilter}>
                <img src={Icon} alt='' />
            </span>

            {FieldToggler && <Tooltip trigger={['focus']} title={Title} placement="topLeft">
                <Select
                    size="large"
                    placeholder={placeholder}
                    style={{ width: 340, marginLeft: 10 }}
                    allowClear
                    showSearch
                    onChange={FilterHandler}
                    options={FieldOptions}
                />
            </Tooltip>}
        </section>
    )
}

export default FilterSelect;