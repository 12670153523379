import { useState, useContext } from 'react';
import { message } from 'antd';
import axios from "axios";

import { FilterObject, RayonObject } from '../../../Utilities/States';
import { Provider } from "../../../contexts/FilterContext";
import RayonContext from '../../../contexts/RayonContext';
import types from "../../../Utilities/Types";

import FCodeName from '../Components/FCodeName';
import FAddress from '../Components/FAddress';
import FSearch from '../Components/FSearch';
import FRayon from '../Components/FRayon';
import FEar from '../Components/FEar';
import FReset from '../Components/FReset';
import FRegion from '../Components/FRegion';
import exceptionHandler from '../../../Utilities/ExceptionHandler';

const FilterContainer = ({ markers, markersHandler }) => {
    const [filter, setFilter] = useState(FilterObject);
    const { type, RayonHandler } = useContext(RayonContext);

    const props = {
        Filter: filter,
        SearchHandler: () => {
            if (
                (filter.codeName && filter.codeNameToggler)
                ||
                (filter.address && filter.addressToggler)
                ||
                (filter.region && filter.regionToggler)
                ||
                (filter.ear && filter.earToggler)
            ) {
                const body = {};
                if (filter.codeName && filter.codeNameToggler) body.codeName = filter.codeName;
                if (filter.address && filter.addressToggler) body.address = filter.address;
                if (filter.region && filter.regionToggler) body.region = filter.region;
                if (filter.ear && filter.earToggler) body.ear = filter.ear;

                axios
                    .post("/intermediates", body)
                    .then((response) => response.data)
                    .then((response) => {
                        RayonHandler(RayonObject);
                        if (response.type === types.Intern || response.type === types.Company) {
                            markersHandler(response.intermediatesIntern);
                        } else {
                            markersHandler(response.intermediatesExtern);
                        }
                    }).catch((exception) => {
                        RayonHandler(RayonObject);
                        markersHandler([]);
                        exceptionHandler(exception);
                    });
            } else {
                message.info("Veuillez vous assurer qu'au moins un des champs est rempli afin d'appliquer un filtre.");
            }
        },
        FilterHandler: (filter) => {
            setFilter(filter);
        },
        ResetHandler: () => {
            setFilter(FilterObject);
            markersHandler(markers);
        }
    }

    return (
        <Provider value={{ ...props }}>
            <div className='filter-container'>
                <FSearch />
                <FRayon />
                <FCodeName />
                <FRegion />
                <FAddress />
                {type === types.Company ? <></> : <FEar />}
                <FReset />
            </div>
        </Provider>
    );
};

export default FilterContainer;
