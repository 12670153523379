const Spinner = () => {
    return (
        <div className="loader-container">
            <div className="loader">
                <svg x="0px" y="0px" width="188px" height="88px" viewBox="0 0 24 30" style={{ enableBackground: "new 0 0 50 50" }}>
                    <rect x="0" y="13" width="2.5" height="5" fill="#2a65f3">
                        <animate attributeName="height" values="5;21;5" begin="0s" dur="1s" repeatCount="indefinite" />
                        <animate attributeName="y" values="13; 5; 13" begin="0s" dur="1s" repeatCount="indefinite" />
                    </rect>
                    <rect x="10" y="13" width="2.5" height="5" fill="#2a65f3">
                        <animate attributeName="height" values="5;21;5" begin="0.2s" dur="1s" repeatCount="indefinite" />
                        <animate attributeName="y" values="13; 5; 13" begin="0.2s" dur="1s" repeatCount="indefinite" />
                    </rect>
                    <rect x="20" y="13" width="2.5" height="5" fill="#2a65f3">
                        <animate attributeName="height" values="5;21;5" begin="0.4s" dur="1s" repeatCount="indefinite" />
                        <animate attributeName="y" values="13; 5; 13" begin="0.4s" dur="1s" repeatCount="indefinite" />
                    </rect>
                    <rect x="30" y="13" width="2.5" height="5" fill="#2a65f3">
                        <animate attributeName="height" values="5;21;5" begin="0.6s" dur="1s" repeatCount="indefinite" />
                        <animate attributeName="y" values="13; 5; 13" begin="0.6s" dur="1s" repeatCount="indefinite" />
                    </rect>
                    <rect x="40" y="13" width="2.5" height="5" fill="#2a65f3">
                        <animate attributeName="height" values="5;21;5" begin="0.8s" dur="1s" repeatCount="indefinite" />
                        <animate attributeName="y" values="13; 5; 13" begin="0.8s" dur="1s" repeatCount="indefinite" />
                    </rect>
                </svg>
            </div>
        </div>
    );
};

export default Spinner;
