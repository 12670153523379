import types from "../Utilities/Types";

const markerMarkup = (marker, type) => {
  let markup = `
    <div class="popup-container">
      <table class="marker-table">
          <tbody>
              <tr>
                  <th class="popup popup-th">Code</th>
                  <th class="popup popup-th">Nom</th>
                  <th class="popup popup-th">Qualité</th>
                  <th class="popup popup-th">Mandante</th>
                  <th class="popup popup-th">Adresse</th>
                  <th class="popup popup-th">Localité</th>
              </tr>
              <tr>
                  <td class="popup popup-td">${marker.code || "-"}</td>
                  <td class="popup popup-td">${marker.nom || "-"}</td>
                  <td class="popup popup-td">${marker.qualite || "-"}</td>
                  <td class="popup popup-td">${marker.ear || "-"}</td>
                  <td class="popup popup-td">${marker.adresse || "-"}</td>
                  <td class="popup popup-td">${marker.localite || "-"}</td>
              </tr>
          </tbody>
      </table>
      <div class="localization">
          <div class="row">
              <div class="inner-col">
                  <div class="longitude icon"></div>
                  <div class="cord">Longitude</div>
                  <div class="value">${marker.longitude}</div>
              </div>
              <div class="inner-col">
                  <div class="latitude icon"></div>
                  <div class="cord">Latitude</div>
                  <div class="value">${marker.latitude}</div>
              </div>
              ${
                type === types.Intern || type === types.Company
                  ? `<div class="inner-col">
                        <div class="production icon"></div>
                        <div class="cord">Exercice Production</div>
                        <div class="value">${marker.year || "-"}</div>
                    </div>
                    <div class="inner-col">
                        <div class="production icon"></div>
                        <div class="cord">Production</div>
                        <div class="value">${marker.production?.toLocaleString("fr-FR", { }) || "-"}</div>
                    </div>`
                  : ""
              }
          </div>
      </div>
    `;

  if (type === types.Intern || type === types.Company) {
    markup += `
        <section class="production-trimestres">
            <div class="trimestres trimestres-one active">Trimestre 1</div>
            <div class="trimestres trimestres-two">Trimestre 2</div>
            <div class="trimestres trimestres-three">Trimestre 3</div>
            <div class="trimestres trimestres-four">Trimestre 4</div>
        </section>
        <table class="branches-table">
            <thead class='branches-table-head'>
                <tr class='branches-table-row'>
                    <th>Branches</th>
                    <th>Production</th>
                </tr>
            </thead>
            <tbody class='branches-table-body' id='${marker.code}'>
                <tr>
                    <td colspan="2">
                        <div class="loader branches-loader">
                            <svg x="0px" y="0px" width="88px" height="44px" viewBox="0 0 24 30" style={{ enableBackground: "new 0 0 50 50" }}>
                                <rect x="0" y="7" width="1.5" height="3" fill="#2a65f3">
                                    <animate attributeName="height" values="3;10;3" begin="0s" dur="1s" repeatCount="indefinite" />
                                    <animate attributeName="y" values="7; 5; 7" begin="0s" dur="1s" repeatCount="indefinite" />
                                </rect>
                                <rect x="5" y="7" width="1.5" height="3" fill="#2a65f3">
                                    <animate attributeName="height" values="3;10;3" begin="0.2s" dur="1s" repeatCount="indefinite" />
                                    <animate attributeName="y" values="7; 5; 7" begin="0.2s" dur="1s" repeatCount="indefinite" />
                                </rect>
                                <rect x="10" y="7" width="1.5" height="3" fill="#2a65f3">
                                    <animate attributeName="height" values="3;10;3" begin="0.4s" dur="1s" repeatCount="indefinite" />
                                    <animate attributeName="y" values="7; 5; 7" begin="0.4s" dur="1s" repeatCount="indefinite" />
                                </rect>
                                <rect x="15" y="7" width="1.5" height="3" fill="#2a65f3">
                                    <animate attributeName="height" values="3;10;3" begin="0.6s" dur="1s" repeatCount="indefinite" />
                                    <animate attributeName="y" values="7; 5; 7" begin="0.6s" dur="1s" repeatCount="indefinite" />
                                </rect>
                                <rect x="20" y="7" width="1.5" height="3" fill="#2a65f3">
                                    <animate attributeName="height" values="3;10;3" begin="0.8s" dur="1s" repeatCount="indefinite" />
                                    <animate attributeName="y" values="7; 5; 7" begin="0.8s" dur="1s" repeatCount="indefinite" />
                                </rect>
                            </svg>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    `;
  }

  markup += "</div>";

  return markup;
};

export default markerMarkup;
