import { useEffect, useState } from "react";
import axios from "axios";
import Map from "./components/Map";
import Spinner from "./components/Spinner";
import Filter from "./components/Filter/Container";
import { TrimestreToggler } from "./Utilities/TrimestreToggler";
import exceptionHandler from "./Utilities/ExceptionHandler";
import { Provider } from "./contexts/RayonContext";
import GetLocation from "./Utilities/GetLocation";
import { RayonObject } from "./Utilities/States";
import Interceptor from "./Interceptor";
import types from "./Utilities/Types";


import "leaflet/dist/leaflet.css";

const App = () => {
  const [rayon, setRayon] = useState(RayonObject);
  const [markers, setMarkers] = useState([]);
  const [source, setSource] = useState([]);
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(true);

  const locationHandler = (position) => {
    const { latitude, longitude } = position.coords;
    
    setRayon({
        ...rayon,
        coordination: {
          lat: latitude,
          lng: longitude
        }
    })
  }

  useEffect(() => {
    TrimestreToggler();
    GetLocation(locationHandler);

    axios
      .get("/intermediates")
      .then((response) => response.data)
      .then((response) => {
        if (response.type === types.Intern || response.type === types.Company) {
          setMarkers(response.intermediatesIntern);
          setSource(response.intermediatesIntern);
        } else {
          setMarkers(response.intermediatesExtern);
          setSource(response.intermediatesExtern);
        }

        setType(response.type);
        setLoading(false);
      })
      .catch((exception) => {
        setMarkers([]);
        setSource([]);
        setType("");
        setLoading(false);

        exceptionHandler(exception);
      });
  }, []);

  const markersHandler = (markers) => {
    setMarkers(markers);
  };

  const RayonHandler = (rayon) => {
    setRayon(rayon);
  };

  return loading ? (
    <Spinner />
  ) : (
    <Provider value={{ Rayon: rayon, RayonHandler, type }}>
      <>
        <Map markers={markers} />
        <Filter markers={source} markersHandler={markersHandler} />
      </>
    </Provider>
  );
};

export default App;
