import axios from 'axios';
import exceptionHandler from './ExceptionHandler';

const GetBranchesMarkup = async (marker) => {
    let markup = '';
    await axios.get(`/branches?code=${marker.code}`)
        .then(response => response.data)
        .then(response => {
            if (response && response.length > 0) {
                const GetBranch = (branch) => {
                    const branchAttributesHolder = response
                        .filter(x => x.branche === branch)
                        .map(x => `data-t${x.trimestre}="${x.valeur.toLocaleString("fr-FR", { })}"`)
                        .join(' ');

                    let branchT1 = response.find((x) => x.branche === branch && x.trimestre === 1);
                    branchT1 = branchT1?.valeur.toLocaleString("fr-FR", { }) || '-';

                    return { branchAttributesHolder, branchT1 };
                }

                const { branchAttributesHolder: at, branchT1: at_t1 } = GetBranch("AT"),
                    { branchAttributesHolder: incendie, branchT1: incendie_t1 } = GetBranch("Incendie"),
                    { branchAttributesHolder: automobile, branchT1: automobile_t1 } = GetBranch("Automobile"),
                    { branchAttributesHolder: assistance, branchT1: assistance_t1 } = GetBranch("Assistance"),
                    { branchAttributesHolder: transport, branchT1: transport_t1 } = GetBranch("Maritime-transport"),
                    { branchAttributesHolder: categories, branchT1: categories_t1 } = GetBranch("Autres Catégories"),
                    { branchAttributesHolder: corporels, branchT1: corporels_t1 } = GetBranch("Accidents corporels");

                markup = `
                    <tr class='branches-table-row'>
                        <td>AT</td>
                        <td class='production-td at' ${at}>${at_t1}</td>
                    </tr>
                    <tr class='branches-table-row'>
                        <td>Incendie</td>
                        <td class='production-td incendie' ${incendie}>${incendie_t1}</td>
                    </tr>
                    <tr class='branches-table-row'>
                        <td>Accidents Corporels</td>
                        <td class="production-td corporels" ${corporels}>${corporels_t1}</td>
                    </tr>
                    <tr class='branches-table-row'>
                        <td>Assistance</td>
                        <td class='production-td assistance' ${assistance}>${assistance_t1}</td>
                    </tr>
                    <tr class='branches-table-row'>
                        <td>Automibile</td>
                        <td class='production-td automibile' ${automobile}>${automobile_t1}</td>
                    </tr>
                    <tr class='branches-table-row'>
                        <td>Autre Categories</td>
                        <td class='production-td categories' ${categories}>${categories_t1}</td>
                    </tr>
                    <tr class='branches-table-row'>
                        <td>Vie et Capitalisation</td>
                        <td class='production-td capitalisation' ${transport}>${transport_t1}</td>
                    </tr>
                    <tr class='branches-table-row branches-total'>
                        <td>Total</td>
                        <td class='production-td total' data-t1="${marker.t1?.toLocaleString("fr-FR", { }) || '-'}" data-t2="${marker.t2?.toLocaleString("fr-FR", { }) || '-'}" data-t3="${marker.t3?.toLocaleString("fr-FR", { }) || '-'}" data-t4="${marker.t4?.toLocaleString("fr-FR", { }) || '-'}">${marker.t1?.toLocaleString("fr-FR", { }) || '-'}</td>
                    </tr>
                `;
            } else {
                markup = `
                    <tr class='branches-table-row'>
                        <td>AT</td>
                        <td class='production-td at' data-t1="-" data-t2="-" data-t3="-" data-t4="-">-</td>
                    </tr>
                    <tr class='branches-table-row'>
                        <td>Incendie</td>
                        <td class='production-td incendie' data-t1="-" data-t2="-" data-t3="-" data-t4="-">-</td>
                    </tr>
                    <tr class='branches-table-row'>
                        <td>Accidents Corporels</td>
                        <td class="production-td corporels" data-t1="-" data-t2="-" data-t3="-" data-t4="-">-</td>
                    </tr>
                    <tr class='branches-table-row'>
                        <td>Assistance</td>
                        <td class='production-td assistance' data-t1="-" data-t2="-" data-t3="-" data-t4="-">-</td>
                    </tr>
                    <tr class='branches-table-row'>
                        <td>Automibile</td>
                        <td class='production-td automibile' data-t1="-" data-t2="-" data-t3="-" data-t4="-">-</td>
                    </tr>
                    <tr class='branches-table-row'>
                        <td>Autre Categories</td>
                        <td class='production-td categories' data-t1="-" data-t2="-" data-t3="-" data-t4="-">-</td>
                    </tr>
                    <tr class='branches-table-row'>
                        <td>Vie et Capitalisation</td>
                        <td class='production-td capitalisation' data-t1="-" data-t2="-" data-t3="-" data-t4="-">-</td>
                    </tr>
                    <tr class='branches-table-row branches-total'>
                        <td>Total</td>
                        <td class='production-td total' data-t1="-" data-t2="-" data-t3="-" data-t4="-">-</td>
                    </tr>
                `;
            }
        })
        .catch(exception => {
            exceptionHandler(exception)
        });

    return markup;
}

export default GetBranchesMarkup;