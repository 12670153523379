import { Input, Tooltip, message } from "antd";

const FilterInput = ({ Icon, ToggleFilter, Filter, FilterHandler, Title, placeholder, FieldToggler, Rayon, RayonHandler }) => {
    return (
        <section className="filter-section">
            <span className="filter-icon address" onClick={ToggleFilter}>
                <img src={Icon} alt='' />
            </span>

            {FieldToggler && <Tooltip trigger={['focus']} title={Title} placement="topLeft">
                <div style={{ display: 'flex', marginLeft: 10 }}>
                    <Input
                        size="large"
                        placeholder={placeholder}
                        style={{ width: 340 }}
                        allowClear
                        type="number"
                        onChange={FilterHandler}
                    />
                    <button className='rayon-button' onClick={() => {
                        if (Filter.rayon === 0 || typeof Filter.rayon !== 'number' || Filter.rayon < 0) {
                            message.info('Please specify a correct value for the "Rayon" field.');
                        } else {
                            if (Filter.rayon < 10) {
                                RayonHandler({ ...Rayon, rayon: Filter.rayon, zoomLevel: 12.8, active: true });
                            } else if (Filter.rayon < 20) {
                                RayonHandler({ ...Rayon, rayon: Filter.rayon, zoomLevel: 10.8, active: true });
                            } else {
                                RayonHandler({ ...Rayon, rayon: Filter.rayon, zoomLevel: 8.8, active: true });
                            }
                        }
                    }}><img src={Icon} alt='Rayon' />
                    </button>
                </div>
            </Tooltip>}
        </section>
    )
}

export default FilterInput;