import { useContext, useEffect, useState } from 'react';
import axios from 'axios';

import Context from '../../../contexts/FilterContext';
import FilterSelect from './Fields/FilterSelect';
import Icon from '../../../assets/Icons/acaps.png';
import exceptionHandler from "../../../Utilities/ExceptionHandler";

const FCodeName = () => {
    const { Filter, FilterHandler } = useContext(Context);
    const [codesNames, setCodesNames] = useState([]);

    useEffect(() => {
        axios
            .get("/intermediates/codes-names")
            .then((response) => response.data)
            .then((response) => {
                const options = response.map(x => {
                    return {
                        label: x.replace(/"/g, '').replace(/\\n/g, '').replace(/\\r/g, '').replace(/\\/g, ''),
                        value: x
                    };
                });

                setCodesNames(options);
            }).catch((exception) => exceptionHandler(exception));
    }, []);

    const Props = {
        Icon: Icon,
        Title: 'Code ACAPS',
        placeholder: "Recherche par code ACAPS...",
        FieldOptions: codesNames,
        FieldToggler: Filter.codeNameToggler,
        ToggleFilter: () => {
            FilterHandler({
                ...Filter,
                codeName: '',
                codeNameToggler: !Filter.codeNameToggler
            });
        },
        FilterHandler: (value) => {
            FilterHandler({
                ...Filter,
                codeName: value
            });
        }
    };

    return (
        <FilterSelect {...Props} />
    );
};

export default FCodeName;