const GetCookie = (cookieName) => {
  const cookiesMap = document.cookie
    .split(";")
    .map((cookieString) => {
      return cookieString.trim().split("=");
    })
    .reduce((cookiesList, current) => {
      cookiesList[current[0]] = current[1];
      return cookiesList;
    }, {});

  return cookiesMap[cookieName];
};

export default GetCookie;
